import styled from "styled-components";
import { COLORS, SIZE, FONT } from "../../../../config";

export const PasswordDetails = styled.div`
  width: 100%;
  height: calc(100% - ${130 + SIZE.LARGE}px);
  margin: 0;
  padding-top: 130px;
  overflow: scroll;
  padding-bottom: ${SIZE.LARGE}px;
`;
export const Relative = styled.div`
  position: relative;
  width: 100%;
`;
export const ShowPassword = styled.div`
  position: absolute;
  right: 0px;
  top: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  padding: 4px;
  width: 50px;
  height: 32px;
  cursor: pointer;
`;
export const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${SIZE.LARGE}px 0 ${SIZE.MEDIUM}px;
  height: 40px;
  svg {
    color: black;
  }
`;

export const Footer = styled.div`
  height: 100px;
  width: 100%;
`;
export const Link = styled.div`
  font-size: ${FONT.SIZE.SMALL};
  span {
    padding: 0 0 ${SIZE.TINY}px 2px;
  }
  a:visited,
  a:link,
  a:active {
    text-decoration: none;
    color: ${COLORS.BLACK};
    padding: 0 0 2px;
    border-bottom: 1px solid ${COLORS.BLACK};
  }
  a:hover {
    text-decoration: none;
    color: ${COLORS.GREY_LIGHT};
    padding: 0 0 ${SIZE.TINY}px;
    border-bottom: 1px solid ${COLORS.GREY_LIGHT};
  }
`;


export const FakeReadOnlyInput = styled.p`
	display:block;
	width: calc(100% - ${SIZE.LARGE}px);
	padding: ${SIZE.MEDIUM}px;
	background: ${COLORS.GREY_DARK};
	border: 1px solid ${COLORS.WHITE};
	border-radius: ${SIZE.BORDER_RADIUS}px;
	font-size: ${FONT.SIZE.BODY};
	outline: none;
  min-height: 18px;

	&:focus {
		background: ${COLORS.WHITE} !important;
		border: 1px solid ${COLORS.BLACK} !important;
	}
`;


export const Label = styled.div`
	width: 100%;
	padding: ${SIZE.SMALL}px 0;
	margin: ${SIZE.SMALL}px 0 0;
	font-size: ${FONT.SIZE.BODY};
`;
