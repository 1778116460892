import styled from "styled-components";
import { COLORS, SIZE, FONT } from "../../config";

interface iProps {}

export const Skip = styled.div`
  display: flex;
  flex: 1;
  justify-self: flex-end;
  align-items: flex-end;
  outline: none;
  align-self: flex-end;
  justify-self: flex-end;
  color: ${COLORS.BLACK};
  bottom: 42px;
  cursor: pointer;
  margin-top: ${SIZE.SMALL}px;

  p {
    padding: 0 0 2px;
    margin: 0;
    border-bottom: 1px solid ${COLORS.BLACK};
    transition: color .2s ease;
  }

  span {
    padding: 0 ${SIZE.TINY}px ${SIZE.TINY}px;
  }

  &:hover {
    p {
      color: ${COLORS.GREY_MID_LIGHT};
      border-bottom: 1px solid ${COLORS.GREY_MID};
    }
  }
`;
