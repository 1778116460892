import { FormattedMessage } from "react-intl";
import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

export interface ITab {
  name: string;
  title: string;
  path: string;
}

interface ITabNavbarProps {
  name: string;
  tabs: ITab[];
  onChange: (path: string) => void;
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: "100%",
      boxShadow: "none",
      backgroundColor: theme.palette.background.paper,
      fontSize: "0.6rem"
    }
  })
);

export default function TabNavbar(props: ITabNavbarProps) {
  const classes = useStyles();
  const { name, tabs } = props;

  function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
    props.onChange(tabs[newValue].path);
  }

  const tabIndex = tabs.findIndex(t => t.name === name);

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        color="default"
        style={{
          boxShadow: "none",
          borderBottom: "1px solid",
          backgroundColor: "#FFFFFF"
        }}
      >
        <Tabs
          value={tabIndex > -1 ? tabIndex : 0}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {tabs.map(tab => (
            <Tab
              label={
                <FormattedMessage
                  id={`tab.${tab.name}`}
                  defaultMessage={tab.name}
                />
              }
              {...a11yProps(0)}
              key={tab.name}
              style={{ fontSize: "0.6rem" }}
            />
          ))}
          {}
        </Tabs>
      </AppBar>
    </div>
  );
}
