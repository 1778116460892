import styled from "styled-components";
import { COLORS, SIZE, FONT } from "../../config";

interface iPanel {
	active: boolean;
}

interface iLiked {
	amount: number;
}

export const AppointmentPanel = styled.div`
	position: fixed;
	top: 65px;
	right: 0px;
	width: calc(100% - ${SIZE.MEDIUM}px);
	max-width: 640px;
	height: calc(100vh - 65px);
	padding: ${SIZE.MEDIUM}px ${SIZE.MEDIUM}px ${SIZE.LARGE}px;
	background: ${COLORS.WHITE};
	color: ${COLORS.BLACK};
	border-left: 1px solid ${COLORS.BLACK};
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	overflow-x: hidden;

	h1 {
		margin-top: 0;
	}

	&::-webkit-scrollbar {
		width: 4px;
		background-color: #f5f5f5;
	}
	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
		background-color: #f5f5f5;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #000000;
		border: 2px solid #555555;
	}

	transform: ${(props: iPanel) =>
		props.active ? `translateX(0px)` : `translateX(640px)`};
	transition: transform .25s ease;

	.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
		padding: 4px;
	}
`;

export const Sub = styled.p`
	padding: 0;
	margin: -${SIZE.MEDIUM}px 0 ${SIZE.LARGE}px;
	font-family: piiqTypeSerif;
`;

export const Title = styled.p`
	padding: 0;
	margin: ${SIZE.LARGE}px 0 ${SIZE.SMALL}px;
	font-weight: 400;
	letter-spacing: 0.27px;
`;

export const Portrait = styled.div`
	border-radius: ${SIZE.BORDER_RADIUS}px;
	width: 100%;
	height: 515px;
	background: ${COLORS.BLACK};
	overflow: hidden;
	position: relative;
	.grad {
		background-image: linear-gradient(
			180deg,
			#000000 0%,
			rgba(0, 0, 0, 0) 100%
		);
		opacity: 0.6;
		width: 100%;
		height: 61px;
		position: absolute;
		top: 0;
		left: 0;
	}
	.img-label {
		position: absolute;
		top: ${SIZE.MEDIUM}px;
		left: ${SIZE.MEDIUM}px;
		color: ${COLORS.WHITE};
	}
`;

export const NoImage = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: ${SIZE.BORDER_RADIUS}px;
	width: 100%;
	min-height: 515px;
	background: ${COLORS.BLACK};
	color: ${COLORS.WHITE};
	svg {
		width: 60px;
		height: 60px;
		margin-bottom: ${SIZE.LARGE}px;
		fill: ${COLORS.WHITE};
	}
`;
export const Divider = styled.div`height: ${SIZE.MEDIUM}px;`;

export const Empty = styled.div`
	border-radius: ${SIZE.BORDER_RADIUS}px;
	border: 1px solid ${COLORS.GREY_MID_LIGHT};
	margin: ${SIZE.MEDIUM}px 0 0;
	padding: ${SIZE.LARGE}px ${SIZE.MEDIUM}px;
	text-align: center;
`;

export const Concern = styled.div`
	border: 1px solid ${COLORS.BLACK};
	border-radius: ${SIZE.SMALL}px;
	padding: ${SIZE.TINY}px ${SIZE.MEDIUM}px;
	display: inline-flex;
	margin-right: ${SIZE.TINY}px;
	margin-bottom: ${SIZE.TINY}px;
	text-align: center;
	font-size: ${FONT.SIZE.SMALL};
`;


export const Product = styled.div`
	border: 1px solid ${COLORS.BLACK};
	border-radius: ${SIZE.SMALL}px;
	padding: ${SIZE.TINY}px ${SIZE.MEDIUM}px;
	display: inline-flex;
	margin-right: ${SIZE.TINY}px;
	margin-bottom: ${SIZE.TINY}px;
	text-align: center;
	font-size: ${FONT.SIZE.SMALL};
`;


export const Board = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
`;
export const LikedImage = styled.div`
	width: ${(props: iLiked) =>
		props.amount === 1 ? "100%" : props.amount === 2 ? "48%" : "96px"};
	height: ${(props: iLiked) =>
		props.amount === 1 ? "480px" : props.amount === 2 ? "240px" : "128px"};
	margin-bottom: ${SIZE.SMALL}px;
	border-radius: ${SIZE.BORDER_RADIUS}px;
	overflow: hidden;
	img {
		width: 100%;
	}
`;


export const Back = styled.button`
	font-size: ${FONT.SIZE.SMALL};
	border: 0;
	outline: none;
	padding: 0 0 2px;
	border-bottom: 1px solid ${COLORS.BLACK};
	background: ${COLORS.WHITE};
	cursor: pointer;

	svg {
		fill: ${COLORS.BLACK};
		stroke: ${COLORS.BLACK};
		width: 14px;
		height: 12px;
		top: 2px;
		left: -14px;
		position: absolute;
	}
	span {
		color: ${COLORS.BLACK};
		padding: 0 0 ${SIZE.TINY}px 2px;
	}
	&:hover {
		border-bottom: 1px solid ${COLORS.GREY_MID_BASE};
	}
`;