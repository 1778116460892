import React, { useState, useEffect } from "react";
import { injectStripe } from "react-stripe-elements";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormattedMessage } from "react-intl";
import moment from "moment-timezone";
import { useSalons, useRegistration } from "../../contexts/AdminContext";
import { SubscriptionState } from "../../contexts/SubscriptionContext";
import { startRegisterPayment } from "../../actions/registrationActions";
import { IPaymentRegister } from "../../reducers/RegistrationReducer";
import { InputPaymentCard, Input, Button, Checkbox } from "../";
import { AmplifyConfig, 
	INewContactDealAccount,
	ZapierFreshworksNewContactDealAccount, newStripeCustomer} from "../../utils";
import { Plan } from "../../screens/Registration/types";
import GrowSumo from 'react-growsumojs';

import {
	Checkout,
	Error,
	Loader,
	CheckboxRow,
	CardNameInput,
	PrePayNotice,
	Footer
} from "./styles";

interface IProps {
	children?: React.ReactNode;
	stripe?: any;
	elements?: any;
	onSubmit: Function;
	supplierCode: string;
}

const CheckoutForm = (props: IProps) => {
	const context = SubscriptionState();
	const [ state, dispatch ] = useSalons();
	const [ stateRegistration, dispatchRegistration ] = useRegistration();
	const [ orderDetails, setOrderDetails ] = useState(
		context.state.orderDetails
	);
	const [ accountDetails ] = useState(context.state.accountDetails);
	const [ deliveryDetails ] = useState(context.state.deliveryDetails);
	const [ cardName, setCardName ] = useState(
		`${context.state.accountDetails.first_name} ${context.state.accountDetails
			.last_name}`
	);
	const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState("");
	const [ productNews, setProductNews ] = useState(
		context.state.accountDetails.news_opt_in
	);
	const [ termsAccept, setTermsAccpet ] = useState(false);
	const getCurrentDate = (separator='/') => {

		let newDate = new Date()
		let date = newDate.getDate();
		let month = newDate.getMonth() + 1;
		let year = newDate.getFullYear();

		return `${date}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}`
	}
	const newCDA:INewContactDealAccount = {
		firstName: context.state.accountDetails.first_name,
		lastName: context.state.accountDetails.last_name,
		mobileNumber: context.state.accountDetails.phone,
		email: context.state.accountDetails.email,
		address: deliveryDetails.address_line_1 + " " + deliveryDetails.address_line_2,
		suburb: deliveryDetails.city,
		state: deliveryDetails.state,
		notes: deliveryDetails.notes,
		postcode: deliveryDetails.postcode,
		country: deliveryDetails.country,
		salonName: context.state.accountDetails.salon,
		SYSAccountNumber: context.state.accountDetails.loreal_account_number,
		dealAmount: String(context.state.orderDetails.total),
		colourBrand: String(context.state.orderDetails.colour_brand),
		mirrorQuantity: String(context.state.orderDetails.mirror_quantity),
		discount: String(context.state.orderDetails.discount),
		promo: String(context.state.orderDetails.supplier_code),
		subscriptionType: String(context.state.orderDetails.subscription_type),
		subscribedDate: String(getCurrentDate())
	}

	useEffect(() => {
		/* Use API_KEY for auth appose to the USER_POOL */
		if (process.env.REACT_APP_ENV === "PROD") AmplifyConfig("API-PROD");
		else AmplifyConfig("API-STAGING");
		GrowSumo.initialize("pk_kTW4WIeY2AIabxeV3w6LNvczqMDEzfwI");
	}, []);

	const handleSubmit = async (ev: { preventDefault: () => void }) => {
		setLoading(true);
		setError("");

		// We don't want to let default form submission happen here, which would refresh the page.
		ev.preventDefault();

		const cardElement = props.elements.getElement("card");

		const billing_details = {
			name: cardName,
			email: accountDetails.email.toLowerCase(),
			phone: accountDetails.phone,
			address: {
				city: deliveryDetails.city,
				country: deliveryDetails.country,
				line1: deliveryDetails.address_line_1,
				line2: deliveryDetails.address_line_2,
				postal_code: deliveryDetails.postcode,
				state: deliveryDetails.state
			}
		};

		/* Test Data */
		// const debugUser = "test101@piiqdigital.com";
		// const billing_details = {
		//   name: "Josh Freeman",
		//   email: debugUser,
		//   phone: "0478196213",
		//   address: {
		//     city: "Mullumbimby",
		//     country: "AU",
		//     line1: "59",
		//     line2: "Tuckeroo Avenue",
		//     postal_code: "2481",
		//     state: "NSW"
		//   }
		// };

		// From here we cal call createPaymentMethod to create a PaymentMethod
		// https://stripe.com/docs/stripe-js/reference#stripe-create-payment-method
		
		try {
			const {
				paymentMethod,
				error
			} = await props.stripe.createPaymentMethod("card", cardElement, {
				billing_details: billing_details
			});

			if (error) {
				alert(error);
				console.log(error);
				if (error.param === "billing_details[name]") {
					const NameError = 'Please enter your name in the field above';
					setError(NameError);
					
				} else {
					alert(error.message);
					setError(error.message);
				}
				setLoading(false);
			} else {
				console.log("paymentMethod", paymentMethod);
				// TODO: SEND THE paymentMethod.id to the PIIQ API
				let _subscriptionType = orderDetails.subscription_type === 'THREEMTHUPFRONT' ? 'ANNUAL' : orderDetails.subscription_type
				const params: IPaymentRegister = {
					benchMounts: orderDetails.mirror_quantity,
					wallMounts: 0,
					supplierCode: props.supplierCode || orderDetails.supplier_code,
					colourBrand: orderDetails.colour_brand,
					mirrors: orderDetails.mirror_quantity,
					discount: orderDetails.discount,
					shipping: orderDetails.shipping,
					total: orderDetails.total,
					plan: _subscriptionType,
					deliveryOption: deliveryDetails.deliveryOption,
					postcode: deliveryDetails.postcode,
					addressLine1: deliveryDetails.address_line_1,
					addressLine2: deliveryDetails.address_line_2,
					lorealAccountNumber: accountDetails.loreal_account_number,
					city: deliveryDetails.city,
					country: deliveryDetails.country,
					state: deliveryDetails.state,
					notes: deliveryDetails.notes,
					email: accountDetails.email.toLowerCase(),
					phone: accountDetails.phone,
					firstName: accountDetails.first_name,
					lastName: accountDetails.last_name,
					name: accountDetails.salon, // SALON NAME
					newsOptIn: accountDetails.news_opt_in,
					locale: "en_AU",
					timeZone: moment.tz.guess(true),
					paymentMethod: paymentMethod.id
				};

				// const params: IPaymentRegister = {
				//   benchMounts: 2,
				//   wallMounts: 3,
				//   supplierCode: "2123",
				//   colourBrand: "Redken",
				//   mirrors: 5,
				//   discount: 0,
				//   shipping: 0,
				//   total: 0,
				//   postcode: "2423",
				//   plan: "MONTHLY",
				//   deliveryOption: "STANDARD",
				//   addressLine1: "test",
				//   addressLine2: "test line 2",
				//   city: "sydney",
				//   country: "AU",
				//   state: "NSW",
				//   notes: "not much",
				//   email: debugUser,
				//   phone: "123456",
				//   firstName: "test 1",
				//   lastName: "123",
				//   name: "Salonz", // SALON NAME
				//   newsOptIn: true,
				//   locale: "en_AU",
				//   timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				//   paymentMethod: paymentMethod.id
				// };

				console.log("TIMEZONE", moment.tz.guess(true));

				const salonDetails = await dispatchRegistration(
					startRegisterPayment(params)
				);
				/* If the API registration fails display why */
				//@ts-ignore
				if (salonDetails.errors) {
					console.log(salonDetails);
					//@ts-ignore
					setError(salonDetails.errors[0].message);
					setLoading(false);
					return false;
				} else {
					await context.dispatch({
						type: "SET_REGISTER_PAYMENT",
						paymentDetails: salonDetails
					});
					setLoading(false);

					ZapierFreshworksNewContactDealAccount(newCDA);
					newStripeCustomer(params);
				
					const customerData = {
						name: params.name,
						email: params.email,
						customerKey: params.email
					};
					
					GrowSumo.setUserInfo(customerData);
					GrowSumo.createSignup(() => {
						console.log('create signup was called successfully')
					});

					/* Payment complete and User should be registered, switch back to User Pool Auth */
					if (process.env.REACT_APP_ENV === "PROD") AmplifyConfig("PROD");
					else AmplifyConfig("STAGING");

					props.onSubmit();
				}
			}
		} catch (e) {
			setError(e.message);
			setLoading(false);
		}
	};

	const onNameChange = (value: string) => {
		setCardName(value);
	};

	const onOptInChange = async () => {
		await context.dispatch({
			type: "UPDATE_ACCOUNT_DETAILS",
			accountDetails: {
				...context.state.accountDetails,
				news_opt_in: !productNews
			}
		});
		setProductNews(!productNews);
	};

	return (
		<Checkout>
			<div>
				{context.state.orderDetails.plan == Plan.PLUS && (
					<div className="margin-bottom-mid">
						<FormattedMessage id="signup.payment.body" />
					</div>)}
				<div style={{background: "#f5FFFA", padding: 24, border: "1px solid green", borderRadius: 4}}>
				<h3>piiq Pro Plus {orderDetails.subscription_type}</h3>
				<h3>{orderDetails.mirror_quantity} x mirror(s)</h3>
				<h3>Total ${orderDetails.total} inc GST</h3>
				</div>
			</div>
			<form onSubmit={handleSubmit}>
				<CardNameInput>
					<Input
						name="card_name"
						onChange={onNameChange}
						placeholder={"Card Holders Name"}
						value={cardName}
						label="Name on Card"
					/>
				</CardNameInput>
				<InputPaymentCard />
				<Error>{error && error}</Error>
				<PrePayNotice>
					<FormattedMessage id="signup.payment.charge" />
				</PrePayNotice>
				
				{/* <CheckboxRow>
					<div className="tick-input" onClick={() => onOptInChange()}>
						<Checkbox
							name="terms_accept"
							checked={productNews}
							onChange={onOptInChange}
						/>
					</div>
					<div>
						<FormattedMessage id="signup.payment.optin" />
					</div>
				</CheckboxRow> */}
				<CheckboxRow>
					<div
						className="tick-input"
						onClick={() => setTermsAccpet(!termsAccept)}>
						<Checkbox name="terms_accept" checked={termsAccept} />
					</div>
					<div>
						I accept the{" "}
						<a href="https://www.piiqdigital.com/terms-of-sale" target="_blank">
							Terms of Sale
						</a>{" "}
						and I have read the{" "}
						<a
							href="https://www.piiqdigital.com/privacy-policy"
							target="_blank">
							Privacy Policy
						</a>.
					</div>
				</CheckboxRow>

				{loading ? (
					<Loader>
						<CircularProgress />
					</Loader>
				) : (
					<Button disabled={!termsAccept} label="Complete Subscription" />
				)}
			</form>
			<Footer />
		</Checkout>
	);
};

export default injectStripe(CheckoutForm);
