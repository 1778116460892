import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { StaticContext } from "react-router";
import { useAppointments } from "../../contexts/AdminContext";
import {
	getAllAppointments,
	startGetAllAppointments,
	startClearAllAppointments
} from "../../actions/appointmentsActions";
import { getAllSalons } from "../../actions/salonsActions";
import { useSalons } from "../../contexts/AdminContext";
import { Notification, Layout, Fetching} from "../../components";
import Insights from "./Insights";
import Appointments from "./Appointments";
import Announcements from "./Announcements";
import PiiqPerformance from "./PiiqPerformance";
import AppointmentsLine from "./AppointmentsLine";
import Retail from "./Retail";
import { useLocalStorage } from "../../utils";
import { CACHE } from "../../config"
import { StatsContainer, SectionHeader } from "./styles";
import mixpanel from 'mixpanel-browser';

interface IPathParams {
	salonId: string;
	appointmentId?: string;
}
interface ITokens {
	usedToken: string[];
}

export default function DashboardScreen ({
	location,
	match,
	history
}: React.PropsWithChildren<
RouteComponentProps<IPathParams, StaticContext, any>
>) {
	const [appointmentsCache, setAppointmentsCache] = useLocalStorage(CACHE.APPOINTMENTS, []);
	const { salonId } = match.params;
	const [ salonState, salonDispatch ] = useSalons();
	const [ state, dispatch ] = useAppointments();
	const { loading, appointments } = state;
	const [ salonName, setSalonName ] = useState('')
	const [ isSample, setIsSample ] = useState(true)
	const [ isAdminOfThisSalon, setIsAdminOfThisSalon ] = useState(false)
	
	useEffect(() => {
		dispatch(startGetAllAppointments(salonId));
		if(appointmentsCache) dispatch(getAllAppointments(appointmentsCache, null));
		salonDispatch(getAllSalons());
		document.title = "Dashboard";
		mixpanel.track("PMA Dashboard - Page accessed");
	}, []);

	useEffect(() => {
		if(appointments.length) { 
			setAppointmentsCache(appointments)
			setIsSample(false)
		}
	}, [appointments]);

	useEffect(() => {
		console.log('salonState.salons', salonState.salons)
		if (salonState.salons && salonState.salons.length > 0) {
			let isAdminOfAnySalons = false;
			let isClientOfAnySalons = false;
			salonState.salons && salonState.salons.map(salon => {
				if (salon.salonAdmin) isAdminOfAnySalons = true;
				if (salon.salonClient) isClientOfAnySalons = true;
				if (salon.id === salonId && salon.salonAdmin) {
					console.log('is admin of this salon', salon)
					setSalonName(salon.name)
					setIsAdminOfThisSalon(true);
					// If Onboard hasn't complete push them to this
					if(!salon.onboardingComplete) history.push(`/${salonId}/onboarding`)
				}
			})
			if (isClientOfAnySalons && !isAdminOfAnySalons) {
				history.push(`/signup`)
			}
		}
	}, [salonState]);

	return (
		<Layout>
			<Notification />
			{isAdminOfThisSalon ? (
				<StatsContainer>
					{/* <SectionHeader style={{paddingTop:"50px"}}>YOUR PIIQ <span>FLEXI PLAN</span></SectionHeader>
					<PiiqPerformance loading={(loading && (!appointmentsCache.length))} appointments={appointmentsCache}/> */}
					<SectionHeader style={{paddingTop:"50px"}}>News - April 2022</SectionHeader>
					<Announcements />
					<>
						<Insights loading={(loading && (!appointmentsCache.length))} appointments={appointmentsCache} isSample={isSample} />
						<Retail loading={(loading && (!appointmentsCache.length))} appointments={appointmentsCache} isSample={isSample} />
						<AppointmentsLine loading={(loading && (!appointmentsCache.length))} appointments={appointmentsCache} isSample={isSample} />
					</>
				</StatsContainer>
			) : (
				<p>You aren't an admin of this salon. Try logging out and logging in again.</p>
			)}
			<Fetching />
		</Layout>
	);
}
