import styled, { createGlobalStyle } from "styled-components";
import { COLORS, SIZE, FONT, LINE_HEIGHT, BREAKPOINTS } from "../../config";

const navWidth = 232;

interface iOverlay {
  open: boolean;
  onClick: Function;
}

interface iContactSupport {
  white: boolean;
}

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-size: ${FONT.SIZE.BODY};
    letter-spacing: 0.2px;
    line-height: ${LINE_HEIGHT.SIZE.BODY};
    font-family: Gerstner_Regular;
    src: url("../assets/fonts/gerstnerprogrammfsl-regular-webfont.woff");
  }

  *,
  *::before,
  *::after {
    box-sizing: content-box !important;
  }

`;

export const ScreenContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 835px;
  margin: 0;
  display: flex;
  flex-direction: row;
  font-family: Gerstner_Regular;
  font-size: ${FONT.SIZE.BODY};

  .phone-input {
    border: 0 !important;
    width: 100% !important;
    height: 40px !important;
    background: ${COLORS.GREY_MID_LIGHT} !important;
  }

  .text-uppercase {
    text-transform: uppercase;
  }

  .text-small {
    font-size: ${FONT.SIZE.SMALL};
  }
  .text-mid {
    font-size: ${FONT.SIZE.MID};
  }
  .text-body {
    font-size: ${FONT.SIZE.BODY};
  }
  .text-blue {
    color: ${COLORS.BLUE};
  }

  .margin-bottom-mid {
    margin-bottom: ${SIZE.MEDIUM}px;
  }

  .margin-bottom-lrg {
    margin-bottom: ${SIZE.LARGE}px;
  }

  .padd-top-mid {
    padding-top: ${SIZE.MEDIUM}px;
  }

  .padd-top-lrg {
    padding-top: ${SIZE.LARGE}px;
  }

  h1 {
    font-size: ${FONT.SIZE.LARGE};
    line-height: ${LINE_HEIGHT.SIZE.LARGE};
    font-weight: 400;
    font-family: Gerstner_Regular;
  }

  h2 {
    font-size: ${FONT.SIZE.TITLE};
    line-height: ${LINE_HEIGHT.SIZE.TITLE};
    font-weight: 400;
    font-family: Gerstner_Regular;
    margin-top: ${SIZE.XLARGE}px;
    margin-bottom: 0;
    padding: 0;
  }
`;

export const ContentContainer = styled.div`
  width: calc(100% - ${SIZE.LARGE * 2}px);
  height: calc(100vh - 60px);
  margin-left: ${navWidth}px;
  padding: ${SIZE.LARGE}px;
  padding-right: 22px;
  background: ${COLORS.WHITE};
  overflow: hidden;

  @media (min-width: ${BREAKPOINTS.DEKSTOP_MEDIUM}px) {
    width: calc(100% - ${SIZE.LARGE * 2 + navWidth}px);
  }
  @media (max-width: 600px) {
    margin-left: 0px;
  }
`;

export const SummaryContainer = styled.div<{image: string}>`
  display: none;
  @media (min-width: ${BREAKPOINTS.DEKSTOP_MEDIUM}px) {
    display: block;
    width: 100%;
    height: calc(100vh - 60px);
    padding: ${SIZE.LARGE}px;
    background: url('../assets/images/onboarding${props => props.image}.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    .summary-header {
      display: flex;
      justify-content: space-between;
      flex-direction: column-reverse;
    }
  }
`;

export const SummaryContent = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const Link = styled.div`
  font-size: ${FONT.SIZE.SMALL};
  span {
    color: ${COLORS.WHITE};
    padding: 0 0 ${SIZE.TINY}px 2px;
  }
  a:visited,
  a:link,
  a:active {
    text-decoration: none;
    color: ${COLORS.WHITE};
    padding: 0 0 2px;
    border-bottom: 1px solid ${COLORS.WHITE};
  }
  a:hover {
    text-decoration: none;
    color: ${COLORS.GREY_LIGHT};
    padding: 0 0 ${SIZE.TINY}px;
    border-bottom: 1px solid ${COLORS.GREY_LIGHT};
  }
`;

export const MobileHeader = styled.div`
  display: block;
  margin: 0 0 ${SIZE.MEDIUM}px 0;
  text-align: center;
  svg {
    width: 72px;
    height: 24px;
    fill: ${COLORS.BLACK};
    path {
      fill: ${COLORS.BLACK};
    }
  }
  @media (min-width: 600px) {
    display: none;
  }
`;

export const MenuIcon = styled.div`
  position: absolute;
  left: 3px;
  top: ${SIZE.LARGE}px;
  cursor: pointer;
`;
export const MobileOverlay = styled.div`
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: ${(props: iOverlay) => (props.open ? "block" : "none")};
`;

export const ContactSupportLink = styled.a`
  border-bottom: 1px solid ${(props: iContactSupport) => (props.white ? "white" : "black")} !important;

  span {
    color: ${(props: iContactSupport) => (props.white ? "white" : "black")}  
  }
`;