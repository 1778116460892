import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api/lib/types";
import {
  GetSalonAppointmentsVariables,
  GetSalonAppointments,
  GetSalonAppointments_getSalon_allAppointments_items_cart_items
} from "../graphql/GetSalonAppointments";
import {
  GetSalonAppointmentDetailsVariables,
  GetSalonAppointmentDetails
} from "../graphql/GetSalonAppointmentDetails";
import { loader } from "graphql.macro";
import { UpdateCartVariables } from "../graphql/UpdateCart";
import { SetAppointmentRebookedVariables } from "../graphql/SetAppointmentRebooked";
import { DisableAppointmentReminderVariables } from "../graphql/DisableAppointmentReminder";
import { ClearAppointmentRebookedVariables } from "../graphql/ClearAppointmentRebooked";
import { EnableAppointmentReminderVariables } from "../graphql/EnableAppointmentReminder";
import { switchTreatmentVariables } from "../graphql/SwitchTreatment";

import {
  IAppointmentAction,
  ISetAppointmentsAction,
  IUpdateStatusAction,
  IUpdateRebookStatusAction,
  IAppointment,
  ISetAppointmentRebooked,
  IClearAppointmentRebooked,
  IEnableAppointmentReminder,
  IDisableAppointmentReminder,
  IUpdateAppointmentAction,
  ICart,
  IUpdateCartAction,
  IClearAppointmentsAction,
  ISwitchTreatmentAction
} from "../reducers/AppointmentsReducer";
import { CartStatus } from "../graphql/globalTypes";
import {
  GetSalonAppointmentVariables,
  GetSalonAppointment
} from "../graphql/GetSalonAppointment";
const GetSalonAppointmentsGQL = loader(
  "./../graphql/GetSalonAppointments.graphql"
);
const GetSalonAppointmentDetailsGQL = loader(
  "./../graphql/GetSalonAppointmentDetails.graphql"
);
const GetSalonAppointmentGQL = loader(
  "./../graphql/GetSalonAppointment.graphql"
);
const UpdateCartGQL = loader("./../graphql/UpdateCart.graphql");
const UpdateAppointmentGQL = loader("./../graphql/UpdateAppointment.graphql");
const SetAppointmentRebookedGQL = loader(
  "./../graphql/SetAppointmentRebooked.graphql"
);
const DisableAppointmentReminderGQL = loader(
  "./../graphql/DisableAppointmentReminder.graphql"
);
const EnableAppointmentReminderGQL = loader(
  "./../graphql/EnableAppointmentReminder.graphql"
);
const ClearAppointmentRebookedGQL = loader(
  "./../graphql/ClearAppointmentRebooked.graphql"
);
const SwitchTreatmentGQL = loader(
	"./../graphql/SwitchTreatment.graphql"
);

export const getAllAppointments = (
  appointments: IAppointment[],
  nextToken: string | null
): ISetAppointmentsAction => ({
  type: "SET_APPOINTMENTS",
  appointments,
  nextToken
});


export const startUpdateAppointment = (appointment: IAppointment) => {
  console.log("Start update appointment");
  const apptmt: any = processAppointment(appointment);
  return (dispatch: React.Dispatch<IAppointmentAction>, state: any) => {
    dispatch(updateAppointment(apptmt.id, apptmt));
  };
};

export const updateAppointment = (
  id: string,
  appointment: IAppointment
): IUpdateAppointmentAction => ({
  type: "UPDATE_APPOINTMENT",
  id,
  appointment
});

export const startUpdateCart = (cart: ICart) => {
  //Process the cart. Get all cart items
  const allItems =
    cart && cart.items
      ? cart.items.filter(
          (
            j: any
          ): j is GetSalonAppointments_getSalon_allAppointments_items_cart_items =>
            j !== null
        )
      : [];

  const cartData = {
    id: cart.id,
    items: allItems,
    itemCount: allItems.reduce(
      (acc: any, current: any) => (current ? acc + current.quantity : 0),
      0
    ),
    deliveryType: cart && cart.deliveryType ? cart.deliveryType : null,
    status: cart && cart.status ? cart.status : "WAITING"
  };

  return (dispatch: React.Dispatch<IAppointmentAction>, state: any) => {
    dispatch(updateCart(cart.id, cartData));
  };
};

export const updateCart = (id: string, cart: ICart): IUpdateCartAction => ({
  type: "UPDATE_CART",
  id,
  cart
});

export const startGetAllAppointments = (
  salonId: string,
  limit?: number,
  nextToken?: string,
  status?: CartStatus
) => {
  return async (dispatch: React.Dispatch<IAppointmentAction>, state: any) => {
    const params: GetSalonAppointmentsVariables = {
      salonId,
      limit,
      nextToken
    };
    dispatch(appointmentsLoading(true));

    let items: IAppointment[] = [];

    do {
      const result = (await API.graphql(
        graphqlOperation(GetSalonAppointmentsGQL, params)
      )) as GraphQLResult;

      const data = result.data as GetSalonAppointments;
      params.nextToken = data?.getSalon?.allAppointments?.nextToken ?? null;

      if (data) {
        items = [
          ...items,
          ...(data?.getSalon?.allAppointments?.items
            ? data.getSalon.allAppointments.items
                .map<IAppointment | null>(item => processAppointment(item))
                .filter((i: any): i is IAppointment => i !== null)
            : [])
        ];
      }
    } while (params.nextToken);
    
    await dispatch(getAllAppointments(items, params.nextToken));
    
    dispatch(appointmentsLoading(false));
  };
};

export const startGetProfile = (
  limit?: number,
  nextToken?: string
) => {
  return async (dispatch: React.Dispatch<IAppointmentAction>, state: any) => {
    const params: any = {
      limit,
      nextToken
    };
    dispatch(appointmentsLoading(true));

    let items: IAppointment[] = [];

     const GetProfile = `query {
      me {
        allAppointments {
          items {
            id
            createdAt
            client {
              id
              name
            }
            staff {
              id
              name
            }
            cart {
              deliveryType
              id
              items {
                currency
                price
                product {
                  id
                  images {
                    url
                  }
                  name
                }
                quantity
              }
              status
              createdAt
              updatedAt
            }
            rebookDelay
            notes
            rebookLength
            rebookNotificationScheduledAt
            rebookNotificationSentAt
            rebookedAt
            imageShares {
              items {
                sharedTo
                createdAt
              }
            }
            client360Image {
              url
              contentType
            }
            clientSelfieImage {
              url
              contentType
            }
            hairAnalysis {
              colorSensitivity
              curl
              density
              elasticity
              porous
              scalpConcerns
              texture
            }
            imageLikes {
              items {
                id
                image {
                  url
                }
                createdAt
              }
              nextToken
            }
            supportYourSalon {
              recommendations {
                productName
                rrp
                bullets
                description
                listPrice
                sku
                productType
                productSize
                productImageMain
              }
              treatment,
            }
            purchasedItems,
            salonPickup
          }
          nextToken
        }
      }
    }`;
    console.log("HI...")
    do {
      console.log("trying...")
      try {
        const result = (await API.graphql(
          graphqlOperation(GetProfile, params)
        )) as GraphQLResult;
        console.log('result', result)
        const data = result.data as any;
        params.nextToken = data?.getSalon?.allAppointments?.nextToken ?? null;
          console.log('data', data)
        if (data) {
          items = [
            ...items,
            ...(data?.me?.allAppointments?.items
              ? data.me.allAppointments.items
                  .map(item => processAppointment(item))
              : [])
          ];
        }
      } catch(result) {
        const data = result.data as any;
        params.nextToken = data?.getSalon?.allAppointments?.nextToken ?? null;
          console.log('data', data)
        if (data) {
          items = [
            ...items,
            ...(data?.me?.allAppointments?.items
              ? data.me.allAppointments.items
                  .map(item => processAppointment(item))
              : [])
          ];
        } else {
          console.error('no getprofile data')
        }
      }
      
    } while (params.nextToken);
    
    await dispatch(getAllAppointments(items, params.nextToken));
    
    dispatch(appointmentsLoading(false));
  };
};

export const startGetUserAppointment = (salonId: string,appointmentId: string) => {
  return async (dispatch: React.Dispatch<IAppointmentAction>, state: any) => {
    const params: any = {salonId,appointmentId};
    dispatch(appointmentsLoading(true));

    let items: IAppointment[] = [];

     const GetAppointment = `query GetAppointment(
      $appointmentId: ID!
      $salonId: ID!
    ) {
      getSalon(id: $salonId) {
        appointment(id: $appointmentId) {
          id
        createdAt
        client {
          id
          name
        }
        staff {
          id
          name
        }
        cart {
          deliveryType
          id
          items {
            currency
            price
            product {
              id
              images {
                url
              }
              name
            }
            quantity
          }
          status
          createdAt
          updatedAt
        }
        rebookDelay
        notes
        rebookLength
        rebookNotificationScheduledAt
        rebookNotificationSentAt
        rebookedAt
        imageShares {
          items {
            sharedTo
            createdAt
          }
        }
        client360Image {
          url
          contentType
        }
        clientSelfieImage {
          url
          contentType
        }
        hairAnalysis {
          colorSensitivity
          curl
          density
          elasticity
          porous
          scalpConcerns
          texture
        }
        imageLikes {
          items {
            id
            image {
              url
            }
            createdAt
          }
          nextToken
        }
        supportYourSalon {
          recommendations {
            productName
            rrp
            bullets
            description
            listPrice
            sku
            productType
            productSize
            productImageMain
          }
          treatment,
        }
        purchasedItems,
        salonPickup
        }
      }
    }`;
    console.log("trying...")
    try {
      const result = (await API.graphql(
        graphqlOperation(GetAppointment, params)
      )) as GraphQLResult;
      if (result.data) {
        const data = result.data as GetSalonAppointment;
        const appointment: IAppointment | null = processAppointment(
          data.getSalon?.appointment
        );
        console.log("Start get user appointment 1");
        appointment && dispatch(updateAppointment(appointment.id, appointment));
        // appointment && dispatch(startUpdateAppointment(appointment))
        return appointment;
      }
    } catch(result) {
      if (result.data) {
        const data = result.data as GetSalonAppointment;
        const appointment: IAppointment | null = processAppointment(
          data.getSalon?.appointment
        );
        console.log("Start get user appointment 2");
        appointment && dispatch(updateAppointment(appointment.id, appointment));
        // appointment && dispatch(startUpdateAppointment(appointment))
        return appointment;
      } else {
        console.error('no getAppointment data')
      }
    }
    
    dispatch(appointmentsLoading(false));
  };
};



export const updateSupportYourSalon = (
  id: string,
  appointment: IAppointment
): IUpdateAppointmentAction => ({
  type: "UPDATE_APPOINTMENT",
  id,
  appointment
});

export const startUpdateSupportYourSalon = (
  appointment: IAppointment
) => {
  return async (dispatch: React.Dispatch<IAppointmentAction>, state: any) => {
    console.log('updatingSupportYourSalon')
    let r = await API.graphql(
      graphqlOperation(UpdateAppointmentGQL, {
        appointmentId: appointment.id,
        input: {
          supportYourSalon: {
            recommendations: appointment.supportYourSalon.recommendations 
          }
        }
      } as any)
    );
    console.log('graphQLOperation', r)
    dispatch(updateSupportYourSalon(appointment.id, appointment));
  };
};
export const updateAppointmentNotes = (
  id: string,
  appointment: IAppointment
): IUpdateAppointmentAction => ({
  type: "UPDATE_APPOINTMENT",
  id,
  appointment
});

export const startUpdateAppointmentNotes = (
  appointment: IAppointment
) => {
  return async (dispatch: React.Dispatch<IAppointmentAction>, state: any) => {
    console.log('updatingAppointmentNotes')
    let r = await API.graphql(
      graphqlOperation(UpdateAppointmentGQL, {
        appointmentId: appointment.id,
        input: {
          notes: appointment.notes
        }
      } as any)
    );
    console.log('graphQLOperation', r)
    dispatch(updateAppointmentNotes(appointment.id, appointment));
  };
};


export const processAppointment = (item: any) => {
  if (item === null) {
    return null;
  }

  //Get all cart items
  const allItems =
    item.cart && item.cart.items
      ? item.cart.items.filter(
          (
            j: any
          ): j is GetSalonAppointments_getSalon_allAppointments_items_cart_items =>
            j !== null
        )
      : [];

  //Get cartID
  const cartid = item.cart && item.cart.id ? item.cart.id : "0";

  //Calculate rebookstatus
  const rebookstatus: any = item.rebookedAt
    ? "COMPLETED"
    : item.rebookNotificationScheduledAt
    ? "SCHEDULED"
    : item.rebookLength
    ? "OPEN"
    : "N/A";

  const appt: IAppointment = {
    id: item.id,
    clientName: item.client ? item.client.name || "(No Name)" : "(No Name)",
    clientId: item.client ? item.client.id : null,
    staffName: item.staff ? item.staff.name || "(No Name)" : "(No Name)",
    staffId: item.staff ? item.staff.id : null,
    cartItems: allItems,
    itemCount: allItems.reduce(
      (acc: any, current: any) => (current ? acc + current.quantity : 0),
      0
    ),
    deliveryType:
      item.cart && item.cart.deliveryType ? item.cart.deliveryType : null,
    createdAt: item.createdAt || "",
    status: item.cart && item.cart.status ? item.cart.status : null,
    rebookStatus: rebookstatus,
    rebookLength: item.rebookLength,
    notes: item?.notes || null,
    rebookDelay: item.rebookDelay,
    rebookNotificationScheduledAt: item.rebookNotificationScheduledAt || null,
    rebookNotificationSentAt: item.rebookNotificationSentAt || null,
    rebookedAt: item.rebookedAt || "",
    cartId: cartid,
    clientSelfieImage: item.clientSelfieImage
      ? item.clientSelfieImage.url
      : null,
    client360Image: item.client360Image ? item.client360Image.url : null,
    facialAnalysis: item.facialAnalysis || null,
    hairAnalysis: item.hairAnalysis || null,
    productRecommendations: [] || null,
    imageLikes: item?.imageLikes?.items || null,
    imageShares: item?.imageShares?.items || null,
    supportYourSalon: item?.supportYourSalon || null,
    salonPickup: item?.salonPickup || null,
    purchasedItems: item?.purchasedItems || null
  };
  
  return appt;
};

export const startGetAllAppointmentDetails = (
  salonId: string,
  limit?: number,
  nextToken?: string
) => {
  return async (dispatch: React.Dispatch<IAppointmentAction>, state: any) => {
    const params: GetSalonAppointmentDetailsVariables = {
      salonId,
      limit,
      nextToken
    };
    const result = (await API.graphql(
      graphqlOperation(GetSalonAppointmentDetailsGQL, params)
    )) as GraphQLResult;

    dispatch(appointmentsLoading(true));

    if (result.data) {
      const data = result.data as GetSalonAppointmentDetails;
      const nextToken = data?.getSalon?.allAppointments?.nextToken || null;

      const items = data?.getSalon?.allAppointments?.items
        ? data.getSalon.allAppointments.items
            .map<IAppointment | null>(item => processAppointment(item))
            .filter((i: any): i is IAppointment => i !== null)
        : [];
      dispatch(getAllAppointments(items, nextToken));
    }
  };
};

export const startGetAppointment = (salonId: string, appointmentId: string) => {
  return async (dispatch: React.Dispatch<IAppointmentAction>, state: any) => {
    const params: GetSalonAppointmentVariables = {
      salonId,
      appointmentId
    };
    const result = (await API.graphql(
      graphqlOperation(GetSalonAppointmentGQL, params)
    )) as GraphQLResult;

    if (result.data) {
      const data = result.data as GetSalonAppointment;
      const appointment: IAppointment | null = processAppointment(
        data.getSalon?.appointment
      );
      appointment && dispatch(updateAppointment(appointment.id, appointment));
      // appointment && dispatch(startUpdateAppointment(appointment))
      return appointment;
    }
  };
};

export const clearAllAppointments = (
  salonId?: string
): IClearAppointmentsAction => ({
  type: "CLEAR_APPOINTMENTS"
});

export const startClearAllAppointments = (salonId?: string) => {
  return async (dispatch: React.Dispatch<IAppointmentAction>, state: any) => {
    dispatch(clearAllAppointments());
  };
};

export const updateRebookStatus = (
  id: string,
  rebookDelay: number | null,
  rebookLength: number | null,
  rebookNotificationScheduledAt: any | null,
  rebookNotificationSentAt: any | null,
  rebookedAt: any,
  rebookStatus: "SCHEDULED" | "COMPLETED" | "OPEN" | "N/A"
): IUpdateRebookStatusAction => ({
  type: "UPDATE_REBOOK_STATUS",
  id,
  rebookDelay,
  rebookLength,
  rebookNotificationScheduledAt,
  rebookNotificationSentAt,
  rebookedAt,
  rebookStatus
});

export const startUpdateRebookStatus = (
  appointmentId: string,
  reBookState: string
) => {
  return async (dispatch: React.Dispatch<IAppointmentAction>, state: any) => {
    if (reBookState === "SetAppointmentRebooked") {
      const result = (await API.graphql(
        graphqlOperation(SetAppointmentRebookedGQL, {
          input: {
            id: appointmentId
          }
        } as SetAppointmentRebookedVariables)
      )) as ISetAppointmentRebooked;

      if (result.data) {
        const data = result.data
          .setAppointmentRebooked as IUpdateRebookStatusAction;
        dispatch(
          updateRebookStatus(
            data.id,
            data.rebookDelay,
            data.rebookLength,
            data.rebookNotificationScheduledAt,
            data.rebookNotificationSentAt,
            data.rebookedAt,
            data.rebookedAt
              ? "COMPLETED"
              : data.rebookNotificationScheduledAt
              ? "SCHEDULED"
              : "OPEN"
          )
        );
      }
    } else if (reBookState === "ClearAppointmentRebooked") {
      const result = (await API.graphql(
        graphqlOperation(ClearAppointmentRebookedGQL, {
          input: {
            id: appointmentId
          }
        } as ClearAppointmentRebookedVariables)
      )) as IClearAppointmentRebooked;

      if (result.data) {
        const data = result.data
          .clearAppointmentRebooked as IUpdateRebookStatusAction;
        dispatch(
          updateRebookStatus(
            data.id,
            data.rebookDelay,
            data.rebookLength,
            data.rebookNotificationScheduledAt,
            data.rebookNotificationSentAt,
            data.rebookedAt,
            data.rebookedAt
              ? "COMPLETED"
              : data.rebookNotificationScheduledAt
              ? "SCHEDULED"
              : "OPEN"
          )
        );
      }
    } else if (reBookState === "EnableAppointmentReminder") {
      const result = (await API.graphql(
        graphqlOperation(EnableAppointmentReminderGQL, {
          input: {
            id: appointmentId
          }
        } as EnableAppointmentReminderVariables)
      )) as IEnableAppointmentReminder;

      if (result.data) {
        const data = result.data
          .enableAppointmentReminder as IUpdateRebookStatusAction;
        dispatch(
          updateRebookStatus(
            data.id,
            data.rebookDelay,
            data.rebookLength,
            data.rebookNotificationScheduledAt,
            data.rebookNotificationSentAt,
            data.rebookedAt,
            data.rebookedAt
              ? "COMPLETED"
              : data.rebookNotificationScheduledAt
              ? "SCHEDULED"
              : "OPEN"
          )
        );
      }
    } else if (reBookState === "DisableAppointmentReminder") {
      const result = (await API.graphql(
        graphqlOperation(DisableAppointmentReminderGQL, {
          input: {
            id: appointmentId
          }
        } as DisableAppointmentReminderVariables)
      )) as IDisableAppointmentReminder;

      if (result.data) {
        const data = result.data
          .disableAppointmentReminder as IUpdateRebookStatusAction;
        dispatch(
          updateRebookStatus(
            data.id,
            data.rebookDelay,
            data.rebookLength,
            data.rebookNotificationScheduledAt,
            data.rebookNotificationSentAt,
            data.rebookedAt,
            data.rebookedAt
              ? "COMPLETED"
              : data.rebookNotificationScheduledAt
              ? "SCHEDULED"
              : "OPEN"
          )
        );
      }
    }
  };
};

export const updateStatus = (
  id: string,
  status: "CANCELLED" | "COMPLETED" | "WAITING"
): IUpdateStatusAction => ({
  type: "UPDATE_STATUS",
  id,
  status
});

export const startUpdateStatus = (
  cartId: string,
  status: "CANCELLED" | "COMPLETED" | "WAITING"
) => {
  return async (dispatch: React.Dispatch<IAppointmentAction>, state: any) => {
    await API.graphql(
      graphqlOperation(UpdateCartGQL, {
        cartId,
        input: {
          status: status
        }
      } as UpdateCartVariables)
    );
    // dispatch(updateStatus(cartId, status));
  };
};


export const switchTreatment = (
	appointmentId: string,
	newTreatment: string
): ISwitchTreatmentAction => ({
	type: "SWITCH_TREATMENT",
	appointmentId,
	newTreatment
});

export const startSwitchTreatment = (
	appointmentId: string,
	newTreatment: string
) => {
	return async (dispatch: React.Dispatch<IAppointmentAction>, state: any) => {
		const params: switchTreatmentVariables = {
			appointmentId,
			newTreatment
		};
    console.log("Switching treatment...", newTreatment)
		const result = (await API.graphql(
			graphqlOperation(SwitchTreatmentGQL, params)
		)) as GraphQLResult;
		console.log("Result", result);
		console.log("Message switch Treatment", newTreatment);
		dispatch(switchTreatment(appointmentId, newTreatment));
	};
};


export const appointmentsLoading = (loading: boolean): IAppointmentAction => ({
  type: "SET_LOADING",
  loading
});
