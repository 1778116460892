import styled from "styled-components";
import { SIZE } from "../../../../config";

export const OrderDetails = styled.div`
	width: 100%;
	height: calc(100% - ${130 + SIZE.LARGE}px);
	margin: 0;
	padding-top: 130px;
	overflow: scroll;
	padding-bottom: ${SIZE.LARGE}px;
`;

export const SummaryDetail = styled.div`
	display: flex;
	flex-direction: row;
	padding: ${SIZE.MEDIUM}px 0 ${SIZE.LARGE}px;

	@media (max-width: 720px) {
		flex-direction: column;
	}
`;
export const SummaryOverview = styled.div`
	padding: ${SIZE.MEDIUM}px 0;
	flex: 2;
	width: 50%;
	h3 {
		padding: 0;
		margin: 0;
		font-size: 11px;
		font-weight: 400;
		letter-spacing: 0.42px;
		text-transform: uppercase;
	}
`;
export const SummarySupport = styled.div`
	flex: 2;
	display: flex;
	flex-direction: column;
	padding: ${SIZE.MEDIUM}px;
	border-radius: ${SIZE.BORDER_RADIUS}px;
	color: #132a9f;
	background-color: rgba(19, 42, 159, 0.1);
	a:link,
	a:active,
	a:visited {
		color: #132a9f;
		text-decoration: underline;
	}
	h3 {
		padding: 0;
		margin: 0;
		font-size: 11px;
		font-weight: 400;
		letter-spacing: 0.42px;
		text-transform: uppercase;
	}
`;

export const Footer = styled.div`
	height: 100px;
	width: 100%;
`;
