import React, { useState } from "react";
import { useStaff } from "../../../contexts/AdminContext";
import EditIcon from "@material-ui/icons/Edit";
import { Formik } from "formik";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import StyledSwitch from "../../../components/StyledSwitch";
import {
  startToggleStaff,
  startEditStaffName
} from "../../../actions/staffActions";
import mixpanel from 'mixpanel-browser';

export function StaffItem(props: any) {
  const { id, name, active } = props.staff;
  const [open, setOpen] = useState(false);
  const reducer = useStaff();
  const [state, dispatch] = reducer;

  function handleClickOpen() {
    setOpen(true);
    mixpanel.track("PMA Settings - Team member name edited");
  }

  function handleClose() {
    setOpen(false);
  }

  function saveStaffOnSubmit(values: any, { resetForm }: any) {
    if (values.name) {
      dispatch(startEditStaffName(id, values.name));
      handleClose();
    } else if (!values.name) {
      alert("Please enter the name");
    }
  }

  return (
    <>
      <td>{name}</td>
      <td>
        <EditIcon
          style={{
            cursor: "pointer",
            height: "18px",
            width: "18px",
            color: "grey"
          }}
          onClick={handleClickOpen}
        />
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <FormattedMessage
              id="editname.title"
              defaultMessage={`Edit Name`}
            />
          </DialogTitle>
          <DialogContent>
            <Formik initialValues={{ name: name }} onSubmit={saveStaffOnSubmit}>
              {({ values, handleChange, handleBlur, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <TextField
                    autoFocus
                    variant="outlined"
                    margin="dense"
                    type="name"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name || ""}
                    style={{ width: "400px" }}
                  />
                  <DialogActions>
                    <Button
                      onClick={handleClose}
                      variant="outlined"
                      size="small"
                      color="primary"
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        width: "50%",
                        fontFamily: "BitstreamRoman",
                        borderRadius: "0px"
                      }}
                    >
                      <FormattedMessage
                        id="button.cancelstaff"
                        defaultMessage={`Cancel`}
                      />
                    </Button>
                    <Button
                      type="submit"
                      variant="outlined"
                      size="small"
                      color="primary"
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        width: "50%",
                        fontFamily: "BitstreamRoman",
                        borderRadius: "0px"
                      }}
                    >
                      <FormattedMessage
                        id="button.save"
                        defaultMessage={`Save`}
                      />
                    </Button>
                  </DialogActions>
                </form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>
      </td>
      <td>
        <StyledSwitch
          onChange={() => {
            dispatch(startToggleStaff(id, !active));
            mixpanel.track(active ? "PMA Settings - Team member deactivated" : "PMA Settings - Team member activated");
          }}
          checked={active !== undefined ? active : true}
          available={active !== undefined ? active : true}
        />
      </td>
    </>
  );
}
