import React, { useRef, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import { History } from "history";
import { FormattedMessage } from "react-intl";
import { EnableBodyScrolling } from "../../../../utils";
import { Button, Content } from "../../../../components";
import { OnboardingDetails } from "../../types";
import { IFrameContainer } from "./styles";
import mixpanel from 'mixpanel-browser';


interface IProps extends RouteComponentProps<any> {
	onboardVersion: string;
	transitionConfig?: any;
	onNavigate: Function;
	onCompleteSection: Function;
	updateRegistrationState: Function;
	registrationDetails: OnboardingDetails;
	salonId: string;
	history: History;
	location: any;
	match: any;
	onCompleteFlow: Function;
}

const CompleteComponent: React.SFC<IProps> = ({
	transitionConfig,
	onCompleteFlow,
	salonId,
	history
}) => {
	const contentScroll = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (contentScroll && contentScroll.current) {
			contentScroll.current.scrollTop = 0;
			window.scrollTo(0, 0);
		}
		onCompleteFlow();
		mixpanel.track("PMA Onboarding - Setup complete - Page accessed event");
	}, []);

	const onSubmit = () => {
		onCompleteFlow();
		/* Reload the dashboard, this should really use the history prop, but Terms causing issues */
		// history.push("/");
		window.open(`/${salonId}/dashboard`, "_self");
		// enable body scroll
		EnableBodyScrolling(true);
	};

	const transitionConfigSetup = useSpring(transitionConfig);

	return (
		<Content
			as={animated.div}
			style={transitionConfigSetup}
			ref={contentScroll}>
			<h1>
				<FormattedMessage id="registration.complete.title" />
			</h1>
			<div className="margin-bottom-lrg">
				<IFrameContainer>
					<iframe 
						width="560" 
						height="315" 
						src="https://www.youtube.com/embed/slWvLdyT_M8" 
						title="YouTube video player" 
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
				</IFrameContainer>
			</div>
			<div className="margin-bottom-lrg">
				<FormattedMessage id="registration.complete.body1" />
			</div>
			<div className="margin-bottom-lrg">
				<FormattedMessage id="registration.complete.body2" />
			</div>
			<div className="margin-bottom-lrg">
				<FormattedMessage id="registration.complete.nextsteps1" />
			</div>
			<div className="margin-bottom-lrg">
				<FormattedMessage id="registration.complete.nextsteps2" />
			</div>
			<div className="margin-bottom-lrg">
				<FormattedMessage id="registration.complete.nextsteps3" />
			</div>
			<div className="margin-bottom-lrg">
				<FormattedMessage id="registration.complete.nextsteps4" />
			</div>
			<div className="padd-top-mid" style={{paddingBottom: 400}}>
				<Button onPress={onSubmit} label="Go to your dashboard" />
			</div>
		</Content>
	);
};

export default withRouter(CompleteComponent);
