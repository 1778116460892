import styled from "styled-components";
import { SIZE } from "../../../../config";

export const Payment = styled.div`
  width: 100%;
  height: calc(100% - ${130 + SIZE.LARGE}px);
  margin: 0;
  padding-top: 130px;
  overflow: scroll;
  padding-bottom: ${SIZE.LARGE}px;
`;

export const IFrameContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;