import React, { useRef, useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { UpdateSalonInput } from "../../../../graphql/globalTypes";
import { startGetAllStaff } from "../../../../actions/staffActions";
import { startCreateStaff } from "../../../../actions/staffActions";
import { useStaff, useSalons } from "../../../../contexts/AdminContext";
import { useTermsAndConditions } from "../../../../contexts/AdminContext";
import { Button, Content, Input, SkipButton } from "../../../../components";
import { SectionType, OnboardingDetails } from "../../types";
import { Label, Member, NoMember, InputRow, Error, Loader } from "./styles";
import mixpanel from 'mixpanel-browser';

interface IProps {
	onboardVersion: string;
	transitionConfig?: any;
	onNavigate: Function;
	onCompleteSection: Function;
	updateRegistrationState: Function;
	registrationDetails: OnboardingDetails;
	salonId: string;
	history: any;
	match: any;
	location: any;
}

const StaffComponent: React.SFC<IProps> = props => {
	const contentScroll = useRef<HTMLDivElement>(null);
	const [ termsState ] = useTermsAndConditions();
	const [ salonsState, salonsDispatch ] = useSalons();
	const [ allStaff, dispatch ] = useStaff();
	const [ currentStaff, setCurrentStaff ] = useState() as any;
	const [ inputName, setInputName ] = useState("");
	const [ disabled, setDisabled ] = useState(false);
	const [ staff, setStaff ] = useState(
		props.registrationDetails.staff || undefined
	);
	const [ error, setError ] = useState("");
	const [ loading, setLoading ] = useState(false);

	useEffect(() => {
		if (contentScroll && contentScroll.current) {
			contentScroll.current.scrollTop = 0;
			window.scrollTo(0, 0);

			/* Fetch all the staff */
			dispatch(startGetAllStaff(props.salonId));
		}
		mixpanel.track("PMA Onboarding - Your Staff - Page accessed event");
	}, []);

	useEffect(
		() => {
			setCurrentStaff(allStaff.staff);
		},
		[ allStaff ]
	);

	useEffect(
		() => {
			updateState();
		},
		[ staff ]
	);

	const onSubmit = async () => {
		setLoading(true);
		// const salonId = "XX-XXX-XX"; // DEBUG FALSE ID
		if (staff && staff.length) {
			let complete = 0;
			await staff.forEach(async (member: string, i: number) => {
				if (!duplicateStaffMember(member)) {
					const result = await dispatch(
						startCreateStaff(props.salonId, member)
					);
					/* TODO: Remove the TS-Ignore and Fix returning Void so can check for errors */
					// @ts-ignore
					if (result && result.errors) {
						setError("Oh no, failed to add staff member's at this time");
						setLoading(false);
						return;
					}
				}
				complete += 1;
				if (complete === staff.length) {
					/* All good, proceed.. */
					proceedNextStep();
				}
			});
		} else {
			/* No Staff to add, just proceed */
			proceedNextStep();
		}
	};

	const duplicateStaffMember = (name: string) => {
		let duplicate = false;
		currentStaff &&
			currentStaff.forEach(staff => {
				if (staff.name.toLowerCase() === name.toLowerCase()) duplicate = true;
			});
		return duplicate;
	};

	// const updateSalonState = () => {
	// 	const salonInput: UpdateSalonInput = {
	// 		onboardingComplete: false,
	// 		onboardingState: `{"version":"${props.onboardVersion}","nextStep":2}`
	// 	};
	// 	salonsDispatch(startUpdateSalon(props.salonId, salonInput));
	// };

	const proceedNextStep = () => {
		setLoading(false);
		props.onNavigate(SectionType.SOCIAL);
		props.onCompleteSection();
	};

	const onInputChange = (value: string, name: string) => {
		if (value.trim() === '') {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
		setInputName(value);
	};

	const addStaffMember = () => {
		const newMember = staff ? [ ...staff ] : [];
		newMember.push(inputName);
		setStaff(newMember);
		setInputName("");
		setDisabled(false);
	};

	const removeStaffMember = (name: string) => {
		const memberRemoved = staff && staff.filter(member => member !== name);
		setStaff(memberRemoved);
	};

	const updateState = () => {
		const state: OnboardingDetails = {
			staff
		};
		props.updateRegistrationState(state);
	};

	const _handleKeyDown = e => {
		if (e.key === "Enter") {
			addStaffMember();
		}
	};

	const transitionConfig = useSpring(props.transitionConfig);

	return (
		<Content as={animated.div} style={transitionConfig} ref={contentScroll}>
			<h1>
				<FormattedMessage id="registration.staff.title" />
			</h1>
			<div className="margin-bottom-mid">
				<FormattedMessage id="registration.staff.body" />
			</div>
			<InputRow>
				<div className="input-area">
					<Input
						onChange={onInputChange}
						placeholder={"Enter a team member name here"}
						value={inputName}
						label="Team Member"
						name="staff"
						onKeyDown={_handleKeyDown}
					/>
				</div>
				<div className="button-area">
					<Button disabled={!inputName} onPress={addStaffMember} label="ADD" />
				</div>
			</InputRow>

			<Label>Your Team</Label>
			<div className="margin-bottom-lrg">
				{staff && staff.length ? (
					staff.map((member: string, i: number) => (
						<Member key={i} onClick={() => removeStaffMember(member)}>
							{member}
							{duplicateStaffMember(member) && (
								<span className="duplicate">
									<FormattedMessage id="registration.staff.duplicate" />
								</span>
							)}
							<span className="remove">
								<FormattedMessage id="registration.button_remove" />
							</span>
						</Member>
					))
				) : (
					<NoMember>No team members added yet</NoMember>
				)}
			</div>

			{loading ? (
				<Loader>
					<CircularProgress />
				</Loader>
			) : (
				<Button disabled={disabled || staff?.length === 0} onPress={onSubmit} label="NEXT →" />
			)}
			<Error>{error && error}</Error>
			<SkipButton onPress={proceedNextStep} />
		</Content>
	);
};

export default withRouter(StaffComponent);
