import { INotificationAction } from "../reducers/NotificationReducer";
import { IAppointment, ICart } from "../reducers/AppointmentsReducer";

export const showAppointmentNotification = (
	appointment: IAppointment | null
): INotificationAction => ({
	type: "APPOINTMENT_NOTIFICATION",
	data: appointment
});

export const showCartNotification = (
	cart: ICart | null
): INotificationAction => ({
	type: "CART_NOTIFICATION",
	data: cart
});

export const hideNotification = (): INotificationAction => ({
	type: "NOTIFICATION_HIDE",
	data: null
});
