import { IAppointment, ICart } from "./AppointmentsReducer";

export interface IStateNotification {
	type?: "APPOINTMENT" | "CART" | null;
	notify: boolean;
	data?: any; //IAppointment | ICart | null;
}

export interface INotificationAction {
	type: "APPOINTMENT_NOTIFICATION" | "CART_NOTIFICATION" | "NOTIFICATION_HIDE";
	data: IAppointment | ICart | null;
}

export const initialStateNotification: IStateNotification = {
	notify: false,
	type: null,
	data: null
};

export const reducerNotification = (
	state: any,
	action: INotificationAction
) => {
	switch (action.type) {
		case "APPOINTMENT_NOTIFICATION":
			return {
				...state,
				notify: true,
				type: "APPOINTMENT",
				data: action.data
			};
		case "CART_NOTIFICATION":
			return {
				...state,
				notify: true,
				type: "CART",
				data: action.data
			};
		case "NOTIFICATION_HIDE":
			return {
				...state,
				notify: false
			};
		default:
			return state;
	}
};
