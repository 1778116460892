import React from "react";
import styled from "styled-components";
import { COLORS, SIZE, FONT, BREAKPOINTS } from "../../config";

export const H1 = styled.h1`  
  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    font-size: 30px;
  }
`;

const H1Component = (props: any) => {
  const { children } = props;
  return (
    <H1 style={props.style}>
      {children}
    </H1>
  );
};

export default H1Component;
