import React from "react";

export type IActions = {
  type: string;
  [name: string]: any;
};

export type IAsyncActions<A extends IActions, S> = (
  dispatch: React.Dispatch<A>,
  state: S
) => void;

export type IAsyncActionsCreator<A extends IActions, S> = (
  ...args: any[]
) => IAsyncActions<A, S>;

export type AsyncDispatch<A extends IActions, S> = (
  action: A | IAsyncActions<A, S>
) => void;

export default function createAsyncDispatch<A extends IActions, S>(
  dispatch: React.Dispatch<A>,
  state: S
): AsyncDispatch<A, S> {
  /*
    /
    / This function enable to pass functions to the dispatcher, it reproduces redux-thunk
    /
    */
  return async (action: A | IAsyncActions<A, S>) => {
    if (typeof action === "object" && action !== null) {
      /*
            / Actions of type object are directly dispatched and follow normal path
            */
      return dispatch(action);
    } else if (typeof action === "function") {
      /*
            / Actions of type function are called with dispatch as argument
            / so they can access it in their body and can call it multiple times
            / for example before and after async functions has finish them jobs
            */
      return action(dispatch, state);
    } else {
      console.warn("Actions should be either an object or a function");
    }
  };
}
