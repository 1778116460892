import { IActions } from "../contexts/createAsyncDispatch";

export interface IStateProducts {
  nextToken: string;
  products: any[];
  loading: boolean;
}

export interface IProduct {
  id: string;
  available: boolean;
  currency: string;
  eligible: boolean;
  customPrice: number;
  eligibleReason: string;
  imageUrls: string[];
  msrp: number;
  name: string;
  createdAt: string;
  updatedAt: string;
}


export interface IStateProductsPVI {
  nextToken: string;
  products: IProductPVI[];
  loading: boolean;
}

export interface IProductPVI {
  productId: string;
  _id: string | null;
  Brand: string | null;
  Bullets: string | null;
  CreatedBy: string | null;
  CreatedDate: string | null;
  Description: string | null;
  ForMen: boolean | null;
  HairConcernsFilters: (string | null)[] | null;
  isArchived: boolean | null;
  isFallback: boolean | null;
  isMask: boolean | null;
  ListPrice: number | null;
  ModifiedDate: string | null;
  Outofstock: boolean | null;
  ProductImageMain: string | null;
  ProductName: string | null;
  ProductSize: string | null;
  ProductType: string | null;
  RRPAU: number | null;
  RRPCA: number | null;
  RRPNZ: number | null;
  RRPUS: number | null;
  SKU: string | null;
  SuitsColoured: (string | null)[] | null;
  SuitsHairColour: (string | null)[] | null;
  SuitsHairDensities: (string | null)[] | null;
  SuitsHairElasticities: (string | null)[] | null;
  SuitsHairPorosity: (string | null)[] | null;
  SuitsHairTextures: (string | null)[] | null;
  SuitsOtherFilters: (string | null)[] | null;
  SuitsScalpConcerns: (string | null)[] | null;
  SuitsGenders: (string | null)[] | null;
}

export interface IGetProductsAction {
  type: "GET_ALL_PRODUCTS";
  id: string;
  salonId: string;
  available?: boolean;
  limit?: number;
  nextToken?: string;
}

export interface ISetLoadingAction {
  type: "SET_LOADING";
  loading: boolean;
}

export interface IClearProductsAction extends IActions {
  type: "CLEAR_PRODUCTS";
  id?: string;
}

export interface ISetProductsAction extends IActions {
  type: "SET_PRODUCTS";
  id?: string;
  products: IProduct[];
}
export interface IToggleProductsAction {
  type: "TOGGLE_PRODUCT";
  productId: string;
  available: boolean;
}
export interface ISetCustomPriceProductAction {
  type: "SET_CUSTOM_PRICE";
  productId: string;
  customPrice: number;
}

export type IProductAsyncAction = any;

export type IProductAction =
  | ISetProductsAction
  | IClearProductsAction
  | IGetProductsAction
  | IToggleProductsAction
  | ISetLoadingAction
  | ISetCustomPriceProductAction;

export const initialStateProducts: IStateProducts = {
  products: [],
  nextToken: "",
  loading: true
};

export const reducerProducts = (
  state: IStateProducts,
  action: IProductAction | IProductAsyncAction
) => {
  state.products.findIndex(p => p.id === action.id);
  switch (action.type) {
    case "SET_PRODUCTS":
      return {
        ...state,
        products: [ ...state.products, ...action.products ],
        nextToken: action.nextToken,
        loading: false
      };

    case "CLEAR_PRODUCTS":
      return {
        ...state,
        products: [],
        loading: false
      };

    case "SET_LOADING":
      return {
        ...state,
        loading: action.loading
      };

    case "TOGGLE_PRODUCT":
      return toggleProduct(state, action);

    case "SET_CUSTOM_PRICE":
      return setCustomPrice(state, action);
  }
  return state;
};

function toggleProduct (state: IStateProducts, action: IToggleProductsAction) {
  const i = state.products.findIndex(s => s.id === action.productId);
  return {
    ...state,
    products: [
      ...state.products.slice(0, i),
      { ...state.products[i], available: action.available },
      ...state.products.slice(i + 1)
    ],
    loading: false
  };
}

function setCustomPrice (
  state: IStateProducts,
  action: ISetCustomPriceProductAction
) {
  const { productId } = action;
  const i = state.products.findIndex(s => s.id === productId);
  return {
    ...state,
    products: [
      ...state.products.slice(0, i),
      { ...state.products[i], customPrice: action.customPrice },
      ...state.products.slice(i + 1)
    ],
    loading: false
  };
}
