import React from "react";
import { Button, SubmitButton } from "./styles";

interface IInputProps {
  label: string;
  type?: string;
  onPress?: Function;
  disabled?: boolean;
}

const ButtonComponent = (props: IInputProps) => {
  const onPress = () => {
    props.onPress && props.onPress();
  };

  return (
    props.type === "submit" ? (
      <SubmitButton
        type="submit"
        disabled={props.disabled ? props.disabled : false}
        onClick={onPress}
        value={props.label} />
    ) : (
      <>
        <Button
          disabled={props.disabled ? props.disabled : false}
          onClick={onPress}>
          {props.label}
        </Button>  
      </>
    )
    
  );
};

export default ButtonComponent;
