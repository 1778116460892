import React, { useEffect } from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { IntlProvider, addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import british from "./languages/en_GB.json";
//import usa from "./languages/en_US.json";
import { theme } from "./Theme";
import { Routes } from "./Routes";
import { AdminProvider } from "./contexts/AdminContext";
import { AmplifyConfig } from "./utils";

import Authenticator from "./screens/Authenticator/Authenticator";
import TermsAndCondition from "./screens/TermsAndConditions/TermsAndCondition";

import mixpanel from 'mixpanel-browser';


//Importing different countries for internationalization
//addLocaleData([...fr, ...en]);
addLocaleData([ ...en ]);

//Internationalization options
const messages: any = {
	en: british,
	"en-AU": british,
	"en-GB": british,
	"en-NZ": british
};

interface IAppState {
	locale: string;
	country: string;
	localeMessages: { [pname: string]: string };
	language: string;
}

if (process.env.REACT_APP_ENV === "PROD") {
	AmplifyConfig("PROD");
} else AmplifyConfig("STAGING");

// const locale = navigator.language;
// const [ language, country ] = locale.split("-");
console.log(navigator.language);
const locale = 'en-GB';
const language = 'en';
const country = 'GB';
export const localeMessages = {
	...messages[language],
	...messages[locale]
};
const App = props => {
	const state: IAppState = {
		localeMessages,
		locale,
		country,
		language
	};

	useEffect(() => {
		
		console.log(
			`%c piiq : admin  |  v1.2 | env ${process.env.REACT_APP_ENV}`,
			"background: #000; color: #fff; padding: 8px"
		);

		var freshsalesScript= document.createElement('script');
		freshsalesScript.text = "function createFcn(nm){(window.freshsales)[nm]=function(){(window.freshsales).push([nm].concat(Array.prototype.slice.call(arguments,0)))}; } (function(url,appToken,formCapture){window.freshsales=window.freshsales||[];if(window.freshsales.length==0){list='init identify trackPageView trackEvent set'.split(' ');for(var i=0;i<list.length;i++){var nm=list[i];createFcn(nm);}var t=document.createElement('script');t.async=1;t.src='https://aus-assets.freshsales.io/assets/analytics.js';var ft=document.getElementsByTagName('script')[0];ft.parentNode.insertBefore(t,ft);freshsales.init('https://piiq-team.myfreshworks.com/crm/sales','7f9b889a27645eda83b4649d50aac1d56f7eb73197694e9cfdbea27626f4276d',true);}})();";
		document.head.appendChild(freshsalesScript);
		
		mixpanel.init("235a53c3d9b3019b91a787d085078107");
	}, []);

	return (
		<IntlProvider locale={state.locale} messages={state.localeMessages}>
			<MuiThemeProvider theme={theme}>
				<Authenticator>
					<AdminProvider>
						<TermsAndCondition
							locale={state.locale}
							country={state.country}
							language={state.language}>
							<Routes />
						</TermsAndCondition>
					</AdminProvider>
				</Authenticator>
			</MuiThemeProvider>
		</IntlProvider>
	);
};

export default App;
