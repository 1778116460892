export const COLORS = {
	WHITE: "#fff",
	BLACK: "#000",
	BLUE: "#0826c1",
	BLUE_MID: "#e4e5f2",
	BLUE_DARK: "#2a3798",
	GREY_BASE: "#222222",
	GREY_MID: "#1d222c",
	GREY_DARK: "#DDDDDD",
	GREY_MID_LIGHT: "#eeeeee",
	GREY_MID_BASE: "#ebebeb",
	GREY_LIGHT: "rgba(255,255,255,0.5)"
};

export const SIZE = {
	BORDER_RADIUS: 4,
	TINY: 4,
	SMALL: 8,
	MEDIUM: 16,
	LARGE: 32,
	XLARGE: 48,
	XXLARGE: 64
};

export const BREAKPOINTS = {
	MOBILE_SMALL: 320,
	MOBILE_MEDIUM: 360,
	MOBILE_LARGE: 480,
	TABLET: 720,
	DEKSTOP: 1024,
	DEKSTOP_MEDIUM: 1200,
	DEKSTOP_LARGE: 1400
};

export const FONT = {
	SIZE: {
		SMALL: "0.75rem",
		BODY: "1rem",
		TITLE: "1.25rem",
		MID: "1.5rem",
		LARGE: "2.5rem"
	}
};

export const LINE_HEIGHT = {
	SIZE: {
		SMALL: "1rem",
		BODY: "1.25rem",
		TITLE: "1.5rem",
		MID: "1.7rem",
		LARGE: "3rem"
	}
};

export interface iWindow {
	width: number | undefined;
	height: number | undefined;
}

export const CACHE = {
	APPOINTMENTS: "PIIQ_APPOINTMENTS",
	CLIENTS: "PIIQ_CLIENTS",
	PRODUCTS: "PIIQ_PRODUCTS"
};
