import React from "react";
import {
	DotChart,
	DotValue,
	Dot,
	Line,
	Title,
	ChartRow,
	Labels
} from "./styles";

interface IInputProps {
	value: number;
	title: string;
	thumb?: string;
	labelLeft: string;
	labelRight: string;
}

const DotChartComponent = (props: IInputProps) => {
	return (
		<ChartRow>
			<DotChart value={props.value}>
				<Title>{props.title}</Title>

				<Dot />
				<Line />
				<Dot />
				<Line />
				<Dot />
				<Line />
				<Dot />
				<Line />
				<Dot />

				<DotValue value={props.value} />

				<Labels>
					<div>{props.labelLeft}</div>
					<div>{props.labelRight}</div>
				</Labels>
			</DotChart>
			{/* <Thumb src={props.thumb} alt={props.label} /> */}
		</ChartRow>
	);
};

export default DotChartComponent;
