import React, { forwardRef } from "react";
import { Panel } from "./styles";

interface IProps {
  children: React.ReactNode;
  style?: any;
  as?: any;
}

const ContentPanel = forwardRef((props: IProps, ref: any) => {
  return (
    <Panel as={props.as && props.as} style={props.style} ref={ref}>
      {props.children}
    </Panel>
  );
});

export default ContentPanel;
