import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Input, Button } from "../../components";
import { validateEmail, ZapierFreshworksNewOtherForm, INewOtherForm } from "../../utils";
import { SubscriptionType } from "../../screens/Registration/types";
import { ContactForm, Label, Loader } from "./styles";

interface IProps {
  subscription_type: SubscriptionType | string;
  subcription_quantity: number;
  bench_quantity: number;
  wall_quantity: number;
}

interface IErrors {
  first_name: string | null;
  last_name: string | null;
  salon: string | null;
  email: string | null;
  phone: string | null;
}

//@ts-ignore
const freshsales = window.freshsales || null;

const ContactFormComponent = (props: IProps, ref: any) => {
  const formErrors: IErrors = {
    first_name: null,
    last_name: null,
    salon: null,
    email: null,
    phone: null
  };
  const [ accountDetails, setAccountDetails ] = useState({
    first_name: "",
    last_name: "",
    salon: "",
    email: "",
    phone: ""
  });
  const [ errors, setErrors ] = useState(formErrors);
  const [ loading, setLoading ] = useState(false);
  const [ complete, setComplete ] = useState(false);

  const onInputChange = (value: string, name: string) => {
    setAccountDetails({
      ...accountDetails,
      [name]: value
    });
  };

  const onPhoneChange = (value: string) => {
    setAccountDetails({
      ...accountDetails,
      phone: value
    });
  };

  const onSubmit = () => {
    setLoading(true);
    const validForm = validateForm();
    if (validForm) onContactSubmit();
  };

  const validateForm = () => {
    const validate: IErrors = {
      first_name: !accountDetails.first_name ? "* First Name required" : null,
      last_name: !accountDetails.last_name ? "* Last Name required" : null,
      salon: !accountDetails.salon ? "* Salon required" : null,
      email: !accountDetails.email ? "* Email required" : null,
      phone: !accountDetails.phone ? "* Phone required" : null
    };
    if (!validateEmail(accountDetails.email)) {
      validate.email = "* Email invalid";
    }

    setErrors(validate);

    if (
      !validate.first_name &&
      !validate.last_name &&
      !validate.salon &&
      !validate.email &&
      !validate.phone
    )
      return true;
    else {
      setLoading(false);
      return false;
    }
  };

  const onContactSucess = (res: any) => {
    // console.log(res);
    setLoading(false);
    setComplete(true);
  };
  const onContactError = (error: any) => {
    console.log(error);
    setLoading(false);
  };

  const onContactSubmit = () => {
    let typeWanted = props.subscription_type;
    if (typeWanted === SubscriptionType[0]) {
      typeWanted = "Annual";
    } else {
      typeWanted = "Monthly";
    }

    const form : INewOtherForm = {
      firstName: accountDetails.first_name,
      lastName: accountDetails.last_name,
      email: accountDetails.email,
      mobileNumber: accountDetails.phone,
      colourBrand: "Other",
      subscriptionType: typeWanted,
      mirrorQuantity: props.subcription_quantity+'',
      salonName: accountDetails.salon
    };


    ZapierFreshworksNewOtherForm(form);
    setLoading(false);
    setComplete(true);

  };

  if (complete) {
    return (
      <ContactForm>
        <h2>
          <FormattedMessage id="signup.account.contact_form.complete" />
        </h2>
      </ContactForm>
    );
  }

  return (
    <ContactForm>
      <h2>
        <FormattedMessage id="signup.account.contact_form.title" />
      </h2>
      <Input
        name="first_name"
        onChange={onInputChange}
        placeholder={"First Name"}
        value={accountDetails.first_name}
        label="First Name*"
        error={errors.first_name}
      />
      <Input
        name="last_name"
        onChange={onInputChange}
        placeholder={"Last Name"}
        value={accountDetails.last_name}
        label="Last Name*"
        error={errors.last_name}
      />
      <Input
        onChange={onInputChange}
        placeholder={
          accountDetails ? accountDetails.salon : "Salon / Trading Name"
        }
        value={accountDetails.salon}
        label="Salon / Trading Name*"
        name="salon"
        error={errors.salon}
      />
      <Input
        onChange={onInputChange}
        placeholder={"Email Address"}
        value={accountDetails.email}
        label="Email Address*"
        name="email"
        error={errors.email}
      />
      <Label>
        <FormattedMessage id="signup.account.form.phone" />
      </Label>
      <div className="account-phone-input">
        <Input
          type="phone"
          onChange={onPhoneChange}
          value={accountDetails.phone}
          error={errors.phone}
        />
      </div>
      {loading ? (
        <Loader>
          <CircularProgress />
        </Loader>
      ) : (
        <Button onPress={onSubmit} label="ENQUIRE →" />
      )}
    </ContactForm>
  );
};

export default ContactFormComponent;
