import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      margin: theme.spacing(2)
    }
  })
);

export default function ProgressCircle () {
  const classes = useStyles();
  const [ progress, setProgress ] = React.useState(0);

  React.useEffect(() => {
    function tick () {
      // reset when reaching 100%
      setProgress(oldProgress => (oldProgress >= 100 ? 0 : oldProgress + 1));
    }

    const timer = setInterval(tick, 20);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div
      style={{
        alignItems: "center",
        bottom: "0px",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-around",
        left: "0px",
        position: "fixed",
        right: "0px",
        top: "0px",
        width: "100%",
        backgroundColor: "rgba(0,0,0,0.7)",
        zIndex: 9999
      }}>
      <CircularProgress
        className={classes.progress}
        variant="determinate"
        value={progress}
        color="secondary"
      />
    </div>
  );
}
