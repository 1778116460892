import styled from "styled-components";
import { COLORS, SIZE, FONT } from "../../config";

export const EditProductDialog = styled.div`
    max-height: 100%;
    max-width: 100%;
    width: 600px;
`;

export const Loading = styled.div`
	padding: 60px;
`;

export const ProductContainer = styled.div`
    display: flex;
    width: 50%;
    padding: 12px;

    p {
        margin: 0;
        margin-bottom: 6px;
        font-size: 14px;
    }

	img {
        max-height: 100%;
        max-width: 100%;
    }
`;


export const DialogHeader = styled.div`
padding: 24px 24px 0 24px;
h2 {
    margin: 0;
}
.MuiIconButton-root {
    padding: 0;
    padding-right: 8px;
}
`;

export const DialogFilter = styled.div`
	padding: 0 24px;

    .MuiInput-root {
        margin-right: 12px;
    }
`;


export const SearchInput = styled.input`
	width: 100%;
	padding: 0 ${SIZE.MEDIUM}px;
	background: ${COLORS.GREY_MID_LIGHT};
	border: 0;
	border-radius: ${SIZE.BORDER_RADIUS}px;
	height: 40px;
	outline: none;
	font-size: ${FONT.SIZE.BODY};
	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
`;