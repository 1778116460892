import styled from "styled-components";
import { SIZE, COLORS, FONT } from "../../../../config";

export const Container = styled.div`
  width: 100%;
  height: calc(100% - ${130 + SIZE.LARGE}px);
  margin: 0;
  padding-top: 130px;
  overflow: scroll;
  padding-bottom: ${SIZE.LARGE}px;
`;
export const Label = styled.div`
  font-size: ${FONT.SIZE.BODY};
  margin: ${SIZE.MEDIUM}px 0 ${SIZE.SMALL}px;
`;
export const Tags = styled.div`
  font-size: ${FONT.SIZE.BODY};
  color: ${COLORS.GREY_MID};
  padding: ${SIZE.SMALL}px;
  display: flex;
  flex-direction: row;
  cursor: pointer;

  span {
    display: none;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.05);
    span {
      display: block;
      color: ${COLORS.GREY_BASE};
      padding: 0 ${SIZE.SMALL}px;
      font-weight: 600;
    }
  }
`;
export const NoTags = styled.div`
  color: ${COLORS.BLACK};
  font-size: ${FONT.SIZE.BODY};
  opacity: 0.3;
`;
export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  .input-area {
    flex: 1;
    margin-right: ${SIZE.SMALL}px;
  }
  .button-area {
    width: 120px;
    height: 76px;
    button {
      height: 38px;
    }
  }
`;
export const Error = styled.div`
  padding: ${SIZE.MEDIUM}px 0 ${SIZE.LARGE}px;
  color: tomato;
`;

export const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${SIZE.LARGE}px 0 ${SIZE.MEDIUM}px;
  height: 40px;
  div {
    width: 30px !important;
    height: 30px !important;
  }
  svg {
    color: black;
  }
`;
