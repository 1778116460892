import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { UpdateSalonInput } from "../../graphql/globalTypes";
import { getAllSalons } from "../../actions/salonsActions";
import { startUpdateSalon } from "../../actions/salonsActions";
import { useSalons } from "../../contexts/AdminContext";
import { SubscriptionState } from "../../contexts/SubscriptionContext";
import { NavPanel } from "../../components";

import { Staff, Social, Rebooking, Complete, Products, Retail } from "./Sections";
import { SectionType, OnboardingDetails } from "./types";

import { ReactComponent as MenuButton } from "../../assets/Images/burger.svg";
import { ReactComponent as PiiqLogo } from "../../assets/Images/logo.svg";
import { EnableBodyScrolling } from "../../utils";
import {
	ScreenContainer,
	ContentContainer,
	SummaryContainer,
	SummaryContent,
	Link,
	MobileHeader,
	MenuIcon,
	MobileOverlay,
	ContactSupportLink
} from "./styles";

interface IProps {}

const ONBOARD_VERSION = "2020-02-06"; // KEEP TRACK OF ONBOARD VERSION

const OnboardingScreen = (props: IProps) => {
	const defaultDetails: OnboardingDetails = {
		staff: [],
		hashtags: [],
		rebooking_message: "",
		rebooking_delay_days: 1
	};
	// const context = SubscriptionState();
	const sections: SectionType[] = [
		SectionType.STAFF,
		SectionType.SOCIAL,
		SectionType.REBOOKING,
		SectionType.PRODUCTS,
		SectionType.RETAIL,
		SectionType.COMPLETE
	];
	const [ state, dispatch ] = useSalons();
	const [ salonId, setSalonId ] = useState("");
	const [ currentSection, setCurrentSection ] = useState() as any;
	const [ currentSectionIndex, setCurrentSectionIndex ] = useState(0);
	const [ completedSections, setCompletedSections ] = useState(0);
	const [ skippedSections, setSkippedSections ] = useState([]) as any[];
	const [ registrationDetails, setRegistrationDetails ] = useState(
		defaultDetails
	);
	const transitionConfig = {
		to: { opacity: 1, transform: `translateY(0)` },
		from: { opacity: 0, transform: `translateY(20px)` }
	};
	const [ navpanelOpen, setNavpanelOpen ] = useState(false);
	const [ onboardSetup, setOnboardSetup ] = useState(false);

	useEffect(() => {
		// Get the Salon ID from the address bar
		const pathId = window.location.pathname;
		const salId = pathId.split("/", 2);
		setSalonId(salId[1]);
		// disbale body scroll
		EnableBodyScrolling(false);
		document.title = "piiq Onboarding";

		// Get latest Salon State
		dispatch(getAllSalons());

		document.title = "Onboarding";
	}, []);


	useEffect(
		() => {
			/* Save the Onboarding flow state */
			completedSections > 0 &&
				completedSections !== sections.length - 1 &&
				saveOnboardFlow(false);
		},
		[ completedSections ]
	);

	useEffect(
		() => {
			if (state.salons.length && !onboardSetup) {
				state.salons.map(salon => {
					if (salon.id === salonId) {
						const onboardState =
							salon.onboardingState && JSON.parse(salon.onboardingState);
						if (salon.onboardingComplete) {
							const end = sections.length - 1;
							setCompletedSections(end);
							setCurrentSection(end);
							setCurrentSectionIndex(end);
						} else if (onboardState && onboardState.completedSections) {
							let startSection = onboardState.completedSections;
							setCompletedSections(startSection);
							setCurrentSection(startSection);
							setCurrentSectionIndex(startSection);
						} else {
							setCurrentSection(sections[currentSectionIndex]);
						}
						setOnboardSetup(true);
					}
				});
			}

			// 
			// setCurrentSection(SectionType.STAFF)
		},
		[ state ]
	);

	const onSectionChange = (section: SectionType) => {
		setCurrentSection(section);
		setCurrentSectionIndex(section);
	};

	const onCompleteSection = (state: any) => {
		const completed = currentSectionIndex + 1;
		setCompletedSections(completed);
	};

	const onCompleteFlow = () => {
		saveOnboardFlow(true);
	};

	const saveOnboardFlow = (onboardingComplete: boolean) => {
		const salonInput: UpdateSalonInput = {
			onboardingComplete: onboardingComplete,
			onboardingState: `{"version":"${ONBOARD_VERSION}","completedSections":${completedSections}}`
		};
		dispatch(startUpdateSalon(salonId, salonInput));
	};

	const updateRegistrationState = (state: OnboardingDetails) => {
		setRegistrationDetails({
			...registrationDetails,
			...state
		});
	};

	const renderSection = () => {
		switch (currentSection) {
			case SectionType.STAFF:
				return (
					<Staff
						onboardVersion={ONBOARD_VERSION}
						onNavigate={onSectionChange}
						onCompleteSection={onCompleteSection}
						transitionConfig={transitionConfig}
						registrationDetails={registrationDetails}
						updateRegistrationState={updateRegistrationState}
						salonId={salonId}
					/>
				);
			case SectionType.SOCIAL:
				return (
					<Social
						onboardVersion={ONBOARD_VERSION}
						onNavigate={onSectionChange}
						onCompleteSection={onCompleteSection}
						transitionConfig={transitionConfig}
						registrationDetails={registrationDetails}
						updateRegistrationState={updateRegistrationState}
						salonId={salonId}
					/>
				);
			case SectionType.REBOOKING:
				return (
					<Rebooking
						onboardVersion={ONBOARD_VERSION}
						onNavigate={onSectionChange}
						onCompleteSection={onCompleteSection}
						transitionConfig={transitionConfig}
						registrationDetails={registrationDetails}
						updateRegistrationState={updateRegistrationState}
						salonId={salonId}
					/>
				);
			case SectionType.PRODUCTS:
				return (
					<Products
						onboardVersion={ONBOARD_VERSION}
						onNavigate={onSectionChange}
						onCompleteSection={onCompleteSection}
						transitionConfig={transitionConfig}
						registrationDetails={registrationDetails}
						updateRegistrationState={updateRegistrationState}
						salonId={salonId}
					/>
				);
			case SectionType.RETAIL:
				return (
					<Retail
						onboardVersion={ONBOARD_VERSION}
						onNavigate={onSectionChange}
						onCompleteSection={onCompleteSection}
						transitionConfig={transitionConfig}
						registrationDetails={registrationDetails}
						updateRegistrationState={updateRegistrationState}
						salonId={salonId}
					/>
				);
			case SectionType.COMPLETE:
				return (
					<Complete
						onboardVersion={ONBOARD_VERSION}
						onNavigate={onSectionChange}
						transitionConfig={transitionConfig}
						onCompleteSection={onCompleteSection}
						registrationDetails={registrationDetails}
						updateRegistrationState={updateRegistrationState}
						salonId={salonId}
						onCompleteFlow={onCompleteFlow}
					/>
				);
		}
	};

	const renderHeader = () => {
		switch (currentSection) {
			case SectionType.STAFF:
				return <FormattedMessage id="registration.heading" />;
			case SectionType.SOCIAL:
				return <FormattedMessage id="registration.heading" />;
			case SectionType.REBOOKING:
				return <FormattedMessage id="registration.heading" />;
			case SectionType.RETAIL:
				return <FormattedMessage id="registration.heading" />;
			case SectionType.COMPLETE:
				return <FormattedMessage id="registration.heading" />;
		}
	};

	const onMobileNavOpen = () => {
		setNavpanelOpen(!navpanelOpen);
	};

	return (
		<ScreenContainer>
			<NavPanel
				type="REGISTRATION"
				activeSection={currentSection}
				onSectionChange={onSectionChange}
				completedSections={completedSections}
				skippedSections={skippedSections}
				open={navpanelOpen}
			/>
			<ContentContainer>
				<MobileOverlay open={navpanelOpen} onClick={onMobileNavOpen} />
				<MobileHeader>
					<PiiqLogo />
					<MenuIcon onClick={onMobileNavOpen}>
						<MenuButton />
					</MenuIcon>
				</MobileHeader>
				<div className="text-uppercase text-small">{renderHeader()}</div>
				{renderSection()}
			</ContentContainer>
			<SummaryContainer image={currentSection}>
				<div className="summary-header">
					<Link style={{marginLeft:"auto"}}>
						<ContactSupportLink href="mailto:info@piiqdigital.com"  white={currentSection === SectionType.STAFF || currentSection === SectionType.COMPLETE}>
							<FormattedMessage id="signup.subscription.contact-link" />
							<span>→</span>
						</ContactSupportLink>
					</Link>
				</div>
				<SummaryContent />
			</SummaryContainer>
		</ScreenContainer>
	);
};

export default OnboardingScreen;
