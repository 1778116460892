import React, { useEffect, useState, useRef } from "react";
import { animated, useSpring } from "react-spring";
import { FormattedMessage } from "react-intl";
import { withRouter, RouteComponentProps } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Auth } from 'aws-amplify';
import { VisibilityOff, Visibility } from "@material-ui/icons";
import { Input, Button, Content } from "../../../../components";
import { SubscriptionState } from "../../../../contexts/SubscriptionContext";
import { validPassword, EnableBodyScrolling } from "../../../../utils";
import { Relative, ShowPassword, Loader, Footer, FakeReadOnlyInput, Label } from "./styles";
import mixpanel from 'mixpanel-browser';

interface IProps {
    transitionConfig?: any;
  onSkipSection: Function;
  onNavigate: Function;
    history: any;
    location: any;
    match: any;
}
  
const PasswordComponent: React.SFC<IProps> = props => {

  const context = SubscriptionState();
  const contentScroll = useRef<HTMLDivElement>(null);
  const [ accountDetails ] = useState(context.state.accountDetails);
  const [ salonDetails ] = useState(context.state.salon);
  const [ errors, setError ] = useState() as any;
  const [ password, setPassword ] = useState() as any;
  const [ passwordConfirm, setPasswordConfirm ] = useState() as any;
  const [ passwordShow, setPasswordShow ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    if (contentScroll && contentScroll.current) {
      contentScroll.current.scrollTop = 0;
      window.scrollTo(0, 0);
    }
    mixpanel.track("PMA Register Account set up page accessed event");
  }, []);

  const onFinishPress = async () => {
    if (!validPassword(password)) {
        setError("* Your password does not meet criteria")
        return false
    }
    else if(password !== passwordConfirm) {
        setError("* Your passwords do not match")
        return false
    } else setError('')
    
    setLoading(true)

    /* Login the user, using temp credientials, then change to there new password */
    const username: string = accountDetails.email;
    // @ts-ignore
    const tempPassword: string = salonDetails.password
    await Auth.signIn({
        username,
        password: tempPassword
    }).then(async (user: any)  => {
        console.log('user',user);
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            await Auth.completeNewPassword(
                user,
                password,
                {}
            ).then(user => {
                setLoading(false)
                /* Users should be logged in with new password set, so progress to onboarding */
                console.log('user password should of changed', user);
              }).catch(e => {
                setError(e.message)
                setLoading(false)
              });
              
              // Enable body scrolling
              EnableBodyScrolling(true);
              /* Pass in there name as a route prop, as Onboarding doesnt access terms state */
              props.history.push(`/${salonDetails.id}/onboarding`, { username: `${accountDetails.first_name} ${accountDetails
                .last_name}` })
        }
    })
    .catch(err => {
        console.log('catch', err.message)
        setError(err.message)
        setLoading(false)
    });
  };

  const loginUser = async () => {
    setLoading(true)

    /* Login the user, using provided credientials*/
    const username: string = accountDetails.email;

    await Auth.signIn({
        username,
        password
    }).then(async (user: any)  => {
        // Enable body scrolling
        EnableBodyScrolling(true);
        /* Pass in there name as a route prop, as Onboarding doesnt access terms state */
        props.history.push(`/${salonDetails.id}/onboarding`, { username: `${accountDetails.first_name} ${accountDetails
          .last_name}` })
    })
    .catch(err => {
        setError(err.message)
        setLoading(false)
    });

  }

  const onInputChange = (value: string, name: string) => {
    if (name === "passwordConfirm") setPasswordConfirm(value);
    setPassword(value)
  };

  const onShowPassword = () => {
    setPasswordShow(!passwordShow);
  };

  const renderShowPassword = () => {
    return (
      <ShowPassword onClick={onShowPassword}>
        {passwordShow ? <>
          <Visibility />
        </> : <>
          <VisibilityOff/>
        </>}
      </ShowPassword>
    );
  };

  const onFormSubmit = (e) => {
    console.log('...')
    if (salonDetails.password) {
      console.log('1')
      if (!password || !passwordConfirm || password !== passwordConfirm || !validPassword(password)) {
        console.log('2')
        e.preventDefault();
      }
    } else {
      console.log('3')
      e.preventDefault();
    }
    e.preventDefault();
  }

  const transitionConfig = useSpring(props.transitionConfig);

  return (
    <Content as={animated.div} style={transitionConfig} ref={contentScroll}>
      <div style={{maxWidth: 900, margin: "0 auto", paddingBottom: 100}}>
      <h1>
        {salonDetails.password ? (
        <FormattedMessage id="signup.order.password.titleNewAccount" /> 
): <FormattedMessage id="signup.order.password.titleHasPassword" />}
      </h1>
      <div>
      {salonDetails.password ? (
        <FormattedMessage id="signup.order.password.setup" /> 
): <FormattedMessage id="signup.order.password.body" />}
      </div>
      <h2 className="margin-bottom-mid">
        {salonDetails.password ? (<FormattedMessage id="signup.account.form.password.title" />) :
        <>
          <FormattedMessage id="signup.order.password.confirm" />
          <div className="margin-bottom-mid"><FormattedMessage id="signup.order.password.confirmDetail" /></div>
        </>}
      </h2>
      <form onSubmit={onFormSubmit}>
      {salonDetails.password ? (
        <>
          <Relative>
            <Label>Your login email</Label>
            <FakeReadOnlyInput>
              {accountDetails.email}
            </FakeReadOnlyInput>
          </Relative>
          <Relative>
            <Input
              type={!passwordShow ? "password" : ""}
              name="password"
              onChange={onInputChange}
              label="New Password"
              error={errors}
            />
            {renderShowPassword()}
          </Relative>
          <Relative className="margin-bottom-mid">
            <Input
              type={!passwordShow ? "password" : ""}
              name="passwordConfirm"
              onChange={onInputChange}
              label="Confirm Password"
            />
            {renderShowPassword()}
          </Relative>
          <div className="text-blue">
            <FormattedMessage id="signup.account.form.password.confirm_nopw" />
          </div>
      </> ) : (
      <>
        <Relative>
        <Label>Your existing email</Label>
        <FakeReadOnlyInput>
          {accountDetails.email}
        </FakeReadOnlyInput>
        </Relative>

        <Relative>
          <Input
            type={!passwordShow ? "password" : ""}
            name="password"
            onChange={onInputChange}
            label="Password"
            error={errors}
          />
            {renderShowPassword()}
        </Relative>
      </>)
      }
      <div className="margin-bottom-mid">
      {loading ? (
          <Loader>
            <CircularProgress />
          </Loader>
        ) : (
        <Button type="submit" disabled={!password} onPress={salonDetails.password ? onFinishPress : loginUser} label={salonDetails.password ? "Create password" : "Connect"} />
        )}
      </div>
      </form>
      <Footer />
      </div>
    </Content>
  );
};

export default withRouter(PasswordComponent);
