import styled from "styled-components";
import { COLORS, SIZE, FONT, BREAKPOINTS } from "../../config";

const navWidth = 232;

interface INavPanel {
  open?: boolean;
}

interface INavItem {
  active: boolean;
  alive: boolean;
}

export const NavPanel = styled.div`
  width: calc(${navWidth}px - ${SIZE.LARGE * 2}px);
  height: 100%;
  position: fixed;
  background: ${COLORS.WHITE};
  z-index: 10;
  border-right: 1px solid ${COLORS.BLACK};
  padding: ${SIZE.LARGE}px;
  transform: translateX(0);
  transition: transform .3s ease;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    transform: ${(props: INavPanel) =>
      props.open ? `translateX(0)` : `translateX(${-navWidth}px) !important`};
  }
`;

export const LogoContainer = styled.div`
  svg {
    width: 72px;
    height: 24px;
    fill: ${COLORS.BLACK};
    path {
      fill: ${COLORS.BLACK};
    }
  }
`;

export const NavContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: ${SIZE.LARGE}px;
  height: calc(100% - 140px);

  @media (min-height: 600px) {
    padding-top: 140px;
    height: calc(100% - 240px);
  }
`;

export const NavList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  margin-left: -12px;
`;

export const NavItem = styled.li`
  position: relative;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${COLORS.BLACK};
  padding-left: 16px;
  margin: 0;
  margin-bottom: 16px;
  cursor: pointer;
  line-height: normal;
  opacity: ${(props: INavItem) => (props.alive ? 1 : 0.3)};
  pointer-events: ${(props: INavItem) => (props.alive ? "auto" : "none")};
  span {
    font-size: ${FONT.SIZE.BODY};
    padding: 0 0 2px;
    border-bottom: 1px solid
      ${(props: INavItem) => (props.active ? COLORS.BLACK : "transparent")};
    transition: border .2s .2s ease;
  }
  sup {
    margin-left: ${SIZE.SMALL}px;
    text-decoration: none;
    font-size: ${FONT.SIZE.SMALL};
    margin-top: -12px;
  }

  .dot {
    width: 10px;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid ${COLORS.BLACK};
    margin-right: 8px;
    /* position: absolute; */
    /* margin-top: 4px;
    margin-left: -18px; */
    /* top: 4px;
    left: 0px; */

    div {
      width: 6px;
      height: 6px;
      border-radius: 6px;
      background: ${COLORS.BLACK};
      transform: ${(props: INavItem) =>
        props.active ? "scale(1)" : "scale(0)"};
      transition: opacity .2s ease, transform .25s ease;
    }
  }

  &:hover {
    .dot {
      div {
        opacity: 0.5;
        transform: scale(0.7);
      }
    }
  }
`;

export const FooterLink = styled.div`
  display: flex;
  flex: 1;
  justify-self: flex-end;
  align-items: flex-end;
  font-size: ${FONT.SIZE.SMALL};
  span {
    padding: 0 ${SIZE.TINY}px 2px;
  }
  a:visited,
  a:link,
  a:active {
    text-decoration: none;
    color: ${COLORS.BLACK};
    padding: 0 0 2px;
    border-bottom: 1px solid ${COLORS.BLACK};
  }
  a:hover {
    text-decoration: none;
    color: ${COLORS.GREY_MID};
    padding: 0 0 2px;
    border-bottom: 1px solid ${COLORS.GREY_MID};
  }
`;
