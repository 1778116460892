import React, { Component } from "react";
import {
  Button,
  TextField,
  Typography,
  Grid,
  InputAdornment
} from "@material-ui/core";
import { Done, VisibilityOff, Visibility } from "@material-ui/icons";
import Auth from "@aws-amplify/auth";
import { FormattedMessage } from "react-intl";
import { ClearLocalStorage } from "../../../utils"
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api/lib/types";
import mixpanel from 'mixpanel-browser';
import { ReactComponent as Logo } from "../../../assets/Images/logo.svg";
import { CSSTransition } from 'react-transition-group';

// component
import "../style.css";

//types
interface Props {
  toggleView(view: string): void;
  sessionId: string;
  userEmail: string;
  animateInProp: boolean;
  redirectTo: string;
}

interface IState {
  error: string;
  animateIn: string;
  loading: string;
  password: string;
  passwordIsMasked: boolean;
  step: string;
  user: any;
}

export default class SignInForm extends Component<Props, IState> {
  state = {
    user: null,
    loading: 'true',
    error: "",
    password: "",
    passwordIsMasked: true,
    step: "signin",
    animateIn: 'false'
  };

  componentDidMount = () => {
    this.setState({ loading: 'false', animateIn : this.props.animateInProp ? 'true' : 'false' })
  }

  togglePasswordMask = () => {
    this.setState(prevState => ({
      passwordIsMasked: !prevState.passwordIsMasked
    }));
  };

  onChangePassword = (e: any) => {
    this.setState({ error: "", password: e.target.value });
  };

  onHandleSubmit = async (e: any) => {
    e.preventDefault(e);
    const { password } = this.state;
    const email = this.props.userEmail;
    this.setState({ loading : 'true' })

    if (password === "") {
      this.setState({ error: "Please enter a password" });
      this.setState({ loading : 'false' })
      return;
    }

    try {
      const cognitoUser = await Auth.signIn(email.trim(), password);
      console.log("SignIn::Submit::Success:", cognitoUser);
      mixpanel.identify(email.trim());
      if (
        cognitoUser &&
        cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED"
      ) {
        this.setState({ user: cognitoUser, step: "newpassword" });
      }

      this.setState({ loading : 'false' })
      this.props.toggleView(this.props.redirectTo);
      
      // // THEN START THE SESSION
      // const StartSession = `mutation StartSession($id: ID!, $input: UpdateSessionInput!){
      //   startSession(id: $id, input: $input){
      //       id
      //       userId
      //       salonId
      //       appointmentId
      //       existingAppointment
      //   }
      // }
      // `;

      // // NEED TO GET SESSION ID FROM URL
      // let sessionId = this.props.sessionId;

      // // START NEW SESSION WITH AUTHENTICATED USER?
      // try {
      //   const { data }: GraphQLResult | any = await API.graphql(
      //     graphqlOperation(StartSession, {
      //       id: sessionId,
      //       input: {
      //         devicePlatform:"ANDROID",
      //         deviceToken:"noToken",
      //       }
      //     })
      //   );
      //   console.log("new session data -", data);
      //   this.setState({ loading : 'false' })
      //   this.props.toggleView(this.props.redirectTo);
      // } catch (e) {
      //   this.setState({ loading : 'false' })
      //   this.props.toggleView(this.props.redirectTo);
      //   console.log(e);
      // }
      
    } catch (e) {
      //const error = e.message;
      const error =
        "Sorry, your password is wrong. Please try again or contact us at support@piiqdigital.com";
      console.log("SignIn::Submit::Error:", error, e.message);
      this.setState({ error, password: "" });
      this.setState({ loading : 'false' })

    }
  };

  render() {
    const { step, password, passwordIsMasked } = this.state;
    const { toggleView, userEmail } = this.props;
    return (
          <form onSubmit={this.onHandleSubmit}>

            <CSSTransition
              in={this.state.animateIn === 'true'}
              timeout={300}
              classNames="opacity">
            <Grid container direction="column" spacing={3} alignItems="stretch">
              <Grid item >
                <Grid container direction="row" justify="space-between">
                <Grid item>
                  <a onClick={() => {toggleView('landing')}} style={{fontSize: 24}}>‹ BACK</a>
                </Grid>
                <Grid item>
                  <Logo width={65} style={{marginTop: 4}}/>
                </Grid>
              </Grid>
              
              <Typography variant="h5" style={{marginTop: 48}}>
                <FormattedMessage id="signin.title" defaultMessage={`Log in to your account`} />
              </Typography>
              <p>Using <strong>{userEmail}</strong></p>
              <label style={{margin: "8px 0", display: "block"}}>Password</label>
                <TextField
                  placeholder="Password"
                  name="password"
                  value={password}
                  variant="outlined"
                  onChange={this.onChangePassword}
                  style={{ width: "100%", background: 'transparent', border: '1px solid white' }}
                  type={passwordIsMasked ? "password" : "text"}
                  InputProps={{
                    style: {
                      color: "white", 
                      height: 60
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        {passwordIsMasked ? (
                          <VisibilityOff
                            style={{ cursor: "pointer" }}
                            onClick={this.togglePasswordMask}
                          />
                        ) : (
                          <Visibility
                            style={{ cursor: "pointer" }}
                            onClick={this.togglePasswordMask}
                          />
                        )}
                      </InputAdornment>
                    )
                  }}
                />
                <Button
                      onClick={() => toggleView("forgot")}
                      style={{
                        color: 'white',
                        textTransform: "none",
                        textDecoration: "underline",
                        padding: 0,
                        margin: "12px 0 35px 0"
                      }}
                    >
                      <FormattedMessage
                        id="button.forgotpassword"
                        defaultMessage={`Forgot password?`}
                      />
                    </Button>

                {this.state.error !== "" && (
                    <Typography style={{marginBottom: 24, color:"#ffa0a0"}}>{this.state.error}</Typography>
                )}
                <button
                  type="button"
                  style={{ background:"#FFF", border: 0,width: "100%", borderRadius: 0, height: 60, textTransform: 'none', fontSize: 16 }}
                  onClick={this.onHandleSubmit}
                  disabled={this.state.loading === 'true'}>
                  {this.state.loading === 'true' ? (<>Loading...</>):(<FormattedMessage id="button.signup" defaultMessage={`Log in`} />)}
                </button>
              </Grid>
            </Grid>
            </CSSTransition>
          </form>
    );
  }
}
