import styled from "styled-components";

interface props {
	active?: boolean;
};

export const Container = styled.div`
  width: calc(100% - 50px);
  margin: 0;
  height: calc(100vh - 200px);
  min-height: 650px;

  .inner-child {
    min-height: 100%;
    max-width: 1000px;
    margin: 0 auto;
  }

  h2 {
    margin-top: 1em;
  }
`;

export const SettingsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  @media (max-width: 768px) {
    flex-direction: column;
    position: absolute;
    top: 140px;
    left: -10px;
    margin-bottom: 20px;
  }
`;
export const SalonColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 10px;

  @media (max-width: 1200px) {
    padding: 0px;
  }
`;
export const RebookDays = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0;
  padding: 0 20px;

  p {
    margin-right: 20px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const RebookInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  input[type=number] {
    /*for absolutely positioning spinners*/
    position: relative;
    padding: 5px;
    height: 40px;
    margin-right: 8px;
    height: 40px;
    width: 80px;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    opacity: 0;
  }

  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    width: 0px;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
  }
`;

export const RebookDelaySave = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 20px;

  button {
    width: 114px !important;

    @media (max-width: 768px) {
      width: 100% !important;
    }
  }
`;

export const RebookArrows = styled.div`
  display: flex;
  flex-direction: column;
  width: 25px;
  height: 50px;
`;

export const InputContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  align-items: center;

  label {
    width: 48%;
    margin-right: 2%;
    max-width: 250px;
    display: inline-block;
    line-height: 1
  }

  span {
    font-size: 10px;
    margin-top: 8px;
    display: block;
    opacity: 0.8
  }

  input {
    height: 40px;
    border-radius: 5px;
    border: 1px solid gray;
  }

  textarea, input {
    width: 40%;
  }

  .MuiInput-root {
    width: 50%;
  }
`;


export const CheckboxContainer = styled.div`
  display: inline-block;
  width: 50%;
`;


export const Brands = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
`

export const BrandCheckbox = styled.div<props>`
  width: 50%;
  cursor: pointer;
  max-width: 80px;
  padding: 15px;
  border-radius: 3px;
  margin-right: 12px;
  margin-bottom: 12px;
  border:  ${props => (props.active === true) ? '2px solid black' : '1px solid whitesmoke'};
  position: relative;
  display:flex;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }

  div {
    position: absolute;
    width: 100%;
    background: black;
    color: white;
    padding: 5px;
    bottom: 0px;
    left: 0;
    font-size: 8px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    text-align: center;
  }

  svg {
    display: ${props => (props.active === true) ? 'block' : 'none'};
    position: absolute;
    right: 5px;
    top: 5px;
    width: 10px;
    height: 10px;
  }
`
export const FixedFooter = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  right: 0;
  padding: 0 20px 20px 20px;
  z-index: 1300;
  display: flex;
  justify-content: flex-end;

  @media (min-width: 940px) {
    width: calc(100% - 200px)
  }
`
export const Tooltip = styled.div`
  background: rgba(255,159,0,0.05);
  border: 1px solid orange;
  color: #e19200;
  padding: 12px;
  border-radius: 5px;
  margin: 12px 0;
`