import React, { useEffect } from "react";
import { animated, useSpring } from "react-spring";
import { ReactComponent as Logo } from "../../assets/Images/logo.svg";
import { SummaryType } from "../../screens/Registration/types";
import { SectionType } from "../../screens/Onboarding/types";
import { FormattedMessage } from "react-intl";

import {
  NavPanel,
  LogoContainer,
  NavContainer,
  NavList,
  NavItem,
  FooterLink
} from "./styles";

interface IProps {
  onSectionChange: Function;
  activeSection: SummaryType | SectionType;
  completedSections: number;
  skippedSections: any[];
  type: "SUBSCRIPTION" | "REGISTRATION";
  open?: boolean;
}

const NavPanelComponent = (props: IProps) => {
  useEffect(() => {}, []);

  useEffect(
    () => {
      if (props.open) {
      }
    },
    [ props.open ]
  );

  const onNavPress = (section: SummaryType | SectionType) => {
    props.onSectionChange(section);
  };

  let navItems = [
    {
      summaryType: SummaryType.SUBSCRIPTION,
      textId: "signup.menu.subscription"
    },
    {
      summaryType: SummaryType.ACCOUNT,
      textId: "signup.menu.account"
    },
    {
      summaryType: SummaryType.DELIVERY,
      textId: "signup.menu.delivery"
    },
    {
      summaryType: SummaryType.PAYMENT,
      textId: "signup.menu.payment"
    },
    {
      summaryType: SummaryType.ORDER,
      textId: "signup.menu.order"
    },
    {
      summaryType: SummaryType.PASSWORD,
      textId: "signup.menu.password"
    }
  ]

  const renderSubscriptionList = () => {
    return (
      <NavList>
        
        {navItems.map((item, i) => (
          <NavItem
            alive={i <= props.activeSection}
            active={props.activeSection === item.summaryType}
            onClick={() => props.skippedSections.includes(i) ? (<></>) : onNavPress(item.summaryType)}>
            <div className="dot">
              <div />
            </div>
            <span style={{textDecoration: props.skippedSections.includes(i) ? 'line-through' : 'none'}}>
              <FormattedMessage id={item.textId} />
            </span>
          </NavItem>
        ))}
        
      </NavList>
    );
  };

  const renderRegistrationList = () => {
    return (
      <NavList>
        <NavItem
          alive={true}
          active={props.activeSection === SectionType.STAFF}
          onClick={() => onNavPress(SectionType.STAFF)}>
          <div className="dot">
            <div />
          </div>
          <span>
            <FormattedMessage id="registration.menu.staff" />
          </span>
          <sup>01</sup>
        </NavItem>
        <NavItem
          alive={props.completedSections >= 1}
          active={props.activeSection === SectionType.SOCIAL}
          onClick={() => onNavPress(SectionType.SOCIAL)}>
          <div className="dot">
            <div />
          </div>
          <span>
            <FormattedMessage id="registration.menu.hashtags" />
          </span>
          <sup>02</sup>
        </NavItem>
        <NavItem
          alive={props.completedSections >= 2}
          active={props.activeSection === SectionType.REBOOKING}
          onClick={() => onNavPress(SectionType.REBOOKING)}>
          <div className="dot">
            <div />
          </div>
          <span>
            <FormattedMessage id="registration.menu.rebooking" />
          </span>
          <sup>03</sup>
        </NavItem>
        <NavItem
          alive={props.completedSections >= 3}
          active={props.activeSection === SectionType.RETAIL}
          onClick={() => onNavPress(SectionType.RETAIL)}>
          <div className="dot">
            <div />
          </div>
          <span>
            <FormattedMessage id="registration.menu.retail" />
          </span>
          <sup>04</sup>
        </NavItem>
        <NavItem
          alive={props.completedSections >= 4}
          active={props.activeSection === SectionType.COMPLETE}
          onClick={() => onNavPress(SectionType.COMPLETE)}>
          <div className="dot">
            <div />
          </div>
          <span>
            <FormattedMessage id="registration.menu.complete" />
          </span>
          <sup>05</sup>
        </NavItem>
      </NavList>
    );
  };

  const transitionConfig = useSpring({});

  return (
    <NavPanel as={animated.div} style={transitionConfig} open={props.open}>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <NavContainer>
        {props.type === "SUBSCRIPTION" ? (
          renderSubscriptionList()
        ) : (
          renderRegistrationList()
        )}
        <FooterLink>
          <span>← </span>
          <a href="http://www.piiqdigital.com" title="Piiq Digital">
            <FormattedMessage id="signup.subscription.site-link" />
          </a>
        </FooterLink>
      </NavContainer>
    </NavPanel>
  );
};

export default NavPanelComponent;
