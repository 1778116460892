import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { StaticContext } from "react-router";
import { useAppointments } from "../../contexts/AdminContext";
import {
	getAllAppointments,
	startGetAllAppointments
} from "../../actions/appointmentsActions";
import { getAllSalons } from "../../actions/salonsActions";
import { useSalons } from "../../contexts/AdminContext";
import { Notification, Layout, Fetching} from "../../components";
import { useLocalStorage } from "../../utils";
import { CACHE } from "../../config"
import { SectionHeader, StatRow, StatColumn, TipRow, Container } from "./styles";
import mixpanel from 'mixpanel-browser';
import moment from "moment";

interface IPathParams {
	salonId: string;
	appointmentId?: string;
}

export default function WeeklyReportScreen ({
	location,
	match,
	history
}: React.PropsWithChildren<
RouteComponentProps<IPathParams, StaticContext, any>
>) {
	const [appointmentsCache, setAppointmentsCache] = useLocalStorage(CACHE.APPOINTMENTS, []);
	const { salonId } = match.params;
	const [ salonState, salonDispatch ] = useSalons();
	const [ state, dispatch ] = useAppointments();
	const { loading, appointments } = state;
	const [ isAdminOfThisSalon, setIsAdminOfThisSalon ] = useState(false)

	const [ lastWeekStats, setLastWeekStats ] = useState({}) as any;
	const [ thisWeekStats, setThisWeekStats ] = useState({}) as any;
	const [ avgSalonStats, setAvgSalonStats ] = useState({
		retail: 12,
		rebook: 45,
		treatment: 4
	}) as any;
	
	const [ retailTipsExpanded, setRetailTipsExpanded ] = useState(false);
	const [ rebookTipsExpanded, setRebookTipsExpanded ] = useState(false);
	const [ treatmentTipsExpanded, setTreatmentTipsExpanded ] = useState(false);
	
	const [ isLoading, setIsLoading ] = useState(true);


	const nowDate = moment(new Date());

	useEffect(() => {
		dispatch(startGetAllAppointments(salonId));
		// if(appointmentsCache) dispatch(getAllAppointments(appointmentsCache, null));
		salonDispatch(getAllSalons());
		document.title = "Weekly report";
		mixpanel.track("PMA Weekly report - Page accessed");
	}, []);

	useEffect(() => {
		if(appointments.length) { 
			// setAppointmentsCache(appointments)
			getAppointments();
		}
	}, [appointments]);

	useEffect(() => {
		console.log('salonState.salons', salonState.salons)
		if (salonState.salons && salonState.salons.length > 0) {
			let isAdminOfAnySalons = false;
			let isClientOfAnySalons = false;
			salonState.salons && salonState.salons.map(salon => {
				if (salon.salonAdmin) isAdminOfAnySalons = true;
				if (salon.salonClient) isClientOfAnySalons = true;
				if (salon.id === salonId && salon.salonAdmin) {
					console.log('is admin of this salon', salon)
					setIsAdminOfThisSalon(true);
					// If Onboard hasn't complete push them to this
					if(!salon.onboardingComplete) history.push(`/${salonId}/onboarding`)
				}
			})
			if (isClientOfAnySalons && !isAdminOfAnySalons) {
				history.push(`/signup`)
			}
		}
	}, [salonState]);

	const getAppointments = () => {
		let lastWeek = {
			total: 0,
			retail: 0,
			rebook: 0,
			treatment: 0,
			retailPercent: '0',
			rebookPercent: '0',
			treatmentPercent: '0'
		}
		let thisWeek = {
			total: 0,
			retail: 0,
			rebook: 0,
			treatment: 0,
			retailPercent: '0',
			rebookPercent: '0',
			treatmentPercent: '0'
		}
		
		appointments.map(appt => {
			const appointmentDate = moment(new Date(appt.createdAt));
			const thisYear = appointmentDate.year() === nowDate.year();
			// IF THIS WEEK
			if(thisYear && appointmentDate.week() === nowDate.week()) {
				thisWeek.total += 1;
				console.log('appt', appt)
				if (appt.supportYourSalon) {
					if (appt.supportYourSalon.treatment) {
						thisWeek.treatment += 1;
					}
					if (appt.supportYourSalon.recommendations) {
						thisWeek.retail += 1;
					}
				}
				if (appt.rebookLength) {
					thisWeek.rebook += 1;
				}
			}
			// IF LAST WEEK
			if(thisYear && appointmentDate.week() === moment(new Date()).subtract(1, 'weeks').week()) {
				lastWeek.total += 1;
				console.log('appt', appt)
				if (appt.supportYourSalon) {
					if (appt.supportYourSalon.treatment) {
						lastWeek.treatment += 1;
					}
					if (appt.supportYourSalon.recommendations) {
						lastWeek.retail += 1;
					}
				}
				if (appt.rebookLength) {
					lastWeek.rebook += 1;
				}
			}
		})
		let lastWeekTotal = lastWeek.total;
		let thisWeekTotal = thisWeek.total;

		if (lastWeekTotal === 0) lastWeekTotal = 1
		if (thisWeekTotal === 0) thisWeekTotal = 1

		thisWeek.retailPercent = (thisWeek.retail / thisWeekTotal * 100).toFixed(0)
		thisWeek.rebookPercent = (thisWeek.rebook / thisWeekTotal * 100).toFixed(0)
		thisWeek.treatmentPercent = (thisWeek.treatment / thisWeekTotal * 100).toFixed(0)

		lastWeek.retailPercent = (lastWeek.retail / lastWeekTotal * 100).toFixed(0)
		lastWeek.rebookPercent = (lastWeek.rebook / lastWeekTotal * 100).toFixed(0)
		lastWeek.treatmentPercent = (lastWeek.treatment / lastWeekTotal * 100).toFixed(0)

		setLastWeekStats(lastWeek);
		setThisWeekStats(thisWeek);

		setIsLoading(false);
	}


	return (
		<Layout>
			<Notification />
			{isAdminOfThisSalon ? (
				<>
					<SectionHeader style={{paddingTop:"50px"}}>Weekly report</SectionHeader>
					<Container>
						{isLoading ? (
							<>Loading...</>
						) : (
						<>
						<StatRow>
							<h3></h3>	
							<StatColumn>
								<h4>This week</h4>
								<h4>Last week</h4>
							</StatColumn>
						</StatRow>
						<StatRow>
							<h3 style={{marginBottom: 24}}>Total appts</h3>
							<StatColumn>
								<h3>{thisWeekStats.total}</h3>
								<h3 style={{opacity: 0.6}}>{lastWeekStats.total}</h3>
							</StatColumn>
						</StatRow>
						<StatRow>
							<h3>Retail</h3>	
							<StatColumn>
								<h3>{thisWeekStats.retailPercent}% <span>{parseInt(thisWeekStats.retailPercent) !== parseInt(lastWeekStats.retailPercent) ? parseInt(thisWeekStats.retailPercent) < parseInt(lastWeekStats.retailPercent) ? '↓' : '↑' : ''}</span></h3>
								<h3 style={{opacity: 0.6}}>{lastWeekStats.retailPercent}%</h3>
							</StatColumn>
						</StatRow>
						<StatRow>
							<StatColumn>
								<p>Avg from similar salons</p>
							</StatColumn>
							<StatColumn>
								<p>{avgSalonStats.retail}%</p>
							</StatColumn>
						</StatRow>
						<TipRow 
							onClick={() => setRetailTipsExpanded(!retailTipsExpanded)}
							status={parseInt(thisWeekStats.retailPercent) >= avgSalonStats.retail ? 'good' : 'bad'}>
							{parseInt(thisWeekStats.retailPercent) >= avgSalonStats.retail ? `Great work! You're crushing retail.` : 'Want to increase retail?'}
							<span>View tips {retailTipsExpanded ? '-' : '+'}</span>
							<ul style={{display: retailTipsExpanded ? 'block' : 'none'}}>
								<li>Give your next client a hair analysis</li>
								<li>Leave the tech out of it, just talk about their hair</li>
								<li>Change the recommended products</li>
							</ul>
						</TipRow>

						<StatRow>
							<h3>Rebook</h3>	
							<StatColumn>
								<h3>{thisWeekStats.rebookPercent}% <span>{parseInt(thisWeekStats.rebookPercent) !== parseInt(lastWeekStats.rebookPercent) ? parseInt(thisWeekStats.rebookPercent) < parseInt(lastWeekStats.rebookPercent) ? '↓' : '↑' : ''}</span></h3>
								<h3 style={{opacity: 0.6}}>{lastWeekStats.rebookPercent}%</h3>
							</StatColumn>
						</StatRow>
						<StatRow>
							<StatColumn>
								<p>Avg from similar salons</p>
							</StatColumn>
							<StatColumn>
								<p>{avgSalonStats.rebook}%</p>
							</StatColumn>
						</StatRow>
						<TipRow 
							onClick={() => setRebookTipsExpanded(!rebookTipsExpanded)}
							status={parseInt(thisWeekStats.rebookPercent) >= avgSalonStats.rebook ? 'good' : 'bad'}>
							{parseInt(thisWeekStats.rebookPercent) >= avgSalonStats.rebook ? `Great work! You're crushing it.` : 'Want to increase rebookings?'}
							<span>View tips {rebookTipsExpanded ? '-' : '+'}</span>
							<ul style={{display: rebookTipsExpanded ? 'block' : 'none'}}>
								<li>Add your online booking url</li>
								<li>With your team come up with 3 rebook goals</li>
								<li>Try to rebook your very next client</li>
							</ul>
						</TipRow>

						<StatRow>
							<h3>Treatment</h3>	
							<StatColumn>
								<h3>{thisWeekStats.treatmentPercent}% <span>{parseInt(thisWeekStats.treatmentPercent) !== parseInt(lastWeekStats.treatmentPercent) ? parseInt(thisWeekStats.treatmentPercent) < parseInt(lastWeekStats.treatmentPercent) ? '↓' : '↑' : ''}</span></h3>
								<h3 style={{opacity: 0.6}}>{lastWeekStats.treatmentPercent}%</h3>
							</StatColumn>
						</StatRow>
						<StatRow>
							<StatColumn>
								<p>Avg from similar salons</p>
							</StatColumn>
							<StatColumn>
								<p>{avgSalonStats.treatment}%</p>
							</StatColumn>
						</StatRow>
						<TipRow 
							onClick={() => setTreatmentTipsExpanded(!treatmentTipsExpanded)}
							status={parseInt(thisWeekStats.treatmentPercent) >= avgSalonStats.treatment ? 'good' : 'bad'}>
							{parseInt(thisWeekStats.treatmentPercent) >= avgSalonStats.treatment ? `Great work! You're crushing it.` : 'Want to increase treatments?'}
							<span>View tips {treatmentTipsExpanded ? '-' : '+'}</span>
							<ul style={{display: treatmentTipsExpanded ? 'block' : 'none'}}>
								<li>Give your next client a hair analysis</li>
								<li>Leave the teach out of it, just talk about them</li>
								<li>Change the recommended treatment</li>
							</ul>
						</TipRow>
						</>
						)}
					</Container>
				</>
			) : (
				<p>You aren't an admin of this salon. Try logging out and logging in again.</p>
			)}
			<Fetching />
		</Layout>
	);
}
