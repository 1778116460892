import styled from "styled-components";
import { COLORS, SIZE, FONT } from "../../config";

interface iProps {
	disabled: boolean;
}
export const Button = styled.button`
	width: calc(100% - ${SIZE.MEDIUM}px);
	height: 44px;
	max-width: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${COLORS.BLACK};
	color: ${COLORS.WHITE};
	font-weight: 400;
	border: 1px solid transparent;
	outline: none;
	border-radius: ${SIZE.BORDER_RADIUS}px;
	margin: ${SIZE.LARGE}px 0 ${SIZE.MEDIUM}px;
	opacity: ${(props: iProps) => (props.disabled ? 0.1 : 1)};
	pointer-events: ${(props: iProps) => (props.disabled ? "none" : "auto")};
	cursor: pointer;
	font-size: ${FONT.SIZE.TITLE};
	transition: background .2s ease, color .3s ease;

	&:hover {
		background: ${COLORS.WHITE};
		color: ${COLORS.BLACK};
		border: 1px solid ${COLORS.BLACK};
	}
`;


export const SubmitButton = styled.input`
	width: calc(100% - ${SIZE.MEDIUM}px);
	height: 44px;
	max-width: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${COLORS.BLACK};
	color: ${COLORS.WHITE};
	font-weight: 400;
	letter-spacing: 1px;
	text-transform: uppercase;
	border: 1px solid transparent;
	outline: none;
	border-radius: ${SIZE.BORDER_RADIUS}px;
	margin: ${SIZE.LARGE}px 0 ${SIZE.MEDIUM}px;
	opacity: ${(props: iProps) => (props.disabled ? 0.1 : 1)};
	pointer-events: ${(props: iProps) => (props.disabled ? "none" : "auto")};
	cursor: pointer;
	font-size: ${FONT.SIZE.TITLE};
	transition: background .2s ease, color .3s ease;

	&:hover {
		background: ${COLORS.WHITE};
		color: ${COLORS.BLACK};
		border: 1px solid ${COLORS.BLACK};
	}
`;
