import { API, graphqlOperation } from "aws-amplify";
import { loader } from "graphql.macro";
import { GraphQLResult } from "@aws-amplify/api/lib/types";
import {
  GetSalonCatalogBrands,
  GetSalonCatalogBrandsVariables,
  GetSalonCatalogBrands_getSalon_catalog_allBrands_items,
  GetSalonCatalogBrands_getSalon_enabledBrands_items
} from "../graphql/GetSalonCatalogBrands";
import {
	GetAllBrands,
	GetAllBrands_allBrands_items,
  GetAllBrandsVariables
} from "../graphql/GetAllBrands";

import { DisableBrandForSalonVariables } from "../graphql/DisableBrandForSalon";
import { EnableBrandForSalonVariables } from "../graphql/EnableBrandForSalon";
import {
  IBrandAction,
  ISetBrandsAction,
  ISetCatalogBrandsAction,
  IEnableBrandAction,
  IDisableBrandAction,
  IBrand,
  ISetLoadingAction
} from "../reducers/BrandsReducer";
const GetSalonCatalogBrandsGQL = loader(
  "./../graphql/GetSalonCatalogBrands.graphql"
);
const EnableBrandForSalonGQL = loader(
  "./../graphql/EnableBrandForSalon.graphql"
);
const DisableBrandForSalonGQL = loader(
  "./../graphql/DisableBrandForSalon.graphql"
);
const GetAllBrandsGQL = loader("./../graphql/GetAllBrands.graphql");



//Get all Catalog Brands
export const getAllBrands = (
  brands: any[],
	nextToken: string | null
): ISetBrandsAction => ({
  type: "SET_BRANDS",
  brands
});

export const startGetAllBrands = (
  limit?: number | null,
  nextToken?: string | null
) => {
  return async (dispatch: React.Dispatch<IBrandAction>, state: any) => {
    const result = (await API.graphql(
      graphqlOperation(GetAllBrandsGQL, {limit, nextToken})
    )) as GraphQLResult;
    console.log("getAllBrands...", result)
    if (result.data) {
			const data = result.data as GetAllBrands;
			const nextToken =
				(data &&
					data.allBrands &&
					data.allBrands.nextToken) ||
				null;

			const items =
				data &&
				data.allBrands &&
				data.allBrands.items
					? (data.allBrands.items.filter(
							item => item !== null
						) as GetAllBrands_allBrands_items[])
					: [];
        console.log('brand items', items);
			dispatch(getAllBrands(items, nextToken));
		}
  };
};

//Get all Catalog Brands
export const getCatalogAllBrands = (
  brands: IBrand[]
): ISetCatalogBrandsAction => ({
  type: "SET_CATALOG_BRANDS",
  brands
});

export const startGetCatalogAllBrands = (
  salonId: string,
  limit?: number | null,
  nextToken?: string | null
) => {
  return async (dispatch: React.Dispatch<IBrandAction>, state: any) => {
    const params: GetSalonCatalogBrandsVariables = {
      salonId,
      limit,
      nextToken
    };

    const result = (await API.graphql(
      graphqlOperation(GetSalonCatalogBrandsGQL, params)
    )) as GraphQLResult;
    if (result.data) {
      const data = result.data as GetSalonCatalogBrands;
      const enabledBrands =
        (data?.getSalon?.enabledBrands?.items?.filter(
          i => i !== null
        ) as GetSalonCatalogBrands_getSalon_enabledBrands_items[]) ?? [];
      const items =
        (data?.getSalon?.catalog?.allBrands?.items?.filter(
          item => item !== null
        ) as GetSalonCatalogBrands_getSalon_catalog_allBrands_items[]).map(
          item => ({
            id: item.id,
            name: item.name || "",
            brandActive: item.active || false,
            active:
              enabledBrands.findIndex(
                i => i.brand && i.brand.id === item.id
              ) !== -1
          })
        ) ?? [];
      dispatch(getCatalogAllBrands(items));
    }
  };
};

export const enableBrand = (
  brandId: string,
  salonId: string
): IEnableBrandAction => ({
  type: "ENABLE_BRAND",
  brandId,
  salonId
});

export const startEnableBrand = (brandId: string, salonId: string) => {
  return async (dispatch: React.Dispatch<IBrandAction>, state: any) => {
    dispatch(setLoading(true));
    const result = await API.graphql(
      graphqlOperation(EnableBrandForSalonGQL, {
        brandId,
        salonId
      } as EnableBrandForSalonVariables)
    );
    console.log("Result Enable Brand", { brandId, salonId, result });
    dispatch(enableBrand(brandId, salonId));
  };
};

export const disableBrand = (
  brandId: string,
  salonId: string
): IDisableBrandAction => ({
  type: "DISABLE_BRAND",
  brandId,
  salonId
});

export const startDisableBrand = (brandId: string, salonId: string) => {
  return async (dispatch: React.Dispatch<IBrandAction>, state: any) => {
    dispatch(setLoading(true));
    const result = await API.graphql(
      graphqlOperation(DisableBrandForSalonGQL, {
        brandId,
        salonId
      } as DisableBrandForSalonVariables)
    );
    console.log("Result Disable Brand", { brandId, salonId, result });
    dispatch(disableBrand(brandId, salonId));
  };
};

export const setLoading = (loading: boolean): ISetLoadingAction => ({
  type: "SET_LOADING",
  loading
});
