import React from "react";
import TabNavbar from "../../components/TabNavbar";
import { H1 } from "../../components";
import { FormattedMessage } from "react-intl";

const tabs = [
  {
    name: "TODAY",
    title: "TODAY'S APPOINTMENTS",
    path: "appointments?navParam=TODAY"
  },
  {
    name: "YESTERDAY",
    title: "YESTERDAY'S APPOINTMENTS",
    path: "appointments?navParam=YESTERDAY"
  },
  {
    name: "THISWEEK",
    title: "THIS WEEK'S APPOINTMENTS",
    path: "appointments?navParam=THISWEEK"
  },
  {
    name: "ALLTIME",
    title: "ALL TIME APPOINTMENTS",
    path: "appointments?navParam=ALLTIME"
  }
];

interface IAppointmentNavbarProps {
  name: string;
  onChange: (path: string) => void;
}

export default function AppointmentNavbar(props: IAppointmentNavbarProps) {
  return (
    <>
      <H1>
        <FormattedMessage
          id="appointments.pageTitle"
          defaultMessage={`ACTIVITIES`}
        />
      </H1>
      <TabNavbar name={props.name} tabs={tabs} onChange={props.onChange} />
    </>
  );
}
