import React, { useState, useEffect } from "react";
import { Grid, Modal } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { ReactComponent as Logo } from "../../../assets/Images/logo.svg";
import { CSSTransition } from 'react-transition-group';
import DownloadPlaystore from "../../../assets/Images/download_playstore.png";
import DownloadAppstore from "../../../assets/Images/download_appstore.png";
import AWS from 'aws-sdk';
import "../style.css";
import {
	startGetUserAppointment
} from "../../../actions/appointmentsActions";
import { useAppointments } from "../../../contexts/AdminContext";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CloseIcon from '@material-ui/icons/Close';

AWS.config.update({
	accessKeyId: 'AKIA3U3BDIH2EPCIWMW2', secretAccessKey: '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', region: 'us-west-2'
});
const dynamoDB = new AWS.DynamoDB.DocumentClient();


//types
interface Props {
  toggleView(view: string): void;
  currentAppointmentId: string;
  animateInProp: boolean;
  userEmail: string;
  userId: string;
  currentSalonId: string;
}

const Success = (props:Props) => {
  const [ appointmentState, appointmentDispatch ] = useAppointments();
  const [ animateIn, setAnimateIn ] = useState('false');
  const [ appointment, setAppointment ] = useState({}) as any;
  const [ productRecommendations, setProductRecommendations ] = useState([]) as any[];
  const [ loadingAppointment, setLoadingAppointment ] = useState(true);
	const [ productDetailsModalVisible, setProductDetailsModalVisible ] = useState(false);
  const [ productDetails, setProductDetails ] = useState({}) as any;
  
  async function getAppointment() {
    if(props.currentSalonId) {
      setLoadingAppointment(true)
      console.log(props.currentSalonId, props.currentAppointmentId)
      let r = await appointmentDispatch(startGetUserAppointment(props.currentSalonId, props.currentAppointmentId)) as any;				
      setAppointment(r)
      console.log(r);
      if (r?.supportYourSalon) setProductRecommendations(r?.supportYourSalon.recommendations)
      setLoadingAppointment(false)
    }
  }

  useEffect(() => {
    setAnimateIn(props.animateInProp ? 'true' : 'false')
    window.scrollTo(0, 0);
    getAppointment();
  }, [])
  
  const toggleSelect = (_r:any) => {
    let _productRecommendations = [...productRecommendations] as any[];
    for (let r of _productRecommendations) {
      if (r.sku === _r.sku) r.isSelected = !r.isSelected;
    }
    console.log(_productRecommendations);
    setProductRecommendations(_productRecommendations);
  }

  return (
    <>
    <CSSTransition
      in={animateIn === 'true'}
      timeout={300}
      classNames="opacity">
    <Grid container direction="column" spacing={10} alignItems="stretch">
      <Grid item>
        <Logo width={95} style={{marginTop: 20}}/>
        <h1 id="title">
          <FormattedMessage id="success.title" defaultMessage={`Success`} />
        </h1>
        <p style={{marginBottom: 24}}>
          <FormattedMessage id="success.content" defaultMessage={`You’re connected to piiq Pro as ${props.userEmail}. Look up and see for yourself!`} />
        </p>
        <hr style={{margin: "35px 0", opacity: 0.4}}></hr>
        {loadingAppointment ? 
          <>Loading..</> 
        : (!productRecommendations || productRecommendations.length === 0) ? (
            <>You don't have any products recommended yet</>
          ) : productRecommendations.map((r:any) => 
            <div style={{display:"flex", flexDirection:"row", alignItems:"center"}} >
              <div onClick={() => toggleSelect(r)}>
                {r.isSelected ? <CheckCircleOutlineIcon htmlColor={"#FFF"} /> : <RadioButtonUncheckedIcon htmlColor={"#FFF"} />}
              </div>
              <div onClick={() => toggleSelect(r)}>
                <img src={r.productImageMain} width={100} height={100} />
              </div>
              <div>
                <p>{r.productName}<br />
                {r.productType}<br />
                ${r.rrp}</p>
                <p onClick={() => {setProductDetails(r); setProductDetailsModalVisible(true); }}>View details</p>
              </div>
            </div>
          )}
        <button
            type="button"
            style={{ marginTop: 12, display:"block", background:"transparent", color: "#FFF", border: "1px solid white",  width: 100, borderRadius: 0, height: 30, textTransform: 'none', fontSize: 16, marginLeft: "auto" }}
            onClick={() => getAppointment()}
            disabled={loadingAppointment}>
              Refresh
          </button>
        
        <hr style={{margin: "35px 0", opacity: 0.4}}></hr>
        <a onClick={() => props.toggleView('appointments')}>View past appointments</a>
        <hr style={{margin: "35px 0", opacity: 0.4}}></hr>
            <p style={{marginBottom: 24}}>Download the piiq app to keep your records in your pocket wherever you go.</p>
            <a href="http://apps.apple.com/au/app/piiq/id1458062799" target="_blank">
              <img
                src={DownloadAppstore}
                style={{
                  width: "48%",
                  height: "auto",
                  marginRight: '2%'
                }}
              />
            </a>
            <a href="http://play.google.com/store/apps/details?id=com.piiq&hl=en&gl=US" target="_blank">
              <img
                src={DownloadPlaystore}
                style={{
                  width: "48%",
                  height: "auto"
                }}
              />
            </a>
      </Grid>
         {/* Product details modal */}
         <Modal
        open={productDetailsModalVisible}
        onClose={() => setProductDetailsModalVisible(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={{background: "white", width: "80%", padding: 20, margin: "24px auto"}}>
          <CloseIcon style={{float: "right"}} onClick={() => setProductDetailsModalVisible(false)}/>
          <h2>{productDetails.productName}</h2>
          <p>{productDetails.productSize} {productDetails.productType}</p>
          <p>{productDetails.bullets}</p>
          <p>{productDetails.description}</p>
        </div>
      </Modal>
    </Grid>
    </CSSTransition>
    </>
  );
}
export default Success;