import * as Observable from "zen-observable";
import { API, graphqlOperation } from "aws-amplify";
import { loader } from "graphql.macro";
import { useParams } from "react-router-dom";
import { useAppointments } from "../contexts/AdminContext";
import {
	startUpdateAppointment,
	startUpdateCart,
	startGetAppointment
} from "../actions/appointmentsActions";
import { useEffect } from "react";
import mixpanel from 'mixpanel-browser';

const AppointmentUpdatedSubGQL = loader(
	"../graphql/AppointmentUpdatedSub.graphql"
);
const CartUpdatedSubGQL = loader("../graphql/CartUpdatedSub.graphql");
interface IPathParams {
	salonId: string;
	appointmentId?: string;
}
interface iProps {
	children: any;
	salonId: string;
}

type UseSubscriptionsReturn = {
	startCartSubscription: Function
	startAppointmentSubscription: Function
	stopCartSubscription: Function
	stopAppointmentSubscription: Function
  }

let cartSubscription, appointmentSubscription;

export function useSubscriptions(): UseSubscriptionsReturn {

	const [ state, dispatch ] = useAppointments();
	const { salonId, appointmentId } = useParams();

	useEffect(() => {
		// Let's check if the browser supports notifications
		if (!("Notification" in window)) {
			// alert("This browser does not support desktop notification");
			console.log("Notifications not supported :(")
		} else {
			Promise.resolve(Notification.requestPermission())
		}
	}, [])

	const startCartSubscription = (updateState: boolean = true) => {
		if(salonId) {
			cartSubscription = (API.graphql(
				graphqlOperation(CartUpdatedSubGQL, { salonId })
				) as Observable<object>).subscribe({
					next: async (eventData: any) => {
						// console.log('cart updated', eventData?.value?.data)
						/* Fetch the latest appointment details */
						const cartData = eventData.value.data.cartUpdated;
						await dispatch(startGetAppointment(salonId, cartData.id))						
						if((cartData.status === 'COMPLETED' || cartData.status === 'WAITING') 
							&& cartData.id !== appointmentId) {
							notifyDesktop(cartData, "CART")
						}
					}
				});
		}
	};

	const startAppointmentSubscription = (updateState: boolean = true) => {
		if(salonId) {
			appointmentSubscription = (API.graphql(
				graphqlOperation(AppointmentUpdatedSubGQL, { salonId })
			) as Observable<object>).subscribe({
				next: async (eventData: any) => {
					console.log('event data', eventData)
					console.log('appointment updated', eventData?.value?.data)
					/* Fetch the latest appointment details */
					const appointmentData = eventData.value.data.appointmentUpdated;
					await dispatch(startGetAppointment(salonId, appointmentData.id))				
					if(appointmentData.salonPickup) { notifyDesktop(appointmentData,  "PURCHASE") }
					else if(appointmentData.rebookLength) { notifyDesktop(appointmentData,  "APPOINTMENT") };

				}
			});
		}
	};

	const stopCartSubscription = () => cartSubscription?.unsubscribe();
	const stopAppointmentSubscription = () =>
		appointmentSubscription?.unsubscribe();


	const notifyDesktop = (data: any, type: "APPOINTMENT" | "CART" | "PURCHASE")  => {

		// Let's check if the browser supports notifications
		if (!("Notification" in window)) {
			// alert("This browser does not support desktop notification");
			console.log("Notifications not supported :(")
		}
		// Let's check whether notification permissions have already been granted
		else if (Notification && Notification.permission === "granted") {
			sendNotification(data, type);
		}
		
		Promise.resolve(Notification.requestPermission()).then(function(permission) {
			// If the user accepts, let's create a notification
			if (permission === "granted") {
				sendNotification(data, type);
			}
		});
	}
	
	const sendNotification = (data: any, type: "APPOINTMENT" | "CART" | "PURCHASE") => {
		let message = '';	
		/* Return false if the data isn't as required, else create the msg */
		switch(type) {
			case "PURCHASE":
				message = `Someone wants to pick up ${data.purchasedItems.length} product${data.purchasedItems.length > 1 ? 's' : ''}`
				mixpanel.track("PMA Notification received - Retail");
				break;
			case "APPOINTMENT":
				if (!data.staff || !data.staff.name) return false
				message = `${data.staff.name} has sent through a rebook request for ${data.client.name}`
				mixpanel.track("PMA Notification received - Rebook");
				break;
			case "CART":
				if (!data.appointment || !data.appointment.client) return false
				message = `${data.appointment.client.name} has just placed a retail order.`
				break;
		}

		const avatar =  'https://admin.piiqapp.com/assets/images/avatar.png';
		const options = {
			badge: avatar,
			icon: avatar,
			image: avatar,
			title: 'piiq Salon Admin Portal',
			body: message,
			data: {},
			requireInteraction: true
		}
		  
		const notification = new Notification("piiq Salon Admin Portal", options);
		
		notification.onclick = function(event) {
			event.preventDefault(); // prevent the browser from focusing the Notification's tab
			const env = process.env.REACT_APP_ENV === "development" ? 'localhost:3000' : 'admin.piiqapp.com'
			window.open(`https://${env}/${salonId}/appointments/${data.id}`, '_self');
		}
	}

	return {
		startCartSubscription,
		startAppointmentSubscription,
		stopCartSubscription,
		stopAppointmentSubscription,
	};
};
