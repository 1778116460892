import React, { useState, useEffect } from "react";
import Auth from "@aws-amplify/auth";
import { Hub, Logger } from "aws-amplify";
import AuthCard from "./AuthCard";
import "./style.css";
import { Grid } from "@material-ui/core";
import mixpanel from 'mixpanel-browser';
import { RegisterOneSignalPlayerId } from "../../utils";
import OneSignal from 'react-onesignal';

interface IProps {
  children: any;
}

interface IState {
  isLoggedInAsAdmin: boolean;
  subscription: boolean;
}

export default (props:IProps) => {

  const defaultState: IState = {
    isLoggedInAsAdmin: false,
      subscription: false
  }
  const [state, setState] = useState(defaultState)
  const [isIBB, setIsIBB] = useState(false)
  const [osInitialised, setOsInitialised] = useState(false)
  const [ospid, setOspid] = useState('') as any;
  const { isLoggedInAsAdmin, subscription } = state;
  const { children } = props;


  useEffect(() => {
  
    let _ospid = new URLSearchParams(window.location.search).get("onesignal_push_id") as any;
    setOspid(_ospid);
	  window.localStorage.setItem("ospid", _ospid ? _ospid : '');
    /* Allow access to the Subscription / Signup Portal */
    if (window.location.pathname === "/ibb"  || window.location.pathname === "/ibb/") {
      setIsIBB(true)
      console.log("is IBB");
    } else if (window.location.pathname === "/register" || window.location.pathname === "/register/" || window.location.pathname === "/signup" || window.location.pathname === "/signup/" ) {
      setState({ ...state, subscription: true })
      console.log('subscription',state.subscription)
    } else {
      checkAuthState();
    }
    
  }, [])

  const checkAuthState = async () => {
    let cognitoUser;
    try {
      cognitoUser = await Auth.currentAuthenticatedUser();
      console.log("Cognito User", cognitoUser);
      if (!cognitoUser.attributes.profile || cognitoUser.attributes.profile === "Role=Salon" ) console.log('is admin')
      if (!cognitoUser.attributes.profile || cognitoUser.attributes.profile === "Role=Salon" ) setState({ ...state, isLoggedInAsAdmin: true });
      let _ospid = window.localStorage.getItem('ospid') ? window.localStorage.getItem('ospid') : '';
      if (_ospid !== '') {
        RegisterOneSignalPlayerId({
          id: cognitoUser.attributes.sub,
          oneSignalPlayerId: _ospid
        })
      } else { 
        console.log('empty ospid')
      }
    } catch (e) {
      console.log("Authenticator::CheckUser:Error:", e.message);
    }

    const watcher = new Logger("auth_watcher");
    //@ts-ignore
    watcher.onHubCapsule = (capsule: any) => {
      switch (capsule.payload.event) {
        case "signIn":
          watcher.log("user signed in");
          console.log("USER SIGNED IN")
          mixpanel.track("PMA Sign in");
          checkAuthState();
          break;
        case "signUp":
          watcher.log("user signed up");
          break;
        case "signOut":
          watcher.log("user signed out");
          localStorage.clear(); 
          sessionStorage.removeItem("connectedSalon");
          sessionStorage.removeItem("salonNumber");
          mixpanel.track("PMA Sign out");
          setState({ ...state, isLoggedInAsAdmin: false });
          break;
        case "signIn_failure":
          watcher.log("user sign in failed");
          mixpanel.track("PMA Sign-in failed");
          break;
        case "configured":
          watcher.log("the Auth module is configured");
      }
    };
    //@ts-ignore
    Hub.listen("auth", watcher);
  }

    return (
      <>
        {isLoggedInAsAdmin || subscription || isIBB  ? (
            children
        ) : (
          <Grid container spacing={3} style={{ marginTop: 0, marginBottom: 0}} id="testing-analytics">
              <Grid item xs={12} sm={12}>
                <div id="auth-container">
                  <AuthCard />
                  <p style={{color: "whitesmoke", fontSize: 10}}>{ospid}</p>
                </div>
              </Grid>
          </Grid>
        )}
      </>
    );
  }
