import React, { useEffect } from "react";
import Layout from "../../components/Layout";

const SupportScreen = () => {
  useEffect(() => {
    onSupportLaunch();
    document.title = "Support";
  }, []);

  const onSupportLaunch = () => {
    // window.open('https://piiqdigital.zendesk.com/hc/en-us', '_blank');
  };

  return (
    <Layout>
      <h1 style={{ marginLeft: "10%" }}> SUPPORT </h1>
      Visit our dedicated support site
      <iframe
        title="support"
        src="https://piiqdigital.zendesk.com/hc/en-us"
        style={{ width: "100%", height: "100vh", borderWidth: "0px" }}>
        <p>Your browser does not support iframes.</p>
      </iframe>
    </Layout>
  );
};

export default SupportScreen;
