import React, { useState } from "react";
import Autosuggest from "react-autosuggest";
import { Avatar } from "../";
import { ReactComponent as SearchIcon } from "../../assets/Images/search.svg";
import { SearchIput, Suggestion, Icon } from "./styles";

interface IInputProps {
	value?: string | number;
	inputLabel: string;
	data: any;
	searchParam: string;
	onSelect: Function;
	disabled: boolean;
}

// Use your imagination to render suggestions.

const SearchComponent = (props: IInputProps) => {
	const defaultSuggestions: any[] = [];
	const [ value, setValue ] = useState("");

	const [ suggestions, setSuggestions ] = useState(defaultSuggestions);

	const onChange = (event, { newValue }) => {
		setValue(newValue);
	};

	const renderSuggestion = suggestion => (
		<Suggestion>
			<Avatar src={suggestion.portrait} />
			{suggestion[props.searchParam]}
		</Suggestion>
	);

	const getSuggestions = (value: any) => {
		const inputValue = value.trim().toLowerCase();
		const inputLength = inputValue.length;

		return inputLength === 0
			? []
			: props.data.filter(
					item =>
						item[props.searchParam].toLowerCase().slice(0, inputLength) ===
						inputValue
				);
	};

	const getSuggestionValue = suggestion => {
		props.onSelect(suggestion);
		return suggestion[props.searchParam];
	};

	// Autosuggest will call this function every time you need to update suggestions.
	// You already implemented this logic above, so just use it.
	const onSuggestionsFetchRequested = ({ value }) => {
		setSuggestions(getSuggestions(value));
	};

	// Autosuggest will call this function every time you need to clear suggestions.
	const onSuggestionsClearRequested = () => {
		setSuggestions([]);
	};

	// Autosuggest will pass through all these props to the input.
	const inputProps = {
		placeholder: props.disabled ? 'Loading...' : props.inputLabel,
		value,
		onChange: onChange,
		disabled: props.disabled
	};

	// Finally, render it!
	return (
		<SearchIput>
			<Autosuggest
				suggestions={suggestions}
				onSuggestionsFetchRequested={onSuggestionsFetchRequested}
				onSuggestionsClearRequested={onSuggestionsClearRequested}
				getSuggestionValue={getSuggestionValue}
				renderSuggestion={renderSuggestion}
				inputProps={inputProps}
			/>
			<Icon>
				<SearchIcon />
			</Icon>
		</SearchIput>
	);
};

export default SearchComponent;
