import { IActions } from "../contexts/createAsyncDispatch";
import { UpdateSalonInput } from "../graphql/globalTypes";

export interface IStateSalons {
	salons: ISalon[];
	loading: boolean;
}

export interface ISalon {
	id: string;
	name: string;
	timeZone: string;
	defaultSocialMediaMessage: string;
	rebookNotificationMessage: string;
	rebookNotificationDelay: number;
	onboardingComplete: boolean | null;
	onboardingState: string | null;
	BrandsAvailableToSalon: [string] | null;
	BrandsVisibleToClients: [string] | null;
	PreferredRecommendationBrand: string | null;
	salonAdmin: boolean | null; 
	salonClient: boolean | null; 
}

export interface IUpdateSalon {
	onboardingComplete: boolean;
	onboardingState: string;
}

export interface ISetSalonsAction extends IActions {
	type: "SET_SALONS";
	salons: ISalon[];
}

export interface ISetLoadingAction {
	type: "SET_LOADING";
	loading: boolean;
}

export interface IGetSocialMediaMessageAction extends IActions {
	type: "GET_SOCIAL_MEDIA_MESSAGE";
	salonId: string;
	defaultSocialMediaMessage?: string | null;
}

export interface ISetSocialMediaMessageAction extends IActions {
	type: "SET_SOCIAL_MEDIA_MESSAGE";
	salonId: string;
	defaultSocialMediaMessage?: string | null;
}
export interface ISetBookingURLAction extends IActions {
	type: "SET_BOOKING_URL";
	salonId: string;
	bookingUrl?: string | null;
}

export interface ISetBookingMessageAction extends IActions {
	type: "SET_BOOKING_MESSAGE";
	salonId: string;
	rebookNotificationMessage?: string | null;
}

export interface ISetBrandsAction extends IActions {
	type: "SET_BRANDS";
	salonId: string;
	BrandsVisibleToClients?: [string] | null,
	PreferredRecommendationBrand?: string | null
}

export interface IGetBookingMessageAction extends IActions {
	type: "SET_BOOKING_MESSAGE";
	salonId: string;
	rebookNotificationMessage?: string | null;
}

export interface ISetBookingDelayAction extends IActions {
	type: "SET_BOOKING_DELAY";
	salonId: string;
	rebookNotificationDelay?: number | null;
}

export interface IGetBookingDelayAction extends IActions {
	type: "SET_BOOKING_DELAY";
	salonId: string;
	rebookNotificationDelay?: number | null;
}
export interface IUpdateSalonAction extends IActions {
	type: "UPDATE_SALON";
	salonId: string;
	salonInput: UpdateSalonInput;
}

export type ISalonAction =
	| ISetSalonsAction
	| ISetLoadingAction
	| IGetSocialMediaMessageAction
	| ISetSocialMediaMessageAction
	| IGetBookingMessageAction
	| ISetBookingMessageAction
	| ISetBookingURLAction
	| ISetBrandsAction
	| IGetBookingDelayAction
	| ISetBookingDelayAction
	| IUpdateSalonAction;

export type ISalonAsyncAction = any;

export const initialStateSalons: IStateSalons = {
	salons: [],
	loading: true
};

export const reducerSalons = (
	state: IStateSalons,
	action: ISalonAction | ISalonAsyncAction
) => {
	switch (action.type) {
		case "SET_SALONS":
			return {
				...state,
				salons: action.salons,
				loading: false
			};

		case "SET_LOADING":
			return {
				...state,
				loading: action.loading
			};

		case "SET_SOCIAL_MEDIA_MESSAGE":
			return setSocialMediaMessage(state, action);

		case "SET_BOOKING_URL":
			return setBookingURL(state, action);
			
		case "GET_SOCIAL_MEDIA_MESSAGE":
			return {
				...state,
				salons: action.salons,
				loading: false
			};

		case "SET_BOOKING_MESSAGE":
			return setBookingMessage(state, action);

		case "GET_BOOKING_MESSAGE":
			return {
				...state,
				salons: action.salons,
				loading: false
			};

		case "SET_BOOKING_DELAY":
			return setBookingDelay(state, action);

		case "GET_BOOKING_DELAY":
			return {
				...state,
				salons: action.salons,
				loading: false
			};
		case "UPDATE_SALON":
			const salonsClone = state.salons.splice(0);
			const salons: any[] = [];

			salonsClone.map(salon => {
				if (action.salonId === salon.id) {
					salon = {
						...salon,
						...action.salonInput
					};
				}
				salons.push(salon);
			});
			// console.log("Salons", salons);
			return {
				...state,
				salons: salons,
				loading: false
			};
	}
	return state;
};

function setBookingMessage (
	state: IStateSalons,
	action: ISetBookingMessageAction
) {
	const i = state.salons.findIndex(s => s.id === action.salonId);
	return {
		...state,
		salons: [
			...state.salons.slice(0, i),
			{
				...state.salons[i],
				rebookNotificationMessage: action.rebookNotificationMessage
			},
			...state.salons.slice(i + 1)
		],
		loading: false
	};
}

function setBookingDelay (state: IStateSalons, action: ISetBookingDelayAction) {
	const i = state.salons.findIndex(s => s.id === action.salonId);
	// console.log("rebookNotificationDelay __ ", state.salons);
	return {
		...state,
		salons: [
			...state.salons.slice(0, i),
			{
				...state.salons[i],
				rebookNotificationDelay: action.rebookNotificationDelay
			},
			...state.salons.slice(i + 1)
		],
		loading: false
	};
}

function setSocialMediaMessage (
	state: IStateSalons,
	action: ISetSocialMediaMessageAction
) {
	const i = state.salons.findIndex(s => s.id === action.salonId);
	return {
		...state,
		salons: [
			...state.salons.slice(0, i),
			{
				...state.salons[i],
				defaultSocialMediaMessage: action.defaultSocialMediaMessage
			},
			...state.salons.slice(i + 1)
		],
		loading: false
	};
}

function setBookingURL (
	state: IStateSalons,
	action: ISetBookingURLAction
) {
	const i = state.salons.findIndex(s => s.id === action.salonId);
	return {
		...state,
		salons: [
			...state.salons.slice(0, i),
			{
				...state.salons[i],
				bookingUrl: action.bookingUrl
			},
			...state.salons.slice(i + 1)
		],
		loading: false
	};
}
