import { IAppointment } from "./AppointmentsReducer";

export interface IClient {
	id: string;
	name: string;
	email?: string;
	appointments: IAppointment[];
}

export interface IStateClients {
	clients: IClient[] | null;
	loading: boolean;
	appointment: IAppointment | null;
}

export const initialStateClients: IStateClients = {
	clients: null,
	loading: false,
	appointment: null
};

export interface ISetClientLoadingAction {
	type: "SET_LOADING";
	loading: boolean;
}

export interface ISetClientAppointmentAction {
	type: "GET_CLIENT_APPOINTMENT";
	appointment: IAppointment | null;
}

export interface ISetClientsAction {
	type: "GET_ALL_CLIENTS";
	clients: IClient[] | null;
}

export interface ISetClientsClearAction {
	type: "CLEAR_CLIENTS";
}

export type IClientsAction =
	| ISetClientLoadingAction
	| ISetClientAppointmentAction
	| ISetClientsAction
	| ISetClientsClearAction;

export const reducerClients = (state: any, action: IClientsAction) => {
	switch (action.type) {
		case "GET_CLIENT_APPOINTMENT":
			return {
				...state,
				appointment: action.appointment
			};
		case "GET_ALL_CLIENTS":
			return {
				...state,
				clients: action.clients
			};
		case "CLEAR_CLIENTS":
			return {
				...state,
				clients: null,
				appointment: null
			};
		default:
			return state;
	}
};
