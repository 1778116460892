import styled from "styled-components";
import { SIZE } from "../../../../config";

export const Payment = styled.div`
  width: 100%;
  height: calc(100% - ${130 + SIZE.LARGE}px);
  margin: 0;
  padding-top: 130px;
  overflow: scroll;
  padding-bottom: ${SIZE.LARGE}px;
`;
export const Inner = styled.div`
  height: ${SIZE.LARGE * 2}px;
  width: 100%;
  pointer-events: none;
`;

export const Footer = styled.div`
  height: 100px;
  width: 100%;
`;
