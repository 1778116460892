import styled from "styled-components";
import { COLORS, SIZE, FONT, BREAKPOINTS } from "../../config";
import TodoBackground from "./../../assets/Images/todobackground.png";
import AllDoneBackground from "./../../assets/Images/alldonebg.png";

interface props {
	done?: boolean;
};


export const Container = styled.div`
	width: calc(100% - ${SIZE.MEDIUM * 2}px);
	padding: ${SIZE.MEDIUM}px;
	margin: auto;

	h3 {
		font-weight: bold;
	}

	code {
		background: whitesmoke;
		display: inline-block;
		margin-bottom: 12px;
		font-family: Arial;
		padding: 8px;
		border: 1px solid grey;
	}
`;

export const Row = styled.div`
	display: flex;
	width: 100%;
	margin-bottom: 48px;
	padding-bottom: 48px;
	border-bottom: 1px solid grey;

	div {
		flex: 1 1 0px
		margin-right: 24px;
		&:last-child {
			margin-right: 0;
		}
	}
`;

export const Flex = styled.div`
	display: flex;
	width: 100%;

	div {
		flex: 1 1 0px
		margin-right: 24px;
		&:last-child {
			margin-right: 0;
		}
	}
`;

export const IFrameContainer = styled.div`
	position: relative;
	overflow: hidden;
	width: 100%;
	padding-top: 56.25%;

	iframe {
		border: 0;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}
`;

