import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { StaticContext, useParams } from "react-router";
import { Notification, Layout, Fetching, Input} from "../../components";
import { Container, Title, Done,ContentContainer, IframeContainer, TodoHeader, TodoContainer, TodoContent, BackButton, TodoLine, Order, Description } from './styles'
import { GetIBBDetail, MarkIBBTodoAsDone, MarkIBBDayAsDone } from "../../utils";
import Button from "@material-ui/core/Button";

interface IPathParams {
	salonId: string;
	appointmentId?: string;
}
export default function IBBDetailScreen ({
    location,
	match,
	history
}: React.PropsWithChildren<
RouteComponentProps<IPathParams, StaticContext, any>
>) {
    const [ loading, setLoading ] = useState(true);
    const [ ibbDetail, setIbbDetail ] = useState() as any;
    const [ email, setEmail ] = useState('') as any;
    const [ value, setValue ] = useState('');
    const [ currentTodoIndex, setCurrentTodoIndex ] = useState(0);
    const [ nextDayIBB, setNextDayIBB ] = useState('');
    const [ ibbTodos, setIbbTodos ] = useState([]);
    const [ userHasCompleted, setUserHasCompleted ] = useState([] as any);
    const { ibbId } = useParams<{ ibbId: string }>()
    const { salonId } = match.params;
    const alpha = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"

    useEffect(() => {
        get();
        if (localStorage.getItem("IBBemail")) {
            console.log(localStorage.getItem("IBBemail"));
            setEmail(localStorage.getItem("IBBemail"))
        }
    }, [ibbId])

    useEffect(() => {
       
        console.log('haschanged', userHasCompleted)
        for (let i = 0; i < userHasCompleted.length; i++) {
            if (userHasCompleted[i]['Value']) {
                let x = window!.document!.getElementById('input_'+userHasCompleted[i]['Todo'])! as HTMLInputElement;
                x.value = userHasCompleted[i]['Value'];
            }
        }
    }, [userHasCompleted])

    const get = async () => {
        let _email = localStorage.getItem("IBBemail") || '';
        let out = await GetIBBDetail(ibbId, _email);
        console.log('r',out.data.response)
        if (out.data.response.nextDayIBB) setNextDayIBB(out.data.response.nextDayIBB)
        setIbbDetail(out.data.response.ibbDayDetails)
        setIbbTodos(out.data.response.ibbToDos)
        if (out.data.response.userHasCompleted) {
            setUserHasCompleted(out.data.response.userHasCompleted);
        }
        setLoading(false)
        if (salonId) {
            document.title = "IBB Details - "+out.data.response.ibbDayDetails.Name+" - Salon";
        } else {
            document.title = "IBB Details - "+out.data.response.ibbDayDetails.Name+" - Guest";
        }
    }

    const goToNextSession = async () => {
        if (nextDayIBB !== '') {
            let out = await MarkIBBDayAsDone(ibbDetail._id, email);
            if (salonId) {
                history.push(`/${salonId}/ibb/${nextDayIBB}`);
            } else {
                history.push(`/ibb/${nextDayIBB}`);
            }
            window.scrollTo(0, 0)
            setNextDayIBB('')
        } else {
            let out = await MarkIBBDayAsDone(ibbDetail._id, email);
            if (salonId) {
                history.push(`/${salonId}/ibb`);
            } else {
                history.push(`/ibb`);
            }
            window.scrollTo(0, 0)
            setNextDayIBB('')
        }
        
    }

    const onBack = async () => {
        if (salonId) {
            history.push(`/${salonId}/ibb?moduleNumber=`+ibbDetail?.ModuleNumber)
        } else {
            history.push(`/ibb?moduleNumber=`+ibbDetail?.ModuleNumber)
        }
    }

    const markAsDonePost = async (todoId, order, hasInput?) => {
        setLoading(true)
        let input = '';
        if (hasInput) {
            let x = window!.document!.getElementById('input_'+todoId)! as HTMLInputElement;
            input =x.value;
        }
        let out = await MarkIBBTodoAsDone(todoId, email, input);
        if (!out.data.statusCode) {
            const _userHasCompleted = [...userHasCompleted]
            _userHasCompleted.push({"Todo":todoId, "Value":input} as any)
            setUserHasCompleted(_userHasCompleted)
            console.log(email, _userHasCompleted, userHasCompleted);
            if (ibbTodos.length === order) {
                setLoading(false)
                injectConfetti()
            }
        } else {
            console.log('r',out.data)
        }
        setLoading(false)
    }

    const injectConfetti = () => {
        let injection = `
                console.log('hi');
                function random(max){
                    return Math.random() * (max - 0) + 0;
                }

                var c1 = document.createDocumentFragment();
                var c2 = document.createDocumentFragment();
                var c3 = document.createDocumentFragment();
                for (var i=0; i<100; i++) {
                    var styles1 = 'transform: translate3d(' + (random(500) - 250) + 'px, ' + (random(200) - 150) + 'px, 0) rotate(' + random(360) + 'deg);\
                                background: hsla('+random(360)+',100%,50%,1);\
                                animation: bang 1000ms ease-out forwards;\
                                opacity: 0;\
                                z-index: 99;\
                                position: absolute;\
                                display: block;\
                                animation-delay: 0.5s;\
                                left: 50%;\
                                top: 0;\
                                width: 4px;\
                                height: 8px';
                    
                    var e1 = document.createElement("i");
                    e1.style.cssText = styles1.toString();
                    c1.appendChild(e1);

                var styles2 = 'transform: translate3d(' + (random(500) - 250) + 'px, ' + (random(200) - 150) + 'px, 0) rotate(' + random(360) + 'deg);\
                                background: hsla('+random(360)+',100%,50%,1);\
                                animation: bang 1000ms ease-out forwards;\
                                opacity: 0;\
                                z-index: 99;\
                                position: absolute;\
                                display: block;\
                                animation-delay: 0.65s;\
                                left: 0;\
                                top: 50%;\
                                width: 4px;\
                                height: 8px';
                    
                    var e2 = document.createElement("i");
                    e2.style.cssText = styles2.toString();
                    c2.appendChild(e2);

                var styles3 = 'transform: translate3d(' + (random(500) - 250) + 'px, ' + (random(200) - 150) + 'px, 0) rotate(' + random(360) + 'deg);\
                                background: hsla('+random(360)+',100%,50%,1);\
                                animation: bang 1000ms ease-out forwards;\
                                opacity: 0;\
                                z-index: 99;\
                                position: absolute;\
                                display: block;\
                                animation-delay: 0.8s;\
                                left: 80%;\
                                top: 80%;\
                                width: 4px;\
                                height: 8px';
                    
                    var e3 = document.createElement("i");
                    e3.style.cssText = styles3.toString();
                    c3.appendChild(e3);
                }
                document.getElementById('confetti').append(c1);
                document.getElementById('confetti').append(c2);
                document.getElementById('confetti').append(c3);
            `;

        let injectionStyle= document.createElement('style');
        injectionStyle.type = 'text/css';
        injectionStyle.appendChild(document.createTextNode(`@keyframes bang {  from {    transform: translate3d(0, 0, 0);    opacity: 1;  }} #confetti { z-index: 24!important;position: relative; }`));
        document.head.appendChild(injectionStyle);

		let injectionScript= document.createElement('script');
		injectionScript.text = injection;
		document.head.appendChild(injectionScript);
        
    }


    const markAsDone = (todo) => {
        console.log("marking as done", todo)
        if (todo.InputType && todo.InputType === 'text') {
            markAsDonePost(todo._id, todo.Order, true);
        } else {
            markAsDonePost(todo._id, todo.Order);
        }
    }

	return (
		<Layout>
			<Notification />
			    <Container>
                    <ContentContainer>
                        <BackButton onClick={onBack}>← BACK</BackButton>
                        <p>MODULE {ibbDetail?.ModuleNumber} - LESSON {ibbDetail?.LessonNumber}</p>
                        <Title>{ibbDetail?.Name}</Title>
                        {ibbDetail?.VideoURL && (
                            <IframeContainer>
                            <iframe 
                                width="560" height="315" 
                                src={"https://www.youtube.com/embed/"+ibbDetail?.VideoURL}
                                title="YouTube video player" 
                                frameBorder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowFullScreen>    
                            </iframe>
                            </IframeContainer>
                        )}
                        <div dangerouslySetInnerHTML={{ __html: ibbDetail?.Transcript }}>
                        </div>
                        {ibbTodos?.length <= userHasCompleted.length ? (
                            <Button
                            variant="outlined"
                            onClick={() => {goToNextSession()} }
                            color="primary"
                            disabled={loading}
                            style={{
                                backgroundColor: "black",
                                color: "white",
                                borderRadius: "0px",
                                padding: '12px 10px',
                                marginTop: '8px',
                                height: 40,
                                marginBottom: 80
                            }}>
                            {nextDayIBB !== '' ? 'GO TO NEXT SESSION' : 'FINISH'}
                        </Button>
                    ) : (<></>)}
                    </ContentContainer>
                    
                    {/* <TodoContainer done={ibbTodos?.length <= userHasCompleted.length}>
                        <TodoContent done={ibbTodos?.length <= userHasCompleted.length}>
                            <div id={'confetti'}></div>
                            <TodoHeader>{ibbTodos?.length <= userHasCompleted.length ? 'ALL DONE' : 'TODO'}</TodoHeader>
                            {(ibbTodos && ibbTodos.length > 0) ? (
                                <>
                                    { ibbTodos.map((ibbTodo:any, i) => (
                                        <TodoLine key={i}>
                                            <Order>
                                            {userHasCompleted?.filter((t:any) => t.Todo === ibbTodo._id ).length > 0 ? (
                                                <Done done={ibbTodos?.length <= userHasCompleted.length}>✔</Done>
                                            ): (
                                                <>{alpha[ibbTodo.Order-1]}</>
                                            )}
                                            </Order>
                                            <Description>
                                                <p>{ibbTodo.Text}</p>
                                                {ibbTodo.InputType && ibbTodo.InputType === 'text' && ibbTodo.Order <= userHasCompleted.length +1 ? (
                                                    <Input 
                                                        id={'input_'+ibbTodo._id}
                                                        name="input_val"
                                                        placeholder={""}
                                                        label="TYPE YOUR ANSWER HERE"
                                                        readonly={!(ibbTodo.Order === userHasCompleted.length +1)}/>
                                                ) : (<></>)}
                                                {ibbTodo.Order === userHasCompleted.length +1 ? (
                                                    <>
                                                    <Button
                                                        variant="outlined"
                                                        onClick={() => markAsDone(ibbTodo) }
                                                        color="primary"
                                                        disabled={loading}
                                                        style={{
                                                            backgroundColor: "black",
                                                            color: "white",
                                                            borderRadius: "0px",
                                                            padding: '12px 10px',
                                                            marginTop: '8px',
                                                            display: userHasCompleted?.filter((t:any) => t.Todo === ibbTodo._id ).length > 0 ? 'none' :'block'

                                                        }}>
                                                        {loading ? 'SUBMITTING...' : ibbDetail?.isReward ? 'CLAIM REWARD' : 'MARK AS DONE'}
                                                    </Button>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </Description>
                                        </TodoLine>
                                    ))}
                                </>
                            ) : (
                                <></>
                            )}
                        </TodoContent>
                    </TodoContainer> */}
                </Container>
			<Fetching />
		</Layout>
	);
}
