import styled from "styled-components";
import {useSpring, animated} from 'react-spring'

import { COLORS, FONT } from "../../config";

interface iNotification {
	show: string;
	type?: "APPOINTMENT" | "CART" | null;
}

export const NotificationContainer = styled.div`
	width: 100%;
	height: 100%;
`;

export const Notification = styled(animated.div)`
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-self: center;
	align-items: center;
	outline: none;
	overflow: hidden;
	width: 100%;
	height: 70px;
	background: ${(props: iNotification) => props?.type === 'APPOINTMENT' ? COLORS.GREY_MID_LIGHT : props?.type === 'CART' ? COLORS.BLUE_MID : COLORS.GREY_MID};
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	cursor: pointer;
	transition: transform .25s ease;
	transform: ${(props: iNotification) =>
		props.show === 'true' ? `translateY(0px)` : `translateY(0px)`};

	h1 {
		width: 100%;
		margin: ${(props: iNotification) => props?.type === 'APPOINTMENT' ? '14px' : '2px 0 0'};
		text-align: center;
	}
	h2 {
		padding: 0;
		margin: 0;
		font-size: ${FONT.SIZE.BODY};
	}
`;
