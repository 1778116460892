import React, { useRef, useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import { FormattedMessage } from "react-intl";
import CircularProgress from "@material-ui/core/CircularProgress";
import ChevronUpIcon from "@material-ui/icons/ChevronLeft";
import ChevronDownIcon from "@material-ui/icons/ChevronRight";
import {
	startSetBookingMessage,
	startSetBookingDelay
} from "../../../../actions/salonsActions";
import { useSalons } from "../../../../contexts/AdminContext";
import { Button, Content, Input, SkipButton } from "../../../../components";
import { SectionType, OnboardingDetails } from "../../types";
import { InputCounter, Label, Error, Loader } from "./styles";
import mixpanel from 'mixpanel-browser';

interface IProps {
	onboardVersion: string;
	transitionConfig?: any;
	onNavigate: Function;
	onCompleteSection: Function;
	updateRegistrationState: Function;
	registrationDetails: OnboardingDetails;
	salonId: string;
}

const RebookingComponent = (props: IProps) => {
	const contentScroll = useRef<HTMLDivElement>(null);
	const [ reminderMessage, setReminderMessage ] = useState(
		props.registrationDetails.rebooking_message || ""
	);
	const [ days, setDays ] = useState(
		props.registrationDetails.rebooking_delay_days || 0
	);
	const [ state, dispatch ] = useSalons();
	const [ error, setError ] = useState("");
	const [ loading, setLoading ] = useState(false);

	useEffect(() => {
		if (contentScroll && contentScroll.current) {
			contentScroll.current.scrollTop = 0;
			window.scrollTo(0, 0);
		}
		mixpanel.track("PMA Onboarding - Rebookings - Page accessed event");
	}, []);

	const onSubmit = async () => {
		setLoading(true);
		if (reminderMessage !== "") {
			const result = await dispatch(
				startSetBookingMessage(props.salonId, reminderMessage)
			);
			const resultDays = await dispatch(
				startSetBookingDelay(props.salonId, days)
			);

			/* TODO: Remove the TS-Ignore and Fix returning Void so can check for errors */
			// @ts-ignore
			if ((result && result.errors) || (resultDays && resultDays.errors)) {
				setError("Oh no, failed to update the rebooking message at this time");
				setLoading(false);
				return;
			} else {
				/* All good, proceed.. */
				const state: OnboardingDetails = {
					rebooking_message: reminderMessage,
					rebooking_delay_days: days
				};
				props.updateRegistrationState(state);
				proceedNextStep();
			}
		} else {
			/* No Staff to add, just proceed */
			proceedNextStep();
		}
	};

	const onInputChange = (value: string) => {
		setReminderMessage(value);
	};

	const onDayIncrease = () => {
		const day = days + 1;
		setDays(day);
	};

	const onDayDecrease = () => {
		if (days > 0) {
			const day = days - 1;
			setDays(day);
		}
	};

	const proceedNextStep = () => {
		setLoading(false);
		props.onNavigate(SectionType.RETAIL);
		props.onCompleteSection();
	};

	const transitionConfig = useSpring(props.transitionConfig);

	return (
		<Content as={animated.div} style={transitionConfig} ref={contentScroll}>
			<h1>
				<FormattedMessage id="registration.rebooking.title" />
			</h1>
			<div className="margin-bottom-mid">
				<FormattedMessage id="registration.rebooking.body_1" />{" "}
			</div>
			<div className="margin-bottom-mid">
				<FormattedMessage id="registration.rebooking.body_2" />
			</div>
			<div className="margin-bottom-lrg">
				<FormattedMessage id="registration.rebooking.body_3" />
			</div>
			<div className="margin-bottom-mid">
				<Input
					onChange={onInputChange}
					placeholder={"eg. Don’t forget to rebook your next session at Deluxe Hair!"}
					value={reminderMessage}
					label="Reminder Message"
					textarea
				/>
			</div>
			<div className="margin-bottom-mid">
				<Label>
					<FormattedMessage id="registration.rebooking.reminder" />{" "}
				</Label>
				<InputCounter>
					{days > 0 ? (<div className="day-number">{days}</div>) : (<></>)}
					<div>{days > 0 ? `Day${days > 1 ? "s" : ""} After` : `On the day`}</div>
					<div className="counter-controls">
						<ChevronUpIcon
							onClick={onDayIncrease}
							style={{ transform: `rotate(90deg)`, cursor: "pointer" }}
						/>
						<ChevronDownIcon
							onClick={onDayDecrease}
							style={{ transform: `rotate(90deg)`, cursor: "pointer" }}
						/>
					</div>
				</InputCounter>
			</div>
			{loading ? (
				<Loader>
					<CircularProgress />
				</Loader>
			) : (
				<Button onPress={onSubmit} label="NEXT →" />
			)}
			<Error>{error && error}</Error>
			<SkipButton onPress={proceedNextStep} />
		</Content>
	);
};

export default RebookingComponent;
