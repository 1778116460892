export enum SectionType {
	"STAFF",
	"SOCIAL",
	"REBOOKING",
	"PRODUCTS",
	"RETAIL",
	"COMPLETE"
}

export interface OnboardingDetails {
	staff?: Array<string>;
	hashtags?: Array<string>;
	rebooking_message?: string;
	rebooking_delay_days?: number;
}
