import React from "react";
import { FormattedMessage } from "react-intl";
import Layout from "../../components/Layout";

export default function NotFoundScreen() {
  return (
    <Layout>
      <FormattedMessage id="title.notfound" defaultMessage={`Not Found`} />
    </Layout>
  );
}
