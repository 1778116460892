import styled from "styled-components";
import { COLORS } from "../../config";

interface iProps {
	readonly: boolean;
}

export const Image = styled.img`
	width: 100%;
	background: ${COLORS.BLACK};
`;

export const ImgContainer = styled.div`
	background: ${COLORS.BLACK};
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	svg {
		circle {
			stroke: white;
		}
	}
`;
