import React, {useState, useEffect} from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Image, ImgContainer } from "./styles";

interface IImageProps {
  src: string;
  alt: string;
  errorContent?: any;
  cache?: boolean;
}

const ImageLoadComponent = (props: IImageProps) => {

	const [imgError, setImgError] = useState(false);
	const [imgLoaded, setImgLoaded] = useState(false);

	useEffect(() => {
		setImgLoaded(false);
		setImgError(false);
	}, [props.src])

	return (
		<>
		<ImgContainer>
			{!imgError && <Image 
				src={`${props.src}&&?${new Date().getTime()}`} 
				alt={props.alt} 
				style={{display: imgLoaded ? 'block' : 'none'}} 
				onError={() => setImgError(true)}
				onLoad={() => setImgLoaded(true)}
			/>}
			{!imgLoaded && !imgError && <CircularProgress/>}
			{imgError && props.errorContent}
		</ImgContainer>
		</>
	);
};

export default ImageLoadComponent;
