import React from "react";
import Layout from "../../components/Layout";

export default function PiiqPosterScreen() {
  return (
    <Layout>
      <div style={{ width: "90%", margin: "auto" }}>
        <h1>Piiq Poster </h1>
        <p>Coming Soon...</p>
      </div>
    </Layout>
  );
}
