import React, { Component, useState } from "react";
import { Button, TextField, Typography, Grid } from "@material-ui/core";
import Auth from "@aws-amplify/auth";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api/lib/types";
import AWS from 'aws-sdk';
import { SendUserPiiqMail } from "../../../utils";
import { FormattedMessage } from "react-intl";
import "../style.css";
import { ReactComponent as Logo } from "../../../assets/Images/logo.svg";
import DownloadPlaystore from "../../../assets/Images/download_playstore.png";
import DownloadAppstore from "../../../assets/Images/download_appstore.png";
import { CSSTransition } from 'react-transition-group';

const dynamoDB = new AWS.DynamoDB.DocumentClient();

//types
interface Props {
  toggleView(view: string): void;
  setUserEmail(email: string) : void;
  animateInProp: boolean;
  sessionId: string;
}

interface IState {
  loading: string;
  animateIn: string;
  email: string;
  error: string;
  [name: string]: string;
}



export default class Landing extends Component<Props, IState> {

  state = {
    loading: 'true',
    email: "",
    error: "",
    animateIn: 'false'
  };
  componentDidMount = () => {
    this.setState({ loading : 'false', animateIn : this.props.animateInProp ? 'true' : 'false' })
  }
  onChange = (e: any) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    this.setState({ [name]: value });
  };
  emailIsValid = (email) => {
    return /\S+@\S+\.\S+/.test(email)
  };
  userExists = (email: string) => {
    return Auth.signIn(email.toLowerCase(), '123').then(res => {
        return false;
    }).catch(error => {
     const code = error.code;
     console.log(error);
     if (!this.emailIsValid(email.toLowerCase())) {
      this.setState({ loading : 'false' })
      this.setState({ error: "Your email is an invalid format" });
      return false;
     }
     switch (code) {
         case 'UserNotFoundException':
           this.props.toggleView('signup')
           this.props.setUserEmail(email.toLowerCase())
            return false;
         case 'NotAuthorizedException':
          this.props.toggleView('signin')
          this.props.setUserEmail(email.toLowerCase())
          return true;
         default:
            this.setState({ loading : 'false' })
            return false;
     }
   });
 }
  onSubmit = async (e) => {
    e.preventDefault(e);

    const { email } = this.state;
    this.setState({ loading : 'true' })

    if (email === "") {
      this.setState({ error: "Please enter an email" });
      this.setState({ loading : 'false' })
      return;
    }

    try {
      // CHECK IF USER EXISTS
      console.log(this.userExists(email.trim()));
    } catch (e) {
      const error = e.message;
      console.log("CheckUserExists::Submit::Error:", e);
      this.setState({ error });
      this.setState({ loading : 'false' })
    }
  };
  render () {
    const { toggleView } = this.props;
    return (
      <form onSubmit={this.onSubmit}>
        <CSSTransition
          in={this.state.animateIn === 'true'}
          timeout={300}
          classNames="opacity">
        <Grid container direction="column" spacing={10} alignItems="stretch">
          <Grid item>
              <Logo width={95} style={{marginTop: 20}}/>
              <h1 id="title">
                <FormattedMessage id="landing.title" defaultMessage={`Welcome`} />
              </h1>
              <p style={{marginBottom: 24}}>
                <FormattedMessage id="landing.content" defaultMessage={`Use your email to log in or create a new account and access piiq Pro.`} />
              </p>
              <label style={{margin: "8px 0", display: "block"}}>Email</label>
              <TextField
                InputProps={{
                  style: {
                    color: "white", 
                    height: 60
                  },
                  autoComplete: "email"
                }}
                style={{ width: "100%", background: 'transparent', border: '1px solid white',marginBottom: 35 }}
                variant="outlined"
                placeholder="Email"
                name="email"
                value={this.state.email}
                onChange={this.onChange}
                disabled={this.state.loading === 'true'}
              />
            {this.state.error !== "" && (
                <Typography style={{marginBottom: 24, color:"#ffa0a0"}}>{this.state.error}</Typography>
            )}
              <button
                type="button"
                style={{ background:"#FFF", border: 0,width: "100%", borderRadius: 0, height: 60, textTransform: 'none', fontSize: 16 }}
                onClick={this.onSubmit}
                disabled={this.state.loading === 'true'}>
                {this.state.loading === 'true' ? (<>Loading...</>):(<FormattedMessage id="button.signup" defaultMessage={`Continue`} />)}
              </button>
              <hr style={{margin: "35px 0", opacity: 0.4}}></hr>
              <p style={{marginBottom: 24}}>Download the piiq app to access your personalised haircare recommendations, celeb match, 360° records and more…</p>
              <a href="http://apps.apple.com/au/app/piiq/id1458062799" target="_blank">
                <img
                  src={DownloadAppstore}
                  style={{
                    width: "48%",
                    height: "auto",
                    marginRight: '2%'
                  }}
                />
              </a>
              <a href="http://play.google.com/store/apps/details?id=com.piiq&hl=en&gl=US" target="_blank">
                <img
                  src={DownloadPlaystore}
                  style={{
                    width: "48%",
                    height: "auto"
                  }}
                />
              </a>
          </Grid>
        </Grid>
        </CSSTransition>
      </form>
    );
  }
}
