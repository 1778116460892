import React, { useState } from "react";
import moment from "moment";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { ReactComponent as HeadIcon } from "../../assets/Images/head_icon.svg";

import {
	RowItem,
	RowIndex,
	Visits,
	LastAppointment,
	Stylist,
	Chevron,
	Avatar,
	Name,
	Left,
	Right
} from "./styles";

interface IInputProps {
	id: string;
	name?: string;
	index: number;
	appointments: number;
	lastAppointment: number;
	stylist: string;
	viewType: "CLIENT_LIST" | "CLIENT";
	appointmentActive?: boolean;
	portrait?: string | null;
	onPress?: Function;
}

const ClientListItemComponent = (props: IInputProps) => {
	const [ imgError, setImgError ] = useState(false);
	const onPress = () => {
		props.onPress && props.onPress();
	};

	const isOdd = (num: number) => {
		const odd = num % 2;
		return odd ? true : false;
	};

	const apptDate = () => moment(props.lastAppointment).format("D.MM.YYYY");
	const apptTime = () => moment(props.lastAppointment).format("HH:mm");
	

	return (
		<RowItem
			dark={isOdd(props.index)}
			onClick={onPress}
			viewType={props.viewType}
			appointmentActive={props.appointmentActive}>
			<Left>
				{/* <RowIndex>{props.index}</RowIndex> */}
				<Avatar>
					{props.portrait && !imgError ? (
						<img
							src={props.portrait}
							alt="selfie"
							onError={e => {
								setImgError(true);
							}}
						/>
					) : (
						<HeadIcon />
					)}
				</Avatar>
				{props.viewType === "CLIENT_LIST" && <Name>{props.name}</Name>}
			</Left>
			<Right viewType={props.viewType}>
				{props.viewType === "CLIENT_LIST" && (
					<Visits>{props.appointments}</Visits>
				)}
				<LastAppointment viewType={props.viewType}>
					{apptDate()} <br /> <span>{apptTime()}</span>
				</LastAppointment>
				<Stylist viewType={props.viewType}>{props.stylist}</Stylist>
				<Chevron viewType={props.viewType}>
					<ChevronRightIcon />
				</Chevron>
			</Right>
		</RowItem>
	);
};

export default ClientListItemComponent;
