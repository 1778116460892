import React, { useState, useEffect } from "react";
import { Grid, Modal } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { ReactComponent as Logo } from "../../../assets/Images/logo.svg";
import { CSSTransition } from 'react-transition-group';
import DownloadPlaystore from "../../../assets/Images/download_playstore.png";
import DownloadAppstore from "../../../assets/Images/download_appstore.png";
import AWS from 'aws-sdk';
import "../style.css";
import {
	startGetAppointment
} from "../../../actions/appointmentsActions";
import { useAppointments } from "../../../contexts/AdminContext";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CloseIcon from '@material-ui/icons/Close';

AWS.config.update({
	accessKeyId: 'AKIA3U3BDIH2EPCIWMW2', secretAccessKey: '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', region: 'us-west-2'
});
const dynamoDB = new AWS.DynamoDB.DocumentClient();


//types
interface Props {
  toggleView(view: string): void;
  currentAppointment: any;
  animateInProp: boolean;
  userEmail: string;
}


const Accordion = withStyles({
  root: {
      boxShadow: 'none',
      backgroundColor: "transparent",
      padding: "0 24px",
      margin: "0 0 12px 0",
      color: "#FFF",
      borderBottom: '1px solid #FFF',
      '&$expanded': {
          margin: "0 0 12px 0"
      },
  },
  expanded: {
      
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
      backgroundColor: 'transparent',
      padding: 0,
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
          minHeight: 56,
      },
  },
  content: {
      display:'block',
      '&$expanded': {
          margin: '12px 0',
      },
  },
  expanded: {
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
      width: '100%',
      padding: 0,
      paddingBottom: 24
  }
}))(MuiAccordionDetails);

const AppointmentDetails = (props:Props) => {
  const [ appointmentState, appointmentDispatch ] = useAppointments();
  const [ animateIn, setAnimateIn ] = useState('false');
  const [ expandedAccordion, setExpandedAccordion ] = useState(-1);
  const [ date, setDate ] = useState('false');
  const [ productRecommendations, setProductRecommendations ] = useState([]) as any[];
	const [ productDetailsModalVisible, setProductDetailsModalVisible ] = useState(false);
	const [ productDetails, setProductDetails ] = useState({}) as any;

  let options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
          

  useEffect(() => {
    setAnimateIn(props.animateInProp ? 'true' : 'false')
    window.scrollTo(0, 0);
    console.log('hi', props.currentAppointment)
    let _d = new Date(props.currentAppointment.createdAt)
    setDate(_d.toLocaleDateString("en-US", options));
    if (props.currentAppointment.supportYourSalon) { 
      for (let r of props.currentAppointment.supportYourSalon.recommendations) {
        r.isSelected = false;
      }
      setProductRecommendations(props.currentAppointment.supportYourSalon.recommendations)
    } else {
      console.log(props.currentAppointment)
    }
  }, [])
  
  const toggleSelect = (_r:any) => {
    let _productRecommendations = [...productRecommendations] as any[];
    for (let r of _productRecommendations) {
      if (r.sku === _r.sku) r.isSelected = !r.isSelected;
    }
    console.log(_productRecommendations);
    setProductRecommendations(_productRecommendations);
  }

  return (
    <>
    <CSSTransition
      in={animateIn === 'true'}
      timeout={300}
      classNames="opacity">
    <Grid container direction="column" spacing={10} alignItems="stretch">
      <Grid item  style={{maxWidth: "100%"}}>
        <Grid container direction="row" justify="space-between" style={{marginBottom: 24}}>
          <Grid item>
            <a onClick={() => {props.toggleView('appointments')}} style={{fontSize: 24}}>‹ BACK</a>
          </Grid>
          <Grid item>
            <Logo width={65} style={{marginTop: 4}}/>
          </Grid>
        </Grid>

        <p>{date}<br />
        <span style={{color:"gray"}}>{props.currentAppointment.staffName}</span></p>
        
        {/* At home haircare */}
        {props.currentAppointment.supportYourSalon && (
          <Accordion expanded={expandedAccordion === 0} onChange={() => {if (expandedAccordion === 0) {setExpandedAccordion(-1)} else {setExpandedAccordion(0)}}}>
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon htmlColor={"#FFF"} />}>
                  <p>At home haircare</p>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  {productRecommendations.map((r:any) => 
                    <div style={{display:"flex", flexDirection:"row", alignItems:"center"}} >
                      <div onClick={() => toggleSelect(r)}>
                        {r.isSelected ? <CheckCircleOutlineIcon htmlColor={"#FFF"} /> : <RadioButtonUncheckedIcon htmlColor={"#FFF"} />}
                      </div>
                      <div onClick={() => toggleSelect(r)}>
                        <img src={r.productImageMain} width={100} height={100} />
                      </div>
                      <div>
                        <p>{r.productName}<br />
                        {r.productType}<br />
                        ${r.rrp}</p>
                        <p onClick={() => {setProductDetails(r); setProductDetailsModalVisible(true); }}>View details</p>
                      </div>
                    </div>
                  )}
                  {/* <a href={`https://supportyoursalon.com.au/create-cart?productSKUs=[${productSkus}]&salonID=${salonId}&email=${email}&name=${props.currentAppointment.clientName}`}>Order delivery</a> */}
                </div>
              </AccordionDetails>
          </Accordion> 
        )}

        {/* Rebooking */}
        {(props.currentAppointment.rebookDelay && props.currentAppointment.rebookLength) && (
          <Accordion expanded={expandedAccordion === 2} onChange={() => {if (expandedAccordion === 2) {setExpandedAccordion(-1)} else {setExpandedAccordion(2)}}}>
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon htmlColor={"#FFF"} />}>
                  <p>Rebooking suggestion</p>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  Rebook in {props.currentAppointment.rebookDelay} weeks for {props.currentAppointment.rebookLength} minutes.
                </div>
              </AccordionDetails>
          </Accordion> 
        )}
        {/* Hair Analysis */}
        {(props.currentAppointment.hairAnalysis) && (
          <Accordion expanded={expandedAccordion === 3} onChange={() => {if (expandedAccordion === 3) {setExpandedAccordion(-1)} else {setExpandedAccordion(3)}}}>
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon htmlColor={"#FFF"} />}>
                  <p>Hair analysis</p>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <p>Colour sensitivity: {props.currentAppointment.hairAnalysis.colorSensitivity}/10<br />
                  Curl: {props.currentAppointment.hairAnalysis.curl}/10<br />
                  Density: {props.currentAppointment.hairAnalysis.density}/10<br />
                  Elasticity: {props.currentAppointment.hairAnalysis.elasticity}/10<br />
                  Porosity: {props.currentAppointment.hairAnalysis.porosity}/10<br />
                  Scalp concerns: {props.currentAppointment.hairAnalysis.scalpConcerns}<br />
                  Texture: {props.currentAppointment.hairAnalysis.texture}/10</p>
                </div>
              </AccordionDetails>
          </Accordion> 
        )}
        {/* piiq Board */}
        {props.currentAppointment.imageLikes && (
          <Accordion expanded={expandedAccordion === 4} onChange={() => {if (expandedAccordion === 4) {setExpandedAccordion(-1)} else {setExpandedAccordion(4)}}}>
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon htmlColor={"#FFF"} />}>
                  <p>piiq Board</p>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{maxWidth: "100%", overflowX:"scroll", display:"flex", flexDirection:"row"}}>
                  {props.currentAppointment.imageLikes.map((i:any) => 
                    <img src={i.image.url} style={{
                      width: "120px",
                      height: "160px"
                    }}/>
                  )}
                  </div>
              </AccordionDetails>
          </Accordion> 
        )}

        {/* piiq Portrait */}
        {props.currentAppointment.clientSelfieImage && (
          <Accordion expanded={expandedAccordion === 5} onChange={() => {if (expandedAccordion === 5) {setExpandedAccordion(-1)} else {setExpandedAccordion(5)}}}>
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon htmlColor={"#FFF"} />}>
                  <p>piiq Portrait</p>
              </AccordionSummary>
              <AccordionDetails>
                <img src={props.currentAppointment.clientSelfieImage} style={{
                  width: "48%",
                  height: "auto"
                }}/>
              </AccordionDetails>
          </Accordion> 
        )}

        {/* 360 */}
        {props.currentAppointment.client360Image && (
          <Accordion expanded={expandedAccordion === 6} onChange={() => {if (expandedAccordion === 6) {setExpandedAccordion(-1)} else {setExpandedAccordion(6)}}}>
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon htmlColor={"#FFF"} />}>
                  <p>360</p>
              </AccordionSummary>
              <AccordionDetails>
                <img src={props.currentAppointment.client360Image} style={{
                  width: "48%",
                  height: "auto"
                }}/>
              </AccordionDetails>
          </Accordion> 
        )}

      {/* Product details modal */}
      <Modal
        open={productDetailsModalVisible}
        onClose={() => setProductDetailsModalVisible(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={{background: "white", width: "80%", padding: 20, margin: "24px auto"}}>
          <CloseIcon style={{float: "right"}} onClick={() => setProductDetailsModalVisible(false)}/>
          <h2>{productDetails.productName}</h2>
          <p>{productDetails.productSize} {productDetails.productType}</p>
          <p>{productDetails.bullets}</p>
          <p>{productDetails.description}</p>
        </div>
      </Modal>

        <hr style={{margin: "35px 0", opacity: 0.4}}></hr>
            <p style={{marginBottom: 24}}>Download the piiq app to keep your records in your pocket wherever you go.</p>
            <a href="http://apps.apple.com/au/app/piiq/id1458062799" target="_blank">
              <img
                src={DownloadAppstore}
                style={{
                  width: "48%",
                  height: "auto",
                  marginRight: '2%'
                }}
              />
            </a>
            <a href="http://play.google.com/store/apps/details?id=com.piiq&hl=en&gl=US" target="_blank">
              <img
                src={DownloadPlaystore}
                style={{
                  width: "48%",
                  height: "auto"
                }}
              />
            </a>
      </Grid>
    </Grid>
    </CSSTransition>
    </>
  );
}
export default AppointmentDetails;