import styled from "styled-components";

export const CheckboxContainer = styled.div`
	margin-left: -${22}px;
	margin-top: 2px;
	width: 30px !important;
	svg {
		fill: black;
	}
`;
