import React, { useEffect, useState } from "react";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { IAppointment } from "../../reducers/AppointmentsReducer";
import { DotChart, Image, Input, H1 } from "../../components"
import { ReactComponent as HeadIcon } from "../../assets/Images/head_icon.svg";
import { localeMessages } from '../../App'
import Typography from "@material-ui/core/Typography";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import {
	AppointmentPanel,
	Title,
	Portrait,
	Sub,
	NoImage,
	Divider,
	Empty,
	Concern,
	Product,
	LikedImage,
	Board,
	Back
} from "./styles";
import { Table } from "antd";
import delivery_btn from "../../assets/Images/delivery_btn.png";
import pickup_btn from "../../assets/Images/pickup_btn.png";
import na_btn from "../../assets/Images/na_btn.png";
import { startUpdateAppointmentNotes, startGetAllAppointments } from "../../actions/appointmentsActions";
import { useAppointments } from "../../contexts/AdminContext";
import { Button } from "@material-ui/core";
import {
  startSwitchTreatment,
  startUpdateSupportYourSalon
} from "./../../actions/appointmentsActions";
import NativeSelect from '@material-ui/core/NativeSelect';
import EditProductDialogComponent from "../../components/EditProductDialog";

//Currency sign
const priceFormat = new Intl.NumberFormat("en-AU", {
	style: "currency",
	currency: "AUD",
	useGrouping: false,
	currencyDisplay: "code"
});


interface IInputProps {
	label?: string;
	salonId: string;
	appointment: IAppointment | null;
	onCloseAppointment: any;
}

const AppointmentPanelComponent = (props: IInputProps) => {
	const [concernsList, setConcerns] = useState([] as any[])
	const appointment = props.appointment;	
	let uniqueId = 0;

	const [ state, dispatch ] = useAppointments();
	const [ notes, setNotes ] = useState(appointment?.notes ? appointment?.notes : '');
	const [treatment, setTreatment] = useState() as any;
	const [canUpdateTreatment, setCanUpdateTreatment] = useState(false) as any;
	const [newTreatment, setNewTreatment] = useState() as any;
	const [recommendations, setRecommendations] = useState() as any;
	const [editProductDialogOpen, setEditProductDialogOpen] = useState(false) as any;
	const [productBeingEdited, setProductBeingEdited] = useState() as any;

	const updateAppointment = (appointment) => {
		const _appt = appointment;
		_appt.notes = notes;
		dispatch(startUpdateAppointmentNotes(_appt));
    	// dispatch(startGetAllAppointments(props.salonId));
	};
	useEffect(() => {
		console.log('props.salonId', props.salonId)
	}, [ ])

	useEffect(() => {
		appointment && renderConcerns()
		console.log('appointment',appointment)
		setNotes(appointment?.notes ? appointment?.notes : '')
		setTreatment(appointment?.supportYourSalon?.treatment)
		setNewTreatment(appointment?.supportYourSalon?.treatment)
		setRecommendations(appointment?.supportYourSalon?.recommendations)
	}, [ appointment ])

	useEffect(() => {
		if (newTreatment != treatment) setCanUpdateTreatment(true)
  	}, [newTreatment]); 

	useEffect(() => {
		console.log('recommendations',recommendations)
  	}, [recommendations]); 


	  const retailColumns = [
		{
			key: "id",
			title: "",
			dataIndex: "productImageMain",
			render: ((productImageMain: string) =>
			productImageMain && (
				<img
				style={{
					width: "20px",
					height: "20px",
					border: "1px solid #9E9D9E"
				}}
				src={'https:' + productImageMain}
				alt="Product"
				/>
			))
		},
		{
			key: "productName",
			title: <FormattedMessage id="product.title" defaultMessage={`Product`} />,
			dataIndex: "productName",
			render: (productName: any) => productName
		},
		{
			key: "productSize",
			title: <FormattedMessage id="product.size" defaultMessage={`Size`} />,
			dataIndex: "productSize",
			render: (productSize: any) => productSize
		},
		{
			key: "productType",
			title: <FormattedMessage id="product.type" defaultMessage={`Type`} />,
			dataIndex: "productType",
			render: (productType: any) => productType
		},
		{
			title: <FormattedMessage id="product.total" defaultMessage={`Total`} />,
			dataIndex: "rrp",
			render: (rrp: any) => (rrp).toFixed(2)
		},
		{
		  title: "",
		  dataIndex: "sku",
		  render: (sku: any) => {
			return (<Button 
			  variant="outlined"
			  onClick={() => {
				setProductBeingEdited(recommendations.find((p) => p.sku == sku));
				setEditProductDialogOpen(true)
			  }}
			  style={{
				color: "black",
				fontSize: "0.8rem",
				borderRadius: "0px",
				position: "relative",
				marginLeft: "12px",
				padding: "2px 15px"
			  }}
			>
			  Edit
			</Button>)
		  }
		}
		];

		

	const handleSwitchTreatment = (newTreatment:string) => {
		if (appointment) {
			dispatch(startSwitchTreatment(appointment?.id, newTreatment));
			setTreatment(newTreatment);
			setNewTreatment(newTreatment);
			appointment.supportYourSalon.treatment = newTreatment;
			setCanUpdateTreatment(false)
		}
	  };

	const renderConcerns = () => {
		const concerns = appointment?.hairAnalysis?.scalpConcerns?.trim().split(",");
		const sc: string[] = []
		concerns?.map(concern => {
			if(concern !== '') {
				const concernTrim = concern.replace(/ /g, '')
				pickScalpConcern(concernTrim) && sc.push(concernTrim)
			}
			return true
		})

		setConcerns(sc)
	}


	const calcTotalPrice = () => {
		let totalPrice = 0;
		if (appointment?.purchasedItems) {
			appointment.purchasedItems.forEach((piSKU) => {
			if (appointment.supportYourSalon) {
				recommendations.forEach((s) => {
				if (s.sku === piSKU) {
				  totalPrice += s.rrp;
				}
			  });
			}
		  })
		}
		return totalPrice;
	  }


	const RetailBodyWrapper = (props: any) => {
		return (
		  <tbody {...props}>
			<React.Fragment>
			  {props.children}
			  <tr className="ant-table-row">
				<td colSpan={5}>
				  <Typography
					align="right"
					style={{
					  textTransform: "uppercase",
					  fontSize: "0.6rem",
					  marginRight: "10px",
					  fontWeight: "bold"
					}}
				  >
					<FormattedMessage id="product.total" defaultMessage={`Total`} />
					: {priceFormat.format(calcTotalPrice())}
				  </Typography>
	
				  <Typography
					align="right"
					style={{
					  textTransform: "uppercase",
					  fontSize: "0.6rem",
					  textDecoration: "underline",
					  fontWeight: "bold",
					  marginRight: "10px"
					}}
				  >
					  {appointment?.purchasedItems?.length ? (
					appointment?.salonPickup ? (
						<>
						<img src={pickup_btn} alt="logo" /> PICKUP
						</>
					) : (
						<>
						<img src={delivery_btn} alt="logo" /> DELIVERED
						</>
					)
				) : (
					<>
					<img src={na_btn} alt="logo" /> NOT BOUGHT
					</>
				)}
				  </Typography>
				</td>
			  </tr>
			</React.Fragment>
		  </tbody>
		);
	  };
	

	const pickScalpConcern = (concern: string) => {
		let name: string | null = null

		switch(concern) {
			case 'growth patterns': 
				name = 'client.appointment.scalp_1'
			break;
			case 'cowlicks':
				name ='client.appointment.scalp_2'
				break;
			case 'dandruff':
				name = 'client.appointment.scalp_3'
				break;
			case 'oily':
				name = 'client.appointment.scalp_4'
				break;
			case 'dry':
				name = 'client.appointment.scalp_5'
				break;
			case 'sensitive':
				name = 'client.appointment.scalp_6'
				break;
		}
		return (name && <FormattedMessage id={name} />)
	}

	const noPortrait = (<NoImage>
			<HeadIcon />
			<div><FormattedMessage id="client.appointment.no_portrait" /></div>
		</NoImage>)

	const no360 = (<NoImage>
			<HeadIcon />
			<div>
				No 360<sup>o</sup> Recorded
			</div>
		</NoImage>)


	const updateSupportYourSalon = (newProduct, oldProduct) => {
		let _appt = props.appointment;
		if (_appt && appointment) {
			let recs = _appt.supportYourSalon.recommendations;
			let i = recs.findIndex(_element => _element.sku === oldProduct.sku);;
			if (i > -1) recs[i] = newProduct;
			_appt.supportYourSalon.recommendations = recs;
			setRecommendations([...recs]);
			console.log('_appt', _appt)
			dispatch(startUpdateSupportYourSalon(_appt));
			setEditProductDialogOpen(false);
		}
	}

	const removeFromSupportYourSalon = (product) => {
		let _appt = props.appointment;
		if (_appt && appointment) {
			let recs = _appt.supportYourSalon.recommendations;
			let i = recs.findIndex(_element => _element.sku === product.sku);;
			recs.splice(i,1);
			_appt.supportYourSalon.recommendations = recs;
			setRecommendations(recs);
			dispatch(startUpdateSupportYourSalon(_appt));
			setEditProductDialogOpen(false);
		}
	}

	return (
		<AppointmentPanel active={appointment !== null}>
			{editProductDialogOpen ? (
				<EditProductDialogComponent 
				onClose={() => setEditProductDialogOpen(false)}
				recommendations={recommendations}
				onSave={updateSupportYourSalon}
				onRemove={removeFromSupportYourSalon}
				productBeingEdited={productBeingEdited}
				salonId={props.salonId}
				/>
			) : (<></>)}
			<Back onClick={props.onCloseAppointment}><ChevronLeftIcon /><span>Close records</span></Back>
			<H1>{appointment && appointment.clientName}</H1>
			<Sub>
				{appointment && moment(appointment.createdAt).format("D.MM.YYYY HH:mm")}
			</Sub>
			<Title>Stylist: {appointment && appointment.staffName}</Title>
			{appointment?.clientSelfieImage ? (
				<Portrait>
					<div className="grad" />
					<div className="img-label"><FormattedMessage id="client.appointment.portrait" /></div>
					<Image
						src={appointment.clientSelfieImage}
						alt="selfie"
						errorContent={noPortrait}
					/>
				</Portrait>
			) : (noPortrait)}
			<Divider />
			{appointment?.client360Image ? (
				<Portrait>
					<div className="grad" />
					<div className="img-label">360<sup>o</sup></div>
					<Image
						src={appointment.client360Image}
						alt="360"
						errorContent={no360}
					/>
				</Portrait>
			) : (no360)}

			<Title><FormattedMessage id="client.appointment.analysis" /></Title>
			{appointment?.hairAnalysis ? (<>
					{/* Denisty */}
					<DotChart 
						title={localeMessages['client.appointment.analysis_option1'].title}
						value={appointment.hairAnalysis.density / 2} 
						labelLeft={localeMessages['client.appointment.analysis_option1'].left}
						labelRight={localeMessages['client.appointment.analysis_option1'].right}
					/>
					{/* Texture */}
					<DotChart 
						title={localeMessages['client.appointment.analysis_option2'].title}
						value={appointment.hairAnalysis.texture / 2} 
						labelLeft={localeMessages['client.appointment.analysis_option2'].left}
						labelRight={localeMessages['client.appointment.analysis_option2'].right}
					/>
					{/* Curl */}
					<DotChart 
						title={localeMessages['client.appointment.analysis_option3'].title}
						value={appointment.hairAnalysis.curl / 2} 
						labelLeft={localeMessages['client.appointment.analysis_option3'].left}
						labelRight={localeMessages['client.appointment.analysis_option3'].right}
					/>
					{/* Sensitivity */}
					<DotChart 
						title={localeMessages['client.appointment.analysis_option4'].title}
						value={appointment.hairAnalysis.colorSensitivity / 2} 
						labelLeft={localeMessages['client.appointment.analysis_option4'].left}
						labelRight={localeMessages['client.appointment.analysis_option4'].right}
					/>
						{/* Porosity */}
						<DotChart 
						title={localeMessages['client.appointment.analysis_option5'].title}
						value={appointment.hairAnalysis.porous / 2} 
						labelLeft={localeMessages['client.appointment.analysis_option5'].left}
						labelRight={localeMessages['client.appointment.analysis_option5'].right }
					/>
					{/* Elasticity */}
					<DotChart 
						title={localeMessages['client.appointment.analysis_option6'].title}
						value={appointment.hairAnalysis.elasticity / 2} 
						labelLeft={localeMessages['client.appointment.analysis_option6'].left}
						labelRight={localeMessages['client.appointment.analysis_option6'].right}
					/>
				</>
			) : (
				<Empty><FormattedMessage id="client.appointment.no_analysis" /></Empty>
			)}
			<Title><FormattedMessage id="client.appointment.treatment_recommended" /></Title>
			<div>
			<NativeSelect id="treatment" value={newTreatment} onChange={(e) => {setNewTreatment(e.target.value)}}>
				<option value={"scalp"}>Scalp</option>
				<option value={"protein"}>Protein</option>
				<option value={"moisture"}>Moisture</option>
				<option value={"none"}>None</option>    
			</NativeSelect>
			{canUpdateTreatment ? (
				<Button 
				variant="outlined"
				onClick={() => handleSwitchTreatment(newTreatment)}
				style={{
					color: "black",
					fontSize: "0.8rem",
					borderRadius: "0px",
					position: "relative",
					marginLeft: "12px",
					padding: "2px 15px"
				}}
				>
					
					Save new treatment
				</Button>
			) : (<></>)}
			</div>

			<Title><FormattedMessage id="client.appointment.product_recommendations" /></Title>
			{recommendations?.length ? (
				<>
				 <Table
					rowKey={() => ++uniqueId + ""}
					components={{ body: { wrapper: RetailBodyWrapper } }}
					columns={retailColumns}
					dataSource={recommendations}
					pagination={{
						size: "small",
						simple: true,
						hideOnSinglePage: true,
						defaultPageSize: 6
					}}
				/>
				
				</>
			) : (
				<Empty><FormattedMessage id="client.appointment.no_products" /></Empty>
			)}
			
			<Title><FormattedMessage id="client.appointment.rebooking" /></Title>
			<p>{appointment?.rebookLength} mins in {appointment?.rebookDelay} weeks</p>
			
			

			<Title><FormattedMessage id="client.appointment.board" /></Title>
			{appointment?.imageLikes?.length ? (
				<Board>
					{appointment.imageLikes.map((liked, i) => <LikedImage key={i} amount={appointment.imageLikes.length}><Image src={liked.image.url} alt="liked"/></LikedImage>)}
				</Board>
			) : (
				<Empty><FormattedMessage id="client.appointment.no_board" /></Empty>
			)}

			<Title><FormattedMessage id="client.appointment.notes" /></Title>
			<Input textarea value={notes} onChange={setNotes}/>
			<Button
				variant="outlined"
				size="small"
				onClick={() => updateAppointment(appointment)}
				color="primary"
				style={{
					backgroundColor: "black",
					color: "white",
					width: "45%",
					fontFamily: "BitstreamRoman",
					borderRadius: "0px"
				}}
			>
				SAVE
			</Button>
		</AppointmentPanel>
	);
};

export default AppointmentPanelComponent;
