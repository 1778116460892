import React, { useReducer } from "react";
import { StripeProvider } from "react-stripe-elements";
import ApplessSignup from "./ApplessSignup";
import { reducerRegistration } from "../../reducers/RegistrationReducer";
import { SubscriptionContext, OrderDetailsModel } from "../../contexts/SubscriptionContext";


interface IProps {}

const ApplessSignupScreenContainer = (props: IProps) => {
  
  let stripeAPI = process.env.REACT_APP_STRIPE_API || '';

  return (
    <StripeProvider apiKey={stripeAPI}>
        <ApplessSignup />
    </StripeProvider>
  );
};

export default ApplessSignupScreenContainer;
