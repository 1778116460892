import React, { useEffect } from "react";
import {
	useSalons,
	useAppointments,
	useProducts
} from "../../contexts/AdminContext";

import Grid from "@material-ui/core/Grid";
import { SalonsListItem } from "./SalonsListItem";
import { Redirect } from "react-router-dom";
import { startClearAllProducts } from "../../actions/productsActions";
import {
	startClearAllAppointments,
	clearAllAppointments
} from "../../actions/appointmentsActions";
import { getAllSalons } from "../../actions/salonsActions";
import { IStateSalons } from "../../reducers/SalonsReducer";
import { FormattedMessage } from "react-intl";
import Layout from "../../components/Layout";
import { History } from "history";
import { useLocalStorage } from "../../utils";
import { CACHE } from "../../config";
import Loading from "./../../components/LoadingComponent";
import mixpanel from 'mixpanel-browser';

interface ISalonSelectorProps {
	history: History;
}

export default function SalonSelectorScreen ({ history }: ISalonSelectorProps) {
	const [ appointmentsCache, setAppointmentsCache ] = useLocalStorage(
		CACHE.APPOINTMENTS,
		[]
	);
	const [ state, dispatch ] = useSalons();
	const [ appoinmentState, appointmentDispatch ] = useAppointments();
	const [ productState, productDispatch ] = useProducts();

	useEffect(() => {
		dispatch(getAllSalons());
		/* Clean previous Salon data */
		setAppointmentsCache([]);
		appointmentDispatch(startClearAllAppointments());
		productDispatch(startClearAllProducts());
		document.title = "Salons";
		mixpanel.track("PMA Salons - Page accessed");
	}, []);

	const { salons } = state as IStateSalons;

	useEffect(() => {
		if (!state.loading) {
			console.log('SALONS ->', state)
			let isAdminOfAnySalons = false;
			state.salons.map((s:any) => {
				if (s.salonAdmin) {
					console.log("admin of this salon", s)
					isAdminOfAnySalons = true;
				}
			})
			if (!isAdminOfAnySalons) {
				console.log('not admin of any salons')
				history.push(`/signup`)
			}
		}
	}, [state])
	
	if (state.loading) {
		return <Loading />;
	}

	// if only one salon just set it and go to dashboard
	if (salons.length === 1 && salons[0] !== null) {
		return <Redirect to={`/${salons[0].id}`} />;
	}

	return (
		<Layout>
			<Grid item={true} xs={12} sm={6} md={6}>
				<h1>
					<FormattedMessage id="salons.pageTitle" defaultMessage={`Salons`} />
				</h1>
				<table style={{ width: "100%" }}>
					<thead
						style={{
							textAlign: "left",
							textTransform: "uppercase",
							fontSize: "0.6rem"
						}}>
						<tr>
							<th>
								<FormattedMessage
									id="salon.nametitle"
									defaultMessage={`Name`}
								/>
							</th>
							<th>
								<FormattedMessage
									id="salon.timeZone"
									defaultMessage={`Time Zone`}
								/>
							</th>
						</tr>
					</thead>
					<tbody>
						{salons.sort((a,b) => {
							if (a.name > b.name) return 1
							if (b.name > a.name) return -1
							return 0
						}).map((salon: any) => (
							<tr key={salon.id}>
								<SalonsListItem salon={salon} />
							</tr>
						))}
					</tbody>
				</table>
			</Grid>
		</Layout>
	);
}
