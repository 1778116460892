import React from "react";
import ProgressCircle from "./ProgressCircle";

const LoadingComponent = () => {
  return (
    <>
    <div
      style={{
        alignItems: "center",
        bottom: "0px",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-around",
        left: "0px",
        position: "fixed",
        right: "0px",
        top: "0px",
        width: "100%",
        backgroundColor: "rgba(255,255,255,0.7)"
      }}
    >
      <ProgressCircle />
      <svg
        version="1.1"
        id="piiq-svg"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        viewBox="0 0 103 37"
        style={{ position: "relative", width: "20%" }}
      >
        <defs>
          <clipPath id="piiq-clip" transform="rotate(30)">
            <path
              d="M5.9,37C2,37,0,34.6,0,29.9V7.1c0-1.3,0.4-2.6,1.2-4.1C2,1.7,3.8,1,6.7,1h8.7
                    c4.1,0,7.4,1.4,10,4.1c2.4,2.6,3.6,5.5,3.6,8.8v0.6c-0.3,3.9-1.8,6.9-4.5,9.1c-2.8,2.1-5.8,3.2-9.1,3.2h-2.8v3.8
                    c0,1.9-0.6,3.4-1.8,4.6C9.6,36.3,8,37,6.4,37L5.9,37z M60.8,37c-1.1,0-2.3-0.3-3.3-0.8c-2.3-1.1-3.5-3-3.5-5.8V6.8
                    C54,2.3,56.4,0,61.2,0C65.7,0.2,68,2.5,68,7v23c0,2.8-1.2,4.8-3.5,6C63.5,36.7,62.2,37,61,37L60.8,37z M40.4,37
                    c-1.1,0-2.1-0.3-3.1-0.8c-2.2-1.1-3.3-3-3.3-5.8V6.8C34,2.3,36.3,0,40.7,0C44.9,0.2,47,2.5,47,7v23c0,2.8-1.1,4.8-3.2,6
                    c-1,0.6-2.1,0.9-3.3,0.9L40.4,37z M96.9,36c-1.6,0-3.1-0.7-4.2-1.8c-1.2-1.2-1.7-2.7-1.7-4.6v-3.8h-2.7c-3.2,0-6.1-1.1-8.8-3.2
                    c-2.6-2.1-4.1-5.2-4.4-9v-0.7c0-3.2,1.2-6.1,3.5-8.7C81,1.4,84.2,0,88.2,0h8.4c2.7,0,4.5,0.7,5.3,2.1c0.8,1.4,1.2,2.7,1.2,4V29
                    c0,4.7-1.9,7-5.7,7L96.9,36z"
            />
          </clipPath>
        </defs>

        <path
          id="piiq-Logo"
          style={{ fill: "black" }}
          d="M5.9,37C2,37,0,34.6,0,29.9V7.1c0-1.3,0.4-2.6,1.2-4.1C2,1.7,3.8,1,6.7,1h8.7
                c4.1,0,7.4,1.4,10,4.1c2.4,2.6,3.6,5.5,3.6,8.8v0.6c-0.3,3.9-1.8,6.9-4.5,9.1c-2.8,2.1-5.8,3.2-9.1,3.2h-2.8v3.8
                c0,1.9-0.6,3.4-1.8,4.6C9.6,36.3,8,37,6.4,37L5.9,37z M60.8,37c-1.1,0-2.3-0.3-3.3-0.8c-2.3-1.1-3.5-3-3.5-5.8V6.8
                C54,2.3,56.4,0,61.2,0C65.7,0.2,68,2.5,68,7v23c0,2.8-1.2,4.8-3.5,6C63.5,36.7,62.2,37,61,37L60.8,37z M40.4,37
                c-1.1,0-2.1-0.3-3.1-0.8c-2.2-1.1-3.3-3-3.3-5.8V6.8C34,2.3,36.3,0,40.7,0C44.9,0.2,47,2.5,47,7v23c0,2.8-1.1,4.8-3.2,6
                c-1,0.6-2.1,0.9-3.3,0.9L40.4,37z M96.9,36c-1.6,0-3.1-0.7-4.2-1.8c-1.2-1.2-1.7-2.7-1.7-4.6v-3.8h-2.7c-3.2,0-6.1-1.1-8.8-3.2
                c-2.6-2.1-4.1-5.2-4.4-9v-0.7c0-3.2,1.2-6.1,3.5-8.7C81,1.4,84.2,0,88.2,0h8.4c2.7,0,4.5,0.7,5.3,2.1c0.8,1.4,1.2,2.7,1.2,4V29
                c0,4.7-1.9,7-5.7,7L96.9,36z"
        />
        <rect
          id="Rectangle-1"
          fill="#FFFFFF"
          clipPath="url(#piiq-clip)"
          x="0"
          y="0"
          width="25"
          height="500"
          transform="rotate(-30)"
        >
          <animate
            attributeType="XML"
            attributeName="x"
            from="-150"
            to="150"
            dur="1.5s"
            repeatCount="indefinite"
          />
        </rect>
        <rect
          id="Rectangle-2"
          fill="#FFFFFF"
          clipPath="url(#piiq-clip)"
          x="0"
          y="0"
          width="10"
          height="500"
          transform="rotate(-30)"
        >
          <animate
            attributeType="XML"
            attributeName="x"
            from="-125"
            to="185"
            dur="1.5s"
            repeatCount="indefinite"
          />
        </rect>
      </svg>
    </div>
    </>
  );
};

export default LoadingComponent;
