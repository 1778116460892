import styled from "styled-components";
import { COLORS, SIZE, FONT, BREAKPOINTS } from "../../config";
import BannerBG from "./../../assets/Images/ibbtopbg.png";
import BannerBGComplete from "./../../assets/Images/ibbtopbgcomplete.png";

interface props {
	i?: number;
	complete?: boolean;
	isTotal?: boolean;
	isReward?: boolean;
};

export const Container = styled.div`
	width: calc(100% - ${SIZE.MEDIUM * 2}px);
	margin: 0;

	@media (min-width: ${BREAKPOINTS.DEKSTOP_MEDIUM}px) {
		width: calc(100% - ${SIZE.LARGE * 2}px);
		/* padding: ${SIZE.LARGE}px;*/
		max-width: 1020px; 
		margin: auto;
	}
`;


export const Loader = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Title = styled.h2`
	font-size: 40px;
	font-family: "BitstreamRomanaBold";
	font-weight: bold;
	text-transform: uppercase;
	margin: 0 0 24px 0;
	padding: 0;
	line-height: 1;

	span {
		clear: both;
		padding-left: 24px;
		display:block;
	}
`;

export const ProgressAmount = styled.div`
	font-size: 42px;


	@media (max-width: ${BREAKPOINTS.DEKSTOP}px) {
		font-size: 24px;
	}
`;
export const ProgressLabel = styled.div`
`;
export const ProgressContainer = styled.div`
	background: white;
	margin-left: auto;
	margin-top: 55px;
	margin-right: 35px;
	padding: 24px;
	width: 33%;

	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		width: 50%;
	}
	@media (max-width: ${BREAKPOINTS.MOBILE_LARGE}px) {
		width: 60%;
	}
`;
export const BannerCTA = styled.div<props>`
	margin-right: auto;
	font-size: 42px;
	line-height: 1;
	margin-top: 30px;
	color: ${props => (props.complete === true) ? 'white' : 'black'}

	em {
		display: block;
		margin-left: 24px;
		font-style: italic;
	}

	span {
		margin-left: 70px;
	}

	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		font-size: 24px;
		em {
			display: block;
			margin-left: 12px;
			font-style: italic;
		}
	
		span {
			margin-left: 24px;
		}
	}


	@media (max-width: ${BREAKPOINTS.MOBILE_LARGE}px) {
		font-size: 16px;
		em {
			display: block;
			margin-left: 8px;
			font-style: italic;
		}
	
		span {
			margin-left: 16px;
		}
	}
`;
export const Banner = styled.div<props>`
	width: calc(100%-24px);
	background: url(${props => (props.complete === true) ? BannerBGComplete : BannerBG});
    background-size: cover;
	height: 190px;
	display: flex;
	margin-left: 24px;

	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		margin: 0;
	}

	@media (max-width: ${BREAKPOINTS.MOBILE_LARGE}px) {
		margin: 0;
		height: 140px;
	}
`;

export const SectionHeader = styled.h2`
	font-size: 40px;
	font-family: "BitstreamRomanaBold";
	font-weight: bold;
	text-transform: uppercase;
	margin: 0 0 24px 0;
	padding: 0;
	line-height: 1;

	span {
		clear: both;
		padding-left: 24px;
		display:block;
	}
	@media (max-width: ${BREAKPOINTS.MOBILE_LARGE}px) {
		font-size: 24px;

		span {
			padding-left: 12px;
		}
	}
`;

export const AccordionContainer = styled.div`

	@media (min-width: ${BREAKPOINTS.TABLET}px) {
		margin-left: 24px;
	}
`;


export const Lessons = styled.div`
	width: 100%;
	border-top: 1px solid #DDD;
	padding-left: 12px;
	padding-top: 24px;
	margin-bottom: 24px;
	h3 {
		margin-bottom: 4px;
	}
	p {
		margin-top: 4px;
	}
	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		margin: 0;
	}
`;
export const Lesson = styled.div`
	display: flex;
	min-height: 80px;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	position: relative;
    height: auto;
`;



export const Graphic = styled.div`
	width: 22px;
	margin-right: 30px;
	height: 100%;
	position: relative;
    display: inline-block;
	min-height: 85px;

	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		display:none;
	}
`;


export const Ball = styled.div<props>`
	width: 22px;
	height: 22px;
	border-radius: 11px;
	background: ${props => (props.complete === true) ? '#7cee00' : '#C7C7C7'};
	top: 38%;
    position: absolute;
	z-index: 1;
`;


export const LineAbove = styled.div<props>`
	${props => (props.i === 0) ? 'display:none;' : ''}
	width 2px; 
	height: 50%;
	background: #C7C7C7;
	position: absolute;
	left: 50%;
	margin-left: -1px;
`;

export const LineBelow = styled.div<props>`
	${props => (props.isTotal) ? 'display:none;' : ''}
	width 2px; 
	height: 50%;
	background: #C7C7C7;
	position: absolute;
	left: 50%;
	bottom: 0;
	margin-left: -1px;
`;


export const AccordionHeading = styled.h2`
	margin-bottom: 4px;
`;

export const AccordionDescription = styled.p`
	font-size: 14px;
	margin-top: 4px;
	color: #333;
`;



export const EmailInputContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-left: 24px;
	margin-bottom: 24px;
	align-items: end;
	max-width: 600px;
	line-height: 1;


	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		margin-left: 0;
	}
`;


export const MarginLeftDiv = styled.div`
	margin-left: 24px;

	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		margin-left: 0;
	}
`;

