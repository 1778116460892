import React from "react";
import OnboardingScreen from "./OnboardingScreen";
import { GlobalStyle } from "./styles";

interface IProps {}

const OnboardingScreenContainer = (props: IProps) => {

  return (
      <>
        <GlobalStyle />
        <OnboardingScreen />
      </>
  );
};

export default OnboardingScreenContainer;
