import React from "react";
import { ResponsivePie } from "@nivo/pie";
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

export default function PieCompt(props: any) {
  return (
    <ResponsivePie
      data={props.data}
      margin={{ top: 5, right: 5, bottom: 55, left: 5 }}
      innerRadius={0.66}
      padAngle={0.7}
      cornerRadius={0}
      colors={props.colorscheme}
      borderWidth={1}
      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
      enableRadialLabels={false}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          size: 4,
          padding: 1,
          stagger: true
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          rotation: -45,
          lineWidth: 6,
          spacing: 10
        }
      ]}
      // fill={props.fill}
      enableSlicesLabels={true}
      legends={[
        {
          anchor: "bottom",
          direction: "column",
          justify: false,
          translateX: -38,
          translateY: 57,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 15,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
      theme={{
        legends: {
          text:props.fontStyle
        },
        tooltip: {
          container: props.fontStyle
        }
      }}
    />
  );
}
