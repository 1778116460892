import { SubscriptionType, Plan } from "../screens/Registration/types";

export interface IRegistration {
  staff: Array<string>;
  hashtags: Array<string>;
  rebooking_message: string;
  rebooking_day_reminder: number;
}

export interface IRegistrationAction {
  type: "UPDATE_REGISTRATION_DETAILS";
  data: IRegistration;
}

export const RegistrationReducer = (
  state: any,
  action: IRegistrationAction
) => {
  switch (action.type) {
    case "UPDATE_REGISTRATION_DETAILS":
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data
        }
      };

    default:
      return state;
  }
};

// SUBSRIPTION INTERFACES -----------------------------------------------

export interface IOrderDetail {
  subscription_type: SubscriptionType | string;
  mirror_quantity: number;
  wall_mount: number;
  bench_mount: number;
  colour_brand?: string;
  supplier_code?: string;
  sub_total?: number;
  discount?: number;
  shipping?: number;
  total?: number;
  plan?: Plan | string;
}

export interface IAccountDetail {
  first_name: string;
  last_name: string;
  salon: string;
  loreal_account_number: string;
  email: string;
  phone: string;
  password: string;
  news_opt_in: boolean;
}

export interface IDeliveryDetail {
  address_line_1: string;
  address_line_2?: string;
  city: string;
  state: string;
  country: string;
  postcode: string;
  notes?: string;
  deliveryOption: "STANDARD" | string;
}

export interface ISalonDetail {
  id: string;
  password: string | null;
}

export interface IPaymentRegister {
  addressLine1: string;
  addressLine2?: string;
  benchMounts: number;
  city: string;
  colourBrand?: string;
  country: "AU" | "NZ" | string;
  discount?: number;
  deliveryOption: "STANDARD" | string;
  email: string;
  firstName: string;
  lastName: string;
  locale: string;
  lorealAccountNumber: string;
  mirrors: number;
  name: string; // SALON NAME
  newsOptIn: boolean;
  notes?: string;
  // password?: string;
  paymentMethod: string;
  phone: string;
  plan: SubscriptionType | string;
  postcode: string;
  wallMounts: number;
  shipping?: number;
  state: string;
  supplierCode?: string;
  timeZone: string;
  total?: number;
}

export interface ISubscription {
  orderDetails: IOrderDetail;
  accountDetails: IAccountDetail;
  deliveryDetails: IDeliveryDetail;
  salon: ISalonDetail;
}

export interface IStateRegisterPayment {
  loading: boolean;
  paymentDetails: ISalonDetail | null;
}

export interface IRegisterPaymentAction {
  type: "SET_REGISTER_PAYMENT";
  paymentDetails: ISalonDetail;
}

export interface ISubscriptionAction {
  type:
    | "UPDATE_ORDER_DETAILS"
    | "UPDATE_ACCOUNT_DETAILS"
    | "UPDATE_DELIVERY_DETAILS"
    | "UPDATE_SALON";
  orderDetails: ISubscription;
  accountDetails: ISubscription;
  deliveryDetails: ISubscription;
  paymentDetails: ISalonDetail;
}

export const initialStatePaymentRegistration: IStateRegisterPayment = {
  loading: true,
  paymentDetails: null
};

export const reducerRegistration = (
  state: any,
  action: ISubscriptionAction | IRegisterPaymentAction
) => {
  switch (action.type) {
    case "UPDATE_ORDER_DETAILS":
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          ...action.orderDetails
        }
      };

    case "UPDATE_ACCOUNT_DETAILS":
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          ...action.accountDetails
        }
      };

    case "UPDATE_DELIVERY_DETAILS":
      return {
        ...state,
        deliveryDetails: {
          ...state.deliveryDetails,
          ...action.deliveryDetails
        }
      };

    case "SET_REGISTER_PAYMENT":
      return {
        ...state,
        salon: {
          ...state.salon,
          ...action.paymentDetails
        }
      };
    default:
      return state;
  }
};
