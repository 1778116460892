import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { StaticContext, useParams } from "react-router";
import { Notification, Layout, Fetching, Input} from "../../components";
import { Container, Title, Done,ContentContainer, IframeContainer, PhotoContent, PhotosContainer, BackButton, TodoHeader, Order, Description } from './styles'
import { GetAcademyDetail } from "../../utils";
import Auth from "@aws-amplify/auth";
interface IPathParams {
	salonId: string;
	appointmentId?: string;
}
export default function AcademyDetailScreen ({
    location,
	match,
	history
}: React.PropsWithChildren<
RouteComponentProps<IPathParams, StaticContext, any>
>) {
    const [ loading, setLoading ] = useState(true);
    const [ academyDetail, setAcademyDetail ] = useState() as any;
    const [ email, setEmail ] = useState('');
    const { academyid } = useParams<{ academyid: string }>()
    const { salonId } = match.params;

    useEffect(() => {
        getAuth();
    }, [])

    const getAuth = async () => {
        try {
            console.log(academyid)
            const cognitoUser = await Auth.currentAuthenticatedUser();
            console.log("Cognito User", cognitoUser.attributes.email);
            setEmail(cognitoUser.attributes.email);
            get(cognitoUser.attributes.email);
        } catch (e) {
            console.log("Authenticator::CheckUser:Error:", e.message);
        }
    }
    const get = async (email) => {
        let out = await GetAcademyDetail(academyid, email);
        console.log('r',out.data.response)
        setAcademyDetail(out.data.response.content)
        setLoading(false)
    }


	return (
		<Layout>
			<Notification />
			    <Container>
                    <ContentContainer>
                        <BackButton onClick={() => history.push(`/${salonId}/academy`)}>← BACK</BackButton>
                        <Title>{academyDetail?.Name}</Title>
                        <IframeContainer>
                        {academyDetail?.VimeoURLID ? (
                            <iframe 
                                width="560" height="315" 
                                src={"https://player.vimeo.com/video/"+academyDetail?.VimeoURLID}
                                title="YouTube video player" 
                                frameBorder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowFullScreen>    
                            </iframe>
                        ) : (
                            <iframe 
                                width="560" height="315" 
                                src={"https://www.youtube.com/embed/"+academyDetail?.YoutubeURLID}
                                title="YouTube video player" 
                                frameBorder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowFullScreen>    
                            </iframe>
                        )}
                        
                        </IframeContainer>
                        <p>TRANSCRIPT</p>
                        <p>{academyDetail?.Transcript}</p>
                    </ContentContainer>
                    <PhotosContainer>
                        <PhotoContent>
                        <TodoHeader>IMAGES</TodoHeader>
                        { academyDetail?.Images && academyDetail?.Images.map((a, i) => 
                            <img src={"https:"+a} style={{width:'100%', height:'auto'}} />
                        )}
                        </PhotoContent>
                    </PhotosContainer>
                </Container>
			<Fetching />
		</Layout>
	);
}
