import React, { useState } from "react";
import { ReactComponent as HeadIcon } from "../../assets/Images/head_icon.svg";
import { ImgAvatar } from "./styles";

interface IImgProps {
	src: string;
}

const ButtonComponent = (props: IImgProps) => {
	const [ imgError, setImgError ] = useState(false);

	return (
		<ImgAvatar>
			{!imgError && props.src ? (
				<img
					src={props.src}
					alt="avatar"
					onError={e => {
						setImgError(true);
					}}
				/>
			) : (
				<HeadIcon />
			)}
		</ImgAvatar>
	);
};

export default ButtonComponent;
