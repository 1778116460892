import React, { useState, useEffect } from "react";
import {
	makeStyles,
	useTheme,
	Theme,
	createStyles
} from "@material-ui/core/styles";
import { Card, CardContent } from "@material-ui/core";
import SignInForm from "./components/SignInForm";
import Success from "./components/Success";
import Appointments from "./components/Appointments";
import AppointmentDetails from "./components/AppointmentDetails";
import StartSession from "./components/StartSession";
import SignUpForm from "./components/SignUpForm";
import Landing from "./components/Landing";
import logo from "./../../assets/Images/logo_blacktransparent.png";
import ForgotPasswordForm from "./components/ForgotPasswordForm";
import Auth from "@aws-amplify/auth";
import { ViewArrayTwoTone } from "@material-ui/icons";

interface IProps {}

// types
interface IState {
	view: string;
	isLoggedIn: any;
	successful: any;
	userEmail: any;
  }



const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		applessLoginContainer: {
			width: '100%',
			minHeight: '100%',
			background: 'black',
			color: 'white',
			padding: 30,
			boxSizing: 'border-box'
		},
		logout: {
			textTransform: "none",
			borderBottom: "1px solid black"
		},
		link: {
			textDecoration: "none",
			textTransform: "uppercase",
			color: "black",
			fontSize: "1rem",
			fontFamily: "Helvetica"
		}
	})
);

const ApplessSignupScreen = (props: IProps) => {
	const [view, setView] = useState("landing");
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [userEmail, setUserEmail] = useState('');
	const [userId, setUserId] = useState('');
	const [sessionId, setSessionId] = useState('');
	const [currentAppointment, setCurrentAppointment] = useState() as any;
	const [currentAppointmentId, setCurrentAppointmentId] = useState('');
	const [currentSalonId, setCurrentSalonId] = useState('');
	const [successful, setSuccessful] = useState(false);

	const classes = useStyles();
	
	const checkAuthState = async (setIsLoggedIn, setUserEmail, setUserId) => {
		try {
			const cognitoUser = await Auth.currentAuthenticatedUser();
			console.log('ROOT CHECK AUTH COGNITO', cognitoUser)
			setUserEmail(cognitoUser.attributes.email);
			setUserId(cognitoUser.username)
			setIsLoggedIn(true);
		} catch (e) {
			setIsLoggedIn(false);
			console.log("Authenticator::CheckUser:Error:", e);
		}
	}

	useEffect(() => {
		if (isLoggedIn) {
			if (sessionId) {
				setView("landing")
			} else {
				setView("appointments")
			}
		}
	}, [isLoggedIn])

	useEffect(() => {
		checkAuthState(setIsLoggedIn, setUserEmail, setUserId);
	}, [view])

	const _setView = (v) => {
		setView(v);
	}

	useEffect(() => {
		let url = new URLSearchParams(window.location.search).get("session")
		if (url) {
			setSessionId(url)
		}
		checkAuthState(setIsLoggedIn, setUserEmail, setUserId);
	}, [])


	useEffect(() => {
		console.log('currentAppointment', currentAppointment)
	}, [currentAppointment])
	return (
		<div className={classes.applessLoginContainer}>
			{sessionId ? 
				isLoggedIn ? (
					<>
					{view === "success" && <Success animateInProp={view === "success"} currentAppointmentId={currentAppointmentId} currentSalonId={currentSalonId} userEmail={userEmail} userId={userId} toggleView={(v) => {_setView(v)}}/>}
					{(view === "startsession" || view === "landing") && <StartSession animateInProp={(view === "startsession" || view === "landing")} userEmail={userEmail} sessionId={sessionId} toggleView={(v) => {_setView(v)}} setCurrentAppointmentId={setCurrentAppointmentId} setCurrentSalonId={setCurrentSalonId}/>}
					{view === "appointments" && <Appointments animateInProp={view === "appointments"} userId={userId} userEmail={userEmail} toggleView={(v) => {_setView(v)}} setCurrentAppointment={setCurrentAppointment} />}
					{view === "appointmentDetails" && <AppointmentDetails animateInProp={view === "appointmentDetails"} userEmail={userEmail} toggleView={(v) => {_setView(v)}} currentAppointment={currentAppointment} />}
					</>
				) : (
					<>
						{view === "landing" && <Landing animateInProp={view === "landing"} setUserEmail={setUserEmail} sessionId={sessionId} toggleView={(v) => {_setView(v)}} />}
						{view === "signin" && <SignInForm redirectTo={'success'} animateInProp={view === "signin"} userEmail={userEmail} sessionId={sessionId} toggleView={(v) => {_setView(v)}} />}
						{view === "signup" && <SignUpForm animateInProp={view === "signup"} userEmail={userEmail} sessionId={sessionId} toggleView={(v) => {_setView(v)}} />}
						{view === "forgot" && <ForgotPasswordForm animateInProp={view === "forgot"} toggleView={(v) => {_setView(v)}} />}
					</>
			) : (
				<>
					{view === "landing" && <Landing animateInProp={view === "landing"} setUserEmail={setUserEmail} sessionId={sessionId} toggleView={(v) => {_setView(v)}} />}
					{view === "signin" && <SignInForm redirectTo={'appointments'} animateInProp={view === "signin"} userEmail={userEmail} sessionId={sessionId} toggleView={(v) => {_setView(v)}} />}
					{view === "signup" && <SignUpForm animateInProp={view === "signup"} userEmail={userEmail} sessionId={sessionId} toggleView={(v) => {_setView(v)}} />}
					{view === "appointments" && <Appointments animateInProp={view === "appointments"} userId={userId} userEmail={userEmail} toggleView={(v) => {_setView(v)}} setCurrentAppointment={setCurrentAppointment} />}
					{view === "appointmentDetails" && <AppointmentDetails animateInProp={view === "appointmentDetails"} userEmail={userEmail} toggleView={(v) => {_setView(v)}} currentAppointment={currentAppointment} />}
				</>
			)}
		</div>
	);
};

export default ApplessSignupScreen;
