import React, { useState, useEffect, useRef } from "react";
import { animated, useSpring } from "react-spring";
import { FormattedMessage } from "react-intl";
import { Input, Button, Content } from "../../../../components";
import { SubscriptionState } from "../../../../contexts/SubscriptionContext";
import { SummaryType, Plan } from "../../types";
import { validateEmail } from "../../../../utils";
import { Label, Footer } from "./styles";
import mixpanel from 'mixpanel-browser';

interface IProps {
  transitionConfig?: any;
  onNavigate: Function;
  onSkipSection: Function;
  onCompleteSection: Function;
}

interface IErrors {
  first_name: string | null;
  last_name: string | null;
  salon: string | null;
  email: string | null;
  phone: string | null;
}

const CreateAccountComponent = (props: IProps) => {
  const formErrors: IErrors = {
    first_name: null,
    last_name: null,
    salon: null,
    email: null,
    phone: null
  };
  const context = SubscriptionState();
  const contentScroll = useRef<HTMLDivElement>(null);
  const [ accountDetails, setAccountDetails ] = useState(
    context.state.accountDetails
  );
  const [ errors, setErrors ] = useState(formErrors);
  const [ submitted, setSubmitted ] = useState(false);

  useEffect(() => {
    if (contentScroll && contentScroll.current) {
      contentScroll.current.scrollTop = 0;
      window.scrollTo(0, 0);
      
    }
    
    mixpanel.track("PMA Create account details page accessed event");
  }, []);

  const onSubmit = async () => {
    setSubmitted(true);
    if (validateForm()) {
      await context.dispatch({
        type: "UPDATE_ACCOUNT_DETAILS",
        accountDetails: {
          ...accountDetails,
          email: accountDetails.email.toLowerCase()
        }
      });
      if (context.state.orderDetails.plan == Plan.PLUS) {
        props.onNavigate(SummaryType.DELIVERY);
      } else if (context.state.orderDetails.plan == Plan.FREE) {
        props.onNavigate(SummaryType.ORDER);
        props.onSkipSection([SummaryType.DELIVERY, SummaryType.PAYMENT]);
      } else {
        props.onNavigate(SummaryType.PAYMENT);
        props.onSkipSection([SummaryType.DELIVERY]);
      }
      
      props.onCompleteSection();
    }
  };

  const validateForm = () => {
    const validate: IErrors = {
      first_name: !accountDetails.first_name ? "* First Name required" : null,
      last_name: !accountDetails.last_name ? "* Last Name required" : null,
      salon: !accountDetails.salon ? "* Salon required" : null,
      email: !accountDetails.email ? "* Email required" : null,
      phone: !accountDetails.phone ? "* Phone required" : null
    };
    if (!validateEmail(accountDetails.email)) {
      validate.email = "* Email invalid";
    }

    setErrors(validate);

    if (
      !validate.first_name &&
      !validate.last_name &&
      !validate.salon &&
      !validate.email &&
      !validate.phone
    )
      return true;
    else return false;
  };

  const onInputChange = (value: string, name: string) => {
    setAccountDetails({
      ...accountDetails,
      [name]: value
    });
    if (submitted) {
      setErrors({
        ...errors,
        [name]:
          name === "email"
            ? !validateEmail(value) ? "* Email invalid" : null
            : null
      });
    }
  };

  const onPhoneChange = (value: any, e:any) => {
      
    setAccountDetails({
      ...accountDetails,
      phone: value.phone
    });
    setErrors({
      ...errors,
      phone: null
    });
  };

  const transitionConfig = useSpring(props.transitionConfig);

  return (
    <Content as={animated.div} style={transitionConfig} ref={contentScroll}>
      <div style={{maxWidth: 900, margin: "0 auto", paddingBottom: 100}}>
      <h1>
        <FormattedMessage id="signup.account.title" />
      </h1>
      <div style={{display: "flex"}}>
      <Input
        name="first_name"
        onChange={onInputChange}
        placeholder={"First Name"}
        value={accountDetails.first_name}
        label="First Name*"
        error={errors.first_name}
        inputStyle={{width:"90%"}}
      />
      <Input
        name="last_name"
        onChange={onInputChange}
        placeholder={"Last Name"}
        value={accountDetails.last_name}
        label="Last Name*"
        error={errors.last_name}
        inputStyle={{width:"90%"}}
      />
      </div>
      <Input
        onChange={onInputChange}
        placeholder={
          accountDetails.salon ? accountDetails.salon : "Salon / Trading Name"
        }
        value={accountDetails.salon}
        label="Salon / Trading Name*"
        name="salon"
        error={errors.salon}
      />
      <Input
        onChange={onInputChange}
        placeholder="eg. 987654321" 
        value={accountDetails.loreal_account_number}
        label="Primary Supplier Account Number (For example, this could be your L'Oreal PPD number or other brand account ID)"
        name="loreal_account_number"
      />
      <Input
        onChange={onInputChange}
        placeholder={"Email Address"}
        value={accountDetails.email}
        label="Email Address*"
        name="email"
        error={errors.email}
      />
      <Label>
        <FormattedMessage id="signup.account.form.phone" />
      </Label>
      <div className="account-phone-input margin-bottom-mid">
        <Input
          type="phone"
          onChange={onPhoneChange}
          value={accountDetails.phone}
          error={errors.phone}
        />
      </div>

      <Button onPress={onSubmit} label="Next" />
      <Footer />
      </div>
    </Content>
  );
};

export default CreateAccountComponent;
