import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { StaticContext } from "react-router";
import { Notification, Layout, Fetching, AcademyContentListItemComponent} from "../../components";
import { Container, Loader, ResetContainer, Content, ProgressAmount, ProgressLabel, ProgressContainer, BannerCTA, Banner, SectionHeader } from './styles'
import { GetAcademyContent } from "../../utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SubscriptionState } from "../../contexts/SubscriptionContext";
import Button from "@material-ui/core/Button";
import Auth from "@aws-amplify/auth";
interface IPathParams {
	salonId: string;
	appointmentId?: string;
}
export default function AcademyScreen ({
    location,
	match,
	history
}: React.PropsWithChildren<
RouteComponentProps<IPathParams, StaticContext, any>
>) {
    
    const [ loading, setLoading ] = useState(true);
    const [ academyContent, setAcademyContent ] = useState([]);
    const [ email, setEmail ] = useState('');
    const { salonId } = match.params;
    

    useEffect(() => {
        getAuth();
    }, [])


    const getAuth = async () => {
        try {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            console.log("Cognito User", cognitoUser.attributes.email);
            get(cognitoUser.attributes.email);
            setEmail(cognitoUser.attributes.email);
        } catch (e) {
            console.log("Authenticator::CheckUser:Error:", e.message);
        }
    }
    const get = async (email) => {
        let out = await GetAcademyContent(email);
        setAcademyContent(out.data.response.content)
        console.log(out.data.response.content)
        setLoading(false)
    }


    const onAcademyPress = (academyContent) => {
        history.push(`/${salonId}/academy/${academyContent._id}`);
        console.log('pushed!')
    }

	return (
		<Layout>
			<Notification />
			    <Container>
                    <SectionHeader>PIIQ <span>ACADEMY</span></SectionHeader>
                        <Content>
                        {(academyContent && academyContent.length > 0) ? (
                            <>
                                { academyContent.map((a, i) => 
                                <AcademyContentListItemComponent 
                                    content={a} 
                                    onPress={() => {onAcademyPress(a)}}/>
                                ) 
                                }
                            </>
                        ) : loading ?
                        <Loader>
                            <CircularProgress/>
                        </Loader> :
                            <></>
                        }
                    </Content>
                </Container>
			<Fetching />
		</Layout>
	);
}
