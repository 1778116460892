import styled from "styled-components";
import { COLORS, SIZE, FONT, BREAKPOINTS } from "../../config";
import BannerBG from "./../../assets/Images/ibbtopbg.png";
import BannerBGComplete from "./../../assets/Images/ibbtopbgcomplete.png";

interface props {
	complete?: boolean;
};

export const Container = styled.div`
	width: 95%;
	padding: 0;
	margin: 0;

	@media (min-width: ${BREAKPOINTS.DEKSTOP_MEDIUM}px) {
		width: calc(100% - ${SIZE.LARGE * 2}px);
		padding: ${SIZE.LARGE}px;
		max-width: 1020px; 
		margin: auto;
	}
`;


export const Loader = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Title = styled.h2`
	font-size: 40px;
	font-family: "BitstreamRomanaBold";
	font-weight: bold;
	text-transform: uppercase;
	margin: 0 0 24px 0;
	padding: 0;
	line-height: 1;

	span {
		clear: both;
		padding-left: 24px;
		display:block;
	}
`;

export const ProgressAmount = styled.div`
	font-size: 42px;


	@media (max-width: ${BREAKPOINTS.DEKSTOP}px) {
		font-size: 24px;
	}
`;
export const ProgressLabel = styled.div`
`;
export const ProgressContainer = styled.div`
	background: white;
	margin-left: auto;
	margin-top: 55px;
	margin-right: 35px;
	padding: 24px;
	width: 33%;

	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		width: 50%;
	}
	@media (max-width: ${BREAKPOINTS.MOBILE_LARGE}px) {
		width: 60%;
	}
`;
export const BannerCTA = styled.div<props>`
	margin-right: auto;
	font-size: 42px;
	line-height: 1;
	margin-top: 30px;
	color: ${props => (props.complete === true) ? 'white' : 'black'}

	em {
		display: block;
		margin-left: 24px;
		font-style: italic;
	}

	span {
		margin-left: 70px;
	}

	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		font-size: 24px;
		em {
			display: block;
			margin-left: 12px;
			font-style: italic;
		}
	
		span {
			margin-left: 24px;
		}
	}


	@media (max-width: ${BREAKPOINTS.MOBILE_LARGE}px) {
		font-size: 16px;
		em {
			display: block;
			margin-left: 8px;
			font-style: italic;
		}
	
		span {
			margin-left: 16px;
		}
	}
`;
export const Banner = styled.div<props>`
	width: calc(100%-24px);
	background: url(${props => (props.complete === true) ? BannerBGComplete : BannerBG});
    background-size: cover;
	height: 190px;
	display: flex;
	margin-left: 24px;

	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		margin: 0;
	}

	@media (max-width: ${BREAKPOINTS.MOBILE_LARGE}px) {
		margin: 0;
		height: 140px;
	}
`;

export const SectionHeader = styled.h2`
	font-size: 40px;
	font-family: "BitstreamRomanaBold";
	font-weight: bold;
	text-transform: uppercase;
	margin: 0 0 24px 0;
	padding: 0;
	line-height: 1;

	span {
		clear: both;
		padding-left: 24px;
		display:block;
	}
	@media (max-width: ${BREAKPOINTS.MOBILE_LARGE}px) {
		font-size: 24px;

		span {
			padding-left: 12px;
		}
	}
`;

export const Content = styled.div`
	display: flex;
	flex-wrap: wrap;
    justify-content: flex-start;
	margin-left: 4px;
	margin-right: -20px;

	@media (max-width: ${BREAKPOINTS.MOBILE_LARGE}px) {
		margin-left: -24px;
	}
`;

export const ResetContainer = styled.div`
	background: #dcbc87;
	color: black;
	padding: 12px;
	display:flex;
	
	p {
		display: block;
    	padding: 10px;
	}
	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		display: block;
	}
`;
