import React, { useState } from "react";
import { useProducts } from "../../contexts/AdminContext";
import StyledSwitch from "./../../components/StyledSwitch";
import IconButton from "@material-ui/core/IconButton";
import Done from "@material-ui/icons/Done";
import Clear from "@material-ui/icons/Clear";
import Zoom from "@material-ui/core/Zoom";
import { Formik, Field } from "formik";
import {
  startToggleProduct,
  startSetCustomPrice
} from "../../actions/productsActions";
import { IProduct } from "../../reducers/ProductsReducer";

interface IProductProps {
  product: IProduct;
}

export function ProductItem(props: IProductProps) {
  const [isVisible, setisVisible] = useState(false);
  const [checked, setchecked] = useState(false);
  const reducer = useProducts();
  const [state, dispatch] = reducer;

  const changePriceOnSubmit = (values: any, { resetForm }: any) => {
    // current Custom price assigned to variable
    const currentCustomPrice = props.product.customPrice;
    //convert recently entered custom price into float value
    const floatCustomPrice = parseFloat(values.customPrice);
    //Checking price if it's same with previous custom price
    if (floatCustomPrice !== currentCustomPrice) {
      const newCustomPrice = values.customPrice;
      dispatch(startSetCustomPrice(id, newCustomPrice));
    } else if (isNaN(currentCustomPrice) || currentCustomPrice === null) {
      console.log(" Not a number or null ");
    } else {
      resetForm({ customPriceLocal: currentCustomPrice.toFixed(2) });
    }
    setisVisible(false);
  };

  const onCloseClick = () => {
    setisVisible(false);
  };

  const onHandleChange = (e: any) => {
    setisVisible(true);
    setchecked(true);
  };

  //Product Currency
  let productCurrency = props.product.currency;
  let price = props.product.msrp;
  //Currency sign
  const priceFormat = new Intl.NumberFormat(navigator.language, {
    style: "currency",
    currency: productCurrency || "AUD",
    useGrouping: false,
    currencyDisplay: "code"
  });
  const customPriceFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: productCurrency || "AUD",
    useGrouping: false,
    currencyDisplay: "code"
  });

  const { id, available, imageUrls: images, name } = props.product;
  return (
    <>
      <td style={{ verticalAlign: "top" }}>
        <p style={{ marginTop: "8px" }}>
          <StyledSwitch
            checked={available}
            onChange={() => {
              dispatch(startToggleProduct(id, !available));
            }}
            available={available}
          />
        </p>
      </td>
      <td style={{ verticalAlign: "top" }}>
        <p style={{ marginTop: "13px" }}>
          <img
            style={{ width: "50px", height: "50px", border: "1px solid #9E9D9E" }}
            src={images[0]}
            alt="Product"
          />
        </p>
      </td>
      <td style={{ verticalAlign: "top" }}>
        <p style={{ marginTop: "8px" }}>{name}</p>
      </td>
      <td style={{ verticalAlign: "top" }}>
        <p style={{ marginTop: "8px" }}> {priceFormat.format(price)}</p>
      </td>
      <td
        style={{
          display: "flex",
          minWidth: "200px",
          verticalAlign: "top",
          width: "200px"
        }}
      >
        <Formik
          initialValues={{
            customPrice: props.product.customPrice
              ? customPriceFormat.format(props.product.customPrice).substr(4)
              : ""
          }}
          onSubmit={changePriceOnSubmit}
        >
          {({ values, handleChange, handleReset, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="customPrice"
                onChange={(e: any) => {
                  handleChange(e);
                  onHandleChange(e);
                }}
                value={values.customPrice}
                style={{
                  padding: "5px",
                  width: "80px",
                  textAlign: "right",
                  flexDirection: "row"
                }}
              />
              <span
                style={{
                  visibility: isVisible ? "visible" : "hidden"
                }}
              >
                <Zoom in={checked} style={{ flexDirection: "row" }}>
                  <IconButton color="primary" type="submit">
                    <Done />
                  </IconButton>
                </Zoom>
                <Zoom in={checked} style={{ flexDirection: "row" }}>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      handleReset();
                      onCloseClick();
                    }}
                  >
                    <Clear />
                  </IconButton>
                </Zoom>
              </span>
            </form>
          )}
        </Formik>
      </td>
    </>
  );
}
