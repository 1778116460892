import React from "react";
import { Input, Button, CounterRow } from "./styles";

interface IInputProps {
  onChange: Function;
  value: number;
}

const InputCounterComponent = (props: IInputProps) => {
  const onIncrease = () => {
    props.onChange("increase");
  };
  const onDecrease = () => {
    if (props.value >= 1) {
      props.onChange("decrease");
    }
  };

  return (
    <CounterRow>
      <Button onClick={onDecrease}>-</Button>
      <Input type="text" value={props.value} onChange={() => {}} />
      <Button onClick={onIncrease}>+</Button>
    </CounterRow>
  );
};

export default InputCounterComponent;
