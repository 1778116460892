export enum SummaryType {
  "SUBSCRIPTION",
  "ACCOUNT",
  "DELIVERY",
  "PAYMENT",
  "ORDER",
  "PASSWORD"
}

export enum SubscriptionType {
  "ANNUAL",
  "MONTHLY",
  "THREEMTHUPFRONT"
}


export enum Plan {
  "FREE",
  "GROWTH",
  "SALON",
  "PLUS"
}

export const Costs = {
  starter_monthly_fee: 25,
  starter_annual_fee: 255,
  monthly_fee: 250,
  annual_fee: 3000,
  three_mth_upfront_fee: 750,
  shipping: 300
};
