import styled from "styled-components";
import { COLORS, SIZE, FONT, BREAKPOINTS } from "../../config";

export const AppointmentsContainer = styled.div`
	width: 90%;
	margin: auto; 
	text-transform: uppercase;


	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		width: 100%;
	}
`;

export const AppointmentMobile = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between; 

`;

export const AppointmentMobileRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 4px;
	span {
		width: 50%;
	}
`;


export const NavigationContainer = styled.div`
	
	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		nav {
			display: flex;
			.MuiListItemIcon-root {
				min-width: 0;
				margin-right: 4px;
			}
		}
	}
`;


export const SideContainer = styled.div`
	p {
			margin-bottom: 10px;
			margin-top: -10px;
			margin-left: 10px;
			text-transform: uppercase;
			font-size: 0.6rem;
	}
`;

