import React, { useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { StaticContext } from "react-router";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ChevronUpIcon from "@material-ui/icons/ChevronLeft";
import ChevronDownIcon from "@material-ui/icons/ChevronRight";
import SettingsNavbar from "../../../components/SettingsNavbar";
import FormComponent from "../../../components/FormComponent";
import { useSalons } from "../../../contexts/AdminContext";
import Loading from "../../../components/LoadingComponent";
import { Notification, Layout } from "../../../components";
import { getAllSalons } from "../../../actions/salonsActions";
import { startSetSocialMediaMessage, startSetBookingURL } from "../../../actions/salonsActions";
import {
	startSetBookingMessage,
	startSetBookingDelay
} from "../../../actions/salonsActions";
import { FormattedMessage } from "react-intl";
import { UpdateSalon, GetSalonDetails } from "../../../utils";
import {
	Container,
	InputContainer,
	FixedFooter,
	Tooltip
} from "../style";
import AddStaff from "./AddStaff";
import { StaffItem } from "./StaffItem";
import { pageData, Pagination } from "../../../components/Pagination";
import { startGetAllStaff } from "../../../actions/staffActions";
import { useStaff } from "../../../contexts/AdminContext";

import mixpanel from 'mixpanel-browser';


interface IPathParams {
	salonId: string;
}

export default function SalonSettingsScreen ({
	history,
	location,
	match
}: React.PropsWithChildren<
	RouteComponentProps<IPathParams, StaticContext, any>
>) {
	const reducer = useSalons();
	const [ state, dispatch ] = reducer;
	const { salons } = state;
	const [ delayDays, setDelayDays ] = useState(0);
	const [ salon, setSalon ] = useState() as any;
	const [ saving, setSaving ] = useState(false);
	const { salonId } = match.params;
	

	const { limit, page } = pageData(location.search);
	const staffReducer = useStaff();
	const [ staffState, staffDispatch ] = staffReducer;
	const { staff } = staffState;
	const realPage = Math.max(page - 1, 0);
	const staffLimit = staff.slice(realPage * limit, (realPage + 1) * limit);

	
	useEffect(
		() => {
			setSalon(salons.find(salon => salon.id === salonId));
			console.log("THIS SALON", salons.find(salon => salon.id === salonId))
		},
		[ salons ]
	);

	useEffect(
		() => {
			
			salon && setDelayDays(salon.rebookNotificationDelay || 0);
			if (
				salon &&
				salon.rebookNotificationDelay &&
				salon.rebookNotificationDelay === delayDays
			)
				setSaving(false);
		},
		[ salon ]
	);


	const SalonNameInput = useRef<HTMLInputElement>(null);
	const SocialMessageInput = useRef<HTMLTextAreaElement>(null);
	const RebookMessageInput = useRef<HTMLTextAreaElement>(null);
	const RebookDelayInput = useRef<HTMLInputElement>(null);
	const BookingURLInput = useRef<HTMLInputElement>(null);

	useEffect(
		() => {
			staffDispatch(startGetAllStaff(salonId));
		},
		[ salonId ]
	);

	useEffect(() => {
		dispatch(getAllSalons());
		document.title = "Salon Settings";
		mixpanel.track("PMA Settings - Salon page accessed");
		console.log("THIS SALON", salons.find(salon => salon.id === salonId))
	}, []);
	
	const onDayCountChange = (value: string) => {
		setDelayDays(parseInt(value));
	};

	const onDelayChange = (type: string) => {
		let delay = delayDays;
		if (type === "increase") {
			delay += 1;
		} else {
			if (delay > 0) delay -= 1;
		}
		setDelayDays(delay);
	};

	const onDelaySave = () => {
		setSaving(true);
		dispatch(startSetBookingDelay(salonId, delayDays));
		mixpanel.track("PMA Settings - Rebook days changed");
	};

	if (state.loading || salon === undefined) {
		return <Loading />;
	}


	const updateAccount = async () => {
		setSaving(true);
		let _salonDetails = {
			"id": salonId,
			"SalonName" : SalonNameInput.current?.value,
			"RebookMessage" : RebookMessageInput.current?.value,
			"RebookDelay" : RebookDelayInput.current?.value,
			"SocialMessage" : SocialMessageInput.current?.value,
			"BookingURL" : BookingURLInput.current?.value
		}
		console.log('sd', _salonDetails);
		let out = await UpdateSalon(_salonDetails);
		console.log('r',out)
		setSaving(false);
	}

	return (
		<Layout>
			<Notification />
			<Container>
				<SettingsNavbar
					name="salon"
					onChange={(path: string) =>
						history.push(`/${match.params.salonId}/${path}`)}
				/>
				<Grid container={true}>
				<Grid item={true} xs={12}>
				<Tooltip>Don't forget to press the Save button in the bottom right.</Tooltip>
				</Grid>
					<Grid item={true} xs={12} sm={6} md={6} style={{borderBottom: "1px solid black",  paddingBottom: 60}}>
							
							<h2>Salon details</h2>
									<InputContainer>
										<label>Salon Name</label>
										<input
											type={"text"}
											name={"salonName"}
											ref={SalonNameInput}
											id="salonName"
											defaultValue={salon.name}
										/>
									</InputContainer>
									<InputContainer>
									<label>
										Rebook message<br />
										<span>The message customers will see in the piiq App when they get a rebooking reminder.</span></label>
									<textarea
										name={"rebookMessage"}
										ref={RebookMessageInput}
										id="rebookMessage"
										defaultValue={salon.rebookNotificationMessage}
									/>	
									</InputContainer>
									<InputContainer>
									<label>Rebook delay<br />
										<span>The number of days after an appointment to send a rebooking reminder.</span></label>
									<input
										type={"number"}
										name={"rebookDelay"}
										ref={RebookDelayInput}
										id="rebookDelay"
										defaultValue={salon.rebookNotificationDelay}
									/>	
									</InputContainer>
									<InputContainer>
									<label>Social message<br />
										<span>Prefill hashtags and account tags when clients share photos from the piiq App on social media.</span></label>
									<textarea
										name={"socialMessage"}
										ref={SocialMessageInput}
										id="socialMessage"
										defaultValue={salon.defaultSocialMediaMessage}
									/>	
									</InputContainer>
									
									<InputContainer>
									<label>Booking URL<br />
										<span>Link to your online booking system so clients can rebook via the piiq App.</span></label>
									<input
										type={"text"}
										name={"bookingUrl"}
										ref={BookingURLInput}
										id="bookingUrl"
										defaultValue={salon.bookingUrl}
									/>	
									</InputContainer>
									<FixedFooter>
									<Button
										variant="outlined"
										size="small"
										onClick={updateAccount}
										color="primary"
										style={{
											backgroundColor: "black",
											color: "white",
											width: "150px",
											borderRadius: "20px",
											height: "60px",
										}}
									>
										{saving ? 'Saving...' : 'Save'}
									</Button>
									</FixedFooter>
								</Grid>
								<Grid item={true} xs={12} sm={6} md={6} style={{borderBottom: "1px solid black",  paddingBottom: 60}}>
									<h2>
										<FormattedMessage
											id="staff.staffmember"
											defaultMessage={`Staff Member`}
										/>
									</h2>
									<p>
										<FormattedMessage
											id="staff.description"
											defaultMessage={`Enabled staff members will see their names appear on the Stylist Sign in screen in piiq Pro.`}
										/>
									</p>
									<table style={{ width: "100%" }}>
										<thead
											style={{
												textAlign: "left"
											}}
										/>
										<tbody>
											{staffLimit.map(staff => (
												<tr key={staff.id}>
													<StaffItem staff={staff} />
												</tr>
											))}
										</tbody>
									</table>
									<div style={{ textAlign: "center" }}>
										<Pagination
											baseUrl={location.pathname}
											queryString={location.search}
											totalCount={staff.length}
										/>
									</div>
									<AddStaff salonId={salonId} />
								</Grid>
							</Grid>
			</Container>
		</Layout>
	);
}
