import { API, graphqlOperation } from "aws-amplify";
import { GetMySalons } from "../graphql/GetMySalons";
import { loader } from "graphql.macro";
import { GraphQLResult } from "@aws-amplify/api/lib/types";
import {
	ISalonAction,
	ISetSalonsAction,
	ISalon,
	ISetSocialMediaMessageAction,
	ISetBookingMessageAction,
	ISetBookingURLAction,
	ISetBrandsAction,
	ISetBookingDelayAction,
	IUpdateSalonAction
} from "../reducers/SalonsReducer";
import { SetSalonDefaultSocialMessageVariables } from "../graphql/SetSalonDefaultSocialMessage";
import { SetSalonBookingURLVariables } from "../graphql/SetSalonBookingURL";
import { UpdateSalonInput } from "../graphql/globalTypes";

const GetMySalonsGQL = loader("./../graphql/GetMySalons.graphql");
const SetSalonBookinglMessageGQL = loader(
	"./../graphql/SetSalonBookingMessage.graphql"
);
const SetSalonBookinglDelayGQL = loader(
	"./../graphql/SetSalonBookingDelay.graphql"
);
const SetSalonDefaultSocialMessageGQL = loader(
	"./../graphql/SetSalonDefaultSocialMessage.graphql"
);
const SetSalonBookingURLGQL = loader(
	"./../graphql/SetSalonBookingURL.graphql"
);

const SetSalonBrandsGQL = loader(
	"./../graphql/SetSalonBrands.graphql"
);
const UpdateSalonGQL = loader("./../graphql/UpdateSalon.graphql");

const setSalons = (salons: ISalon[]): ISetSalonsAction => ({
	type: "SET_SALONS",
	salons
});

export const getAllSalons = (limit?: string, nextToken?: string) => {
	return async (dispatch: React.Dispatch<ISalonAction>, state: any) => {
		const result = (await API.graphql(
			graphqlOperation(GetMySalonsGQL, {
				limit,
				nextToken
			})
		)) as GraphQLResult;

		if (result.data) {
			const data = result.data as GetMySalons;
			const items =
				data.me && data.me.salonAccess && data.me.salonAccess.items
					? data.me.salonAccess.items
							.map<any>(item => {
								if (item == null || item.salon === null) {
									return null;
								}
								return {
									id: item.salon.id,
									salonAdmin: item.salonAdmin,
									salonClient: item.salonClient,
									name: item.salon.name || "",
									bookingUrl: item.salon.bookingUrl || "",
									timeZone: item.salon.timeZone || "",
									defaultSocialMediaMessage:
										item.salon.defaultSocialMediaMessage || "",
									rebookNotificationMessage:
										item.salon.rebookNotificationMessage || "",
									rebookNotificationDelay:
										item.salon.rebookNotificationDelay || 0,
									onboardingComplete: item.salon.onboardingComplete || null,
									onboardingState: item.salon.onboardingState || null,
									BrandsAvailableToSalon: item.salon.BrandsAvailableToSalon || null,
									BrandsVisibleToClients: item.salon.BrandsVisibleToClients || null,
									PreferredRecommendationBrand: item.salon.PreferredRecommendationBrand || null,
								};
							})
							.filter((item): item is ISalon => item !== null)
					: [];
			dispatch(setSalons(items));
		}
	};
};

export const startSetBookingMessage = (
	salonId: string,
	rebookNotificationMessage?: string | null
) => {
	return async (dispatch: React.Dispatch<ISalonAction>, state: any) => {
		const params = {
			salonId,
			rebookNotificationMessage
		};

		const result = (await API.graphql(
			graphqlOperation(SetSalonBookinglMessageGQL, params)
		)) as GraphQLResult;
		dispatch(setBookingMessage(salonId, rebookNotificationMessage || ""));
	};
};


const setBookingMessage = (
	salonId: string,
	rebookNotificationMessage: string
): ISetBookingMessageAction => ({
	type: "SET_BOOKING_MESSAGE",
	salonId,
	rebookNotificationMessage
});



export const startSetBrands = (
	salonId: string,
	BrandsVisibleToClients?: [string] | null,
	PreferredRecommendationBrand?: string | null
) => {
	return async (dispatch: React.Dispatch<ISalonAction>, state: any) => {
		const params = {
			salonId,
			BrandsVisibleToClients,
    		PreferredRecommendationBrand
		};

		const result = (await API.graphql(
			graphqlOperation(SetSalonBrandsGQL, params)
		)) as GraphQLResult;
		dispatch(setBrands(salonId, BrandsVisibleToClients, PreferredRecommendationBrand));
	};
};


const setBrands = (
	salonId: string,
	BrandsVisibleToClients?: [string] | null,
	PreferredRecommendationBrand?: string | null
): ISetBrandsAction => ({
	type: "SET_BRANDS",
	salonId,
	BrandsVisibleToClients,
    PreferredRecommendationBrand
});

export const startSetBookingDelay = (
	salonId: string,
	rebookNotificationDelay?: number | null
) => {
	return async (dispatch: React.Dispatch<ISalonAction>, state: any) => {
		const params = {
			salonId,
			rebookNotificationDelay
		};

		console.log("start rebookNotificationDelay", params);

		const result = (await API.graphql(
			graphqlOperation(SetSalonBookinglDelayGQL, params)
		)) as GraphQLResult;

		console.log("result", result);

		dispatch(setBookingDelay(salonId, rebookNotificationDelay || 0));
	};
};

const setBookingDelay = (
	salonId: string,
	rebookNotificationDelay: number
): ISetBookingDelayAction => ({
	type: "SET_BOOKING_DELAY",
	salonId,
	rebookNotificationDelay
});

const setSocialMediaMessage = (
	salonId: string,
	defaultSocialMediaMessage: string
): ISetSocialMediaMessageAction => ({
	type: "SET_SOCIAL_MEDIA_MESSAGE",
	salonId,
	defaultSocialMediaMessage
});


const setBookingURL = (
	salonId: string,
	bookingUrl: string
): ISetBookingURLAction => ({
	type: "SET_BOOKING_URL",
	salonId,
	bookingUrl
});

export const startSetSocialMediaMessage = (
	salonId: string,
	defaultSocialMediaMessage: string
) => {
	return async (dispatch: React.Dispatch<ISalonAction>, state: any) => {
		const params: SetSalonDefaultSocialMessageVariables = {
			salonId,
			defaultSocialMediaMessage
		};

		const result = (await API.graphql(
			graphqlOperation(SetSalonDefaultSocialMessageGQL, params)
		)) as GraphQLResult;
		console.log("Result", result);
		console.log("Message Set it", defaultSocialMediaMessage);
		dispatch(setSocialMediaMessage(salonId, defaultSocialMediaMessage || ""));
	};
};


export const startSetBookingURL = (
	salonId: string,
	bookingUrl: string
) => {
	return async (dispatch: React.Dispatch<ISalonAction>, state: any) => {
		const params: SetSalonBookingURLVariables = {
			salonId,
			bookingUrl
		};

		const result = (await API.graphql(
			graphqlOperation(SetSalonBookingURLGQL, params)
		)) as GraphQLResult;
		console.log("Result", result);
		console.log("BookingUrl Set it", bookingUrl);
		dispatch(setBookingURL(salonId, bookingUrl || ""));
	};
};

export const startUpdateSalon = (
	salonId: string,
	salonInput: UpdateSalonInput
) => {
	return async (dispatch: React.Dispatch<ISalonAction>, state: any) => {
		const params = {
			salonId,
			input: salonInput
		};
		console.log("START UPDATE SALON");
		const result = (await API.graphql(
			graphqlOperation(UpdateSalonGQL, params)
		)) as GraphQLResult;
		console.log("UPDATE SALON Result", result);
		dispatch(setSalonUpdate(salonId, salonInput));
	};
};

const setSalonUpdate = (
	salonId: string,
	salonInput: UpdateSalonInput
): IUpdateSalonAction => ({
	type: "UPDATE_SALON",
	salonId,
	salonInput
});
