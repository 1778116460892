import { IActions } from "../contexts/createAsyncDispatch";

export interface IStateBrands {
  brands: any[];
  loading: boolean;
}

export interface IBrand {
  id: string;
  name: string;
  brandActive: boolean;
  active: boolean;
}

export interface IBrandPVI {
  id: string;
  BrandName: string;
  BrandAbbreviation: string;
}

export interface IGetCatalogBrandsAction {
  type: "GET_CATALOG_ALL_BRANDS";
  salonId: string;
  active?: boolean | null;
  limit?: number;
  nextToken?: string;
}

export interface IGetAllBrandsAction {
  type: "GET_ALL_BRANDS";
  limit?: number;
  nextToken?: string;
}

export interface ISetBrandsAction extends IActions {
  type: "SET_BRANDS";
  brands: any[];
}

export interface ISetCatalogBrandsAction extends IActions {
  type: "SET_CATALOG_BRANDS";
  brands: IBrand[];
}

export interface ISetLoadingAction {
  type: "SET_LOADING";
  loading: boolean;
}

export type IBrandAsyncAction = any;

export interface IDisableBrandAction {
  type: "DISABLE_BRAND";
  brandId: string;
  salonId: string;
}

export interface IEnableBrandAction {
  type: "ENABLE_BRAND";
  brandId: string;
  salonId: string;
}
export type IBrandAction =
  | ISetBrandsAction
  | ISetCatalogBrandsAction
  | IGetCatalogBrandsAction
  | IGetAllBrandsAction
  | IEnableBrandAction
  | IDisableBrandAction
  | ISetLoadingAction;

export const initialStateBrands: IStateBrands = {
  brands: [],
  loading: true
};

export const reducerBrands = (
  state: IStateBrands,
  action: IBrandAction | IBrandAsyncAction
) => {
  switch (action.type) {
    case "SET_CATALOG_BRANDS":
      return {
        ...state,
        brands: action.brands,
        loading: false
      };
      case "SET_BRANDS":
      return {
        ...state,
        brands: action.brands,
        loading: false
      };

    case "SET_LOADING":
      return {
        ...state,
        loading: action.loading
      };

    case "ENABLE_BRAND":
      return enableBrand(state, action);

    case "DISABLE_BRAND":
      return disableBrand(state, action);
  }
  return state;
};

function enableBrand (state: IStateBrands, action: IEnableBrandAction) {
  const i = state.brands.findIndex(brand => brand.id === action.brandId);
  return {
    ...state,
    brands: [
      ...state.brands.slice(0, i),
      { ...state.brands[i], active: true },
      ...state.brands.slice(i + 1)
    ],
    loading: false
  };
}

function disableBrand (state: IStateBrands, action: IDisableBrandAction) {
  const i = state.brands.findIndex(brand => brand.id === action.brandId);
  return {
    ...state,
    brands: [
      ...state.brands.slice(0, i),
      { ...state.brands[i], active: false },
      ...state.brands.slice(i + 1)
    ],
    loading: false
  };
}
