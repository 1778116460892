import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api/lib/types";
import {
	GetSalonProducts,
	GetSalonProducts_getSalon_allProductsForSalon_items
} from "../graphql/GetSalonProducts";
import {
	GetAllProducts,
	GetAllProducts_allProducts_items
} from "../graphql/GetAllProducts";
import { loader } from "graphql.macro";
import { UpdateProductVariables } from "../graphql/UpdateProduct";
import {
	IProductAction,
	ISetProductsAction,
	IClearProductsAction,
	IToggleProductsAction,
	ISetCustomPriceProductAction,
	IProduct
} from "../reducers/ProductsReducer";
const GetSalonProductsGQL = loader("./../graphql/GetSalonProducts.graphql");
const UpdateProductGQL = loader("./../graphql/UpdateProduct.graphql");
const GetAllProductsGQL = loader("./../graphql/GetAllProducts.graphql");

const getAllProducts = (
	products: any[],
	nextToken: string | null
): ISetProductsAction => ({
	type: "SET_PRODUCTS",
	products,
	nextToken
});

export const startGetAllProducts = (
	limit?: string,
	nextToken?: string
) => {
	return async (dispatch: React.Dispatch<any>, state: any) => {
		const result = (await API.graphql(
			graphqlOperation(GetAllProductsGQL, {
				limit,
				nextToken
			})
		)) as GraphQLResult;
		if (result.data) {
			const data = result.data as GetAllProducts;
			const nextToken =
				(data &&
					data.allProducts &&
					data.allProducts.nextToken) ||
				null;

			const items =
				data &&
				data.allProducts &&
				data.allProducts.items
					? (data.allProducts.items.filter(
							item => item !== null
						) as GetAllProducts_allProducts_items[])
					: [];
			dispatch(getAllProducts(items, nextToken));
		}
	};
};

const getAllSalonProducts = (
	products: IProduct[],
	nextToken: string | null
): ISetProductsAction => ({
	type: "SET_PRODUCTS",
	products,
	nextToken
});

export const startGetAllSalonProducts = (
	salonId: string,
	limit?: string,
	nextToken?: string,
	available?: boolean
) => {
	return async (dispatch: React.Dispatch<IProductAction>, state: any) => {
		const result = (await API.graphql(
			graphqlOperation(GetSalonProductsGQL, {
				salonId,
				limit,
				nextToken,
				available
			})
		)) as GraphQLResult;
		if (result.data) {
			const data = result.data as GetSalonProducts;
			const nextToken =
				(data &&
					data.getSalon &&
					data.getSalon.allProductsForSalon &&
					data.getSalon.allProductsForSalon.nextToken) ||
				null;

			const items =
				data &&
				data.getSalon &&
				data.getSalon.allProductsForSalon &&
				data.getSalon.allProductsForSalon.items
					? (data.getSalon.allProductsForSalon.items.filter(
							item => item !== null
						) as GetSalonProducts_getSalon_allProductsForSalon_items[]).map(item => ({
							id: item.id,
							available: item.available || false,
							currency: item.currency || "",
							eligible: item.eligible || false,
							customPrice: item.customPrice || 0,
							eligibleReason: item.eligibleReason || "",
							imageUrls: item.images
								? item.images
										.map(img => (img ? img.url : null))
										.filter((img): img is string => img !== null)
								: [],
							msrp: item.msrp || 0,
							name: item.name || "",
							createdAt: item.createdAt || "",
							updatedAt: item.updatedAt || ""
						}))
					: [];
			dispatch(getAllSalonProducts(items, nextToken));
		}
	};
};

const clearAllProducts = (salonId?: string): IClearProductsAction => ({
	type: "CLEAR_PRODUCTS"
});

export const startClearAllProducts = (salonId?: string) => {
	return async (dispatch: React.Dispatch<IProductAction>, state: any) => {
		dispatch(clearAllProducts(salonId));
	};
};

export const toggleProduct = (
	productId: string,
	available: boolean
): IToggleProductsAction => ({
	type: "TOGGLE_PRODUCT",
	productId,
	available
});

export const startToggleProduct = (productId: string, available: boolean) => {
	return async (dispatch: React.Dispatch<IProductAction>, state: any) => {
		const result = (await API.graphql(
			graphqlOperation(UpdateProductGQL, {
				productId,
				input: { available }
			} as UpdateProductVariables)
		)) as GraphQLResult;
		console.log("Result", result);
		dispatch(toggleProduct(productId, available));
	};
};

export const setCustomPrice = (
	productId: string,
	customPrice: number
): ISetCustomPriceProductAction => ({
	type: "SET_CUSTOM_PRICE",
	productId,
	customPrice
});

export const startSetCustomPrice = (productId: string, customPrice: number) => {
	return async (dispatch: React.Dispatch<IProductAction>, state: any) => {
		const result = (await API.graphql(
			graphqlOperation(UpdateProductGQL, {
				productId,
				input: { customPrice }
			} as UpdateProductVariables)
		)) as GraphQLResult;
		console.log("Result", result);
		dispatch(setCustomPrice(productId, customPrice));
	};
};
