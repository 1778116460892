import React from "react";
import TabNavbar from "./TabNavbar";
import { FormattedMessage } from "react-intl";
import { H1 } from "../components";

const tabs = [
  {
    name: "salon",
    title: "SALON",
    path: "settings/salon"
  },
  {
    name: "retail",
    title: "RETAIL",
    path: "settings/retail"
  },
  {
    name: "account",
    title: "ACCOUNT & BILLING",
    path: "settings/account"
  }
];

interface ISettingNavbarProps {
  name: string;
  onChange: (path: string) => void;
}

export default function SettingNavbar(props: ISettingNavbarProps) {
  return (
    <>
      <H1 style={{ marginLeft: "20px" }}>
        <FormattedMessage id="settings.title" defaultMessage={`Settings`} />
      </H1>
      <TabNavbar name={props.name} tabs={tabs} onChange={props.onChange} />
    </>
  );
}
