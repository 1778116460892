import React, { useState, useEffect } from "react";
import { useSpring } from "react-spring";
import { useAppointments } from "../../contexts/AdminContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FetchingContainer, Spinner, WaitMsg } from "./styles";
import { localeMessages } from "../../App";

interface IInputProps {
	onPress?: Function;
	children?: any;
}

let count = 0;

const FetchingDataComponent = (props: IInputProps) => {
	const messages = [
		localeMessages["loading_notice"].msg_1,
		localeMessages["loading_notice"].msg_2,
		localeMessages["loading_notice"].msg_3,
		localeMessages["loading_notice"].msg_4,
		localeMessages["loading_notice"].msg_5,
		localeMessages["loading_notice"].msg_6,
		localeMessages["loading_notice"].msg_7,
		localeMessages["loading_notice"].msg_8
	];
	const completeMsg = localeMessages["loading_notice"].msg_complete;
	const [ state ] = useAppointments();
	const [ index, setIndex ] = useState(count);
	const rotateTime = 1100;
	let timer;

	useEffect(() => {
		startTimer();
		return () => clearTimer();
	}, []);

	useEffect(() => {}, [ index ]);

	const startTimer = () => {
		timer = setInterval(() => {
			count += 1;
			if (count < messages.length) setIndex(count);
			else {
				count = 0;
				setIndex(0);
			}
		}, rotateTime);
	};

	const clearTimer = () => {
		count = 0;
		clearInterval(timer);
	};

	const animationProp = useSpring({
		to: { opacity: 1, color: "#ffffff" },
		from: { opacity: 0, color: "#c3c3c3" },
		reset: true
	});

	const Rotator = () => (
		<WaitMsg style={animationProp}>{messages[index]}</WaitMsg>
	);

	return (
		<FetchingContainer show={state.loading}>
			{state.loading ? (
				<Spinner>
					<CircularProgress />
				</Spinner>
			) : null}
			<h2>{state.loading ? <Rotator /> : completeMsg}</h2>
		</FetchingContainer>
	);
};

export default FetchingDataComponent;
