import styled from "styled-components";
import { COLORS, SIZE, FONT, BREAKPOINTS } from "../../config";

interface iInputProps {
	error?: string | null;
	readonly?: boolean;
}

export const Container = styled.div`
	position: relative;
	width: 100%;
`;

export const Label = styled.div`
	width: 100%;
	padding: ${SIZE.SMALL}px 0;
	margin: ${SIZE.SMALL}px 0 0;
	font-size: ${FONT.SIZE.BODY};
`;

export const Input = styled.input`
	width: calc(100% - ${SIZE.LARGE}px);
	padding: 0 ${SIZE.MEDIUM}px;
	background: ${(props: iInputProps) =>
		props.readonly ? COLORS.GREY_DARK : COLORS.GREY_MID_BASE};
	border: 1px solid ${COLORS.WHITE};
	border-radius: ${SIZE.BORDER_RADIUS}px;
	border-bottom: ${(props: iInputProps) =>
		props.error ? `1px solid tomato` : `1px solid ${COLORS.WHITE}`};
	font-size: ${FONT.SIZE.BODY};
	height: 44px;
	outline: none;
	pointer-events: ${(props: iInputProps) => (props.readonly ? "none" : "auto")};

	&:focus {
		background: ${COLORS.WHITE} !important;
		border: 1px solid ${COLORS.BLACK} !important;
	}
`;



export const TextArea = styled.textarea`
	width: calc(100% - ${SIZE.LARGE}px);
	padding: ${SIZE.MEDIUM}px;
	background: ${COLORS.GREY_MID_LIGHT};
	border: 1px solid ${COLORS.WHITE};
	border-radius: ${SIZE.BORDER_RADIUS}px;
	font-size: ${FONT.SIZE.BODY};
	min-height: 100px;
	outline: none;

	&:focus {
		background: ${COLORS.WHITE};
		border: 1px solid ${COLORS.BLACK};
	}
`;

export const Error = styled.div`
	color: tomato;
	padding: ${SIZE.SMALL}px ${SIZE.SMALL}px 0;
`;
