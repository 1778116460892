import React from "react";
import { ResponsiveLine } from "@nivo/line";
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export default function BarCompt (props: any) {
	return (
		<ResponsiveLine
			data={props.data}
			margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
			xScale={{ type: "point" }}
			yScale={{
				type: "linear",
				min: "auto",
				max: "auto",
				// stacked: true,
				reverse: false
			}}
			axisTop={null}
			axisRight={null}
			axisBottom={props.axisBottom}
			axisLeft={{
				orient: "left",
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: props.yLabel,
				legendOffset: -40,
				legendPosition: "middle"
			}}
			colors={{ scheme: "nivo" }}
			pointSize={10}
			pointColor={{ theme: "background" }}
			pointBorderWidth={2}
			pointBorderColor={{ from: "serieColor" }}
			pointLabel="y"
			// enablePointLabel
			enableSlices="x"
			// pointLabelYOffset={-12}
			motionStiffness={75}
			sliceTooltip={props.customTooltip}
			motionDamping={20}
			useMesh={true}
			legends={[
				{
					anchor: "bottom-right",
					direction: "column",
					justify: false,
					translateX: 100,
					translateY: 0,
					itemsSpacing: 0,
					itemDirection: "left-to-right",
					itemWidth: 80,
					itemHeight: 20,
					itemOpacity: 0.75,
					symbolSize: 12,
					symbolShape: "circle",
					symbolBorderColor: "rgba(0, 0, 0, .5)",
					effects: [
						{
							on: "hover",
							style: {
								itemBackground: "rgba(0, 0, 0, .03)",
								itemOpacity: 1
							}
						}
					]
				}
			]}
		/>
	);
}
