import styled from "styled-components";
import { SIZE, FONT } from "../../../../config";

export const Error = styled.div`
  padding: 0 ${SIZE.MEDIUM}px;
  color: tomato;
`;

export const Label = styled.div`
  font-size: ${FONT.SIZE.BODY};
  margin: ${SIZE.MEDIUM}px 0 ${SIZE.SMALL}px;
`;

export const ShowPassword = styled.div`
  position: absolute;
  right: 0px;
  top: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  padding: 4px;
  width: 50px;
  height: 32px;
  cursor: pointer;
`;
export const Relative = styled.div`
  position: relative;
  width: 100%;
`;

export const Footer = styled.div`
  height: 100px;
  width: 100%;
`;
