import React, { useState, useEffect } from "react";
import { useSubscriptions } from "../../utils/useSubscriptions";
import { useParams, useHistory } from "react-router-dom";
import { useSpring } from "react-spring";

import { useNotification } from "../../contexts/AdminContext";
import { hideNotification } from "../../actions/notificationAction";
import { NotificationContainer, Notification } from "./styles";
import mixpanel from 'mixpanel-browser';

interface IInputProps {
	onPress?: Function;
	children?: any;
}

const NotificationComponent = (props: IInputProps) => {
	const [ show, setShow ] = useState(false);
	const { salonId } = useParams();
	const [ state, dispatch ] = useNotification();
	const history = useHistory();
	const hideTime = 2500;
	let timerHide;

	const {
		startCartSubscription,
		stopCartSubscription,
		startAppointmentSubscription,
		stopAppointmentSubscription
	} = useSubscriptions();

	useEffect(() => {
		startCartSubscription();
		startAppointmentSubscription();
		return () => {
			if (timerHide) clearTimeout(timerHide);
			// Clean appointment list on unmount
			stopCartSubscription();
			stopAppointmentSubscription();
		};
	}, []);

	useEffect(
		() => {
			setShow(state.notify);
		},
		[ state ]
	);

	useEffect(
		() => {
			show && hideNotificationUI();
		},
		[ show ]
	);

	const hideNotificationUI = () => {
		timerHide = setTimeout(() => dispatch(hideNotification()), hideTime);
	};

	const onPress = () => {
		// props.onPress && props.onPress();
		dispatch(hideNotification());
		setShow(false);
		console.log("NOTIFICATION", state)
		switch(state.type) {
			case "CART":
				history.push(`/${salonId}/appointments/${state?.data?.id}`)
				mixpanel.track("PMA Notification clicked - Retail");
				break;
			case "APPOINTMENT":
				history.push(`/${salonId}/appointments/${state?.data?.id}`)
				mixpanel.track("PMA Notification clicked - Rebook");
				break;	
		}
	};

	const animatedProp = useSpring({
		transform: `translateY(${show ? 0 : -100}px)`,
		from: { transform: `translateY(-100px)` },
		config: { duration: 200 },
		reset: !show
	});

	return (
		<NotificationContainer onClick={onPress}>
			<Notification
				type={state.type}
				show={show.toString()}
				style={animatedProp}>
				<h1>
					{state.type === "APPOINTMENT" ? (
						"✂️ Appointment Update ✂️"
					) : state.type === "CART" ? (
						"🛍️ Retail update 🛍️"
					) : (
						""
					)}
				</h1>
				{state.type === "CART" && (
					<h2>
						{state.data?.items?.length} items purchased
					</h2>
				)}
			</Notification>
		</NotificationContainer>
	);
};

export default NotificationComponent;
