import React, { useReducer } from "react";
import { StripeProvider } from "react-stripe-elements";
import RegistrationScreen from "./RegistrationScreen";
import { reducerRegistration } from "../../reducers/RegistrationReducer";
import { SubscriptionContext, OrderDetailsModel } from "../../contexts/SubscriptionContext";

import { GlobalStyle } from "./styles";

interface IProps {}

const RegistrationScreenContainer = (props: IProps) => {
  const [ state, dispatch ] = useReducer(
    reducerRegistration,
    OrderDetailsModel
  );
  const stateValue = { state, dispatch };
  
  let stripeAPI = process.env.REACT_APP_STRIPE_API || '';

  return (
    <SubscriptionContext.Provider value={stateValue}>
    <StripeProvider apiKey={stripeAPI}>
      <>
      <GlobalStyle />
        <RegistrationScreen />
      </>
    </StripeProvider>
    </SubscriptionContext.Provider>
  );
};

export default RegistrationScreenContainer;
