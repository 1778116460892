import styled from "styled-components";
import BackgroundImg from "../../assets/Images/subscription/block-button.jpg";
import { COLORS, SIZE, FONT } from "../../config";

interface iButton {
	selected: boolean;
}

export const BlockButton = styled.button`
	position: relative;
	overflow: hidden;
	width: 100%;
	min-width: 160px;
	height: 140px;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	background: ${(props: iButton) =>
		props.selected ? COLORS.BLACK : COLORS.GREY_MID_LIGHT};
	color: ${(props: iButton) => (props.selected ? COLORS.WHITE : COLORS.BLACK)};
	border: 1px solid transparent;
	outline: none;
	border-radius: ${SIZE.BORDER_RADIUS}px;
	margin: ${SIZE.MEDIUM}px 0 ${SIZE.MEDIUM}px;
	padding: ${SIZE.MEDIUM}px;
	cursor: pointer;
	font-size: ${FONT.SIZE.TITLE};

	transition: background .2s ease, color .3s ease, transform .2s ease;

	@media (max-width: 600px) {
		width: calc(100% - ${SIZE.LARGE}px);
	}

	.block-image {
		display: none;
		background: ${(props: iButton) =>
			props.selected
				? `rgba(0,0,0,0.5) url(${BackgroundImg}) no-repeat center center;`
				: COLORS.GREY_MID_LIGHT};
		background-size: cover;
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 0;
		top: 0;
		left: 0;
		opacity: ${(props: iButton) => (props.selected ? 0.7 : 0)};
		transition: transform .2s ease;
	}

	&:hover {
		color: ${(props: iButton) =>
			props.selected ? COLORS.WHITE : COLORS.BLACK};
		border: 1px solid rgba(0, 0, 0, 0.3);
		/* transform: translateY(-3px); */
	}

	.block-title {
		position: relative;
	}

	.block-description {
		position: relative;
		padding-top: ${SIZE.SMALL}px;
		font-size: ${FONT.SIZE.SMALL};
		text-align: left;
	}
	.block-amount {
		position: relative;
		font-size: ${FONT.SIZE.SMALL};
		height: 100%;
	}

	.block-footer-head {
		position: relative;
		font-size: ${FONT.SIZE.SMALL};
		padding-bottom: ${SIZE.SMALL}px;
	}

	.block-footer {
		position: relative;
		font-family: BitstreamRoman;
		font-size: 1.2rem;
		text-align: left;
		span {
			font-family: Gerstner_Regular;
			padding-left: ${SIZE.TINY}px;
			font-size: ${FONT.SIZE.SMALL};
		}
	}
	.text-body {
		font-size: ${FONT.SIZE.TITLE};
	}
`;
