import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { ChartToggle } from "./styles";

interface iProps {
	onChange: (event: React.ChangeEvent<{}>, checked: boolean) => void;
	checked: boolean;
	label: string;
}
const CustomSwitch = withStyles({
	switchBase: {
		color: "#000",
		"&$checked": {
			color: "#000"
		},
		"&$checked + $track": {
			backgroundColor: "#2e2e2e"
		}
	},
	checked: {},
	track: {}
})(Switch);
export default (props: iProps) => {
	return (
		<ChartToggle>
			<FormControl component="fieldset">
				<FormGroup aria-label="position" row>
					<FormControlLabel
						value="top"
						control={
							<CustomSwitch
								checked={props.checked}
								onChange={props.onChange}
								value="checkedA"
							/>
						}
						label={props.label}
						labelPlacement="top"
						onChange={props.onChange}
					/>
				</FormGroup>
			</FormControl>
		</ChartToggle>
	);
};
