import React, { Component } from "react";
import { Button, TextField, Typography, Grid, InputAdornment } from "@material-ui/core";
import Auth from "@aws-amplify/auth";
import { API, graphqlOperation } from "aws-amplify";
import { Done, VisibilityOff, Visibility } from "@material-ui/icons";
import { GraphQLResult } from "@aws-amplify/api/lib/types";
import AWS from 'aws-sdk';
import { SendUserPiiqMail } from "../../../utils";
import { FormattedMessage } from "react-intl";
import { ReactComponent as Logo } from "../../../assets/Images/logo.svg";
import { CSSTransition } from 'react-transition-group';

const dynamoDB = new AWS.DynamoDB.DocumentClient();

//types
interface Props {
  toggleView(view: string): void;
  sessionId: string;
  animateInProp: boolean;
  userEmail: string;
}

interface IState {
  loading: string;
  firstname: string;
  password: string;
  error: string;
  animateIn: string;
  passwordIsMasked: boolean;
}

export default class SignUpForm extends Component<Props, IState> {
  state = {
    loading: 'true',
    firstname: '',
    password: "",
    passwordIsMasked: true,
    error: "",
    animateIn: 'false'
  };
  componentDidMount = () => {
    this.setState({ loading : 'false', animateIn : this.props.animateInProp ? 'true' : 'false' })
  }

  onChangeName = (e: any) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    this.setState({ firstname: value });
  };
  onChangePassword = (e: any) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    this.setState({ password: value });
  };
  onSubmit = async (e) => {
    e.preventDefault(e);

    const { firstname, password } = this.state;
    this.setState({ loading : 'true' })
    const email = this.props.userEmail;

    if (firstname === "") {
      this.setState({ error: "Please enter your first name" });
      this.setState({ loading : 'false' })
      return;
    }

    if (password === "") {
      this.setState({ error: "Please enter a password" });
      this.setState({ loading : 'false' })
      return;
    }


    try {
      // FIRST, SIGN UP
      const SignUpResult = await Auth.signUp({
        username: email.trim(),
        password,
        attributes: { email: email.trim(), profile: "Role=Client" }
      });
      console.log("SignUp::Submit::Success:", SignUpResult);


      const signin = await Auth.signIn({
          username:email.trim(),
          password:password
      })

      // THEN, UPDATE TERMS OF SERVICE
      const UpdateTermsOfService = `mutation UpdateTermsOfService($id:ID!, $input: UpdateUserInput!) {
        updateUser(id: $id, input: $input) {
            id
            name
            termsOfServiceLocale
            termsOfServiceVersion
            termsOfServiceAcceptedAt
        }
      }`;
      const result: GraphQLResult | any = await API.graphql(
        graphqlOperation(UpdateTermsOfService, {
          id:SignUpResult.userSub,
          input: { name: firstname, termsOfServiceLocale:"en-US", termsOfServiceVersion:2 }
        })
      );

      console.log('accepting ts and cs', result);
      this.props.toggleView('success');
      SendUserPiiqMail(email.trim(), "Your new piiq account", "Welcome to piiq!", "You've just signed up for a new piiq account using <strong>" + email.trim() + "</strong>. <br><br> To access your appointment records, including celebrity match, hair inspo, and 360 photo, download the piiq app from the link below and login using your new account.<br><br><a href='https://apps.apple.com/au/app/piiq/id1458062799'>Click here to download for iOS</a><br><br><a href='https://play.google.com/store/apps/details?id=com.piiq&hl=en&gl=US'>Click here to download for Android</a>")
      
      // // THEN START THE SESSION
      // const StartSession = `mutation StartSession($id: ID!, $input: UpdateSessionInput!){
      //   startSession(id: $id, input: $input){
      //       id
      //       userId
      //       salonId
      //       appointmentId
      //       existingAppointment
      //   }
      // }
      // `;

      // // NEED TO GET SESSION ID FROM URL
      // let sessionId = this.props.sessionId;

      // // START NEW SESSION WITH AUTHENTICATED USER?
      // try {
      //   const { data }: GraphQLResult | any = await API.graphql(
      //     graphqlOperation(StartSession, {
      //       id: sessionId,
      //       input: {
      //         devicePlatform:"ANDROID",
      //         deviceToken:"noToken",
      //       }
      //     })
      //   );
      //   console.log("new session data -", data);
      //   this.props.toggleView('success');
      //   SendUserPiiqMail(email.trim(), "Your new piiq account", "Welcome to piiq!", "You've just signed up for a new piiq account using <strong>" + email.trim() + "</strong>. <br><br> To access your appointment records, including celebrity match, hair inspo, and 360 photo, download the piiq app from the link below and login using your new account.<br><br><a href='https://apps.apple.com/au/app/piiq/id1458062799'>Click here to download for iOS</a><br><br><a href='https://play.google.com/store/apps/details?id=com.piiq&hl=en&gl=US'>Click here to download for Android</a>")
      // } catch (e) {
      //   this.setState({ loading : 'false' })
      //   console.log(e);
      // }
    
    } catch (e) {
      const error = e.message;
      console.log("SignUp::Submit::Error:", e);
      this.setState({ error });
      this.setState({ loading : 'false' })
    }
  };


  togglePasswordMask = () => {
    this.setState(prevState => ({
      passwordIsMasked: !prevState.passwordIsMasked
    }));
  };
  render () {
    const { toggleView, userEmail } = this.props;
    const { passwordIsMasked } = this.state;
    return (
      <form autoComplete="off" onSubmit={this.onSubmit}>
      <CSSTransition
        in={this.state.animateIn === 'true'}
        timeout={300}
        classNames="opacity">
        <Grid container direction="column" spacing={10} alignItems="stretch">
          <Grid item>
            <Grid container direction="row" justify="space-between">
              <Grid item>
                <a onClick={() => {toggleView('landing')}} style={{fontSize: 24}}>‹ BACK</a>
              </Grid>
              <Grid item>
                <Logo width={65} style={{marginTop: 4}}/>
              </Grid>
            </Grid>
            <Typography variant="h5" style={{marginTop: 48}}>
              <FormattedMessage id="signup.title" defaultMessage={`Create your account`} />
            </Typography>
            <p>Sign up with <strong>{userEmail}</strong></p>
            <label style={{margin: "8px 0", display: "block"}}>First name</label>
            <TextField
              InputProps={{
                style: {
                  color: "white", 
                  height: 60
                },
                autoComplete: "given-name",
              }}
              style={{ width: "100%", background: 'transparent', border: '1px solid white' }}
              variant="outlined"
              placeholder="eg. Sam"
              name="given-name"
              type="text"
              value={this.state.firstname}
              onChange={this.onChangeName}
              disabled={this.state.loading === 'true'}
            />
            <label style={{margin: "8px 0", display: "block"}}>Password</label>
            <TextField
              InputProps={{
                style: {
                  color: "white", 
                  height: 60
                },
                endAdornment: (
                  <InputAdornment position="end">
                    {passwordIsMasked ? (
                      <VisibilityOff
                        style={{ cursor: "pointer" }}
                        onClick={this.togglePasswordMask}
                      />
                    ) : (
                      <Visibility
                        style={{ cursor: "pointer" }}
                        onClick={this.togglePasswordMask}
                      />
                    )}
                  </InputAdornment>
                )
              }}
              style={{ width: "100%", background: 'transparent', border: '1px solid white' }}
              variant="outlined"
              type={passwordIsMasked ? "password" : "text"}
              placeholder="Password"
              name="password"
              value={this.state.password}
              onChange={this.onChangePassword}
              disabled={this.state.loading === 'true'}
            />
            <p style={{fontSize: 14, marginBottom: 16}}>Your password must:
              <ul>
                <li>Be at least 8 characters long</li>
                <li>Contain at least 1 number</li>
                <li>Contain at least 1 lowercase letter</li>
                <li>Contain at least 1 uppercase letter</li>
                </ul>
            </p>
            {this.state.error !== "" && (
                <Typography style={{marginBottom: 24, color:"#ffa0a0"}}>{this.state.error}</Typography>
            )}
            <button
              type="button"
              style={{ background:"#FFF", border: 0,width: "100%", borderRadius: 0, height: 60, textTransform: 'none', fontSize: 16 }}
              onClick={this.onSubmit}
              disabled={this.state.loading === 'true'}>
              {this.state.loading === 'true' ? (<>Loading...</>):(<FormattedMessage id="button.signup" defaultMessage={`Create account`} />)}
            </button>
            <p>By continuing, you agree to piiq’s <a style={{color: 'white'}} href="https://piiqdigital.com/privacy-policy" target="_blank">Terms and Conditions</a> and <a style={{color: 'white'}} href="https://piiqdigital.com/privacy-policy" target="_blank">Privacy Policy</a></p>
          </Grid>
        </Grid>
        </CSSTransition>
      </form>
    );
  }
}
