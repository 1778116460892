import { useEffect, useState } from "react";
import Amplify from "aws-amplify";
import { iWindow, CACHE } from "../config";
import { Costs, SubscriptionType, Plan } from "../screens/Registration/types";
import axios from 'axios';

// CHANGE BEFORE DEPLOYING
let isDev = false;

export const AmplifyConfig = (
	type: "PROD" | "STAGING" | "API-PROD" | "API-STAGING"
) => {
	const config = {
		env: "dev",
		aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC_ENDPOINT,
		aws_appsync_region: "us-west-2",
		aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
		aws_appsync_apiKey: process.env.REACT_APP_APPSYNC_API,
		Auth: {
			region: "us-west-2",
			userPoolId: process.env.REACT_APP_USERPOOL_ID,
			userPoolWebClientId: process.env.REACT_APP_USERPOOL_CLIENTID
		}
	};
	switch (type) {
		case "PROD":
			config.env = "prod";
			config.aws_appsync_authenticationType = "AMAZON_COGNITO_USER_POOLS";
			break;
		case "STAGING":
			config.env = "dev";
			config.aws_appsync_authenticationType = "AMAZON_COGNITO_USER_POOLS";
			break;
		case "API-PROD":
			config.env = "prod";
			config.aws_appsync_authenticationType = "API_KEY";
			break;
		case "API-STAGING":
			config.env = "dev";
			config.aws_appsync_authenticationType = "API_KEY";
			break;
	}

	Amplify.configure(config);
};

export const validPassword = (password: string) => {
	const passw = /^(?=.*\d)(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,50}$/;
	if (password.match(passw)) {
		return true;
	} else {
		return false;
	}
};

export const validateEmail = (email: string) => {
	var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
};

export const getMonth = (monthNum: number) => {
	let month = "Jan";
	switch (monthNum) {
		case 1:
			month = "Jan";
			break;
		case 2:
			month = "Feb";
			break;
		case 3:
			month = "Mar";
			break;
		case 4:
			month = "Apr";
			break;
		case 5:
			month = "May";
			break;
		case 6:
			month = "Jun";
			break;
		case 7:
			month = "Jul";
			break;
		case 8:
			month = "Aug";
			break;
		case 9:
			month = "Sept";
			break;
		case 10:
			month = "Oct";
			break;
		case 11:
			month = "Nov";
			break;
		case 12:
			month = "Dec";
			break;
	}
	return month;
};

export const useWindowSize = () => {
	const isClient = typeof window === "object";

	function getSize () {
		const currentWindowSize: iWindow = {
			width: isClient ? window.innerWidth : undefined,
			height: isClient ? window.innerHeight : undefined
		};

		return currentWindowSize;
	}

	const [ windowSize, setWindowSize ] = useState(getSize);
	// @ts-ignore
	useEffect(() => {
		if (!isClient) {
			return false;
		}

		function handleResize () {
			setWindowSize(getSize());
		}

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return windowSize;
};

export const EnableBodyScrolling = (scroll: boolean) => {
	const body = document.querySelector("#root");
	if (!scroll) {
		//@ts-ignore
		body.style.overflow = "hidden";
	} else {
		//@ts-ignore
		body.style.overflow = "auto";
	}
};

export const CalcMonthlyDiscount = (quantity: number, plan: Plan) => {
	if (plan === Plan.FREE) {
		return 0
	} else if (plan === Plan.GROWTH) {
		let monthlyDiscount = Costs.starter_monthly_fee;
		return monthlyDiscount;
	} else if (plan === Plan.SALON) {
		let monthlyDiscount = Costs.monthly_fee;
		if (quantity === 2) monthlyDiscount = Costs.monthly_fee - 50;
		if (quantity === 3) monthlyDiscount = Costs.monthly_fee - 75;
		if (quantity >= 4) monthlyDiscount = Costs.monthly_fee - 100;
	
		return monthlyDiscount;
	} else if (plan === Plan.PLUS) {
		let monthlyDiscount = Costs.monthly_fee;
		if (quantity === 2) monthlyDiscount = Costs.monthly_fee - 50;
		if (quantity === 3) monthlyDiscount = Costs.monthly_fee - 75;
		if (quantity >= 4) monthlyDiscount = Costs.monthly_fee - 100;
		return monthlyDiscount;
	} else {
		let monthlyDiscount = Costs.monthly_fee;
		if (quantity === 2) monthlyDiscount = Costs.monthly_fee - 50;
		if (quantity === 3) monthlyDiscount = Costs.monthly_fee - 75;
		if (quantity >= 4) monthlyDiscount = Costs.monthly_fee - 100;
		return monthlyDiscount;
	}
	
};

export const CalcAnnualDiscount = (quantity: number, plan: Plan) => {
	if (plan === Plan.FREE) {
		return 0
	} else if (plan === Plan.GROWTH) {
		let annualDiscount = Costs.starter_annual_fee;
		return annualDiscount;
	} else if (plan === Plan.SALON) {
		let annualDiscount = Costs.annual_fee;
		if (quantity === 1) annualDiscount = (Costs.monthly_fee) * 12 * 0.85;
		if (quantity === 2) annualDiscount = (Costs.monthly_fee - 50) * 12 * 0.85;
		if (quantity === 3) annualDiscount = (Costs.monthly_fee - 75) * 12 * 0.85;
		if (quantity >= 4) annualDiscount = (Costs.monthly_fee - 100) * 12 * 0.85;

		return annualDiscount;
	} else if (plan === Plan.PLUS) {
		let annualDiscount = Costs.annual_fee;
		if (quantity === 1) annualDiscount = (Costs.monthly_fee) * 12 * 0.85;
		if (quantity === 2) annualDiscount = (Costs.monthly_fee - 50) * 12 * 0.85;
		if (quantity === 3) annualDiscount = (Costs.monthly_fee - 75) * 12 * 0.85;
		if (quantity >= 4) annualDiscount = (Costs.monthly_fee - 100) * 12 * 0.85;

		return annualDiscount;
	} else {
		let annualDiscount = Costs.annual_fee;
		if (quantity === 1) annualDiscount = (Costs.monthly_fee) * 12 * 0.85;
		if (quantity === 2) annualDiscount = (Costs.monthly_fee - 50) * 12 * 0.85;
		if (quantity === 3) annualDiscount = (Costs.monthly_fee - 75) * 12 * 0.85;
		if (quantity >= 4) annualDiscount = (Costs.monthly_fee - 100) * 12 * 0.85;

		return annualDiscount;
	}
	
};

export const CalcUpfrontDiscount = (quantity: number, plan: Plan) => {
		let upfrontDiscount = CalcMonthlyDiscount(quantity, plan) * 3 * 0.9
		return upfrontDiscount;
};

export const CalcMonthlyCost = (quantity: number, plan: Plan | undefined) => {
	if (plan === Plan.GROWTH) {
		return 29;
	} else if (plan === Plan.SALON) {
		return 79
	} else if (plan === Plan.PLUS) {
		let monthlyCost = 275;
		if (quantity == 2) monthlyCost = monthlyCost - 50;
		if (quantity == 3) monthlyCost = monthlyCost - 75;
		if (quantity >= 4) monthlyCost = monthlyCost - 100;
		console.log(monthlyCost, quantity)
		return monthlyCost * quantity;
	} else {
		return 0
	}
};

export const CalcAnnualCost = (quantity: number, plan: Plan | undefined) => {
	if (plan === Plan.GROWTH) {
		return 29 * 10;
	} else if (plan === Plan.SALON) {
		return 79 * 10
	} else if (plan === Plan.PLUS) {
		let annualCost = 275 * 10;
		if (quantity === 2) annualCost = (275 - 50) * 10;
		if (quantity === 3) annualCost = (275 - 75) * 10;
		if (quantity >= 4) annualCost = (275 - 100) * 10;
		return annualCost * quantity;
	} else {
		return 0
	}
};

export const CalcUpfrontCost = (quantity: number, plan: Plan) => {
	return CalcUpfrontDiscount(quantity, plan) * quantity;
};

export const CalcDiscount = (type: string, quantity: number, plan: Plan) => {
	let discount = 0;
	if (type === SubscriptionType[0]) {
		discount = quantity * (Costs.annual_fee - CalcAnnualDiscount(quantity, plan));
	} else if (type === SubscriptionType[1]) {
		discount = quantity * (Costs.monthly_fee - CalcMonthlyDiscount(quantity, plan));
	} else {
		discount = quantity * (Costs.three_mth_upfront_fee - CalcUpfrontDiscount(quantity, plan));
	}
	return `$${discount}`;
};

export function useLocalStorage (key, initialValue) {
	// State to store our value
	// Pass initial state function to useState so logic is only executed once
	const [ storedValue, setStoredValue ] = useState(() => {
		try {
			// Get from local storage by key
			const item = window.localStorage.getItem(key);
			// Parse stored json or if none return initialValue
			return item ? JSON.parse(item) : initialValue;
		} catch (error) {
			// If error also return initialValue
			console.log(error);
			return initialValue;
		}
	});

	// Return a wrapped version of useState's setter function that ...
	// ... persists the new value to localStorage.
	const setValue = value => {
		try {
			// Allow value to be a function so we have same API as useState
			const valueToStore =
				value instanceof Function ? value(storedValue) : value;
			// Save state
			setStoredValue(valueToStore);
			// Save to local storage
			window.localStorage.setItem(key, JSON.stringify(valueToStore));
		} catch (error) {
			// A more advanced implementation would handle the error case
			console.log(error);
		}
	};

	return [ storedValue, setValue ];
}

export const ClearLocalStorage = () => {
	const [ appointmentsCache, setAppointmentsCache ] = useLocalStorage(
		CACHE.APPOINTMENTS,
		[]
	);
	setAppointmentsCache([]);

	return null;
};

export interface INewContactDealAccount {
	firstName: string;
	lastName: string;
	mobileNumber: string;
	email: string;
	address: string;
	notes?: string;
	subscribeEmail?: string;
	suburb: string;
	state: string;
	postcode: string;
	country: string;
	salonName: string;
	SYSAccountNumber?: string;
	dealAmount: string;
	website?: string;
	colourBrand: string;
	abn?: string;
	mirrorQuantity: string;
	discount: string;
	subscriptionType: string;
	subscribedDate: string;
	promo: string;
  }


export const ZapierFreshworksNewContactDealAccount = (newCDA: INewContactDealAccount) => {
	
	let url =  "https://hooks.zapier.com/hooks/catch/4781910/o7icfb8/";
	if (isDev) url = "https://cors-anywhere.herokuapp.com/https://hooks.zapier.com/hooks/catch/4781910/o7icfb8/"; 
	axios.post<any>(url, 
	newCDA, {
		headers: {
			'Content-Type': 'text/plain'
		  }
	}).then(r => {
		console.log(r);
		return r;
	});
}


export const ZapierNewBetaSignup = (body: any) => {
	
	let url =  "https://hooks.zapier.com/hooks/catch/4781910/ba65hko/";
	if (isDev) url = "https://cors-anywhere.herokuapp.com/https://hooks.zapier.com/hooks/catch/4781910/ba65hko/"
	axios.post<any>(url, 
		body, {
		headers: {
			'Content-Type': 'text/plain'
		  }
	}).then(r => {
		console.log(r);
		return r;
	});
}
 

export const newStripeCustomer = (params: any) => {
	
	let url =  "https://piiq-node-api.herokuapp.com/new-stripe-customer";
	if (isDev) url = "https://cors-anywhere.herokuapp.com/https://hooks.zapier.com/hooks/catch/4781910/o7icfb8/"
	axios.post<any>(url, params).then(r => {
		console.log(r);
		return r;
	});
}




export const ZapierFreshworksNewContactDealAccountPrePayment = (newCDA: INewContactDealAccount) => {
	
	let url =  "https://hooks.zapier.com/hooks/catch/4781910/bhnojvt/";
	if (isDev) url = "https://cors-anywhere.herokuapp.com/https://hooks.zapier.com/hooks/catch/4781910/o7icfb8/"
	axios.post<any>(url, 
	newCDA, {
		headers: {
			'Content-Type': 'text/plain'
		  }
	}).then(r => {
		console.log(r);
		return r;
	});
}


export interface INewOtherForm {
	firstName: string;
	lastName: string;
	mobileNumber: string;
	email: string;
	salonName: string;
	lorealAccountNumber?: string;
	dealAmount?: string;
	website?: string;
	colourBrand: string;
	abn?: string;
	mirrorQuantity: string;
	discount?: string;
	subscriptionType: string;
	subscribedDate?: string;
	promo?: string;
  }


export const ZapierFreshworksNewOtherForm = (newCDA: INewOtherForm) => {
	
	let url =  "https://hooks.zapier.com/hooks/catch/4781910/ovbdtuw/";
	if (isDev) url = "https://cors-anywhere.herokuapp.com/https://hooks.zapier.com/hooks/catch/4781910/ovbdtuw/"
	axios.post<any>(url, 
	newCDA, {
		headers: {
			'Content-Type': 'text/plain'
		  }
	}).then(r => {
		console.log(r);
		return r;
	});
}

export interface INewSYSSalon {
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	addressLine1: string;
	addressLine2: string;
	suburb: string;
	state: string;
	postcode: string;
	salonName: string;
	lorealAccountNumber?: number;
	abn?: string;
  }


export const SYSNewSalonSignup = (newSalon: INewSYSSalon) => {
	
	let url =  "https://supportyoursalon.com.au/api/1.1/wf/SignupNewSalonWithMirror";
	if (isDev) url = "https://supportyoursalon.com.au/version-test/api/1.1/wf/SignupNewSalonWithMirror"
	axios.post<any>(url, 
		newSalon, {
		headers: {
			'Authorization': 'Bearer 2380f499b12cde64cd8ca7ba442ffb59'
		  }
	}).then(r => {
		return r;
	});
}


export const GetSYSAccountNumber = async (email: any) => {
	
	let url =  "https://supportyoursalon.com.au/api/1.1/wf/getSYSaccountnumberbyemail";
	if (isDev) url = "https://supportyoursalon.com.au/version-test/api/1.1/wf/getSYSaccountnumberbyemail"
	return axios.post<any>(url, 
		{email : email}, {
		headers: {
			'Authorization': 'Bearer 2380f499b12cde64cd8ca7ba442ffb59'
		  }
	});
}


export const GetIBBLessons = async (email: string) => {
	
	let url =  "https://supportyoursalon.com.au/api/1.1/wf/GetIBBLessons";
	if (isDev) url = "https://supportyoursalon.com.au/version-test/api/1.1/wf/GetIBBLessons"
	let out = axios.post<any>(url, 
		{email: email}).then(r => {
		return r;
	});
	return out;
}

export const GetIBBDetail = async (ibbDayID: string, email: string) => {
	
	let url =  "https://supportyoursalon.com.au/api/1.1/wf/GetIBBDayDetail";
	if (isDev) url = "https://supportyoursalon.com.au/version-test/api/1.1/wf/GetIBBDayDetail"
	let out = axios.post<any>(url, 
		{ibbDay: ibbDayID, email: email}).then(r => {
		return r;
	});
	return out;
}



export const MarkIBBTodoAsDone = async (todo: string, email: string, value: string) => {
	
	let url =  "https://supportyoursalon.com.au/api/1.1/wf/IBBMarkAsDone";
	if (isDev) url = "https://supportyoursalon.com.au/version-test/api/1.1/wf/IBBMarkAsDone"
	let out = axios.post<any>(url, 
		{todo: todo, email: email, value: value}).then(r => {
		return r;
	});
	return out;
}

export const MarkIBBDayAsDone = async (day: string, email: string) => {
	
	let url =  "https://supportyoursalon.com.au/api/1.1/wf/IBBMarkDayAsDone";
	if (isDev) url = "https://supportyoursalon.com.au/version-test/api/1.1/wf/IBBMarkDayAsDone";
	let out = axios.post<any>(url, 
		{day: day, email: email}).then(r => {
		return r;
	});
	return out;
}


export const UpdateSalonRetailPreference = async (email: string, preference: string) => {
	
	let url =  "https://supportyoursalon.com.au/api/1.1/wf/UpdateSalonRecPreference";
	if (isDev) url = "https://supportyoursalon.com.au/version-test/api/1.1/wf/UpdateSalonRecPreference"
	let out = axios.post<any>(url, 
		{email: email, preference: preference}).then(r => {
		return r;
	});
	return out;
}



export const GetSalonBrandsAndPref = async (email: string) => {
	
	let url =  "https://supportyoursalon.com.au/api/1.1/wf/GetSalonBrandsAndPref";
	if (isDev) url = "https://supportyoursalon.com.au/version-test/api/1.1/wf/GetSalonBrandsAndPref"
	let out = axios.post<any>(url, 
		{email: email}).then(r => {
		return r;
	});
	return out;
}



export const ResetIBB = async (email: string) => {
	
	let url =  "https://supportyoursalon.com.au/api/1.1/wf/ResetIBB";
	if (isDev) url = "https://supportyoursalon.com.au/version-test/api/1.1/wf/ResetIBB"
	let out = axios.post<any>(url, 
		{email: email}).then(r => {
		return r;
	});
	return out;
}


export const GetAcademyContent = async (email: string) => {
	
	let url =  "https://supportyoursalon.com.au/api/1.1/wf/GetPiiqAcademyContent";
	if (isDev) url = "https://supportyoursalon.com.au/version-test/api/1.1/wf/GetPiiqAcademyContent"
	let out = axios.post<any>(url, 
		{email: email}).then(r => {
		return r;
	});
	return out;
}


export const GetAcademyDetail = async (academyId : string, email: string) => {
	
	let url =  "https://supportyoursalon.com.au/api/1.1/wf/GetPiiqAcademyContentDetail";
	if (isDev) url = "https://supportyoursalon.com.au/version-test/api/1.1/wf/GetPiiqAcademyContentDetail"
	let out = axios.post<any>(url, 
		{email: email, id: academyId}).then(r => {
		return r;
	});
	return out;
}


export const GetSalonOrders = async (email: string) => {
	
	let url =  "https://supportyoursalon.com.au/api/1.1/wf/GetSalonOrders";
	if (isDev) url = "https://supportyoursalon.com.au/version-test/api/1.1/wf/GetSalonOrders"
	let out = axios.post<any>(url, 
		{email: email}).then(r => {
		return r;
	});
	return out;
}



export const GetSalonOrderDetails = async (orderid: string, email: string) => {
	
	let url =  "https://supportyoursalon.com.au/api/1.1/wf/GetSalonOrderDetails";
	if (isDev) url = "https://supportyoursalon.com.au/version-test/api/1.1/wf/GetSalonOrderDetails"
	let out = axios.post<any>(url, 
		{email: email, orderID: orderid}).then(r => {
		return r;
	});
	return out;
}


export const GetSalonAccount = async (email: string) => {
	
	let url =  "https://supportyoursalon.com.au/api/1.1/wf/GetSalonAccount";
	if (isDev) url = "https://supportyoursalon.com.au/version-test/api/1.1/wf/GetSalonAccount"
	let out = axios.post<any>(url, 
		{email: email}).then(r => {
		return r;
	});
	return out;
}


export const UpdateSalonAccount = async (salon: object) => {

	let url =  "https://supportyoursalon.com.au/api/1.1/wf/UpdateSalonAccount";
	if (isDev) url = "https://supportyoursalon.com.au/version-test/api/1.1/wf/UpdateSalonAccount"
	let out = axios.post<any>(url, 
		salon).then(r => {
		return r;
	});
	return out;
}


export const SendUserPiiqMail = async (email: string, subject: string, title: string, body: string) => {

	let url =  "https://supportyoursalon.com.au/api/1.1/wf/SendUserPiiqMail";
	if (isDev) url = "https://supportyoursalon.com.au/version-test/api/1.1/wf/UpdateSalonAccount"
	let out = axios.post<any>(url, 
		{email, subject, title, body}).then(r => {
		return r;
	});
	return out;
}


export const getStripeCustomerInvoices = async (params: any) => {
	
	let url = "https://piiq-node-api.herokuapp.com/get-invoices-for-salon-id"
	if (isDev) url = "https://cors-anywhere.herokuapp.com/https://piiq-node-api.herokuapp.com/get-invoices-for-salon-id"; 
	let out = axios.post<any>(url, params).then(r => {
		console.log(r);
		return r;
	});
	return out;
}


export const GetSalonDetails = async (params: any) => {
	
	let url = "https://piiq-node-api.herokuapp.com/get-salon-details"
	if (isDev) url = "https://cors-anywhere.herokuapp.com/https://piiq-node-api.herokuapp.com/get-salon-details"; 
	let out = axios.post<any>(url, params).then(r => {
		console.log(r);
		return r;
	});
	return out;
}


export const UpdateAWSAccount = async (account: object) => {

	let url = "https://piiq-node-api.herokuapp.com/update-aws-account"
	if (isDev) url = "https://cors-anywhere.herokuapp.com/https://piiq-node-api.herokuapp.com/update-aws-account"; 	
	let out = axios.post<any>(url, 
		{account}).then(r => {
		return r;
	});
	return out;
}


export const UpdateSalon = async (salon: object) => {

	let url = "https://piiq-node-api.herokuapp.com/update-salon-details"
	if (isDev) url = "https://cors-anywhere.herokuapp.com/https://piiq-node-api.herokuapp.com/update-salon-details"; 
	let out = axios.post<any>(url, 
		{salon}).then(r => {
		return r;
	});
	return out;
}


export const RegisterOneSignalPlayerId = async (user: object) => {
	console.log("RegisterOneSignalPlayerId", user)
	let url = "https://piiq-node-api.herokuapp.com/register-onesignal-player-id"
	if (isDev) url = "https://cors-anywhere.herokuapp.com/https://piiq-node-api.herokuapp.com/register-onesignal-player-id"; 
	let out = axios.post<any>(url, 
		{user}).then(r => {
		return r;
	});
	return out;
}

let headers = {headers: {
	'PRIVATE-KEY': 'e02c19f9-dec6-4e63-b77a-bbe3819f2115'
  }}

export const createOrGetUser = async (username, name) => {
	username = "Salon#"+username
	// CREATE OR GET USER
	let body = {
		"username": username,
		"secret": "123456aA"
	}
	let out = await axios.put<any>("https://api.chatengine.io/users/", 
		body,headers).then(r => {return r})
	
	// RENAME USER TO SALON NAME
	let body2 = {
		"first_name": name
	}
	let out2 = await axios.patch<any>(`https://api.chatengine.io/users/${out.data.id}/`,
		body2,headers);    

	// GET OR CREATE CHAT WITH PIIQ SUPPORT TEAM 
	let body3 = {
		"usernames": [username, "piiq-support"],
		"title": `piiq Support - ${name}`,
		"is_direct_chat": false
	}
	let out3 = await axios.put<any>(`https://api.chatengine.io/chats/`,
		body3,headers);  
	
	console.log(out3);
	if (out3.data.last_message.created === "") {

	
	// Send message to chat
	let body4 = {
		"text": "Welcome to piiq! If you have any questions this is a great way to contact us, we usually reply within 1 business day."
	}
	let out4 = await axios.post<any>(`https://api.chatengine.io/chats/${out3.data.id}/messages/`,
		body4,headers);  
	}

	return out2.data;

}
