import React from "react";
import {
  CardElement
  // CardNumberElement,
  // CardExpiryElement,
  // CardCvcElement
} from "react-stripe-elements";

import { PaymentRow } from "./styles";

interface IInputProps {}

const InputPaymentComponent = (props: IInputProps) => {
  return (
    <PaymentRow>
      <label className="margin-bottom-md">
        Card Details
        {/* <CardNumberElement />
        <CardExpiryElement />
        <CardCvcElement /> */}
        <CardElement style={{ base: { fontSize: "18px" } }} />
      </label>
    </PaymentRow>
  );
};

export default InputPaymentComponent;
