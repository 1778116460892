import React, { useContext, useReducer } from "react";
import createAsyncDispatch, { AsyncDispatch } from "./createAsyncDispatch";
import {
  IStateProducts,
  IProductAction,
  reducerProducts,
  initialStateProducts
} from "../reducers/ProductsReducer";
import {
  IStateStaff,
  IStaffAction,
  reducerStaff,
  initialStateStaff
} from "../reducers/StaffReducer";

import {
  IStateAppointments,
  IAppointmentAction,
  reducerAppointments,
  initialStateAppointments
} from "../reducers/AppointmentsReducer";

import {
  IStateOrders,
  IOrderAction,
  reducerOrders,
  initialStateOrders
} from "../reducers/OrdersReducer";

import {
  IStateBrands,
  IBrandAction,
  reducerBrands,
  initialStateBrands
} from "../reducers/BrandsReducer"
import {
  IStateSalons,
  ISalonAction,
  reducerSalons,
  initialStateSalons
} from "../reducers/SalonsReducer"
import {
  IStateTermsAndConditions,
  ITermsAndConditionsAction,
  reducerTermsAndConditions,
  initialStateTermsAndConditions
} from "../reducers/TermsAndConditionsReducer"

import {
  IStateRegisterPayment,
  IRegisterPaymentAction,
  reducerRegistration,
  initialStatePaymentRegistration
} from "../reducers/RegistrationReducer"

import {
  IStateNotification,
  INotificationAction,
  reducerNotification,
  initialStateNotification
} from "../reducers/NotificationReducer"

import {
  IStateClients,
  IClientsAction,
  reducerClients,
  initialStateClients
} from "../reducers/ClientsReducer"

export interface IAdminContext {
  products?: [IStateProducts, AsyncDispatch<IProductAction, IAdminContext>?];
  staff?: [IStateStaff, AsyncDispatch<IStaffAction, IAdminContext>?];
  appointments?: [
    IStateAppointments,
    AsyncDispatch<IAppointmentAction, IAdminContext>?
  ];
  salons?: [IStateSalons, AsyncDispatch<ISalonAction, IAdminContext>?];
  brands?: [IStateBrands, AsyncDispatch<IBrandAction, IAdminContext>?];
  termsAndConditions?: [
    IStateTermsAndConditions,
    AsyncDispatch<ITermsAndConditionsAction, IAdminContext>?
  ];
  registration?: [IStateRegisterPayment, AsyncDispatch<IRegisterPaymentAction, IAdminContext>?]
  notification?: [IStateNotification, AsyncDispatch<INotificationAction, IAdminContext>?]
  clients?: [IStateClients, AsyncDispatch<IClientsAction, IAdminContext>?]
}

const AdminContext = React.createContext<IAdminContext>({});

export const AdminProvider: React.FC<{}> = ({ children }) => {
  //Products
  const [products, productsDispatch] = useReducer(
    reducerProducts,
    initialStateProducts
  );

  //Appointments
  const [appointments, appointmentsDispatch] = useReducer(
    reducerAppointments,
    initialStateAppointments
  );

  //Staff
  const [staff, staffDispatch] = useReducer(reducerStaff, initialStateStaff);

  //Brands
  const [brands, brandsDispatch] = useReducer(
    reducerBrands,
    initialStateBrands
  );

  //Salons
  const [salons, salonsDispatch] = useReducer(
    reducerSalons,
    initialStateSalons
  );

  //Terms and Conditions
  const [termsAndConditions, termsAndConditionsDispatch] = useReducer(
    reducerTermsAndConditions,
    initialStateTermsAndConditions
  );

  const [paymentRegistration, paymentRegistrationDispatch] = useReducer(
    reducerRegistration, initialStatePaymentRegistration
  )

  const [notification, notifcationDispatch] = useReducer(
    reducerNotification, initialStateNotification
  )

  const [clients, clientsDispatch] = useReducer(
    reducerClients, initialStateClients
  )

  const state: IAdminContext = {
    products: [products],
    staff: [staff],
    appointments: [appointments],
    salons: [salons],
    brands: [brands],
    termsAndConditions: [termsAndConditions],
    registration: [paymentRegistration],
    notification: [notification],
    clients: [clients]
  };

  const productsDispatchAsync: AsyncDispatch<
    IProductAction,
    IAdminContext
  > = createAsyncDispatch(productsDispatch, state);

  const appointmentsDispatchAsync: AsyncDispatch<
    IAppointmentAction,
    IAdminContext
  > = createAsyncDispatch(appointmentsDispatch, state);

  const staffDispatchAsync: AsyncDispatch<
    IStaffAction,
    IAdminContext
  > = createAsyncDispatch(staffDispatch, state);
  const brandsDispatchAsync: AsyncDispatch<
    IBrandAction,
    IAdminContext
  > = createAsyncDispatch(brandsDispatch, state);
  const salonsDispatchAsync: AsyncDispatch<
    ISalonAction,
    IAdminContext
  > = createAsyncDispatch(salonsDispatch, state);
  const termsAndConditionsDispatchAsync: AsyncDispatch<
    ITermsAndConditionsAction,
    IAdminContext
  > = createAsyncDispatch(termsAndConditionsDispatch, state);
  const paymentRegistrationDispatchAsync: AsyncDispatch<
    IRegisterPaymentAction,
    IAdminContext
  > = createAsyncDispatch(paymentRegistrationDispatch, state);

  const notifcationDispatchAsync: AsyncDispatch<INotificationAction,IAdminContext> = createAsyncDispatch(notifcationDispatch, state)
  const clientsDispatchAsync: AsyncDispatch<IClientsAction,IAdminContext> = createAsyncDispatch(clientsDispatch, state)

  return (
    <AdminContext.Provider
      value={{
        products: [products, productsDispatchAsync],
        salons: [salons, salonsDispatchAsync],
        brands: [brands, brandsDispatchAsync],
        appointments: [appointments, appointmentsDispatchAsync],
        staff: [staff, staffDispatchAsync],
        termsAndConditions: [
          termsAndConditions,
          termsAndConditionsDispatchAsync
        ],
        registration: [paymentRegistration, paymentRegistrationDispatchAsync],
        notification: [notification, notifcationDispatchAsync],
        clients: [ clients, clientsDispatchAsync ]
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export const useProducts = () => {
  const contextValue = useContext(AdminContext);
  return contextValue.products as [
    IStateProducts,
    AsyncDispatch<IProductAction, IAdminContext>
  ];
};

export const useStaff = () => {
  const contextValue = useContext(AdminContext);
  return contextValue.staff as [
    IStateStaff,
    AsyncDispatch<IStaffAction, IAdminContext>
  ];
};

export const useBrands = () => {
  const contextValue = useContext(AdminContext);
  return contextValue.brands as [
    IStateBrands,
    AsyncDispatch<IBrandAction, IAdminContext>
  ];
};

export const useAppointments = () => {
  const contextValue = useContext(AdminContext);
  return contextValue.appointments as [
    IStateAppointments,
    AsyncDispatch<IAppointmentAction, IAdminContext>
  ];
};

export const useSalons = () => {
  const contextValue = useContext(AdminContext);
  return contextValue.salons as [
    IStateSalons,
    AsyncDispatch<ISalonAction, IAdminContext>
  ];
};

export const useTermsAndConditions = () => {
  const contextValue = useContext(AdminContext);
  return contextValue.termsAndConditions as [
    IStateTermsAndConditions,
    AsyncDispatch<ITermsAndConditionsAction, IAdminContext>
  ];
};

export const useRegistration = () => {
  const contextValue = useContext(AdminContext);
  return contextValue.registration as [
    IStateRegisterPayment,
    AsyncDispatch<IRegisterPaymentAction, IAdminContext>
  ];
};

export const useNotification = () => {
  const contextValue = useContext(AdminContext);
  return contextValue.notification as [
    IStateNotification,
    AsyncDispatch<INotificationAction, IAdminContext>
  ];
};

export const useClients = () => {
  const contextValue = useContext(AdminContext);
  return contextValue.clients as [
    IStateClients,
    AsyncDispatch<IClientsAction, IAdminContext>
  ];
};