import React, {useState, useEffect} from "react";
import { ResponsiveBar } from "@nivo/bar";
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const CHARTVALUE = {
	NUMBER: "NUMBER",
	MONEY: "MONEY"
};


export default function BarCompt (props: any) {
	const [ ausWideAverage, setAusWideAverage ] = useState(0);

	useEffect(() => {
		getAusWideAverage(props);
	  }, [props, props.chartDuration]);

	const getAusWideAverage = (props:any) => {
		if (props.chartType === CHARTVALUE.MONEY) { 
			if (props.chartDuration === "all") {
				setAusWideAverage(3500);
			} else if (props.chartDuration === "year") {
				setAusWideAverage(3500);
			} else if (props.chartDuration === "month") {
				setAusWideAverage(50);
			} else if (props.chartDuration === "week") {
				setAusWideAverage(40);
			}  else {
				setAusWideAverage(0);
			}
		} else { 
			if (props.chartDuration === "all") {
				setAusWideAverage(90);
			} else if (props.chartDuration === "year") {
				setAusWideAverage(90);
			} else if (props.chartDuration === "month") {
				setAusWideAverage(2);
			} else if (props.chartDuration === "week") {
				setAusWideAverage(2);
			}  else {
				setAusWideAverage(0);
			}
		}
	}

	return (
		<ResponsiveBar
			data={props.data}
			keys={props.keys}
			indexBy={props.indexBy}
			margin={{ top: 50, right: 130, bottom: 60, left: 60 }}
			padding={0.3}
			colors={props.color}
			groupMode={props.groupMode || "stacked"}
			defs={[
				{
					id: "dots",
					type: "patternDots",
					background: "inherit",
					color: "#38bcb2",
					size: 4,
					padding: 1,
					stagger: true
				},
				{
					id: "lines",
					type: "patternLines",
					background: "inherit",
					color: "#eed312",
					rotation: -45,
					lineWidth: 6,
					spacing: 10
				}
			]}
			fill={props.fill}
			axisTop={null}
			axisRight={null}
			axisBottom={props.axisBottom}
			axisLeft={props.axisLeft}
			tooltip={props.customTooltip}
			enableLabel={props.enableLabel}
			labelSkipWidth={12}
			labelSkipHeight={12}
			labelTextColor={{ from: "color", modifiers: [ [ "darker", 1.6 ] ] }}
			legends={[
				{
					dataFrom: "keys",
					anchor: "bottom-right",
					direction: "column",
					justify: false,
					translateX: 120,
					translateY: 0,
					itemsSpacing: 2,
					itemWidth: 100,
					itemHeight: 20,
					itemDirection: "left-to-right",
					itemOpacity: 0.85,
					symbolSize: 20,
					effects: [
						{
							on: "hover",
							style: {
								itemOpacity: 1
							}
						}
					]
				}
			]}
			theme={{
				legends: {
					text: props.fontStyle
				},
				axis: {
					legend: {
						text: props.fontStyle
					}
				},
				tooltip: {
					container: props.fontStyle
				}
			}}
			animate={props.animate}
			motionStiffness={115}
			motionDamping={20}
			markers={[
				{
					axis: 'y',
					value: ausWideAverage,
					lineStyle: { 
						stroke: 'rgba(0, 0, 0, .35)', 
						strokeWidth: 2 
					},
					legend: 'Australia-wide average',
					// legendOrientation: 'vertical'
				},
			]}
		/>
	);
}
