import React, { useState, useEffect }  from "react";
import clsx from "clsx";
import {
	makeStyles,
	useTheme,
	Theme,
	createStyles
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import styled from "styled-components";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import { StaticContext } from "react-router";
import Auth from "@aws-amplify/auth";
import logo from "./../assets/Images/blacklogo.png";
import { withRouter, Link, RouteComponentProps } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import TermsAndConditionDialogue from "../screens/TermsAndConditions/TermsAndConditionDialogue";
import { useSalons } from "../contexts/AdminContext";
import { BREAKPOINTS } from "../config";

const drawerWidth = 200;

const SalonName = styled.div`
@media (max-width: ${BREAKPOINTS.TABLET}px) {
	display:none;
}
`;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex"
		},
		appBar: {
			transition: theme.transitions.create([ "margin", "width" ], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen
			}),
			boxShadow: "none",
			backgroundColor: "white",
			color: "black",
			paddingLeft: drawerWidth,
			['@media (max-width:940px)']: { // eslint-disable-line no-useless-computed-key
				paddingLeft: 0,
			}
		},
		menuButton: {
			marginRight: theme.spacing(2),
			display: "none",
			['@media (max-width:940px)']: { // eslint-disable-line no-useless-computed-key
				display: "block"
			}
		},
		hideMenuButton: {
			color: "black",
			['@media (min-width:940px)']: { // eslint-disable-line no-useless-computed-key
				display: "none"
			}
		},
		hide: {
			display: "none"
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
			position: "relative",
			['@media (max-width:940px)']: { // eslint-disable-line no-useless-computed-key
				position: "fixed",
				zIndex: 1200
			}
		},
		drawerPaper: {
			width: drawerWidth
		},
		drawerHeader: {
			display: "flex",
			alignItems: "center",
			padding: "0 8px",
			...theme.mixins.toolbar,
			justifyContent: "flex-end",
			backgroundColor: "white",
			color: "black",
			float: "left",
			border: "none"
		},
		menu: {
		},
		logout: {
			textTransform: "none",
			borderBottom: "1px solid black"
		},
		link: {
			textDecoration: "none",
			color: "black",
			fontSize: "1rem"
		},
		content: {
			flexGrow: 1,
			padding: theme.spacing(3),
			transition: theme.transitions.create("margin", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen
			})
		},
		contentShift: {
			transition: theme.transitions.create("margin", {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen
			}),
			marginLeft: 0
		},
		main: {
			minHeight: "calc(100% - 65px)",
			position: "absolute",
			top: "65px",
			width: "calc(100% - 240px)",
			['@media (max-width:940px)']: { // eslint-disable-line no-useless-computed-key
				width: "calc(100% - 48px);"
			}
		}
	})
);

interface IPathParams {
	appointmentId?: string;
	orderId?: string;
	salonId?: string;
}


function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
	  width,
	  height
	};
  }


function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
	useEffect(() => {
	  function handleResize() {
		setWindowDimensions(getWindowDimensions());
	  }
  
	  window.addEventListener('resize', handleResize);
	  return () => window.removeEventListener('resize', handleResize);
	}, []);
  
	return windowDimensions;
  }

function Layout ({
	children,
	match
}: React.PropsWithChildren<
	RouteComponentProps<IPathParams, StaticContext, any>
>) {
	const classes = useStyles();
	const theme = useTheme();
	const [ open, setOpen ] = React.useState(false);
	const { appointmentId, salonId } = match.params;
	const [ salonState, salonDispatch ] = useSalons();
	const [ salonName, setSalonName ] = useState('');
	const [ isLoggedIn, setIsLoggedIn ] = useState(false);
	const { height, width } = useWindowDimensions();

	useEffect(() => {
		getAuth()
	}, []);

	useEffect(() => {
		salonState.salons && salonState.salons.map(salon => {
			if(salon.id === salonId) {
				setSalonName(salon.name)
			}
		})
	}, [salonState]);


    const getAuth = async () => {
        try {
            const cognitoUser = await Auth.currentAuthenticatedUser();
			setIsLoggedIn(true);
        } catch (e) {
            setIsLoggedIn(false);
            console.log("Authenticator::CheckUser:Error: in Layout.tsx", e.message);
        }
    }
	function getDrawerOpen () {
		if (width <= 940) {
			return open;
		} else {
			return true
		}
	}

	function handleDrawerOpen () {
		setOpen(true);
	}

	function handleDrawerClose () {
		setOpen(false);
	}

	const handleLogout = async () => {
		await Auth.signOut();
	};

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar position="fixed" className={clsx(classes.appBar)}>
				<Toolbar>
					{(isLoggedIn && salonId) && (
						<IconButton
							color="inherit"
							aria-label="open drawer"
							onClick={handleDrawerOpen}
							edge="start"
							className={clsx(classes.menuButton)}>
							<MenuIcon />
						</IconButton>
					)}
					<img
						src={logo}
						alt="logo"
						style={{
							width: "80px",
							height: "auto",
							float: "left",
						}}
					/>
					<div style={{  marginLeft: "auto", display: "flex" }}>
						<SalonName style={{paddingRight: "40px"}}>
							<p>{salonName}</p>
						</SalonName>
						{(isLoggedIn && salonId) && (
						<Link to="/" className={classes.link}>
							<ListItem button className={classes.logout} onClick={handleLogout}>
								<FormattedMessage id="menu.signout" defaultMessage={`Log out`} />
							</ListItem>
						</Link>
						)}
					</div>
				</Toolbar>
			</AppBar>
			<Drawer
				className={classes.drawer}
				variant="persistent"
				anchor="left"
				open={getDrawerOpen()}
				classes={{
					paper: classes.drawerPaper
				}}>
				<div className={classes.drawerHeader}>
					
					<IconButton onClick={handleDrawerClose} className={clsx(classes.hideMenuButton)}>
						{theme.direction === "ltr" ? (
							<ChevronLeftIcon />
						) : (
							<ChevronRightIcon />
						)}
					</IconButton>
				</div>
				<Divider />
				<List
					style={{ backgroundColor: "white", color: "black", height: "100vh" }}>
					{/* <Link to="/:salonId/dashboard" className={classes.link}> <ListItem button onClick={handleDrawerClose}  >DASHBOARD </ListItem></Link> */}
					{ salonState.salons.length > 1 ? (
						<Link to="/" className={classes.link}>
							<ListItem
								button
								className={classes.menu}
								onClick={handleDrawerClose}>
								<FormattedMessage id="menu.salons" defaultMessage={`Salons`} />
							</ListItem>
						</Link>) : (
							<></>
						)
					}
					{salonId && (
						<Link to={`/${salonId}/weekly`} className={classes.link}>
							<ListItem
								button
								className={classes.menu}
								onClick={handleDrawerClose}>
								Weekly report
							</ListItem>
						</Link>
					)}
					{salonId && (
						<Link to={`/${salonId}/dashboard/`} className={classes.link}>
							<ListItem
								button
								className={classes.menu}
								onClick={handleDrawerClose}>
								<FormattedMessage
									id="menu.dashboard"
									defaultMessage={`Dashboard`}
								/>
							</ListItem>
						</Link>
					)}

					{salonId && (
						<Link
							to={`/${salonId}/appointments/${appointmentId || ""}`}
							className={classes.link}>
							<ListItem
								button
								className={classes.menu}
								onClick={handleDrawerClose}>
								<FormattedMessage
									id="menu.appointments"
									defaultMessage={`Appointments`}
								/>
							</ListItem>
						</Link>
					)}

					{salonId && (
						<Link to={`/${salonId}/clients`} className={classes.link}>
							<ListItem
								button
								className={classes.menu}
								onClick={handleDrawerClose}>
								<FormattedMessage
									id="menu.clients"
									defaultMessage={`Clients`}
								/>
							</ListItem>
						</Link>
					)}

					{salonId && (
						<Link to={`/${salonId}/messaging`} className={classes.link}>
							<ListItem
								button
								className={classes.menu}
								onClick={handleDrawerClose}>
								Messaging
							</ListItem>
						</Link>
					)}

					{salonId && (
						<Link to={`/${salonId}/orders`} className={classes.link}>
							<ListItem
								button
								className={classes.menu}
								onClick={handleDrawerClose}>
								<FormattedMessage
									id="menu.orders"
									defaultMessage={`SYS orders`}
								/>
							</ListItem>
						</Link>
					)}

					{salonId && (
						<Link to={`/${salonId}/ibb`} className={classes.link}>
							<ListItem
								button
								className={classes.menu}
								onClick={handleDrawerClose}>
								<FormattedMessage
									id="menu.ibb"
									defaultMessage={`IBB`}
								/>
							</ListItem>
						</Link>
					)}

					{/* {salonId && (
						<Link to={`/${salonId}/academy`} className={classes.link}>
							<ListItem
								button
								className={classes.menu}
								onClick={handleDrawerClose}>
								<FormattedMessage
									id="menu.academy"
									defaultMessage={`ACADEMY`}
								/>
							</ListItem>
						</Link>
					)} */}

					{/* {salonId && (
						<Link to={`/${salonId}/products`} className={classes.link}>
							<ListItem
								button
								className={classes.menu}
								onClick={handleDrawerClose}>
								<FormattedMessage
									id="menu.products"
									defaultMessage={`PRODUCTS`}
								/>
							</ListItem>
						</Link>
					)} */}
					{/* {salonId && (
						<Link to={`/${salonId}/support`} className={classes.link}>
						<ListItem
							button
							className={classes.menu}
							onClick={handleDrawerClose}
						>
							<FormattedMessage
							id="menu.support"
							defaultMessage={`SUPPORT`}
							/>
						</ListItem>
						</Link>
					)} */}
					{salonId && (
						<Link to={`/${salonId}/settings`} className={classes.link}>
							<ListItem
								button
								className={classes.menu}
								onClick={handleDrawerClose}>
								<FormattedMessage
									id="menu.settings"
									defaultMessage={`Settings`}
								/>
							</ListItem>
						</Link>
					)}

					{salonId && (
						<Link to={`/${salonId}/resources`} className={classes.link}>
							<ListItem
								button
								className={classes.menu}
								onClick={handleDrawerClose}>
								<FormattedMessage
									id="menu.resources"
									defaultMessage={`Help & resources`}
								/>
							</ListItem>
						</Link>
					)}
					<div
						style={{
							position: "absolute",
							bottom: 0
						}}>
						<TermsAndConditionDialogue />
						<div>Version 2.0.2a</div>
					</div>
				</List>
			</Drawer>
			<main className={clsx(classes.content)} onClick={handleDrawerClose}>
				<div
					className={clsx(classes.main)}>
					{children}
				</div>
			</main>
		</div>
	);
}

export default withRouter(Layout);
