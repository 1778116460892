import React, { Component } from "react";
import {
  Button,
  TextField,
  Typography,
  Grid,
  InputAdornment
} from "@material-ui/core";
import Auth from "@aws-amplify/auth";
import { FormattedMessage } from "react-intl";
import { Done } from "@material-ui/icons";
import RecoveryOntheway from "./RecoveryOntheway";
import { CSSTransition } from 'react-transition-group';
import { ReactComponent as Logo } from "../../../assets/Images/logo.svg";

//types
interface Props {
  toggleView(view: string): void;
  animateInProp: boolean;
}

interface IState {
  email: string;
  error: string;
  step: string;
  animateIn: string;
  message: string;
  [name: string]: any;
}

export default class ForgotPasswordForm extends Component<Props, IState> {
  state = {
    email: "",
    valid: false,
    error: "",
    animateIn: 'false',
    message: "",
    step: "send"
  };

  componentDidMount = () => {
    this.setState({ animateIn : this.props.animateInProp ? 'true' : 'false' })
  }
  onChange = (e: any) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    this.setState({ [name]: value });
  };
  onSubmit = async () => {
    const { email } = this.state;

    if (email === "") {
      this.setState({ error: "Please enter a valid email address" });
      return;
    }

    try {
      const cognitoUser = await Auth.forgotPassword(email.trim());
      this.setState({ step: "recovery" });
      console.log("SignIn::Submit::Success:", cognitoUser);
    } catch (e) {
      let error = e.message;
      console.log("SignIn::Submit::Error:", error);
      if (error === "Username/client id combination not found.")
        error = "Sorry, we couldn’t find an account with that email address.";
      this.setState({ error });
    }
  };

  validateEmail(email: string) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  /**
   * Stop enter submitting the form.
   * @param keyEvent Event triggered when the user presses a key.
   */
  onKeyDown(keyEvent: any) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  render() {
    const { toggleView } = this.props;
    const { step } = this.state;
    return (
      <form onKeyDown={this.onKeyDown}>
      <CSSTransition
        in={this.state.animateIn === 'true'}
        timeout={300}
        classNames="opacity">
          <Grid container direction="column" spacing={3} alignItems="stretch">
            <Grid item >
              <Grid container direction="row" justify="space-between">
                <Grid item>
                  <a onClick={() => {toggleView('signin')}} style={{fontSize: 24}}>‹ BACK</a>
                </Grid>
                <Grid item>
                  <Logo width={65} style={{marginTop: 4}}/>
                </Grid>
              </Grid>
            
            <Typography variant="h5" style={{marginTop: 48}}>
              <FormattedMessage id="resetpassword.title" defaultMessage={`Reset password`} />
            </Typography>        
            <p>To request a password reset, please send an email to <a style={{color: 'white'}} href="mailto:support@piiqdigital.com">support@piiqdigital.com</a></p>
          </Grid> 
        </Grid>
        </CSSTransition>
      </form>
    );
  }
}
