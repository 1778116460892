import styled from "styled-components";
import { COLORS, SIZE } from "../../config";

export const Checkout = styled.div`
	width: 100%;
	height: 100%;
	margin: 0;
	padding-bottom: ${SIZE.LARGE}px;
`;

export const Loader = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: ${SIZE.LARGE}px 0 ${SIZE.MEDIUM}px;
	height: 40px;
	svg {
		color: black;
	}
`;

export const Error = styled.div`
	padding: ${SIZE.SMALL}px;
	color: tomato;
`;

export const CardNameInput = styled.div`
	input {
		font-size: 18px;
	}
	margin-bottom: ${SIZE.MEDIUM}px;
`;

export const CheckboxRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: ${SIZE.SMALL}px 0;

	.tick-input {
		width: 20px;
		margin-right: ${SIZE.TINY}px;
		margin-left: 12px;
		margin-top: -2px;
	}

	a:link,
	a:active,
	a:visited {
		color: ${COLORS.BLACK};
		text-decoration: underline;
	}
	input {
		height: 20px !important;
		width: 20px !important;
		border: 0;
		border-radius: ${SIZE.BORDER_RADIUS}px;
		background: ${COLORS.GREY_MID_LIGHT};
	}
`;

export const PrePayNotice = styled.div`
	padding: ${SIZE.MEDIUM}px;
	margin: ${SIZE.MEDIUM}px 0;
	background: ${COLORS.BLUE_MID};
	color: ${COLORS.BLUE_DARK};
	text-align: center;
	border-radius: ${SIZE.BORDER_RADIUS}px;
`;
export const Footer = styled.div`
	height: 100px;
	width: 100%;
`;
