import styled from "styled-components";
import { SIZE, FONT } from "../../config";

export const ContactForm = styled.div`
  width: calc(100% - ${SIZE.SMALL}px);
  margin: 0;
  padding: ${SIZE.SMALL}px 0;
`;

export const Label = styled.div`
  font-size: ${FONT.SIZE.BODY};
  margin: ${SIZE.MEDIUM}px 0 ${SIZE.SMALL}px;
`;

export const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${SIZE.LARGE}px 0 ${SIZE.MEDIUM}px;
  height: 40px;
  svg {
    color: black;
  }
`;
