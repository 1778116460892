import styled from "styled-components";
import { COLORS, SIZE, FONT } from "../../config";

interface iProps {
	readonly: boolean;
}

export const Input = styled.input`
	width: 22px;
	padding: 0 ${SIZE.MEDIUM}px;
	background: ${COLORS.GREY_MID_LIGHT};
	border: 0;
	border-radius: ${SIZE.BORDER_RADIUS}px;
	height: 40px;
	outline: none;
	text-align: center;
	pointer-events: none;
	font-size: ${FONT.SIZE.BODY};
	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
`;

export const Button = styled.button`
	border: 1px solid ${COLORS.BLACK};
	border-radius: ${SIZE.BORDER_RADIUS}px;
	margin: 0 ${SIZE.TINY}px;
	background: ${COLORS.WHITE};
	display: block;
	height: 36px;
	cursor: pointer;
	outline: none;
	font-size: ${FONT.SIZE.BODY};
	&:hover {
		background: ${COLORS.BLACK};
		color: ${COLORS.WHITE};
	}
`;

export const CounterRow = styled.div`
	display: flex;
	flex-direction: row;
`;
