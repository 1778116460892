import React, { useState, useEffect } from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import NativeSelect from '@material-ui/core/NativeSelect';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { IAppointment } from "../../reducers/AppointmentsReducer";
import {
  startUpdateStatus,
  startUpdateRebookStatus,
  startSwitchTreatment
} from "./../../actions/appointmentsActions";
import { useAppointments } from "../../contexts/AdminContext";
import { FormattedMessage } from "react-intl";
import open_btn from "../../assets/Images/open_btn.png";
import complete_btn from "../../assets/Images/complete_btn.png";
import scheduled_btn from "../../assets/Images/scheduled_btn.png";
import delivery_btn from "../../assets/Images/delivery_btn.png";
import pickup_btn from "../../assets/Images/pickup_btn.png";
import na_btn from "../../assets/Images/na_btn.png";
import tick_btn from "../../assets/Images/tick_btn.png";
import { NavigationContainer, SideContainer } from "./styles";
import { startUpdateSupportYourSalon } from "../../actions/appointmentsActions";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { Table } from "antd";
import { useSalons } from "../../contexts/AdminContext";
import { getAllSalons } from "../../actions/salonsActions";

import EditProductDialogComponent from "../../components/EditProductDialog";

import mixpanel from 'mixpanel-browser';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(1),
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "flex-end",
      alignItems: "center",
      overflow: "hidden"
    },
    closeButton: {
      color: theme.palette.grey[500],
      marginLeft: -15
    }
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6" style={{}}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
    overflow: 'hidden'
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

export interface IAppointmentDialogsProps {
  appointment: IAppointment;
  salonId: string;
  onOpen: Function;
  onClose: Function;
  open: boolean
}

export default function AppointmentDialogs(props: IAppointmentDialogsProps) {
  const reducerSalon = useSalons();
  const [stateSalon, dispatchSalon] = reducerSalon;
  const [delayDays, setDelayDays] = useState(0);
  const [salon, setSalon] = useState() as any;
  const [treatment, setTreatment] = useState() as any;
  const [canUpdateTreatment, setCanUpdateTreatment] = useState(false) as any;
  const [newTreatment, setNewTreatment] = useState() as any;
  const [editProductDialogOpen, setEditProductDialogOpen] = useState(false) as any;
  const [productBeingEdited, setProductBeingEdited] = useState() as any;
  const { salons } = stateSalon;

  useEffect(() => {
    setSalon(salons.find(salon => salon.id === salonId));
  }, [salons]);

  useEffect(() => {
    salon && setDelayDays(salon.rebookNotificationDelay || 0);
  }, [salon]);

  useEffect(() => {
    dispatchSalon(getAllSalons());
  }, []);


  ///////////////////////////////////////////
  const reducer = useAppointments();
  const [state, dispatch] = reducer;

  let uniqueId = 0;

  let {
    id,
    clientName,
    staffName,
    createdAt,
    cartItems,
    rebookStatus,
    rebookNotificationSentAt,
    cartId,
    purchasedItems,
    supportYourSalon,
    salonPickup
  } = props.appointment;

  useEffect(() => {
		setTreatment(supportYourSalon?.treatment)
		setNewTreatment(supportYourSalon?.treatment)
  }, [supportYourSalon]);

  useEffect(() => {
		if (newTreatment != treatment) setCanUpdateTreatment(true)
  }, [newTreatment]); 


  const handleClickOpen = () => { mixpanel.track("PMA Client sessions - Session dialog opened", {"Client Name" : clientName, "Client session ID" : id}); props.onOpen()};
  const handleClose = () => {
    props.onClose();};

  const handleCancelled = () => {
    dispatch(startUpdateStatus(cartId, "CANCELLED"));
  };

  const handleCompleted = () => {
    dispatch(startUpdateStatus(cartId, "COMPLETED"));
  };

  const handleWaiting = () => {
    dispatch(startUpdateStatus(cartId, "WAITING"));
  };

  const handleSwitchTreatment = (newTreatment:string) => {
    dispatch(startSwitchTreatment(id, newTreatment));
    setTreatment(newTreatment);
    setNewTreatment(newTreatment);
    setCanUpdateTreatment(false)
    supportYourSalon.treatment = newTreatment;
  };

  const handleSetAppointmentRebooked = () => {
    dispatch(startUpdateRebookStatus(id, "SetAppointmentRebooked"));
    mixpanel.track("PMA Client sessions - Mark rebooked button", {"Client Name" : clientName, "Client session ID" : id});
  };

  const handleClearAppointmentRebooked = () => {
    dispatch(startUpdateRebookStatus(id, "ClearAppointmentRebooked"));
    mixpanel.track("PMA Client sessions - Unmark rebook button", {"Client Name" : clientName, "Client session ID" : id});
  };

  const handleEnableAppointmentReminder = () => {
    dispatch(startUpdateRebookStatus(id, "EnableAppointmentReminder"));
    mixpanel.track("PMA Client sessions - Setup reminder", {"Client Name" : clientName, "Client session ID" : id});
  };

  const handleDisableAppointmentReminder = () => {
    dispatch(startUpdateRebookStatus(id, "DisableAppointmentReminder"));
    mixpanel.track("PMA Client sessions - Remove reminder", {"Client Name" : clientName, "Client session ID" : id});
  };

  const updateSupportYourSalon = (newProduct, oldProduct) => {
    console.log(newProduct, oldProduct)
    let _appt = props.appointment;
    let recs = _appt.supportYourSalon.recommendations;
    let i = recs.findIndex(_element => _element.sku === oldProduct.sku);;
    if (i > -1) recs[i] = newProduct;
    _appt.supportYourSalon.recommendations = recs;
    dispatch(startUpdateSupportYourSalon(_appt));
    setEditProductDialogOpen(false);
  }

  const removeFromSupportYourSalon = (product) => {
    console.log('removing', product)
    let _appt = props.appointment;
    let recs = _appt.supportYourSalon.recommendations;
    let i = recs.findIndex(_element => _element.sku === product.sku);;
    recs.splice(i,1);
    _appt.supportYourSalon.recommendations = recs;
    dispatch(startUpdateSupportYourSalon(_appt));
    setEditProductDialogOpen(false);
  }


  //Date formatter
  const dateFormatter = new Intl.DateTimeFormat("en-AU", {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric"
  });
  //Currency sign
  const priceFormat = new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
    useGrouping: false,
    currencyDisplay: "code"
  });

  
  const deliveryType = purchasedItems ? (
    salonPickup ? (
      "PICKED UP IN-STORE"
    ) : (
      "DELIVERED TO HOME"
    )
  ) : (
    "NOT BOUGHT"
  )
  const deliveryTypeID = purchasedItems ? (
    salonPickup ? (
      "pickup"
    ) : (
      "delivery"
    )
  ) : (
    "not_bought"
  );
  const { salonId } = props;

  const calcTotalPrice = () => {
    let totalPrice = 0;
    if (purchasedItems) {
      purchasedItems.forEach((piSKU) => {
        if (supportYourSalon) {
          supportYourSalon.recommendations.forEach((s) => {
            if (s.sku === piSKU) {
              totalPrice += s.rrp;
            }
          });
        }
      })
    }
    return totalPrice;
  }

  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleListItemClick = (event: any, index: any) => {
    setSelectedIndex(index);
  };
  

  const retailColumns = [
    {
      key: "id",
      title: "",
      dataIndex: "productImageMain",
      render: ((productImageMain: string) =>
        productImageMain && (
          <img
            style={{
              width: "50px",
              height: "50px",
              border: "1px solid #9E9D9E"
            }}
            src={'https:' + productImageMain}
            alt="Product"
          />
        ))
    },
    {
      key: "productName",
      title: <FormattedMessage id="product.title" defaultMessage={`Product`} />,
      dataIndex: "productName",
      render: (productName: any) => productName
    },
    {
      key: "productSize",
      title: <FormattedMessage id="product.size" defaultMessage={`Size`} />,
      dataIndex: "product",
      render: (productSize: any) => productSize
    },
    {
      key: "productType",
      title: <FormattedMessage id="product.type" defaultMessage={`Type`} />,
      dataIndex: "product",
      render: (productType: any) => productType
    },
    {
      title: <FormattedMessage id="product.total" defaultMessage={`Total`} />,
      dataIndex: "rrp",
      render: (rrp: any) => {
        if (rrp) {return (rrp).toFixed(2)} else {return 0}}
    },
    {
      title: <FormattedMessage id="product.total" defaultMessage={`Total`} />,
      dataIndex: "sku",
      render: (sku: any) => {
        return (<Button 
          variant="outlined"
          onClick={() => {
            setProductBeingEdited(supportYourSalon.recommendations.find((p) => p.sku == sku));
            setEditProductDialogOpen(true)
          }}
          style={{
            color: "black",
            fontSize: "0.8rem",
            borderRadius: "0px",
            position: "relative",
            marginLeft: "12px",
            padding: "2px 15px"
          }}
        >
          Edit product
        </Button>)
      }
    },
  ];

  const rebookColumns = [
    {
      title: (
        <FormattedMessage
          id="rebook.staffname"
          defaultMessage={`Please rebook with`}
        />
      ),
      dataIndex: "staffName",
      key: "staffName",
      render: (staffName: string) => staffName
    },
    {
      title: (
        <FormattedMessage
          id="rebook.rebooklength"
          defaultMessage={`for(mins)`}
        />
      ),
      dataIndex: "rebookLength",
      key: "rebookLength",
      render: (rebookLength: number) => rebookLength
    },
    {
      title: (
        <FormattedMessage
          id="rebook.rebookdelay"
          defaultMessage={`in approximately(weeks)`}
        />
      ),
      dataIndex: "rebookDelay",
      key: "rebookDelay",
      render: (rebookDelay: number) => rebookDelay
    }
  ];

  const RetailBodyWrapper = (props: any) => {
    return (
      <tbody {...props}>
        <React.Fragment>
          {props.children}
          <tr>
            <td colSpan={50}>
              <Button 
              variant="outlined"
              onClick={() => {
                supportYourSalon.recommendations.push(supportYourSalon.recommendations[0]);
                setProductBeingEdited(supportYourSalon.recommendations[supportYourSalon.recommendations.length-1]);
                setEditProductDialogOpen(true)
              }}
              style={{
                color: "black",
                border: 0,
                textTransform: "none",
                textDecoration: "underline",
                fontSize: "0.8rem",
                position: "relative",
                margin: 0,
                padding: 0
              }}
              >
                + Add product
              </Button>
            </td>
          </tr>
          <tr className="ant-table-row">
            <td colSpan={50}>
              <Typography
                align="right"
                style={{
                  textTransform: "uppercase",
                  fontSize: "0.6rem",
                  marginRight: "10px",
                  fontWeight: "bold"
                }}
              >
                <FormattedMessage id="product.total" defaultMessage={`Total`} />
                : {priceFormat.format(calcTotalPrice())}
              </Typography>

              <Typography
                align="right"
                style={{
                  textTransform: "uppercase",
                  fontSize: "0.6rem",
                  textDecoration: "underline",
                  fontWeight: "bold",
                  marginRight: "10px"
                }}
              >
                <FormattedMessage
                  id={`appointment.deliverytype.${deliveryTypeID}`}
                  defaultMessage={`${deliveryType}`}
                />
              </Typography>
            </td>
          </tr>
        </React.Fragment>
      </tbody>
    );
  };

  const RebookBodyWrapper = (props: any) => {
    return (
      <tbody {...props}>
        <React.Fragment>
          {props.children}

          <tr className="ant-table-row">
            <td colSpan={3} style={{ fontSize: "8px", fontWeight: "lighter" }}>
              {rebookStatus === "SCHEDULED" ? (
                <Link
                  to={`/${salonId}/settings/salon`}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <FormattedMessage
                    id="text.displayBookingReminder"
                    defaultMessage={`Your client will receive a rebook reminder through the piiq app in {numdays} days. You can change the number of days under settings.`}
                    values={{
                      numdays: (
                        <u>{delayDays}</u>
                      ) /*Todo:Get the numdays from the settings, when available*/
                    }}
                  />
                </Link>
              ) : (
                ""
              )}
            </td>
          </tr>
        </React.Fragment>
      </tbody>
    );
  };

  let dialogContentAndActions;
  if (selectedIndex === 1) {
    dialogContentAndActions = (
      <div>
      {editProductDialogOpen ? (
        <EditProductDialogComponent 
          onClose={() => setEditProductDialogOpen(false)}
          recommendations={supportYourSalon.recommendations}
          onSave={updateSupportYourSalon}
          onRemove={removeFromSupportYourSalon}
          productBeingEdited={productBeingEdited}
          salonId={salonId}
          />
      ) : (<></>)}
        <DialogContent style={{ margin: "auto" }}>

          
          <p><b>Recommended treatment</b></p>
          <NativeSelect id="treatment" value={newTreatment} onChange={(e) => {setNewTreatment(e.target.value)}}>
            <option value={"scalp"}>Scalp</option>
            <option value={"protein"}>Protein</option>
            <option value={"moisture"}>Moisture</option>
            <option value={"none"}>None</option>    
          </NativeSelect>

          {canUpdateTreatment ? (
            <Button 
              variant="outlined"
              onClick={() => handleSwitchTreatment(newTreatment)}
              style={{
                color: "black",
                fontSize: "0.8rem",
                borderRadius: "0px",
                position: "relative",
                marginLeft: "12px",
                padding: "2px 15px"
              }}
            >
              Save new treatment
            </Button>
          ) : (<></>)}
          <p><b>Recommended at-home haircare</b></p>
          <Table
            rowKey={() => ++uniqueId + ""}
            components={{ body: { wrapper: RetailBodyWrapper } }}
            columns={retailColumns}
            dataSource={supportYourSalon ? supportYourSalon.recommendations : []}
            pagination={{
              size: "small",
              simple: true,
              hideOnSinglePage: true,
              defaultPageSize: 6
            }}
          />
          
        </DialogContent>
       <DialogActions style={{ marginRight: "10px" }}>
         
          {props.appointment.status === "COMPLETED" ? (
            <>
              <div
                style={{
                  position: "relative",
                  right: "5px",
                  bottom: "10px"
                }}
              >
                <Button
                  variant="outlined"
                  onClick={handleCancelled}
                  style={{
                    color: "black",
                    fontSize: "0.8rem",
                    fontFamily: "BitstreamRoman",
                    borderRadius: "0px"
                  }}
                >
                  <FormattedMessage
                    id="button.cancelOrder"
                    defaultMessage={`Cancel Order`}
                  />
                </Button>
                <div
                  style={{
                    width: "5px",
                    height: "auto",
                    display: "inline-block"
                  }}
                />
                <Button
                  variant="outlined"
                  onClick={handleWaiting}
                  style={{
                    color: "white",
                    fontSize: "0.8rem",
                    fontFamily: "BitstreamRoman",
                    borderRadius: "0px",
                    backgroundColor: "#B18F58"
                  }}
                >
                  <FormattedMessage
                    id="button.reopenOrder"
                    defaultMessage={`Reopen Order`}
                  />
                </Button>
              </div>
            </>
          ) : props.appointment.status === "CANCELLED" ? (
            <>
              <Button
                variant="outlined"
                onClick={handleWaiting}
                style={{
                  color: "white",
                  fontSize: "0.8rem",
                  fontFamily: "BitstreamRoman",
                  borderRadius: "0px",
                  backgroundColor: "#B18F58",
                  position: "relative",
                  right: "10px",
                  bottom: "10px"
                }}
              >
                <FormattedMessage
                  id="button.reopenOrder"
                  defaultMessage={`Reopen Order`}
                />
              </Button>
            </>
          ) : (
            <>
              <div
                style={{
                  position: "relative",
                  right: "10px",
                  bottom: "10px"
                }}
              >
                {props.appointment.cartItems.length > 0 && (
                  <>
                <Button
                  variant="outlined"
                  onClick={handleCancelled}
                  style={{
                    color: "black",
                    fontSize: "0.8rem",
                    fontFamily: "BitstreamRoman",
                    borderRadius: "0px"
                  }}
                >
                  <FormattedMessage
                    id="button.cancelOrder"
                    defaultMessage={`Cancel Order`}
                  />
                </Button>
                <div
                  style={{
                    width: "5px",
                    height: "auto",
                    display: "inline-block"
                  }}
                />
                <Button
                  variant="outlined"
                  onClick={handleCompleted}
                  style={{
                    color: "white",
                    fontSize: "0.8rem",
                    fontFamily: "BitstreamRoman",
                    borderRadius: "0px",
                    backgroundColor: "#60786C"
                  }}
                >
                  <FormattedMessage
                    id="button.completeOrder"
                    defaultMessage={`Complete Order`}
                  />
                </Button>
                </>)}
              </div>
            </>
          )}
        </DialogActions>
      </div>
    );
  } else {
    dialogContentAndActions = (
      <div>
        <DialogContent style={{ margin: "auto", textTransform: "uppercase" }}>
          <br /> <br /> <br />
          <Table
            rowKey={() => ++uniqueId + ""}
            components={{ body: { wrapper: RebookBodyWrapper } }}
            columns={rebookColumns}
            dataSource={[props.appointment]}
            pagination={{
              size: "small",
              simple: true,
              hideOnSinglePage: true,
              defaultPageSize: 3
            }}
          />
        </DialogContent>
        <DialogActions style={{ marginRight: "10px" }}>
          {props.appointment.rebookStatus === "COMPLETED" ? (
            <>
              <Button
                variant="outlined"
                onClick={handleClearAppointmentRebooked}
                disabled={rebookNotificationSentAt != null}
                style={{
                  color: "white",
                  fontSize: "0.8rem",
                  fontFamily: "BitstreamRoman",
                  borderRadius: "0px",
                  backgroundColor: "#B18F58",
                  position: "relative",
                  right: "10px",
                  bottom: "10px"
                }}
              >
                <FormattedMessage
                  id="button.reopenbooking"
                  defaultMessage={`Reopen`}
                />
              </Button>
            </>
          ) : props.appointment.rebookStatus === "SCHEDULED" ? (
            <>
              <div
                style={{
                  borderTop: "1px solid #9E9D9E",
                  paddingTop: "20px",
                  textTransform: "uppercase",
                  fontSize: "0.5rem"
                }}
              >
                <br />

                <Button
                  variant="outlined"
                  onClick={handleDisableAppointmentReminder}
                  disabled={rebookNotificationSentAt != null}
                  style={{
                    color: "black",
                    fontSize: "0.8rem",
                    fontFamily: "BitstreamRoman",
                    borderRadius: "0px",
                    position: "relative",
                    right: "10px",
                    bottom: "10px"
                  }}
                >
                  <FormattedMessage
                    id="button.removeBookingReminder"
                    defaultMessage={`Remove Reminder`}
                  />
                </Button>
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  position: "relative",
                  right: "10px",
                  bottom: "10px"
                }}
              >
                <Button
                  variant="outlined"
                  onClick={handleEnableAppointmentReminder}
                  disabled={rebookNotificationSentAt != null}
                  style={{
                    color: "white",
                    fontSize: "0.8rem",
                    fontFamily: "BitstreamRoman",
                    borderRadius: "0px",
                    backgroundColor: "#2C3669"
                  }}
                >
                  <FormattedMessage
                    id="button.setupBookingReminder"
                    defaultMessage={`Setup Reminder`}
                  />
                </Button>
                <div
                  style={{
                    width: "5px",
                    height: "auto",
                    display: "inline-block"
                  }}
                />
                <Button
                  variant="outlined"
                  onClick={handleSetAppointmentRebooked}
                  disabled={rebookNotificationSentAt != null}
                  style={{
                    color: "white",
                    fontSize: "0.8rem",
                    fontFamily: "BitstreamRoman",
                    borderRadius: "0px",
                    backgroundColor: "#60786C"
                  }}
                >
                  <FormattedMessage
                    id="button.bookingRebooked"
                    defaultMessage={`Mark as Rebooked`}
                  />
                </Button>
              </div>
            </>
          )}
        </DialogActions>
      </div>
    );
  }
  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        style={{
          color: "black",
          fontSize: "0.7rem",
          fontFamily: "BitstreamRoman",
          borderRadius: "0px"
        }}
      >
        <FormattedMessage
          id="button.viewAppointment"
          defaultMessage={`View Details`}
        />
      </Button>      
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        fullWidth
        maxWidth="md"
        scroll={'paper'}
      >
        <Grid container spacing={1} style={{ width:'100%'}}>
          <Grid item xs={12}sm={4} style={{ backgroundColor: "#EFEFEF",overflow: "hidden" }}>
            <SideContainer>
              <List aria-label="appointment meta data">
                <ListItem>
                  <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <p style={{ textTransform: "uppercase", fontSize: "1rem" }}>
                      <FormattedMessage
                        id="appointment.title"
                        defaultMessage={`Appointment`}
                      />
                    </p>
                  </DialogTitle>
                </ListItem>
                <ListItem>
                  <p
                  >
                    <b>
                      <FormattedMessage
                        id="appointment.date"
                        defaultMessage={`Date`}
                      />
                    </b>
                  </p>
                  <p>
                    {dateFormatter.format(new Date(createdAt))} <br />
                  </p>
                  </ListItem>
                  <ListItem>
                  <p>
                    <b>
                      <FormattedMessage
                        id="appointment.client"
                        defaultMessage={`Client`}
                      />
                    </b>
                  </p>
                  <p>
                    {clientName} <br />
                  </p>
                  </ListItem>
                  <ListItem>
                  <p>
                    <b>
                      <FormattedMessage
                        id="appointment.stylist"
                        defaultMessage={`Stylist`}
                      />
                    </b>
                  </p>
                  <p>
                    {staffName} <br />
                  </p>
                </ListItem>
              </List>
              </SideContainer>
              <NavigationContainer>
              <List
                component="nav"
                aria-label="navigation"
                style={{
                  marginBottom: "10px",
                  marginTop: "-10px",
                  marginLeft: "10px"
                }}
              >
                <ListItem
                  button
                  selected={selectedIndex === 1}
                  onClick={event => handleListItemClick(event, 1)}
                >
                  <ListItemIcon>
                    {purchasedItems ? (
                      salonPickup ? (
                        <img src={pickup_btn} alt="logo" />
                      ) : (
                        <img src={delivery_btn} alt="logo" />
                      )
                    ) : (
                      supportYourSalon?.recommendations ? (
                        <img src={tick_btn} alt="logo" />
                      ) : (
                        <img src={na_btn} alt="logo" />
                      )
                    )}
                  </ListItemIcon>
                  <p
                    style={{
                      textTransform: "uppercase",
                      fontSize: "0.7rem",
                      fontWeight: "lighter"
                    }}
                  >
                    <FormattedMessage
                      id="appointment.retail"
                      defaultMessage={`Retail`}
                    />
                  </p>
                </ListItem>

                <ListItem
                  button
                  selected={selectedIndex === 2}
                  onClick={event => handleListItemClick(event, 2)}
                >
                  <ListItemIcon>
                    {rebookStatus === "COMPLETED" ? (
                      <img src={complete_btn} alt="logo" />
                    ) : rebookStatus === "SCHEDULED" ? (
                      <img src={scheduled_btn} alt="logo" />
                    ) : rebookStatus === "N/A" ? (
                      <img src={na_btn} alt="logo" />
                    ) : (
                      <img src={open_btn} alt="logo" />
                    )}
                  </ListItemIcon>
                  <p
                    style={{
                      textTransform: "uppercase",
                      fontSize: "0.7rem",
                      fontWeight: "lighter"
                    }}
                  >
                    <FormattedMessage
                      id="appointment.rebook"
                      defaultMessage={`Rebook`}
                    />
                  </p>
                </ListItem>

                <ListItem
                  button
                >
                  <Link
                    to={`/${salonId}/clients/${props.appointment.clientId}?appointmentId=${id}`}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                  <p
                    style={{
                      textTransform: "uppercase",
                      fontSize: "0.7rem",
                      fontWeight: "lighter"
                    }}
                  >
                    <FormattedMessage
                      id="appointment.clientRecords"
                      defaultMessage={`Go to client records`}
                    />
                  </p>
                  </Link>
                </ListItem>
              </List>
            </NavigationContainer>
          </Grid>
          <Grid item sm={8}>
            {dialogContentAndActions}
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
