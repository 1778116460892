import styled from "styled-components";
import { COLORS, SIZE, FONT } from "../../config";

interface iProps {
	disabled: boolean;
}
export const RadioRow = styled.button`
	width: calc(100% - ${SIZE.MEDIUM}px);
	height: 44px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background: transparent;
	padding: 12px;
	border: 0;
	color: ${COLORS.BLACK};
	outline: none;
	transition: background .2s ease;
	margin: 0 0 ${SIZE.SMALL}px;
	cursor: pointer;
	border-radius: 2px;

	span {
		font-weight: bold;
	}

	div,
	span {
		text-align: left;
		flex: 1;
		flex-direction: column;
		display: flex;
		font-size: ${FONT.SIZE.BODY};
	}
	.description span {
		font-size: ${FONT.SIZE.SMALL};
	}

	input {
		width: 24px;
		height: 24px;
		background: ${COLORS.GREY_MID_LIGHT};
		border: 0;
	}

	&:hover {
		background: rgba(0, 0, 0, 0.051);
	}
`;

export const Image = styled.img`
	width: 44px;
	height: 44px;
	margin-right: ${SIZE.MEDIUM}px;
	background: black;
	border-radius: ${SIZE.BORDER_RADIUS}px;
	border: 1px solid ${COLORS.BLACK};
`;
