import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { StaticContext } from "react-router";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAppointments, useClients } from "../../contexts/AdminContext";
import {
    startGetAllAppointments,
} from "../../actions/appointmentsActions";
import { startGetAllClients } from "../../actions/clientsActions"
import { Layout, ClientListItem, SearchInput, Notification, Fetching, H1 } from "../../components";
import { useLocalStorage } from "../../utils"
import { CACHE } from "../../config"
import { ClientsContainer, Loader, ClientList, Header, Sub, ListHeader, HeaderRight} from "./styles";
import mixpanel from 'mixpanel-browser';

interface IPathParams {
	salonId: string;
	appointmentId?: string;
}

interface IClient {
    id: string;
    name: string;
    stylist: string;
    stylistId: string;
    lastAppointment: string;
    appointments: any;
    portrait:string | null;
}

export default function ClientsScreen ({
    location,
	match,
	history
}: React.PropsWithChildren<
RouteComponentProps<IPathParams, StaticContext, any>
>) {
    const [appointmentsCache, setAppointmentsCache] = useLocalStorage(CACHE.APPOINTMENTS, []);
    const currentView : "CLIENT_LIST" | "CLIENT" = "CLIENT_LIST";
    const defaultAppointment: any = null;
    const [ state, dispatch ] = useClients();
    const [ appointmentState, appointmentDispatch ] = useAppointments();
	const { salonId } = match.params;
    const { clients } = state;
    const { loading, appointments } = appointmentState;
    const [ clientList, setClientList ] = useState() as any;
    const [ viewType, setViewType ] = useState() as any;
    const [ currentAppointment, setCurrentAppointment] = useState(defaultAppointment)

	useEffect(() => {
        // dispatch(startGetAllClients(salonId));
        /* TODO: Migrate to using the getClients API rather than Appointments filtering */
        appointmentDispatch(startGetAllAppointments(salonId));
        mixpanel.track("PMA Client Records - Page accessed");
    }, []);

    

    useEffect(() => {
        document.title = "Clients";
	}, []);

	useEffect(() => {
        // filterClients();
        // console.log('CLIENTS _', clients)
	}, [clients]);

    useEffect(() => {
        if(appointments.length) setAppointmentsCache(appointments)
    }, [appointments]);
    
    useEffect(() => {
        if(appointmentsCache.length) filterClients();
	}, [appointmentsCache]);

    const filterClients = () => {
        const clientList: IClient[] = [];
        const clientIds: string[] = [];
        
        setViewType(currentView);
        appointmentsCache.map(appt => {
           if (!appt.clientId) appt.clientId = 'none'
            const clientModel:IClient = {
                id: appt.clientId,
                name: appt.clientName,
                stylist: appt.staffName,
                stylistId: appt.staffId,
                lastAppointment: appt.createdAt,
                portrait: appt.clientSelfieImage,
                appointments: [appt]
            }
            /* Create the client record */
            if(!clientIds.includes(appt.clientId) && appt.clientId) {
                clientIds.push(appt.clientId)
                clientList.push(clientModel)
            }
            /* Update existing Client record */
            else {
                for (let i in clientList) {
                    if (clientList[i].id === appt.clientId) {
                        clientList[i] = {
                            ...clientModel,
                            appointments: [...clientList[i].appointments, appt]
                        }
                       break; //Stop this loop, record found.
                    }
                  }
            }
        })
        clientList.sort((a,b) => {
            let _a = new Date(a.lastAppointment);
            let _b = new Date(b.lastAppointment);
            if (_a < _b) return -1;
            return 1;
        })
        setClientList(clientList);
    }

    const onClientPress = (client: IClient) => {
        history.push(`/${salonId}/clients/${client.id}`);
    }

	return (
		<Layout>
            <Notification />
			<ClientsContainer>
				<Header viewType={viewType} appointmentActive={currentAppointment}>
                    <H1>{"Client records"}</H1>
                    <SearchInput 
                        disabled={loading}
                        data={clientList}
                        searchParam={'name'}
                        inputLabel={"Search for client"}
                        onSelect={onClientPress}
                    />
                </Header>
                <Sub>{<div>{clientList ? clientList.length : 0} Clients</div> }</Sub>
                <ClientList viewType={viewType} appointmentActive={currentAppointment}>
				{(clientList && clientList.length > 0) ? (<>
                   <>
                   <ListHeader>
                        {/* <div>#</div> */}
                        <div>Client Name</div>
                        <HeaderRight>
                            <div>Visits</div>
                            <div>Last Appointment</div>
                            <div>Last Stylist</div>
                        </HeaderRight>
                    </ListHeader>
                    { clientList.slice(0).reverse().map((client, i) => <ClientListItem 
                            key={client.id} 
                            id={client.id}
                            name={client.name}
                            index={i + 1}
                            portrait={client.portrait}
                            appointments={client.appointments.length}
                            lastAppointment={client.lastAppointment}
                            stylist={client.stylist}
                            onPress={() => onClientPress(client)}
                            viewType={viewType}
                        />) 
                    }
                    </>
				</>) : loading ?
                <Loader>
					<CircularProgress/>
				</Loader> :
                <Loader>
                     <div>No client history</div>
                </Loader>
                }
                </ClientList>
                
			</ClientsContainer>
            <Fetching />
		</Layout>
	);
}
