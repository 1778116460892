import styled from "styled-components";
import { SIZE, COLORS, BREAKPOINTS } from "../../../../config";
// import hairgirl from "../../../../assets/Images/hairgirl.jpg";

interface iPlanButton {
	active: boolean;
}
export const BlockRow = styled.div`
	display: flex;
	flex-direction: row;

	button:nth-child(1) {
		margin-right: ${SIZE.SMALL}px;
	}
	button:nth-child(2) {
		margin-left: ${SIZE.SMALL}px;
	}

	@media (max-width: 600px) {
		flex-direction: column;

		button:nth-child(1) {
			margin-right: 0px;
		}
		button:nth-child(2) {
			margin-left: 0px;
		}
	}
`;
export const CounterRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin: ${SIZE.XLARGE}px 0;
	width: fit-content;

	h2 {
		margin: 0!important;
	}
	.counter-row-label {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-right: 40px; 
	}
`;

export const OtherLabel = styled.div`
	padding: ${SIZE.LARGE}px;
	background: ${COLORS.BLUE_MID};
	color: ${COLORS.BLUE_DARK};
	text-align: center;
	border-radius: ${SIZE.BORDER_RADIUS}px;
`;

export const Footer = styled.div`
	height: 100px;
	width: 100%;
`;

export const PlanButton = styled.div`
	margin: 1%;
	cursor: pointer;
	width: 48%;
	padding: 20px;
	border: ${(props: iPlanButton) =>
		props.active ? `1px solid grey` : `1px solid white`}
	box-shadow: ${(props: iPlanButton) =>
		props.active ? `4px 4px 4px whitesmoke` : `none`}
	box-sizing: border-box!important;

	background: ${(props: iPlanButton) =>
		props.active ? `white` : `whitesmoke`}

	ul {
		padding-left: 20px;
	}
	
	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		width: 98%;
	}
`;

export const PlanHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	h3 {
		font-size: 24px;
		line-height: 30px;
	}
	h3, p {
		margin: 0;
	}

`

export const PaymentFreqButton = styled.div`
	margin: 1%;
	cursor: pointer;
	width: 48%;
	padding: 20px;
	box-sizing: border-box!important;
	border: ${(props: iPlanButton) =>
		props.active ? `1px solid grey` : `1px solid white`}
	box-shadow: ${(props: iPlanButton) =>
		props.active ? `4px 4px 4px whitesmoke` : `none`}

	background: ${(props: iPlanButton) =>
		props.active ? `white` : `whitesmoke`}

	@media (min-width: ${BREAKPOINTS.DEKSTOP}px) {
		width: 23%;
	}

	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		width: 98%;
	}
`;

export const ComingSoon = styled.div`
	display: inline-block;
	border-radius: 4px;
	color: #7b5000;
    background: #ffcb6c;
    padding: 4px 6px;
    margin-top: 14px;
`;

export const AvailableNow = styled.div`
	display: inline-block;
	border-radius: 4px;
	color: #007b00;
    background: #b3ffa1;
    padding: 4px 6px;
    margin-top: 14px;
`;

export const MostPopular = styled.div`
	display: inline-block;
	border-radius: 4px;
	color: #645f00;
    background: #fff64e;
    padding: 4px 6px;
    margin-top: 14px;
`;


export const GirlBackground = styled.div`
`
