import React, { useState } from "react";
// Material UI
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { FormattedMessage } from "react-intl";
//Dialogs
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputAdornment from "@material-ui/core/InputAdornment";
import { VisibilityOff, Visibility } from "@material-ui/icons";
//Authentication for Password Change
import Auth from "@aws-amplify/auth";
import mixpanel from 'mixpanel-browser';

export interface IPasswordComponentProps {
  title: string;
  buttonText: string;
  description?: string;
}

interface State {
  showCurrentPassword: boolean;
  showNewPassword: boolean;
  showPassword: boolean;
  newPassword: string;
  verifyPassword: string;
  currentPassword: string;
  // message: string;
  error: string;
  success: boolean;
}

export default function PasswordComponent(props: IPasswordComponentProps) {
  const { title, buttonText, description } = props;
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState<State>({
    newPassword: "",
    verifyPassword: "",
    currentPassword: "",
    showPassword: false,
    showCurrentPassword: false,
    showNewPassword: false,
    error: "",
    success: false,
  });

  const handleChange = (prop: keyof State) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickShowCurrentPassword = () => {
    setValues({ ...values, showCurrentPassword: !values.showCurrentPassword });
  };

  const handleClickShowNewPassword = () => {
    setValues({ ...values, showNewPassword: !values.showNewPassword });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleSavePassword = async (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const { currentPassword, newPassword, verifyPassword } = values;
    if (currentPassword === "" && newPassword === "" && verifyPassword === "") {
      setValues({ ...values, error: "All the fields are empty !!!" });
      return;
    }

    if (currentPassword === "") {
      setValues({ ...values, error: " Please enter your current password." });
      return;
    }
    if (newPassword === "") {
      setValues({ ...values, error: "Please enter a new password." });
      return;
    }

    if (verifyPassword === "") {
      setValues({ ...values, error: "Please enter a verify password." });
      return;
    }
    if (newPassword !== verifyPassword) {
      setValues({
        ...values,
        error: "New Password and Verify Password don't match."
      });
      return;
    }

    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, currentPassword, newPassword);
      setValues({
        ...values,
        error: "Your password has been changed. Thank you",
        success: true
      });
      mixpanel.track("PMA Password changed");
    } catch (e) {
      if (e.message) {
        setValues({ ...values, error: e.message });
      } else {
        setValues({ ...values, error: e });
      }
    }
  };

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setValues({
      ...values,
      newPassword: "",
      verifyPassword: "",
      currentPassword: ""
    });
    setOpen(false);
  }

  return (
    <>
      <h2>
          <FormattedMessage id={`form.${title}`} defaultMessage={`${title}`}/ > 
      </h2>
        
        <Grid
          container={true}
          direction="column"
        >
            <Grid item={true} xs={12} sm={12} md={12}>
              {description && (
                <Typography gutterBottom>
                  <p>
                    <FormattedMessage
                      id={`form.${description}`}
                      defaultMessage={description}
                    />
                  </p>
                </Typography>
              )}
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12}>
            <Button
              variant="outlined"
              size="small"
              color="default"
              style={{
                width: "100%",
                fontFamily: "BitstreamRoman",
                color: "black",
                borderRadius: "0px",
                maxWidth: 250
              }}
              onClick={handleClickOpen}
            >
              <FormattedMessage
                id={`button.${buttonText}`}
                defaultMessage={buttonText}
              />
            </Button>
            
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
              id="one"

            >
              <Typography>
                <IconButton
                  aria-label="Close"
                  style={{
                    position: "relative",
                    top: "spacing(1)",
                    left: "spacing(1)"
                  }}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              </Typography>
              
              {values.success ? (
              <>
                <DialogTitle id="form-dialog-title">
                  <FormattedMessage
                    id={`button.${buttonText}`}
                    defaultMessage={'Success'}
                  />
                </DialogTitle>
                <DialogContent>
                  <Typography gutterBottom>
                    <p>
                      <FormattedMessage
                        id={`form.${description}`}
                        defaultMessage={values.error}
                      />
                    </p>
                  </Typography>
                </DialogContent>
                <DialogActions style={{ justifyContent: "center", paddingBottom: 16 }}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={handleClose}
                    color="primary"
                    style={{
                      backgroundColor: "black",
                      color: "white",
                      width: "45%",
                      fontFamily: "BitstreamRoman",
                      borderRadius: "0px",
                      maxWidth: 250
                    }}
                  >
                    <FormattedMessage
                      id="button.cancel"
                      defaultMessage={`Close`}
                    />
                  </Button>
              </DialogActions>
              </> ) : (
              <>
              <DialogTitle id="form-dialog-title">
                <FormattedMessage
                  id={`button.${buttonText}`}
                  defaultMessage={buttonText}
                />
              </DialogTitle>
              <DialogContent>
                <Typography style={{ marginBottom: "20px", color: "primary" }}>
                  {values.error}
                </Typography>
                <Grid item={true} xs={12} sm={12} md={12}>
                  <TextField
                    id="current-password"
                    variant="outlined"
                    type={values.showCurrentPassword ? "text" : "password"}
                    label="Current Password"
                    value={values.currentPassword}
                    style={{ minWidth: "400px", marginBottom: "10px" }}
                    onChange={handleChange("currentPassword")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="toggle password visibility"
                            onClick={handleClickShowCurrentPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {values.showCurrentPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={12} md={12}>
                  <TextField
                    id="new-password"
                    variant="outlined"
                    type={values.showNewPassword ? "text" : "password"}
                    label="New Password"
                    value={values.newPassword}
                    style={{
                      minWidth: "400px",
                      marginBottom: "10px"
                    }}
                    onChange={handleChange("newPassword")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="toggle password visibility"
                            onClick={handleClickShowNewPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {values.showNewPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={12} md={12}>
                  <TextField
                    id="verify-password"
                    variant="outlined"
                    type={values.showPassword ? "text" : "password"}
                    label="Verify Password"
                    value={values.verifyPassword}
                    style={{ minWidth: "400px", borderRadius: "0px" }}
                    onChange={handleChange("verifyPassword")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {values.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </DialogContent>
              <DialogActions style={{ justifyContent: "center", paddingBottom: 16 }}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleClose}
                  color="primary"
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    width: "45%",
                    fontFamily: "BitstreamRoman",
                    borderRadius: "0px",
                    maxWidth: 250
                  }}
                >
                  <FormattedMessage
                    id="button.cancel"
                    defaultMessage={`Cancel`}
                  />
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleSavePassword}
                  color="primary"
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    width: "45%",
                    fontFamily: "BitstreamRoman",
                    borderRadius: "0px",
                    maxWidth: 250
                  }}
                >
                  <FormattedMessage
                    id="button.savepassword"
                    defaultMessage={`Save Password`}
                  />
                </Button>
              </DialogActions>
              </>
              )}
            </Dialog>
          </Grid>

        </Grid>
    </>
  );
}
