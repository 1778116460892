import React, { useEffect, useState } from "react";
import { useAppointments } from "../../contexts/AdminContext";
import { RouteComponentProps } from "react-router-dom";
import { StaticContext } from "react-router";
import AppointmentNavbar from "./AppointmentNavbar";
import { startGetAllAppointments } from "../../actions/appointmentsActions";
import { FormattedMessage } from "react-intl";
import Loading from "../../components/LoadingComponent";
import { Notification, Layout, Fetching } from "../../components";
import { Table } from "antd";
import { IAppointment } from "../../reducers/AppointmentsReducer";
import open_btn from "../../assets/Images/open_btn.png";
import complete_btn from "../../assets/Images/complete_btn.png";
import scheduled_btn from "../../assets/Images/scheduled_btn.png";
import delivery_btn from "../../assets/Images/delivery_btn.png";
import pickup_btn from "../../assets/Images/pickup_btn.png";
import { AppointmentsContainer, AppointmentMobile, AppointmentMobileRow } from "./styles";
import na_btn from "../../assets/Images/na_btn.png";
import tick_btn from "../../assets/Images/tick_btn.png";
import Button from "@material-ui/core/Button";

import { Link } from "react-router-dom";
import AppointmentDialogs from "./AppointmentDialogue";
import "../../css/antd-table.css";
import { useLocalStorage } from "../../utils";
import { CACHE } from "../../config";
import mixpanel from 'mixpanel-browser';


interface IPathParams {
	salonId: string;
	appointmentId?: string;
}
interface ITokens {
	usedToken: string[];
}

export default function AppointmentsScreen ({
	location,
	match,
	history
}: React.PropsWithChildren<
	RouteComponentProps<IPathParams, StaticContext, any>
>) {
	const [ appointmentsCache, setAppointmentsCache ] = useLocalStorage(
		CACHE.APPOINTMENTS,
		[]
	);
	const [ state, dispatch ] = useAppointments();
	const { salonId } = match.params;
	const { appointments } = state;
	const { nextToken } = state;
	const [ prevToken, setPrevToken ] = useState() as any;
	const [ usedTokens, setUsedTokens ] = useState<ITokens["usedToken"]>([]);
	const [ modalVisible, setModalVisible ] = useState(true);
	let uniqueId = 0;

	interface ISorter {
		columnKey: string;
	}
	interface IFilter {
		status?: any;
		rebookStatus?: any;
		staffName?: any;
	}

	const [ filteredInfo, setFilteredInfo ] = useState<IFilter>({
		status: [],
		rebookStatus: [],
		staffName: []
	});

	const params = new URLSearchParams(location.search);
	const navParam = params.get("navParam") || "ALLTIME";

	//Date formatter
	const dateFormatter = new Intl.DateTimeFormat("en-AU", {
		day: "numeric",
		month: "short",
		year: "numeric",
		hour: "numeric",
		minute: "numeric"
	});

	let date = new Date();
	const weekDate = date.setDate(date.getDate() - 7);
	let weekDateTop = weekDate - weekDate % 86400000;
	weekDateTop += new Date().getTimezoneOffset() * 60 * 1000;

	let date1 = new Date();
	const yesterDate = date1.setDate(date1.getDate() - 1);
	let yesterDateTop = yesterDate - yesterDate % 86400000;
	yesterDateTop += new Date().getTimezoneOffset() * 60 * 1000;

	const todayDate = new Date().getTime();
	let todayDateTop = todayDate - todayDate % 86400000;
	todayDateTop += new Date().getTimezoneOffset() * 60 * 1000;
	
	const filteredAppointments =
		navParam === "ALLTIME"
			? appointmentsCache.sort((a,b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
			: navParam === "THISWEEK"
				? appointmentsCache.filter(appointment => {
						return Date.parse(appointment.createdAt) >= weekDateTop;
					})
				: navParam === "YESTERDAY"
					? appointmentsCache.filter(appointment => {
							return (
								Date.parse(appointment.createdAt) >= yesterDateTop &&
								Date.parse(appointment.createdAt) < todayDateTop
							);
						})
					: appointmentsCache.filter(appointment => {
							return Date.parse(appointment.createdAt) >= todayDateTop;
						});

	useEffect(() => {
		uniqueId = 0;
		dispatch(startGetAllAppointments(salonId));
		document.title = "Appointments";
		mixpanel.track("PMA Client sessions - Page accessed");
	}, []);


	useEffect(
		() => {
			if (appointments.length) setAppointmentsCache(appointments);
		},
		[ appointments ]
	);

	useEffect(
		() => {
			if (appointments.length > 0) {
				if (prevToken !== nextToken) {
					setPrevToken(nextToken);
					const used: string[] = [ ...usedTokens ];
					// Fetch more if we havn't already used the nextToken
					if (!(usedTokens.indexOf(nextToken) > -1)) {
						used.push(nextToken);
						setUsedTokens(used);
						dispatch(startGetAllAppointments(salonId, undefined, nextToken));
					}
				}
			}
		},
		[ appointments ]
	);

	if ((state.loading && !appointmentsCache.length) || !appointmentsCache) {
		return <Loading />;
	}
	const handleChange = (pagination: any, filters: any, sorter: any) => {
		setFilteredInfo(filters);
	};

	const checkAppointmentsCache = () => {
		
		console.log('appointments2', appointments)
		console.log('appointmentsCache2', appointmentsCache)
		console.log(match.params.appointmentId);
		console.log(appointments.filter((a) => a.id === match.params.appointmentId))
		console.log(modalVisible)
	}


	//Get the unique staff to display in the filter popup.
	const uniqueStaff = [
		...new Set(
			appointmentsCache
				.map(appt => appt.staffName)
				.filter(staffName => staffName !== null || staffName !== undefined)
		)
	];

	const uniqueStaffArrObj: any = [];

	for (let staffName of uniqueStaff) {
		uniqueStaffArrObj.push({
			text: staffName,
			value: staffName
		});
	}
	//Ant design UI library is used for the table as it has excellent sorting, filtering features.
	const columns = [
		{
			render: (record: any) => {
				return (<AppointmentMobile>
					<div style={{width: '35%'}}>
						{dateFormatter.format(new Date(record.createdAt))}
						<br /><br />
						Client: {record.clientName}
						<br />
						Staff: {record.staffName}
					</div>
					<div style={{width: '55%'}}>
						<AppointmentMobileRow>
							<span>Treatment:</span> 
							<span>
								{record.supportYourSalon?.treatment ? record.supportYourSalon.treatment : '-' }
							</span>
						</AppointmentMobileRow>
						<AppointmentMobileRow>
							<span>Retail: </span>
							<span>
								{record.purchasedItems ? (
									record.salonPickup ? (
										<img src={pickup_btn} alt="logo" />
									) : (
										<img src={delivery_btn} alt="logo" />
									)
								) : (
									record.supportYourSalon?.recommendations ? (
										<img src={tick_btn} alt="logo" />
									) : (
										<img src={na_btn} alt="logo" />
									)
								)}
							</span>
						</AppointmentMobileRow>
						<AppointmentMobileRow>
							<span>
								Rebook: 
							</span>
							<span>
								{record.rebookStatus === "COMPLETED" ? (
									<img src={complete_btn} alt="logo" />
								) : record.rebookStatus === "SCHEDULED" ? (
									<img src={scheduled_btn} alt="logo" />
								) : record.rebookStatus === "OPEN" ? (
									<img src={open_btn} alt="logo" />
								) : (
									<img src={na_btn} alt="logo" />
								)}
							</span>
						</AppointmentMobileRow>
						<Link
							to={`/${match.params
								.salonId}/appointments/${record.id}${location.search}`}
							style={{ textDecoration: "none" }}>
							<AppointmentDialogs
								appointment={record}
								salonId={salonId}
								open={match.params.appointmentId === record.id && modalVisible}
								onOpen={() => {
									history.push(
										`/${match.params.salonId}/appointments/${record.id}`
									);
									setModalVisible(true);
								}}
								onClose={() => {
									history.push(`/${match.params.salonId}/appointments`);
									setModalVisible(false);
								}}
							/>{" "}
						</Link>
					</div>
				</AppointmentMobile>
			)},
			responsive: ['xs']
		},{
			title: (
				<FormattedMessage
					id="appointment.appointmentDate"
					defaultMessage={`time/date`}
				/>
			),
			dataIndex: "createdAt",
			key: "createdAt",
			sorter: (a: IAppointment, b: IAppointment) =>
				Date.parse(a.createdAt) - Date.parse(b.createdAt),
			ellipsis: true,
			render: (createdAt: any) => dateFormatter.format(new Date(createdAt)),
			responsive: ['sm']
		},
		{
			title: (
				<FormattedMessage
					id="appointment.clientName"
					defaultMessage={`Client Name`}
				/>
			),
			dataIndex: "clientName",
			key: "clientName",
			sorter: (a: IAppointment, b: IAppointment) =>
				a.clientName.length - b.clientName.length,
			defaultSortOrder: 'descend',
			ellipsis: true,
			responsive: ['sm']
		},
		{
			title: (
				<FormattedMessage id="appointment.stylist" defaultMessage={`Stylist`} />
			),
			dataIndex: "staffName",
			key: "staffName",
			filters: uniqueStaffArrObj,
			filteredValue: filteredInfo.staffName || null,
			onFilter: (value: any, record: IAppointment) =>
				record.staffName.includes(value),
			ellipsis: true,
			responsive: ['sm']
		},
		{
			title: (
				<FormattedMessage id="appointment.treatment" defaultMessage={`Treatment`} />
			),
			dataIndex: "treatment",
			key: "treatment",
			filteredValue: filteredInfo.status || null,
			onFilter: (value: any, record: IAppointment) => {
				return record.supportYourSalon.treatment === value;
			},
			ellipsis: true,
			render: (supportYourSalon: any, record: IAppointment) => {
				return record.supportYourSalon?.treatment ? record.supportYourSalon?.treatment : '-'
			},
			responsive: ['sm']
		},{
			title: (
				<FormattedMessage id="appointment.retail" defaultMessage={`Retail`} />
			),
			dataIndex: "purchasedItems",
			key: "purchasedItems",
			filteredValue: filteredInfo.status || null,
			onFilter: (value: any, record: IAppointment) => {
				return record.purchasedItems.includes(value);
			},
			ellipsis: true,
			render: (purchasedItems: any, record: IAppointment) => {
				return purchasedItems ? (
					record.salonPickup ? (
						<img src={pickup_btn} alt="logo" />
					) : (
						<img src={delivery_btn} alt="logo" />
					)
				) : (
					record.supportYourSalon?.recommendations ? (
						<img src={tick_btn} alt="logo" />
					) : (
						<img src={na_btn} alt="logo" />
					)
					
				);
			},
			responsive: ['sm']
		},
		{
			title: (
				<FormattedMessage id="appointment.rebook" defaultMessage={`Rebook`} />
			),
			dataIndex: "rebookStatus",
			key: "rebookStatus",
			filters: [
				{ text: "Open", value: "OPEN" },
				{ text: "Complete", value: "COMPLETED" },
				{ text: "Scheduled", value: "SCHEDULED" }
			],
			filteredValue: filteredInfo.rebookStatus || null,
			onFilter: (value: any, record: IAppointment) =>
				record.rebookStatus.includes(value),
			ellipsis: true,
			render: (rebookStatus: any) => {
				return rebookStatus === "COMPLETED" ? (
					<img src={complete_btn} alt="logo" />
				) : rebookStatus === "SCHEDULED" ? (
					<img src={scheduled_btn} alt="logo" />
				) : rebookStatus === "OPEN" ? (
					<img src={open_btn} alt="logo" />
				) : (
					<img src={na_btn} alt="logo" />
				);
			},
			responsive: ['sm']
		},
		{
			title: "Details",
			key: "id",
			render: (appointment: IAppointment) => (
				<Link
					to={`/${match.params
						.salonId}/appointments/${appointment.id}${location.search}`}
					style={{ textDecoration: "none" }}>
					<AppointmentDialogs
						appointment={appointment}
						salonId={salonId}
						open={match.params.appointmentId === appointment.id && modalVisible}
						onOpen={() => {
							history.push(
								`/${match.params.salonId}/appointments/${appointment.id}`
							);
							setModalVisible(true);
						}}
						onClose={() => {
							history.push(`/${match.params.salonId}/appointments`);
							setModalVisible(false);
						}}
					/>{" "}
				</Link>
			),
			responsive: ['sm']
		}
	];

	return (
		<Layout>
			<Notification />
			<AppointmentsContainer>
				<AppointmentNavbar
					name={navParam}
					onChange={(path: string) => {
						history.push(`/${match.params.salonId}/${path}`);
					}}
				/>
				<br />

				<div>
					<Button onClick={() => checkAppointmentsCache()}>
						Cache
					</Button>
					{/* OPEN DIALOG IF PARAMS */}
					{match.params.appointmentId && appointmentsCache.length > 0 && (
					<AppointmentDialogs
						appointment={appointmentsCache.filter((a) => a.id === match.params.appointmentId)[0]}
						salonId={salonId}
						open={modalVisible}
						onOpen={() => {
							history.push(
								`/${match.params.salonId}/appointments/${match.params.appointmentId}`
							);
							setModalVisible(true);
						}}
						onClose={() => {
							history.push(`/${match.params.salonId}/appointments`);
							setModalVisible(false);
						}}
					/>
					)}
					<Table
						rowKey={() => ++uniqueId + ""}
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment     
						// @ts-ignore
						columns={columns}
						dataSource={filteredAppointments}
						onChange={(pagination, filters, sorter) => {handleChange(pagination, filters, sorter)}}
						pagination={{
							size: "small",
							simple: true,
							hideOnSinglePage: true,
							defaultPageSize: 6
						}}
					/>
				</div>
			</AppointmentsContainer>
			<Fetching />
		</Layout>
	);
}
