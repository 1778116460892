import styled from "styled-components";
import { SIZE, FONT, COLORS } from "../../../../config";

export const Container = styled.div`
	width: 100%;
	height: calc(100% - ${130 + SIZE.LARGE}px);
	margin: 0;
	padding-top: 130px;
	overflow: scroll;
	padding-bottom: ${SIZE.LARGE}px;
`;
export const Label = styled.div`
	font-size: ${FONT.SIZE.BODY};
	margin: ${SIZE.MEDIUM}px 0 ${SIZE.SMALL}px;
`;
export const InputCounter = styled.div`
	min-width: 140px;
	height: 34px;
	background: ${COLORS.GREY_MID_LIGHT};
	padding: ${SIZE.SMALL}px;
	padding-left: 20px;
	border-radius: ${SIZE.BORDER_RADIUS}px;
	display: inline-flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	position: relative;

	.day-number {
		padding-right: ${SIZE.TINY}px;
	}
	.counter-controls {
		display: flex;
		flex-direction: column;
		width: 32px;
		justify-content: center;
		position: absolute;
		right: 0;
	}
`;
export const Error = styled.div`
	padding: ${SIZE.MEDIUM}px 0 ${SIZE.LARGE}px;
	color: tomato;
`;
export const Loader = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: ${SIZE.LARGE}px 0 ${SIZE.MEDIUM}px;
	height: 40px;
	div {
		width: 30px !important;
		height: 30px !important;
	}
	svg {
		color: black;
	}
`;
