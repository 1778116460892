import React, { Component } from "react";
import Auth from "@aws-amplify/auth";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api/lib/types";
import { TextField, Typography, Grid } from "@material-ui/core";
import { ReactComponent as Logo } from "../../../assets/Images/logo.svg";
import { CSSTransition } from 'react-transition-group';

// component
import "../style.css";
import Button from "@material-ui/core/Button";
import { withRouter, Link, RouteComponentProps } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import { FormattedMessage } from "react-intl";
import DownloadPlaystore from "../../../assets/Images/download_playstore.png";
import DownloadAppstore from "../../../assets/Images/download_appstore.png";

//types
interface Props {
  toggleView(view: string): void;
  setCurrentAppointmentId(appointmentId: string): void;
  setCurrentSalonId(salonId: string): void;
  sessionId: string;
  animateInProp: boolean;
  userEmail: string;
}

interface IState {
  animateIn: string;
  loading: string;
}

export default class StartSession extends Component<Props, IState> {
  state = {
    loading: 'true',
    animateIn: 'false'
  };
  componentDidMount = () => {
    this.setState({ loading: 'false', animateIn : this.props.animateInProp ? 'true' : 'false' })
  }

  start = async () => {
    this.setState({ loading : 'true' })

      // THEN START THE SESSION
      const StartSession = `mutation StartSession($id: ID!, $input: UpdateSessionInput!){
        startSession(id: $id, input: $input){
            id
            userId
            salonId
            appointmentId
            existingAppointment
        }
      }
      `;

      // NEED TO GET SESSION ID FROM URL
      let sessionId = this.props.sessionId;

      // START NEW SESSION WITH AUTHENTICATED USER?
      try {
        const { data }: GraphQLResult | any = await API.graphql(
          graphqlOperation(StartSession, {
            id: sessionId,
            input: {
              devicePlatform:"ANDROID",
              deviceToken:"noToken",
            }
          })
        );
        console.log("new session data -", data);
        this.setState({ loading : 'false' })
        this.props.setCurrentAppointmentId(data.startSession.appointmentId);
        this.props.setCurrentSalonId(data.startSession.salonId);
        this.props.toggleView('success');
      } catch (e) {
        console.log(e);
      }
    this.props.toggleView('success');
  }

  signOut = async () => {
    let r = await Auth.signOut();
    this.props.toggleView('landing')
    window.location.reload() 
  }
  
  
  render() {
    const { toggleView, userEmail } = this.props;
    return (
      <>
      <CSSTransition
        in={this.state.animateIn === 'true'}
        timeout={300}
        classNames="opacity">
      <Grid container direction="column" spacing={10} alignItems="stretch">
        <Grid item>
          <Grid container direction="row" justify="space-between">
            <Grid item>
              <Logo width={95} style={{marginTop: 20}}/>
            </Grid>
            <Grid item>
              <Link to={"/signup?session=" + this.props.sessionId} style={{color:'white', textDecoration: 'none'}}>
                <ListItem button onClick={this.signOut} style={{marginTop: 20, padding: 0, color: "white", fontSize: 24, textTransform: "uppercase", textDecoration: 'none'}}>
                  <FormattedMessage id="menu.signout" defaultMessage={`LOG OUT`} />
                </ListItem>
              </Link>
            </Grid>
          </Grid>
          <h1 id="title">
            <FormattedMessage id="startSession.title" defaultMessage={`Welcome back`} />
          </h1>
          <p>You're currently logged in with account <strong>{userEmail}</strong></p>
          <button
            type="button"
            style={{ background:"#FFF", border: 0,width: "100%", borderRadius: 0, height: 60, textTransform: 'none', fontSize: 16 }}
            onClick={this.start}
            disabled={this.state.loading === 'true'}>
              {this.state.loading === 'true' ? (<>Loading...</>):(<>Log me into the mirror</>)}
          </button>

          <hr style={{margin: "35px 0", opacity: 0.4}}></hr>
            <p style={{marginBottom: 24}}>Download the piiq app to access your personalised haircare recommendations, celeb match, 360° records and more…</p>
            <a href="http://apps.apple.com/au/app/piiq/id1458062799" target="_blank">
              <img
                src={DownloadAppstore}
                style={{
                  width: "48%",
                  height: "auto",
                  marginRight: '2%'
                }}
              />
            </a>
            <a href="http://play.google.com/store/apps/details?id=com.piiq&hl=en&gl=US" target="_blank">
              <img
                src={DownloadPlaystore}
                style={{
                  width: "48%",
                  height: "auto"
                }}
              />
            </a>
        </Grid>
      </Grid>
      </CSSTransition>
      </>
    );
  }
}
