import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { StaticContext } from "react-router";
import { Notification, Layout, Input, H1} from "../../components";
import { Container, Loader, AccordionContainer,MarginLeftDiv,  EmailInputContainer, Lessons, Lesson, SectionHeader, AccordionHeading, AccordionDescription, Graphic, Ball, LineAbove, LineBelow } from './styles'
import { GetIBBLessons, SendUserPiiqMail } from "../../utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SubscriptionContext } from "../../contexts/SubscriptionContext";
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {Button} from '@material-ui/core';

export default function IBBScreen ({
    location,
	match,
	history
}: React.PropsWithChildren<
RouteComponentProps<any, StaticContext, any>
>) {
    
    const [ loading, setLoading ] = useState(true);
    const [ expandedModule, setExpandedModule ] = useState(0);
    const [ modules, setModules ] = useState([] as any[]);
    const [ ibbLessons, setIbbLessons ] = useState([]);
    const [ completedLessons, setCompletedLessons ] = useState([]);
    const [ email, setEmail ] = useState('') as any;
    const [ emailInviteSent, setEmailInviteSent ] = useState(false) as any;
    const [ emailInputVisible, setEmailInputVisible ] = useState(true);
    const [ emailInputValue, setEmailInputValue ] = useState('') as any;
    const { salonId } = match.params;
    const MODULE_NAME_DICT = {
        1: "Intro & Quick Start Guide",
        2: "Fundamentals",
        3: "Advanced",
        4: "Hot Tips and Extras",
        5: "Bonus - Hot tips & extras"
    }

    useEffect(() => {
		if (salonId) {
            document.title = "IBB - Salon";
            localStorage.setItem('IBBemail','')
        } else {
            document.title = "IBB - Guest";
        }

        const params = new URLSearchParams(location.search);
        const emailParam = params.get("email") || localStorage.getItem('IBBemail');
        console.log('emailParam', emailParam)
        console.log("localStorage.getItem('IBBemail')", localStorage.getItem('IBBemail'))
        if ( emailParam ) {
            localStorage.setItem('IBBemail', emailParam)
            setEmail(emailParam);
            setEmailInputVisible(false);
            setEmailInputValue(emailParam);
        }
        if (email !== '') {
            setEmailInputVisible(false);
        }
        
        console.log('getting...')
        let moduleNumber = new URLSearchParams(window.location.search).get("moduleNumber")
        if (moduleNumber) setExpandedModule(parseInt(moduleNumber))
        get(emailParam ? emailParam : '');

	}, [])
    
    const Accordion = withStyles({
        root: {
            boxShadow: 'none',
            padding: "0 24px",
            margin: "0 0 12px 0",
            border: '1px solid rgba(0, 0, 0, .125)',
            '&$expanded': {
                margin: "0 0 12px 0"
            },
        },
        expanded: {
            
        },
    })(MuiAccordion);
    
    const AccordionSummary = withStyles({
        root: {
            backgroundColor: 'transparent',
            padding: 0,
            marginBottom: -1,
            minHeight: 56,
            '&$expanded': {
                minHeight: 56,
            },
        },
        content: {
            display:'block',
            '&$expanded': {
                margin: '12px 0',
            },
        },
        expanded: {
        },
    })(MuiAccordionSummary);
    
    const AccordionDetails = withStyles((theme) => ({
        root: {
            width: '100%',
            padding: 0,
        }
    }))(MuiAccordionDetails);


    const get = async (email: string = '') => {
        let out = await GetIBBLessons(email);
        setIbbLessons(out.data.response.ibbLessons.filter((l) => l.ModuleNumber !== undefined))
        
        console.log('r', out.data.response)
        
        // COMPILE MODULES FROM LESSONS
        let _modules = [] as any[]
        for (let lesson of out.data.response.ibbLessons) {
            let module_exists = false;
            let module_index = -1;
            for (let i = 0; i < _modules.length; i++) {
                let module = _modules[i];
                if (module.number === lesson.ModuleNumber) {
                    module_exists = true;
                    module_index = i;
                }
            }
            if (lesson.ModuleNumber) {
                if (!module_exists) {
                    _modules.push({
                        number: lesson.ModuleNumber,
                        name: MODULE_NAME_DICT[lesson.ModuleNumber],
                        duration: lesson.EstTimeMinutes,
                        lessons: [lesson]
                    });
                } else {
                    _modules[module_index].duration += lesson.EstTimeMinutes;
                    _modules[module_index].lessons.push(lesson);
                }
            }
            
            
        }       

        if (out.data.response.completed) {
            setCompletedLessons(out.data.response.completed)
            console.log(out.data.response.completed);
            // WORK OUT WHICH MODULE IS COMPLETED AND WHICH THEYRE UP TO
            let moduleNumber = new URLSearchParams(window.location.search).get("moduleNumber")
            let highestModule = _modules[0].number;
            _modules.map((m:any, mi) => {
                m.lessons.map((l:any, li) => {
                    if (out.data.response.completed.map((_l:any) => {return _l.IBBDay}).includes(l._id)) {
                        // CHECK IF ITS THE LAST LESSON IN THE MODULE
                        if (li + 1 === m.lessons.length) {
                            highestModule = m.number + 1;
                            m.isComplete = true;
                        }  else {
                            // OTHERWISE, SET HIGHEST MODULE TO THIS ONE 
                            highestModule = m.number;
                        }
                    }
                })
            })
            // IF THE URL DOESNT HAVE THE MODULE NUMBER SET
            // SET THE MODULE COMPLETED TO BE EXPANDED
            if (!moduleNumber) {
                setExpandedModule(parseInt(highestModule))
            }
        }
        setModules(_modules)
        console.log('_modules',_modules);
        setLoading(false)
    }

    const onIBBDayPress = (ibbDay) => {
        if (salonId) {
            history.push(`/${salonId}/ibb/${ibbDay._id}`);
        } else {
            history.push(`/ibb/${ibbDay._id}`);
        }
    }        

	const onEmailInputChange = (value: string) => {
		setEmailInputValue(value);
	};

	const onEmailSet = () => {
		setEmail(emailInputValue);
        localStorage.setItem('IBBemail', emailInputValue);
        setEmailInputVisible(false);
        get(emailInputValue);
	};

    const onEmailInvite = async () => {
        setEmailInviteSent(false);
        let out = await SendUserPiiqMail(
            emailInputValue, 
            "Access your piiq training", 
            "Here's your piiq training", 
            "Hey there!<br /><br />Here's your piiq Smart Salon training. Access it anytime on any device. <br /><br /><a href='https://admin.piiqapp.com/ibb/?email="+emailInputValue+"' target='_blank' style='padding: 8px 12px; background: black;border-radius: 2px;font-family: Helvetica, Arial, sans-serif;font-size: 14px; color: #ffffff;text-decoration: none;font-weight:bold;display: inline-block;'>Click here to get started</a>");
        console.log('email sent', out)
        
        setEmailInviteSent(true);
        setEmailInputValue('');
    }

	return (
		<Layout>
			<Notification />
			    <Container>
                    <H1>Instant Business Booster</H1>
                    <MarginLeftDiv >
                        <p>Quickly and easily learn how to use the entire piiq Smart Salon System in your own time with these short bite-sized training modules.</p>
                    </MarginLeftDiv>
                    {/* SHOW EMAIL SIGN IN IF PUBLIC */}
                    {salonId ? (
                        <>
                            <EmailInputContainer>
                                <Input
                                    inputStyle={{width: '100%', borderRadius: 0}}
                                    name="email"
                                    onChange={onEmailInputChange}
                                    placeholder={"eg isabella.lee@gmail.com"}
                                    value={emailInputValue}
                                    label="Enter someone's email to invite them to complete the IBB training"
                                />
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={onEmailInvite}
                                    color="primary"
                                    style={{
                                        backgroundColor: "black",
                                        color: "white",
                                        width: "30%",
                                        borderRadius: "0px",
                                        height: 42,
                                        marginBottom: 1
                                    }}>
                                Invite    
                                </Button>
                            </EmailInputContainer>
                            {emailInviteSent && (
                                <EmailInputContainer>
                                    <p style={{color: '#63bf00'}}>Invite successfully sent</p>
                                </EmailInputContainer>    
                            )}
                            
                        </>
                    ) : (
                        <>
                        <MarginLeftDiv>
                            <div style={{background:"#ffcc7c", padding: 20}}>
                            <h2 style={{marginTop: 0, marginBottom: 10}}>
                                Like what you see?
                            </h2>
                            <h3 style={{marginBottom: 0, marginTop: 0}}>Sign up for a piiq Pro subscription to get started! <a href="/register" style={{color:"black"}}>Click here to sign up now</a></h3>
                            </div>
                        </MarginLeftDiv>
                        
                        {!emailInputVisible ? (
                            <EmailInputContainer>
                                <div style={{
                                        background: '#ededed',
                                        padding: '0 16px'}}>
                                    <p>Currently logged in as {email}
                                    <span
                                        onClick={() => {
                                            localStorage.setItem('IBBemail','');
                                            setEmailInputVisible(true);
                                        }}
                                        style={{
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                            marginLeft: 12
                                        }}>
                                    Not you? Click here   
                                    </span>
                                    </p>
                                </div>
                            </EmailInputContainer>
                        ) : (
                            <EmailInputContainer>
                                <Input
                                    inputStyle={{width: '100%', borderRadius: 0}}
                                    name="email"
                                    onChange={onEmailInputChange}
                                    placeholder={"eg isabella.lee@gmail.com"}
                                    value={emailInputValue}
                                    label="Enter your email to log in and track your progress:"
                                />
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={onEmailSet}
                                    color="primary"
                                    style={{
                                        backgroundColor: "black",
                                        color: "white",
                                        width: "30%",
                                        borderRadius: "0px",
                                        height: 42,
                                        marginBottom: 1
                                    }}>
                                    Log in    
                                </Button>
                            </EmailInputContainer>
                        )}
                        </>
                    )}
                    
                    
                    {(ibbLessons && ibbLessons.length > 0) ? (
                    <AccordionContainer>
                        { modules.map((module, i) => 
                            <Accordion key={i} expanded={expandedModule === module.number} onChange={() => {if (expandedModule === module.number) {setExpandedModule(-1)} else {setExpandedModule(module.number)}}}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}>
                                    <AccordionHeading>Module {i+1} - {module.name}</AccordionHeading>
                                    <AccordionDescription>
                                        {module.isComplete ? (<span style={{background:'#63bf00', color: 'white', borderRadius: 4, marginRight: 12, display:'inline-block', padding: 4}}>COMPLETE</span>) : (<span></span>)}
                                        {module.lessons.length} videos ({module.duration} minutes total)</AccordionDescription>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Lessons>
                                        { module.lessons.map((ibbLesson:any, i) => 
                                            <Lesson key={i}>
                                                <Graphic>
                                                    <LineAbove 
                                                        i={i} 
                                                        isTotal={i === module.lessons.length - 1}  />
                                                    <Ball 
                                                        i={i} 
                                                        isTotal={i === module.lessons.length - 1} 
                                                        complete={completedLessons.map((l:any) => {return l.IBBDay}).includes(ibbLesson._id)}/>
                                                    <LineBelow  
                                                        i={i} 
                                                        isTotal={i === module.lessons.length - 1} />
                                                </Graphic>
                                                <div style={{flex:1, flexDirection:'column', display:'inline-block', width: '80%'}}>
                                                    <h3>#{i+1} - {ibbLesson.Name}</h3>
                                                    <p>{ibbLesson.Description}</p>
                                                </div>
                                                <Button 
                                                    variant="outlined"
                                                    color="primary"
                                                    style={{
                                                        backgroundColor: "black",
                                                        color: "white",
                                                        borderRadius: "0px",
                                                        marginLeft: "12px"
                                                    }}
                                                    onClick={() => onIBBDayPress(ibbLesson)}>
                                                        {completedLessons.map((l:any) => {return l.IBBDay}).includes(ibbLesson._id) ? 'Watch again' : 'View' }
                                                    </Button>
                                            </Lesson>) 
                                        }
                                    </Lessons>
                                </AccordionDetails>
                            </Accordion> )   
                        }
                        
                    </AccordionContainer>) : loading ?
                    <Loader>
                        <CircularProgress/>
                    </Loader> :
                        <></>
                    }
                </Container>
			
		</Layout>
	);
}
