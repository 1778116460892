import { IActions } from "../contexts/createAsyncDispatch";

export interface IStateStaff {
	staff: IStaff[];
	loading: boolean;
}

export interface IStaff {
	id: string;
	active: boolean;
	name: string;
}

export interface IGetStaffAction {
	type: "GET_ALL_STAFF";
	id?: string;
	salonId: string;
	active?: boolean;
	limit?: number;
	nextToken?: string;
}

export interface ISetStaffAction extends IActions {
	type: "SET_STAFF";
	id?: string;
	staff: IStaff[];
}

export interface ISetLoadingAction {
	type: "SET_LOADING";
	loading: boolean;
}

export type IStaffAsyncAction = any;

export interface IToggleStaffAction {
	type: "TOGGLE_STAFF";
	staffId: string;
	active: boolean;
}

export interface ICreateStaffAction {
	type: "CREATE_STAFF";
	salonId: string;
	name: string;
	id: string;
}

export interface IEditStaffNameAction {
	type: "EDIT_STAFF_NAME";
	staffId: string;
	name: string;
}

export type IStaffAction =
	| IGetStaffAction
	| ISetStaffAction
	| ISetLoadingAction
	| ICreateStaffAction
	| IToggleStaffAction
	| IEditStaffNameAction;

export const initialStateStaff: IStateStaff = {
	staff: [],
	loading: true
};

export const reducerStaff = (
	state: IStateStaff,
	action: IStaffAction | IStaffAsyncAction
) => {
	switch (action.type) {
		case "SET_STAFF":
			return {
				...state,
				staff: action.staff,
				loading: false
			};

		case "SET_LOADING":
			return {
				...state,
				loading: action.loading
			};

		case "TOGGLE_STAFF":
			return toggleStaff(state, action);

		case "CREATE_STAFF":
			return createStaff(action, state);

		case "EDIT_STAFF_NAME":
			return editStaffName(action, state);
	}
	return state;
};

function editStaffName (action: IEditStaffNameAction, state: IStateStaff) {
	const { name, staffId } = action;
	const i = state.staff.findIndex(s => s.id === staffId);
	return {
		...state,
		staff: [
			...state.staff.slice(0, i),
			{ ...state.staff[i], name },
			...state.staff.slice(i + 1)
		],
		loading: false
	};
}

function createStaff (action: ICreateStaffAction, state: IStateStaff) {
	const { name, id } = action;
	return {
		...state,
		staff: [ ...state.staff, { name, id } ],
		loading: false
	};
}

function toggleStaff (state: IStateStaff, action: IToggleStaffAction) {
	const i = state.staff.findIndex(s => s.id === action.staffId);
	return {
		...state,
		staff: [
			...state.staff.slice(0, i),
			{ ...state.staff[i], active: action.active },
			...state.staff.slice(i + 1)
		],
		loading: false
	};
}
