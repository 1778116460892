import React, { useEffect, useState, useRef } from "react";
import { animated, useSpring } from "react-spring";
import { withRouter } from "react-router-dom";
import { Button, Content } from "../../../../components";
import { FormattedMessage } from "react-intl";
import { SummaryType } from "../../types";
import { SubscriptionState } from "../../../../contexts/SubscriptionContext";
import {
  SummaryDetail,
  SummarySupport,
  SummaryOverview,
  Footer
} from "./styles";
import mixpanel from 'mixpanel-browser';

interface IProps {
  transitionConfig?: any;
  onSkipSection: Function;
  onNavigate: Function;
  onCompleteSection: Function;
  history: any;
  location: any;
  match: any;
}

const OrderComponent: React.SFC<IProps> = props => {
  const context = SubscriptionState();
  const contentScroll = useRef<HTMLDivElement>(null);
  const [ deliveryDetails ] = useState(context.state.deliveryDetails);
  const [ accountDetails ] = useState(context.state.accountDetails);
  const [ salonDetails ] = useState(context.state.salon);

  useEffect(() => {
    if (contentScroll && contentScroll.current) {
      contentScroll.current.scrollTop = 0;
      window.scrollTo(0, 0);
    }
    mixpanel.track("PMA Register Order details page accessed event");
    mixpanel.register({
			'Email': context.state.accountDetails.email,
			'Account Type': 'Paid Salon'
		});
  }, []);

  const onFinishPress = () => {
    props.onCompleteSection();
    props.onNavigate(SummaryType.PASSWORD);
  };

  const transitionConfig = useSpring(props.transitionConfig);

  return (
    <Content as={animated.div} style={transitionConfig} ref={contentScroll}>
      <div style={{maxWidth: 900, margin: "0 auto", paddingBottom: 100}}>
      <h1>Order Details</h1>
      <div>
        Congratulations on joining the piiq family. And thank you for being part
        of transforming the way salon professionals and their guests connect.
        We will confirm the details of your order via email shortly. Before you
        take off, please finish setting up your piiq account by clicking FINISH CREATING ACCOUNT below.
      </div>

      <SummaryDetail>
        <SummaryOverview>
          <h3>Account DETAILS</h3>
          <div className="margin-bottom-mid">
            {`${accountDetails.first_name} ${accountDetails.last_name}`}
            <br />
            {`${accountDetails.salon}`}
            <br />
            {`${accountDetails.phone}`} <br />
            {`${accountDetails.email}`}
          </div>

          <h3>Delivery Address</h3>
          <div className="margin-bottom-mid">
            {`${deliveryDetails.address_line_1}`} <br />
            {`${deliveryDetails.address_line_2}`} <br />
            {`${deliveryDetails.city} ${deliveryDetails.state} `} <br />
            {`${deliveryDetails.country} ${deliveryDetails.postcode}`}
          </div>
        </SummaryOverview>
        <SummarySupport>
          <h3 className="margin-bottom-mid">SUPPORT</h3>
          <div>
            <div className="margin-bottom-mid">
              Don't hesitate to get in touch if you need anything or if you just
              want to have a chat:
            </div>

            <h3>Phone:</h3>
            <div className="margin-bottom-mid">
              <a href="tel:+61488847447">+61 48884 7447 (PIIQ)</a>
            </div>

            <h3>Email:</h3>
            <div className="margin-bottom-mid">
              <a
                className="margin-bottom-mid"
                href="mailto:support.piiqdigital.com">
                support@piiqdigital.com
              </a>
            </div>
            <h3>FAQ’s:</h3>
            <a href="http://www.piiqdigital.com/faqs" target="_blank">
              www.piiqdigital.com/faqs
            </a>
          </div>
        </SummarySupport>
      </SummaryDetail>
      <div className="margin-bottom-mid">
        {<Button onPress={onFinishPress} label="Finish creating account" />}
      </div>
      <Footer />
      </div>
    </Content>
  );
};

export default withRouter(OrderComponent);
