import styled from "styled-components";
import { COLORS, SIZE, FONT, BREAKPOINTS } from "../../config";

interface iProps {
	value: number;
}

export const SearchIput = styled.div`
	width: calc(100%);
	max-width: 400px;
	/* background: red; */
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin: ${SIZE.MEDIUM}px 0;
	position: absolute;
	right: ${SIZE.MEDIUM}px;
	top: ${SIZE.SMALL}px;
	/* transition: background .2s ease, color .3s ease; */
	input {
		padding: ${SIZE.SMALL}px;
		width: 200px;
		height: 44px;
		outline: none;
	}

	.react-autosuggest__suggestions-container {
		display: none;
	}

	.react-autosuggest__container--open
		.react-autosuggest__suggestions-container {
		display: block;
		position: absolute;
		top: 51px;
		width: 200px;
		border: 1px solid #aaa;
		background-color: #fff;
		font-family: Helvetica, sans-serif;
		font-weight: 300;
		font-size: 16px;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		z-index: 2;
	}

	.react-autosuggest__suggestions-list {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	.react-autosuggest__suggestion {
		cursor: pointer;
		padding: 10px 20px;
	}

	.react-autosuggest__suggestion:not(:first-child) {
		border-top: 1px solid #ddd;
	}

	.react-autosuggest__suggestion--focused {
		background-color: #0c7eaf;
		color: #fff;
	}

	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		position: relative;
		right: 0;
		top: 0;
		display: block;

		input {
			width: 100%;
		}
	}
`;

export const Suggestion = styled.div`
	padding: ${SIZE.SMALL}px ${SIZE.MEDIUM}px;
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: row;
`;

export const Icon = styled.div`
	position: absolute;
	width: 24px;
	height: 24px;
	top: 14px;
	right: ${SIZE.TINY}px;
	
	svg {
		width: 14px;
		height: 14px;
		fill: ${COLORS.BLACK};
	}
`;
