import React, { useState, useEffect, useRef } from "react";
import { animated, useSpring } from "react-spring";
import ReactFlagsSelect from "react-flags-select";
import "react-flags-select/css/react-flags-select.css";
import { FormattedMessage } from "react-intl";
import { Input, Button, RadioButton, Content } from "../../../../components";
import { SummaryType } from "../../types";
import { SubscriptionState } from "../../../../contexts/SubscriptionContext";
import { Error, HolidayMsg, Label, Footer } from "./styles";
import NativeSelect from '@material-ui/core/NativeSelect';
import {
	INewContactDealAccount,
	INewSYSSalon,
	SYSNewSalonSignup,
	ZapierFreshworksNewContactDealAccountPrePayment
  } from "../../../../utils";
import mixpanel from 'mixpanel-browser';

interface IProps {
	transitionConfig?: any;
  onSkipSection: Function;
  onNavigate: Function;
	onCompleteSection: Function;
}

interface IErrors {
	address_line_1: string | null;
	address_line_2?: string | null;
	city: string | null;
	state: string | null;
	postcode: string | null;
}

const DeliveryComponent = (props: IProps) => {
	const formErrors: IErrors = {
		address_line_1: null,
		address_line_2: null,
		city: null,
		state: null,
		postcode: null
	};
	const context = SubscriptionState();
	const contentScroll = useRef<HTMLDivElement>(null);
	const [ deliveryDetails, setDeliveryDetails ] = useState(
		context.state.deliveryDetails
	);
	const [ countryCode, setCountryCode ] = useState("AU");
	const [ errors, setErrors ] = useState(formErrors);

	console.log(context);

	useEffect(() => {
		if (contentScroll && contentScroll.current) {
			contentScroll.current.scrollTop = 0;
			window.scrollTo(0, 0);
		}
		mixpanel.track("PMA Register Delivery details page accessed event");
		
	}, []);
	
	useEffect(() => {
		mixpanel.identify(context.state.accountDetails.email);
		mixpanel.alias(context.state.accountDetails.email);
		mixpanel.register({
			'Email': context.state.accountDetails.email,
			'Account Type': 'Unpaid Salon'
		});
	}, [context.state.accountDetails.email]);

	const getCurrentDate = (separator='/') => {

		let newDate = new Date()
		let date = newDate.getDate();
		let month = newDate.getMonth() + 1;
		let year = newDate.getFullYear();

		return `${date}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}`
		}

	const onSubmit = async () => {
		if (validateForm()) {
			await context.dispatch({
				type: "UPDATE_DELIVERY_DETAILS",
				deliveryDetails: {
					...deliveryDetails,
					country: countryCode
				}
			});

			const newCDA:INewContactDealAccount = {
				firstName: context.state.accountDetails.first_name,
				lastName: context.state.accountDetails.last_name,
				mobileNumber: context.state.accountDetails.phone,
				email: context.state.accountDetails.email,
				address: deliveryDetails.address_line_1 + " " + deliveryDetails.address_line_2,
				suburb: deliveryDetails.city,
				state: deliveryDetails.state,
				notes: deliveryDetails.notes,
				postcode: deliveryDetails.postcode,
				country: countryCode == "AU" ? "Australia" : "New Zealand",
				salonName: context.state.accountDetails.salon,
				SYSAccountNumber: context.state.accountDetails.loreal_account_number,
				dealAmount: String(context.state.orderDetails.total),
				colourBrand: String(context.state.orderDetails.colour_brand),
				mirrorQuantity: String(context.state.orderDetails.mirror_quantity),
				discount: String(context.state.orderDetails.discount),
				promo: String(context.state.orderDetails.supplier_code),
				subscriptionType: String(context.state.orderDetails.subscription_type),
				subscribedDate: String(getCurrentDate())
			}
			ZapierFreshworksNewContactDealAccountPrePayment(newCDA);

			const newSalon:INewSYSSalon = {
				firstName: context.state.accountDetails.first_name,
				lastName: context.state.accountDetails.last_name,
				phoneNumber: context.state.accountDetails.phone,
				lorealAccountNumber: parseInt(context.state.accountDetails.loreal_account_number),
				email: context.state.accountDetails.email,
				addressLine1: deliveryDetails.address_line_1,
				addressLine2: deliveryDetails.address_line_2 ? deliveryDetails.address_line_2 : '',
				suburb: deliveryDetails.city,
				state: deliveryDetails.state,
				postcode: deliveryDetails.postcode,
				salonName: context.state.accountDetails.salon
			}
			SYSNewSalonSignup(newSalon);
			
			props.onNavigate(SummaryType.PAYMENT);
			props.onCompleteSection();


		}
	};

	const validateForm = () => {
		const validate: IErrors = {
			address_line_1: !deliveryDetails.address_line_1
				? "* Address line 1 required"
				: null,
			city: !deliveryDetails.city ? "* City required" : null,
			state: !deliveryDetails.state ? "* State required" : null,
			postcode: !deliveryDetails.postcode ? "* Postcode required" : null
		};

		setErrors(validate);

		if (
			!validate.address_line_1 &&
			!validate.city &&
			!validate.state &&
			!validate.postcode
		)
			return true;
		else return false;
	};

	const onInputChange = (value: string, name: string) => {
		setDeliveryDetails({
			...deliveryDetails,
			[name]: value
		});
		setErrors({
			...errors,
			[name]: null
		});
	};

	const onSelectFlag = (code: string) => {
		setCountryCode(code);
	};

	const transitionConfig = useSpring(props.transitionConfig);

	return (
		<Content as={animated.div} style={transitionConfig} ref={contentScroll}>
			<div style={{maxWidth: 900, margin: "0 auto", paddingBottom: 100}}>
			<h1>
				<FormattedMessage id="signup.delivery.title" />
			</h1>
			<div>
				<FormattedMessage id="signup.delivery.body" />
			</div>
			<h2>
				<FormattedMessage id="signup.delivery.form.title" />
			</h2>
			<Input
				onChange={onInputChange}
				label="Address Line 1*"
				placeholder="Street Address"
				name="address_line_1"
				value={deliveryDetails.address_line_1}
				error={errors.address_line_1}
			/>
			<Input
				onChange={onInputChange}
				label="Address Line 2"
				placeholder="Apartment, Suite, etc."
				name="address_line_2"
				value={deliveryDetails.address_line_2}
				error={errors.address_line_2}
			/>
			<div style={{display: "flex"}}>
				<Input
					onChange={onInputChange}
					label="City / Suburb*"
					placeholder="City / Suburb"
					name="city"
					value={deliveryDetails.city}
					error={errors.city}
					inputStyle={{width:"80%"}}
				/>
				<Input
					onChange={onInputChange}
					placeholder="State / County"
					label="State / County*"
					name="state"
					value={deliveryDetails.state}
					error={errors.state}
					inputStyle={{width:"80%"}}
				/>
				<Input
					onChange={onInputChange}
					placeholder="Postcode/ZIP"
					label="Postcode/ZIP*"
					name="postcode"
					value={deliveryDetails.postcode}
					error={errors.postcode}
					inputStyle={{width:"80%"}}
				/>
			</div>
			<Label>
				<FormattedMessage id="signup.delivery.form.field_5" />
			</Label>
			<NativeSelect id="countries" value={countryCode} onChange={(e) => {onSelectFlag(e.target.value)}}>
				<option value={"AU"}>Australia</option>
				<option value={"AU"}>New Zealand</option>
			</NativeSelect>

			<h2 className="margin-bottom-mid">
				<FormattedMessage id="signup.delivery.form.notes" />
			</h2>
			<Input
				onChange={onInputChange}
				placeholder="List the retail brands you currently recommend to your clients for at home haircare and styling so we can make sure they're available in your piiq Smart Salon System"
				name="notes"
				textarea
				value={deliveryDetails.notes}
			/>
			<Button onPress={onSubmit} label="Next" />
			<Footer />
			</div>
		</Content>
	);
};

export default DeliveryComponent;
