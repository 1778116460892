import styled from "styled-components";
import { COLORS, SIZE, BREAKPOINTS, FONT } from "../../config";

interface iProps {
  open: boolean;
}

export const Expander = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  outline: none;
  overflow: hidden;
`;

export const InnerContent = styled.div`
  max-height: ${(props: iProps) => (props.open ? "560px" : "0px")};
  width: calc(100% - ${SIZE.MEDIUM * 2}px);
  background: ${COLORS.GREY_BASE};
  padding: ${(props: iProps) =>
    props.open ? `${SIZE.MEDIUM}px` : `${SIZE.MEDIUM}px`};
  transition: max-height .2s ease;

  @media (min-width: ${BREAKPOINTS.MOBILE_MEDIUM}px) {
    max-height: ${(props: iProps) => (props.open ? "560px" : "0px")};
    padding: ${(props: iProps) =>
      props.open ? `${SIZE.LARGE}px` : `${SIZE.LARGE}px`};
    width: calc(100% - ${SIZE.LARGE * 2}px);
  }
  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    max-height: ${(props: iProps) => (props.open ? "560px" : "0px")};
  }
`;

export const Container = styled.div`
  overflow: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  min-height: ${(props: iProps) => (props.open ? "430px" : 0)};
  max-height: 615px;
  height: ${(props: iProps) => (props.open ? `calc(100vh - 250px)` : 0)};
  transition: height .2s ease;

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    min-height: ${(props: iProps) => (props.open ? "560px" : 0)};
  }
`;

export const ExpandButton = styled.div`
  width: calc(100% - ${SIZE.LARGE * 2}px);
  padding: ${SIZE.MEDIUM}px ${SIZE.LARGE}px;
  background: ${COLORS.GREY_BASE};
  color: ${COLORS.WHITE};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  svg {
    transition: transform .2s ease;
  }
`;

export const Footer = styled.div``;
