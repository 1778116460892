import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { StaticContext, useParams } from "react-router";
import { Notification, Layout, Fetching, Input} from "../../components";
import { Container, Title, Half ,ContentContainer, BackButton } from './styles'
import { GetSalonOrderDetails } from "../../utils";
import CircularProgress from "@material-ui/core/CircularProgress";

import Auth from "@aws-amplify/auth";
interface IPathParams {
	salonId: string;
	appointmentId?: string;
}
export default function OrderDetailsScreen ({
    location,
	match,
	history
}: React.PropsWithChildren<
RouteComponentProps<IPathParams, StaticContext, any>
>) {
    const [ loading, setLoading ] = useState(true);
    const [ orderDetails, setOrderDetails ] = useState() as any;
    const [ productsLineItems, setProductsLineItems ] = useState() as any;
    
    const [ email, setEmail ] = useState('');
    const { orderid } = useParams<{ orderid: string }>()
    const { salonId } = match.params;

    useEffect(() => {
        getAuth();
    }, [])

    const getAuth = async () => {
        try {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            console.log("Cognito User", cognitoUser.attributes.email);
            setEmail(cognitoUser.attributes.email);
            get(cognitoUser.attributes.email);
        } catch (e) {
            console.log("Authenticator::CheckUser:Error:", e.message);
        }
    }
    const get = async (email) => {
        let out = await GetSalonOrderDetails(orderid, email);
        console.log('r',out.data.response)
        setOrderDetails(out.data.response)
        let pli = [] as any[]
        out.data.response.lineItems?.map((li,i) => {
            out.data.response.products.map((p,ii) => {
                if (li["Product"] === p["_id"]) {
                    pli.push({
                        lineItem:li as any,
                        product:p as any
                    })  
                }
            })
        })
        console.log(pli)
        setProductsLineItems(pli)
        setLoading(false)
    }


	return (
		<Layout>
			<Notification />
			    <Container>
                    
                    {loading ?
                        (<CircularProgress/>)
                             :
                        (<ContentContainer><Half>
                            <BackButton onClick={() => history.push(`/${salonId}/orders`)}>← BACK</BackButton>
                            <h2>Order #{orderDetails?.order.OrderNumber}</h2>
                            <p>{orderDetails?.order.FirstName} {orderDetails?.order.LastName}</p>
                            <p>{orderDetails?.order["Email"]}</p>
                            <p>{orderDetails?.order["Phone"]}</p>
                            <p>{orderDetails?.address["Address Line 1"]}</p>
                            <p>{orderDetails?.address["Address Line 2"]}</p>
                            <p>{orderDetails?.address["Suburb or City"]} {orderDetails?.address["State"]} {orderDetails?.address["Postcode"]}</p>
                        </Half>
                        <Half>
                            <h2>Products</h2>
                            {productsLineItems?.map((pli, i) => {
                                return (
                                    <p>{pli.lineItem["Quantity"]} x {pli.product["Product Name"]} ${pli.lineItem["Total"]}</p>
                                )
                            })}
                            <hr></hr>
                            <p>Subtotal: ${orderDetails?.order["Subtotal"]}</p>
                            <p>Shipping: ${orderDetails?.order["Shipping"]}</p>
                            <p>Grand total: ${orderDetails?.order["GrandTotal"]}</p>
                        </Half>
                    </ContentContainer>
                    )}
                </Container>
			<Fetching />
		</Layout>
	);
}
