import { API, graphqlOperation } from "aws-amplify";
import { loader } from "graphql.macro";
import { GraphQLResult } from "@aws-amplify/api/lib/types";
import {
	GetSalonStaff,
	GetSalonStaff_getSalon_allStaff_items
} from "./../graphql/GetSalonStaff";
import { UpdateStaffVariables } from "./../graphql/UpdateStaff";
import { CreateStaffVariables } from "./../graphql/CreateStaff";
import {
	IStaffAction,
	ICreateStaffAction,
	IEditStaffNameAction,
	IToggleStaffAction,
	ISetStaffAction,
	IStaff
} from "../reducers/StaffReducer";
const GetSalonStaffGQL = loader("./../graphql/GetSalonStaff.graphql");
const UpdateStaffGQL = loader("./../graphql/UpdateStaff.graphql");
const CreateStaffGQL = loader("./../graphql/CreateStaff.graphql");

const getAllStaff = (staff: IStaff[]): ISetStaffAction => ({
	type: "SET_STAFF",
	staff
});

export const startGetAllStaff = (
	salonId: string,
	limit?: string,
	nextToken?: string,
	active?: boolean
) => {
	return async (dispatch: React.Dispatch<IStaffAction>, state: any) => {
		const result = (await API.graphql(
			graphqlOperation(GetSalonStaffGQL, {
				salonId,
				limit,
				nextToken,
				active
			})
		)) as GraphQLResult;

		if (result.data) {
			const data = result.data as GetSalonStaff;
			if (data.getSalon) {
				const items =
					data &&
					data.getSalon &&
					data.getSalon &&
					data.getSalon.allStaff &&
					data.getSalon.allStaff.items
						? (data.getSalon.allStaff.items.filter(
								item => item !== null
							) as GetSalonStaff_getSalon_allStaff_items[]).map(item => ({
								id: item.id,
								name: item.name || "",
								active: item.active || false
							}))
						: [];
				dispatch(getAllStaff(items));
			}
		}
	};
};

export const createStaff = (
	salonId: string,
	name: string,
	id: string
): ICreateStaffAction => ({
	type: "CREATE_STAFF",
	salonId,
	name,
	id
});

interface StaffResult {
  data: {
    createStaff: {
      id: string;
      name: string;
    }
  }
}

export const startCreateStaff = (salonId: string, name: string) => {
	return async (dispatch: React.Dispatch<IStaffAction>, state: any) => {
		try {
			const result = (await API.graphql(
				graphqlOperation(CreateStaffGQL, {
					input: { name, salonId }
				} as CreateStaffVariables)
			)) as StaffResult;
      console.log("Result", result);
      const id = result.data?.createStaff?.id;
			dispatch(createStaff(salonId, name, id));
		} catch (error) {
			console.log("action err ", error);
			return error;
		}
	};
};

export const toggleStaff = (
	staffId: string,
	active: boolean
): IToggleStaffAction => ({
	type: "TOGGLE_STAFF",
	staffId,
	active
});

export const startToggleStaff = (staffId: string, active: boolean) => {
	return async (dispatch: React.Dispatch<IStaffAction>, state: any) => {
		const result = (await API.graphql(
			graphqlOperation(UpdateStaffGQL, {
				staffId,
				input: { active }
			} as UpdateStaffVariables)
		)) as GraphQLResult;
		console.log("Result", result);
		dispatch(toggleStaff(staffId, active));
	};
};

export const editStaffName = (
	staffId: string,
	name: string
): IEditStaffNameAction => ({
	type: "EDIT_STAFF_NAME",
	staffId,
	name
});

export const startEditStaffName = (staffId: string, name: string) => {
	return async (dispatch: React.Dispatch<IStaffAction>, state: any) => {
		const result = (await API.graphql(
			graphqlOperation(UpdateStaffGQL, {
				staffId,
				input: { name }
			} as UpdateStaffVariables)
		)) as GraphQLResult;
		console.log("Result", result);
		dispatch(editStaffName(staffId, name));
	};
};
