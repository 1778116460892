import styled from "styled-components";
import { animated } from "react-spring";

import { COLORS, FONT, SIZE, BREAKPOINTS } from "../../config";

interface iFetching {
	show: boolean;
}

export const FetchingContainer = styled.div`
	min-width: 320px;
	height: 40px;
	position: fixed;
	top: 12px;
	left: 50%;
	margin-left: -115px;
	font-size: ${FONT.SIZE.SMALL};
	text-align: center;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-self: center;
	justify-content: center;
	background: rgba(255, 255, 255, 0.1);
	color: ${COLORS.WHITE};
	border: 1px solid ${COLORS.GREY_LIGHT};
	border-radius: ${SIZE.BORDER_RADIUS}px;
	z-index: 9999999999;
	opacity: ${(props: iFetching) => (props.show ? 1 : 0)};
	transform: ${(props: iFetching) =>
		props.show ? `translateY(0)` : `translateY(-10px)`};
	transition: opacity .4s 1s ease, transform .4s 1s ease;

	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		display: none;
	}
`;

export const Spinner = styled.div`
	position: absolute;
	left: 6px;
	top: 6px;
	div {
		width: 18px !important;
		height: 18px !important;
	}

	svg {
		circle {
			stroke: ${COLORS.WHITE};
		}
	}

	margin: 4px ${SIZE.SMALL}px 0;
`;

export const WaitMsg = styled(animated.div)`
    font-size: 14px;
`;
