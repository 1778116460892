import { createContext, useContext } from "react";
import {
  IOrderDetail,
  IAccountDetail,
  IDeliveryDetail,
  ISalonDetail,
  ISubscription
} from "../reducers/RegistrationReducer";

export const OrderDetailsModel = {
  orderDetails: <IOrderDetail>{
    subscription_type: "",
    mirror_quantity: 1,
    bench_mount: 1,
    wall_mount: 0,
    colour_brand: "",
    supplier_code: "",
    sub_total: 0,
    discount: 0,
    shipping: 0,
    total: 0,
    plan: ""
  },
  accountDetails: <IAccountDetail>{
    first_name: "",
    last_name: "",
    salon: "",
    loreal_account_number: "",
    email: "",
    phone: "",
    password: "",
    news_opt_in: false
  },
  deliveryDetails: <IDeliveryDetail>{
    address_line_1: "",
    address_line_2: "",
    city: "",
    state: "",
    country: "",
    postcode: "",
    notes: "",
    deliveryOption: "STANDARD"
  },
  salon: <ISalonDetail>{
    id: ""
  }
};

export const SubscriptionContext = createContext<any>(OrderDetailsModel);

interface IContext {
  state: ISubscription;
  dispatch: any;
}

export const SubscriptionState = () =>
  useContext<IContext>(SubscriptionContext);
