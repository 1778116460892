import styled from "styled-components";
import BackgroundImg from "../../assets/Images/subscription/account_default.jpg";
import { COLORS, SIZE, BREAKPOINTS, FONT } from "../../config";

interface iRow {
  header?: boolean;
}

interface iSummary {
  desktop: boolean;
}

export const SummaryCard = styled.div`
  width: 100%;
  min-width: 250px;
  max-width: ${(props: iSummary) => (props.desktop ? `420px` : `100%`)};
  border-radius: 10px;
  background: ${COLORS.BLACK};
  overflow: hidden;
  font-size: ${FONT.SIZE.BODY};

  @media (max-height: 820px) {
    margin-top: ${(props: iSummary) =>
      props.desktop ? `-${SIZE.LARGE}px` : `0`};
  }
  @media (min-width: ${BREAKPOINTS.DEKSTOP_LARGE}px) {
    max-width: 480px;
  }
`;

export const Header = styled.div`
  min-height: ${(props: iSummary) => (props.desktop ? `20px` : `20px`)};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLORS.GREY_LIGHT};
  background: url(${BackgroundImg}) no-repeat center center;
  background-size: cover;

  @media (min-height: 920px) {
    height: ${(props: iSummary) => (props.desktop ? `240px` : `20px`)};
  }
  @media (min-height: 1030px) {
    height: ${(props: iSummary) => (props.desktop ? `380px` : `20px`)};
  }
`;

export const InfoContent = styled.div`
  background: ${COLORS.WHITE};
  height: 100%;
  border-radius: 10px;
  margin-top: -20px;
  .info-padding {
    padding: 0 ${SIZE.MEDIUM}px;
  }
  .info-padding-top {
    padding-top: ${SIZE.MEDIUM}px;
  }
  .info-padding-bottom {
    padding-bottom: ${SIZE.MEDIUM}px;
  }
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  color: ${COLORS.BLACK};
  padding: ${(props: iRow) => (props.header ? `${2}px 0` : `${2}px 0`)};
  font-size: ${(props: iRow) =>
    props.header ? FONT.SIZE.SMALL : FONT.SIZE.BODY};

  div:nth-child(1) {
    display: flex;
    flex: 1;
    align-items: center;
    text-transform: uppercase;
    font-size: ${FONT.SIZE.SMALL};
    img {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      margin-right: ${SIZE.MEDIUM}px;
    }
  }
  div:nth-child(2) {
    text-align: center;
    width: 40px;
  }
  div:nth-child(3) {
    width: 80px;
    text-align: right;
    margin-left: ${SIZE.SMALL}px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
  .supplier-input {
    width: 120px !important;
    input {
      text-align: right;
    }
  }

  @media (min-height: 520px) {
    padding: ${(props: iRow) =>
      props.header ? `${SIZE.SMALL}px 0` : `${SIZE.TINY}px 0`};
  }

  @media (min-height: 600px) {
    padding: ${(props: iRow) =>
      props.header ? `${SIZE.SMALL}px 0` : `${SIZE.TINY}px 0`};
  }
`;
export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${COLORS.BLACK};
  margin: ${SIZE.SMALL}px 0;
  @media (min-height: 600px) {
    margin: ${SIZE.MEDIUM}px 0;
  }
`;

export const Stat = styled.div`
  background: ${COLORS.BLACK};
  color: ${COLORS.WHITE};
  border-radius: 4px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  margin-left: 10px;
  padding: ${SIZE.TINY}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StripePower = styled.div`
  width: 100%;
  padding: ${SIZE.MEDIUM}px 0;
  text-align: center;
`;

export const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
