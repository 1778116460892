import { Switch } from "@material-ui/core";
import { Cancel, CheckCircle } from "@material-ui/icons";
import React from "react";

function StyledSwitch({
  classes,
  available,
  color,
  icon,
  iconChecked,
  ...rest
}: any) {
  return (
    <Switch
      {...rest}
      color={available ? "default" : "secondary"}
      icon={<Cancel />}
      checkedIcon={<CheckCircle />}
    />
  );
}

export default StyledSwitch;
