import React, { useState } from "react";
import Button from "@material-ui/core/Button";

import {
	Item, Link
} from "./styles";


const AcademyContentListItemComponent = (props: any) => {
	
	const onPress = () => {
		props.onPress && props.onPress();
	};

		return (
			<Item>
				<div
					onClick={onPress}>
				<img 
					src={props.content.Images && props.content.Images.length > 0 ? "https:"+props.content.Images[0] : "https://via.placeholder.com/270x153.png?text=..."} 
					alt='x' />
						<span>▶</span>
				</div>
				<p>{props.content.Name}</p>
				<small>{props.content.EstimatedTimeToComplete} minutes</small>
				<small>{props.content.Category}</small>
				<br />
				<Link
					onClick={onPress}>
					VIEW CONTENT
				</Link>
			</Item>)
};

export default AcademyContentListItemComponent;
