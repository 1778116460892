import styled from "styled-components";
import { COLORS, SIZE, FONT } from "../../config";

interface iProps {
	value: number;
}

export const ChartRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;
export const DotChart = styled.div`
	width: calc(100% - ${SIZE.SMALL}px);
	max-width: 400px;
	height: ${(props: iProps) => `44px`};
	display: flex;
	justify-content: center;
	align-items: center;
	margin: ${SIZE.MEDIUM}px 0 ${SIZE.LARGE}px;
	position: relative;
	/* transition: background .2s ease, color .3s ease; */
`;
export const Line = styled.div`
	width: 100%;
	height: 1px;
	background: ${COLORS.GREY_MID_BASE};
`;
export const Dot = styled.div`
	width: 16px;
	height: 4px;
	border-radius: ${SIZE.BORDER_RADIUS}px;
	margin: 0 2px;
	background: ${COLORS.BLACK};
`;

export const DotValue = styled.div`
	position: absolute;
	width: 12px;
	height: 12px;
	margin-left: ${(props: iProps) => `-${props.value * 2}px`};
	border-radius: 6px;
	background: ${COLORS.BLACK};
	left: ${(props: iProps) => `${props.value * 20}%`};
	transition: left .2s ease;
`;

export const Title = styled.div`
	position: absolute;
	top: -${SIZE.MEDIUM}px;
	left: 0;
	font-size: ${FONT.SIZE.BODY};
	/* font-weight: 500; */
`;
export const Thumb = styled.img`
	width: 40px;
	height: 40px;
	margin-left: ${SIZE.MEDIUM}px;
	background: ${COLORS.WHITE};
`;

export const Labels = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	width: 100%;
	top: 35px;
	font-size: ${FONT.SIZE.SMALL};
`;
