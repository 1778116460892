import styled from "styled-components";
import { COLORS, SIZE, FONT, BREAKPOINTS } from "../../config";

export const StatsContainer = styled.div`
	width: calc(100% - ${SIZE.MEDIUM * 2}px);
	padding: ${SIZE.MEDIUM}px;
	margin: auto;

	@media (min-width: ${BREAKPOINTS.DEKSTOP_MEDIUM}px) {
		width: calc(100% - ${SIZE.LARGE * 2}px);
		/* padding: ${SIZE.LARGE}px;*/
		max-width: 1020px; 
	}

	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		width: 100%;
		padding: 0;
		margin-left: -24px;
	}
`;

export const Divider = styled.div`
	width: 100%;
	height: ${SIZE.LARGE}px;
`;
export const DividerSmall = styled.div`
	width: 100%;
	height: ${SIZE.SMALL}px;
`;
export const InsightsContainer = styled.div`
	min-height: 325px;
	padding: 24px 0 64px 0;
	border-top: 1px solid #d8d8d8;
`;
export const LineContainer = styled.div`
	height: 480px;
	width: 100%;
	svg {
		height: 520px;
	}
	padding-bottom: 20px;
`;
export const GraphContainer = styled.div`
	height: 380px;
	width: 100%;
`;

export const PieColumn = styled.div`
	width: 100%;
	height: 280px;
	position: relative;
	margin-bottom: ${SIZE.XXLARGE}px;
	@media (min-width: ${BREAKPOINTS.TABLET}px) {
		flex-direction: row;
		width: 210px;
		height: 220px;
		margin-bottom: ${SIZE.LARGE}px;
	}
	@media (min-width: ${1080}px) {
		width: 220px;
		height: 330px;
	}
	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		svg > g > g:last-of-type {
			display: block;
			transform: translateX(25px) translateY(210px);
		}
	}
`;
export const Stat = styled.h2`
	padding: 0;
	margin: 0;
	font-family: Helvetica, Arial;
	font-size: 32px;
	color: black;
`;
export const Loader = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 400px;
	svg {
		circle {
			/* fill: ${COLORS.BLACK}; */
			stroke: ${COLORS.BLACK};
		}
	}
`;
export const BottomValue = styled.text`
	background: red;
	padding: 10px 5px;
	margin: 5px;
	width: 100%;
	height: 40px;
`;
export const StatsRow = styled.div`
	display: flex;
	flex: 1;
	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		display: block;
	}

`;
export const StatColumn = styled.div`
	width: 25%;
	padding-left: 24px;
	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		display: flex;
		width: 100%;
	}
`;
export const ChartContainer = styled.div`
	width: 75%;
	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		display: flex;
		width: 100%;
		padding-left: 24px;
		padding-top: 24px;
	}
`;
export const StatItem = styled.div`
	margin: ${SIZE.MEDIUM}px 0 0;
	p {
		min-width: 80px;
		margin-right: ${SIZE.MEDIUM}px;
		font-size: ${FONT.SIZE.BODY} !important;
	}
	max-width: 25%;
	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		p {
			font-size: 12px!important;
		}
		h2 {
			font-size: 16px!important;
		}
	}
`;


export const ChartLabel = styled.div`
	color: #808080;
	text-transform: uppercase;
	font-size: 12px;
	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		position: absolute;
	}
`;
export const StatLabel = styled.div`
	color: #808080;
	text-transform: uppercase;
	font-size: 12px;
`;
export const PieContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	@media (min-width: ${BREAKPOINTS.TABLET}px) {
		flex-direction: row;
	}
`;
export const HeaderRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		display: block;
	}
`;
export const HeaderOption = styled.div`
	display: flex;
	flex-direction: row;
	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		padding-left: 24px;
	}
`;
export const SectionHeader = styled.h2`
	font-size: 40px;
	font-family: "BitstreamRomanaBold";
	font-weight: bold;
	margin: 0 0 24px 0;
	padding: 0;
	line-height: 1;

	span {
		clear: both;
		padding-left: 24px;
		display:block;
	}

	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		font-size: 24px;
		padding-left: 24px;
		span {
			padding-left: 0;
		}
	}
`;

export const AnnouncementImg = styled.img`
	width: 100%;
	height: auto;
	margin-bottom: 10px;
`;


export const AnnouncementHeadline = styled.h3`
	font-size: 24px;
	width: 88.8%;
    margin: 0;
    line-height: 1.2;
`;

export const AnnouncementCopy = styled.p`
	font-size: 14px;
	width: 88.8%;
	margin: 10px 0 14px 0;
`;

export const SampleNotice = styled.div`
	font-size: 14px;
	display:inline-block;
	padding: 10px;
	background: #DDD;
	color: #222;
	margin-left: 20px;
`;


