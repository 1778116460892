import styled from "styled-components";
import { SIZE, FONT, COLORS } from "../../config";

export const Terms = styled.div`
  width: 100%;
  height: 100%;
`;
export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: ${SIZE.LARGE}px 0 ${SIZE.LARGE}px;
  button {
    padding: ${SIZE.MEDIUM}px;
  }
`;
export const Inner = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  padding: ${SIZE.MEDIUM}px;
  width: calc(100% - ${SIZE.LARGE}px);
  height: 100%;
  a:link,
  a:active,
  a:visited,
  a:hover {
    color: black;
    font-weight: bold;
  }
`;
