import styled from "styled-components";
import { SIZE, FONT, COLORS } from "../../../../config";

export const Error = styled.div`
	padding: 0 ${SIZE.MEDIUM}px;
	color: tomato;
`;

export const Label = styled.div`
	font-size: ${FONT.SIZE.BODY};
	margin: ${SIZE.MEDIUM}px 0 ${SIZE.SMALL}px;
`;

export const Footer = styled.div`
	height: 100px;
	width: 100%;
`;

export const HolidayMsg = styled.div`
	margin: ${SIZE.MEDIUM}px 0;
	padding: ${SIZE.MEDIUM}px;
	border-radius: ${SIZE.BORDER_RADIUS}px;
	background: ${COLORS.BLUE_MID};
	color: ${COLORS.BLUE_DARK};
`;
