import styled from "styled-components";
import { BREAKPOINTS } from "../../config";

interface iTips {
	status: string;
}

export const Container = styled.div`
	width: 100%;
	max-width: 800px;
`;
export const TipRow = styled.div`
	background: ${(props: iTips) =>
		props.status === 'good' ? `#D5FFBB` : `#FFECBC`};
	cursor: pointer;
	width: 100%;
	padding: 12px;
	margin-bottom: 48px;

	span {
		float: right;
	}
	ul {
		padding-left: 24px;
	}
`;

export const StatRow = styled.div`
	display: flex;
	flex: 1;
	margin-bottom: 12px;
	h3 {
		width: 50%;
		font-size: 24px;
		margin: 0;
	}
	p, span {
		font-size: 14px;
	}
	p {
		opacity:  0.6;
		margin: 0;
	}
`;
export const StatColumn = styled.div`
	width: 50%;
	display: flex;
	
	h3, h4, span {
		width: 50%;
		font-weight: normal;
	}
	span {
		opacity: 0.6;
	}
`;

export const SectionHeader = styled.h2`
	font-size: 40px;
	font-family: "BitstreamRomanaBold";
	font-weight: bold;
	margin: 0 0 24px 0;
	padding: 0;
	line-height: 1;

	span {
		clear: both;
		padding-left: 24px;
		display:block;
	}

	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		font-size: 24px;
		span {
			padding-left: 0;
		}
	}
`;

