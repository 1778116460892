import styled from "styled-components";
import { COLORS, SIZE, FONT, BREAKPOINTS } from "../../config";
import TodoBackground from "./../../assets/Images/todobackground.png";
import AllDoneBackground from "./../../assets/Images/alldonebg.png";

interface props {
	done?: boolean;
};


export const Container = styled.div`
	width: calc(100% - ${SIZE.MEDIUM * 2}px);
	padding: ${SIZE.MEDIUM}px;
	margin: auto;
	display: flex;

	@media (min-width: ${BREAKPOINTS.DEKSTOP_MEDIUM}px) {
		width: calc(100% - ${SIZE.LARGE * 2}px);
		/* padding: ${SIZE.LARGE}px;*/
		max-width: 1020px; 
		margin: auto;
	}
	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		display:block;
	}
`;

export const TodoHeader = styled.h2`
	font-size: 42px;
	margin-top: 0;
	margin-bottom: 12px;
	line-height: 1;
`;


export const ContentContainer = styled.div<props>`
	display:flex;
	width: 100%;
`;

export const Half = styled.div`
	width: 50%;
`;

export const PhotosContainer = styled.div<props>`
	width: 34%;
	align-self: flex-start;  
	margin-top: 58px;
	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		width: 100%;
	}
`;

export const PhotoContent = styled.div<props>`
	width: 100%;
	padding: 0 24px;

	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		padding: 0;	
	}
`;

export const BackButton = styled.button`
	background: white;
	border: 0;
	outline: 0;
	font-size: 24px;
	cursor: pointer;
	margin-bottom: 24px;
`;

export const Loader = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 60vh;
`;

export const Done = styled.div<props>`
	background: ${props => (props.done === true) ? '#4E4E4E' : '#CCC'};
	border-radius: 2px;
	width: 32px;
	height: 32px;
    font-size: 22px;
    padding-left: 7px;
	margin-top: 12px;
	color: white;
`;

export const TodoLine = styled.div`
	display: flex;
`;

export const Order = styled.div`
	font-size: 42px;
	min-width: 48px;
`;

export const Description = styled.div`

`;


export const Title = styled.h2`
	font-size: 40px;
	font-family: "BitstreamRomanaBold";
	font-weight: bold;
	text-transform: uppercase;
	margin: 0 0 24px 0;
	padding: 0;
	line-height: 1;

	span {
		clear: both;
		padding-left: 24px;
		display:block;
	}

	@media (max-width: ${BREAKPOINTS.MOBILE_LARGE}px) {
		font-size: 24px;

		span {
			padding-left: 12px;
		}
	}
`;

export const IframeContainer = styled.div`
	position: relative;
	overflow: hidden;
	width: 100%;
	padding-top: 56%;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}
`;