import React, { useRef, useState, useEffect } from "react";
import { animated, useSpring } from "react-spring";
import { FormattedMessage } from "react-intl";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSalons } from "../../../../contexts/AdminContext";
import { startSetSocialMediaMessage } from "../../../../actions/salonsActions";
import { Button, Content, Input, SkipButton } from "../../../../components";
import { SectionType, OnboardingDetails } from "../../types";

import { Label, Tags, NoTags, InputRow, Error, Loader } from "./styles";
import mixpanel from 'mixpanel-browser';

interface IProps {
	onboardVersion: string;
	transitionConfig?: any;
	onNavigate: Function;
	onCompleteSection: Function;
	updateRegistrationState: Function;
	registrationDetails: OnboardingDetails;
	salonId: string;
}

const SocialComponent = (props: IProps) => {
	const contentScroll = useRef<HTMLDivElement>(null);
	const [ state, dispatch ] = useSalons();
	const [ hashtags, setHashtags ] = useState(
		props.registrationDetails.hashtags || undefined
	);
	const [ disabled, setDisabled ] = useState(false);
	const [ inputName, setInputName ] = useState("");
	const [ error, setError ] = useState("");
	const [ loading, setLoading ] = useState(false);

	useEffect(() => {
		if (contentScroll && contentScroll.current) {
			contentScroll.current.scrollTop = 0;
			window.scrollTo(0, 0);
		}
		mixpanel.track("PMA Onboarding - Add socials - Page accessed event");
	}, []);

	useEffect(
		() => {
			updateState();
		},
		[ hashtags ]
	);

	const onSubmit = async () => {
		setLoading(true);
		if (hashtags && hashtags.length) {
			let socialMsg = "";
			hashtags.forEach((tag: string, i: number) => {
				socialMsg = socialMsg + " " + tag;
			});
			const result = await dispatch(
				startSetSocialMediaMessage(props.salonId, socialMsg)
			);
			/* TODO: Remove the TS-Ignore and Fix returning Void so can check for errors */
			// @ts-ignore
			if (result && result.errors) {
				setError("Oh no, failed to update the social message at this time");
				setLoading(false);
				return;
			} else {
				/* All good, proceed.. */
				proceedNextStep();
			}
		} else {
			/* No Staff to add, just proceed */
			proceedNextStep();
		}
	};

	const onInputChange = (value: string, name: string) => {
		if (value.trim() === '') {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
		setInputName(value);
	};

	const removeHashTag = (name: string) => {
		const tagRemoved = hashtags && hashtags.filter(tag => tag !== name);
		setHashtags(tagRemoved);
	};

	const addHashTag = () => {
		const newTag = hashtags ? [ ...hashtags ] : [];
		newTag.push(inputName);
		setHashtags(newTag);
		setInputName("");
		setDisabled(false);
	};

	const updateState = () => {
		const state: OnboardingDetails = {
			hashtags
		};
		props.updateRegistrationState(state);
	};

	const _handleKeyDown = e => {
		if (e.key === "Enter") {
			addHashTag();
		}
	};

	const proceedNextStep = () => {
		setLoading(false);
		props.onNavigate(SectionType.REBOOKING);
		props.onCompleteSection();
	};

	const transitionConfig = useSpring(props.transitionConfig);

	return (
		<Content as={animated.div} style={transitionConfig} ref={contentScroll}>
			<h1>
				<FormattedMessage id="registration.social.title" />
			</h1>
			<div className="margin-bottom-mid">
				<FormattedMessage id="registration.social.body" />
			</div>
			<div className="margin-bottom-lrg">
				<InputRow>
					<div className="input-area">
						<Input
							onChange={onInputChange}
							placeholder={"Enter a #hashtag or @handle"}
							value={inputName}
							label="Hashtags and handles"
							name="hashtags"
							onKeyDown={_handleKeyDown}
						/>
					</div>
					<div className="button-area">
						<Button disabled={!inputName} onPress={addHashTag} label="ADD" />
					</div>
				</InputRow>
			</div>
			<Label>
				<FormattedMessage id="registration.social.yourtags" />
			</Label>
			<div className="margin-bottom-lrg">
				{hashtags && hashtags.length ? (
					hashtags.map((member: string, i: number) => (
						<Tags key={i} onClick={() => removeHashTag(member)}>
							{member}
							<span>
								<FormattedMessage id="registration.button_remove" />
							</span>
						</Tags>
					))
				) : (
					<NoTags>No hashtags or handles added yet</NoTags>
				)}
			</div>
			{loading ? (
				<Loader>
					<CircularProgress />
				</Loader>
			) : (
				<Button disabled={disabled || hashtags?.length === 0 } onPress={onSubmit} label="NEXT →" />
			)}
			<Error>{error && error}</Error>
			<SkipButton onPress={proceedNextStep} />
		</Content>
	);
};

export default SocialComponent;
