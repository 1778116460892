import { IActions } from "../contexts/createAsyncDispatch";
import { UpdateTermsOfService_updateUser } from "../graphql/UpdateTermsOfService";
import { GetTermsOfService_defaultTermsOfService } from "../graphql/GetTermsOfService";
import { ActionSheetIOS } from "react-native";

export interface IStateTermsAndConditions {
  loading: boolean;
  termsAndConditions: GetTermsOfService_defaultTermsOfService | null;
  me: UpdateTermsOfService_updateUser | null;
  user: UpdateTermsOfService_updateUser | null;
}

export interface ILoadingTermsAndConditionsAction extends IActions {
  type: "LOADING";
  loading: boolean;
}

export interface ISetTermsAndConditionsAction extends IActions {
  type: "SET_TERMS_AND_CONDITIONS";
  termsAndConditions: GetTermsOfService_defaultTermsOfService;
  user: UpdateTermsOfService_updateUser;
}

export interface ISetUpdateTermsAndConditionsAction extends IActions {
  type: "SET_USER";
  user: UpdateTermsOfService_updateUser;
}

export type ITermsAndConditionsAction =
  | ILoadingTermsAndConditionsAction
  | ISetUpdateTermsAndConditionsAction
  | ISetTermsAndConditionsAction;

export const initialStateTermsAndConditions: IStateTermsAndConditions = {
  loading: true,
  termsAndConditions: null,
  me: null,
  user: null
};

export const reducerTermsAndConditions = (
  state: IStateTermsAndConditions,
  action: ITermsAndConditionsAction
) => {
  console.log("TERMS action", action);
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: action.loading
      };

    case "SET_TERMS_AND_CONDITIONS":
      return {
        ...state,
        loading: action.loading,
        termsAndConditions: action.termsAndConditions,
        user: action.user,
        me: action.user
      };

    case "SET_USER":
      return {
        ...state,
        user: action.user
      };
  }
  return state;
};
