import React from "react";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

export interface IPaginationProps {
  baseUrl: string;
  queryString: string;
  totalCount: number;
}

export interface IPageData {
  params: URLSearchParams;
  limit: number;
  page: number;
}

export function pageData (queryString: string) {
  const params = new URLSearchParams(queryString);
  const limit = Number.parseInt(params.get("limit") || "10");
  const page = Number.parseInt(params.get("page") || "1");

  return {
    params,
    limit,
    page
  };
}

export function Pagination (props: IPaginationProps) {
  const { baseUrl, queryString, totalCount } = props;
  const { limit, page } = pageData(queryString);
  const totalPages = Math.ceil(totalCount / limit);

  const nextParams = new URLSearchParams(queryString);
  nextParams.set("page", Math.min(page + 1, totalPages).toString());
  const prevParams = new URLSearchParams(queryString);
  prevParams.set("page", Math.max(page - 1, 1).toString());

  if (totalPages === 1) return null;
  return (
    <div>
      <IconButton>
        <Link to={`${baseUrl}?${prevParams.toString()}`}>
          <KeyboardArrowLeftIcon style={{ color: "#9E9D9E" }} />
        </Link>
      </IconButton>
      <span>
        {page} / {totalPages}
      </span>
      <IconButton>
        <Link to={`${baseUrl}?${nextParams.toString()}`}>
          <KeyboardArrowRightIcon style={{ color: "#9E9D9E" }} />
        </Link>
      </IconButton>
    </div>
  );
}
