import React, { Component } from "react";
import { Card, CardContent } from "@material-ui/core";
import SignInForm from "./components/SignInForm";
import SignUpForm from "./components/SignUpForm";
import logo from "./../../assets/Images/logo_blacktransparent.png";
import ForgotPasswordForm from "./components/ForgotPasswordForm";

// types
interface IState {
  view: string;
}
export default class AuthCard extends Component<{}, IState> {
  public constructor(props: {}) {
    super(props);
    this.state = {
      view: "signin"
    };
    this.toggleView = this.toggleView.bind(this);
  }

  public render() {
    const { view } = this.state;
    return (
      <Card id="auth-card">
        <img
          src={logo}
          alt="piiq"
          style={{
            height: "125px",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto"
          }}
        />
        <CardContent>
          {view === "signin" && <SignInForm toggleView={this.toggleView} />}
          {/* {view === "signup" && <SignUpForm toggleView={this.toggleView} />} */}
          {view === "forgot" && (
            <ForgotPasswordForm toggleView={this.toggleView} />
          )}
        </CardContent>
      </Card>
    );
  }

  private toggleView(view: string) {
    this.setState({ view });
  }
}
