import React, { useRef, useEffect } from "react";
import { animated, useSpring } from "react-spring";
import { Elements } from "react-stripe-elements";
import { FormattedMessage } from "react-intl";
import { Content, CheckoutForm } from "../../../../components";
import { SummaryType } from "../../types";
import { Inner, Footer } from "./styles";
import mixpanel from 'mixpanel-browser';

interface IProps {
	transitionConfig?: any;
	onNavigate: Function;
	onSkipSection: Function;
	onCompleteSection: Function;
	supplierCode: string;
}

const PaymentComponent = (props: IProps) => {
	const contentScroll = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (contentScroll && contentScroll.current) {
			contentScroll.current.scrollTop = 0;
			window.scrollTo(0, 0);
		}
		mixpanel.track("PMA Register Payment page accessed event");
	}, []);

	const onSubmit = () => {
		props.onNavigate(SummaryType.ORDER);
		props.onCompleteSection();
	};

	const transitionConfig = useSpring(props.transitionConfig);

	return (
		<Content as={animated.div} style={transitionConfig} ref={contentScroll}>
			<div style={{maxWidth: 900, margin: "0 auto", paddingBottom: 100}}>
			<h1>
			<FormattedMessage id="signup.payment.title" />
			</h1>
			<Elements>
				<CheckoutForm supplierCode={props.supplierCode} onSubmit={onSubmit} />
			</Elements>
			<Inner />
			<Footer />
			</div>
		</Content>
	);
};

export default PaymentComponent;
