import styled from "styled-components";
import { COLORS, SIZE, FONT, BREAKPOINTS } from "../../config";
import BigCircle from "./../../assets/Images/bigcircle.png";

interface props {
	i?: number;
	complete?: boolean;
	isTotal?: boolean;
	isReward?: boolean;
};

export const Item = styled.div`
	display: block;
	flex: 0 32%;
	margin-bottom: 2%; /* (100-32*3)/2 */
	padding: 20px;
	box-sizing: border-box;
	position: relative;
	cursor: pointer;
	div {
		position: relative;
	}
	img,span{;width:100%;top:0;bottom:0;margin:auto;height: auto;}
	span{
		position: absolute; 
		margin: 0; 
		top: 50%; 
		left: 0;
		height:1.5em;
		text-align:center;
		font:48px/1.5 sans-serif;
		color:white;
		text-shadow:0 0 0.5em black;
		margin-top: -0.75em;}

	p {
		margin: 0;
	}

	small {
		display: block;
	}

	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		flex: 0 50%;
	}
`;


export const Link = styled.a`
	display: inline-block;
	border-bottom: 1px solid black;
	text-transform: uppercase;
	font-weight: bold;
	cursor: pointer;
`;