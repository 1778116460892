import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useParams, useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import {
	AnnouncementImg,
	AnnouncementHeadline,
	AnnouncementCopy
} from "./styles";
import Announcement1Image from "./../../assets/Images/announcement1.png";
import Announcement2Image from "./../../assets/Images/announcement2.png";
import Announcement3Image from "./../../assets/Images/announcement3.png";


interface iProps {
	imageSrc: string;
	headline: string;
	copy: any;
	buttonCopy?: string;
	href?: string;
	route?: string;
}


function Announcement(props: iProps) {
	const { salonId } = useParams();
	const history = useHistory();
	return (
		<div style={{ paddingBottom: "64px" }}>
			{/* <AnnouncementImg src={props.imageSrc} /> */}
			<AnnouncementHeadline>{props.headline}</AnnouncementHeadline>
			<AnnouncementCopy>{props.copy}</AnnouncementCopy>
			{props.href ? (
				<a href={props.href} target="_blank" style={{textDecoration: 'none'}}>
					<Button
						variant="outlined"
						style={{
							color: "white",
							fontSize: "16px",
							fontFamily: "Helvetica",
							borderRadius: "0px",
							background: "black",
							textTransform: "none",
							letterSpacing: 0
						}}
					>
						{props.buttonCopy}
					</Button>
				</a>
			) : (
				<>
				{props.buttonCopy ? (
					<Button
						variant="outlined"
						onClick={() => history.push(`/${salonId}/${props.route}`)}
						style={{
							color: "white",
							fontSize: "16px",
							fontFamily: "Helvetica",
							borderRadius: "0px",
							background: "black",
							textTransform: "none",
							letterSpacing: 0
						}}
					>
						{props.buttonCopy}
					</Button>
				) : (
					<></>
				)}
				</>
			)}
			
		</div>
	)
}

export default function Announcements () {

	return (
		<Grid container spacing={3} style={{
			paddingLeft: "24px"

			}}>
			<Grid item xs={12} md={4} sm={4}>
				<Announcement 
					imageSrc={Announcement1Image} 
					headline={"New Training!"} 
					copy={["• How to introduce piiq to clients?",(<br/>),"• What if you disagree with the recommendations?"]} 
					buttonCopy={"Go to training"} 
					route={"ibb"} 
					/> 
			</Grid>
			<Grid item xs={12} md={4} sm={4}>
				<Announcement 
					imageSrc={Announcement2Image} 
					headline={"Free Salon Marketing!"} 
					copy={["• Add a service to your booking menu ‘advanced consultation and analysis’.",(<br/>),"• Post the link in the Facebook group and tag @Bjorn Behrendt"]} 
					buttonCopy={"Go to FB group"} 
					href={"https://www.facebook.com/groups/229015668821860"} 
					/>
			</Grid>
			<Grid item xs={12} md={4} sm={4}>
				<Announcement 
					imageSrc={Announcement3Image} 
					headline={"New Features!"} 
					copy={["• Product switching - change the recommended products in the mirror. ",(<br/>),"• Add products - add up to 10 products per session. ",(<br />),"• Treatment switching - change the recommended treatment in piiq Pro."]} 
					buttonCopy={"Not updated yet?"} 
					route={'ibb'} 
					/>
			</Grid>
		</Grid>
	);
}
