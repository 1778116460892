import { IActions } from "../contexts/createAsyncDispatch";
import { GetSalonAppointments_getSalon_allAppointments_items_cart_items } from "../graphql/GetSalonAppointments";
// import { useLocalStorage } from "../../utils";
import { CACHE } from "../config";

export interface IStateAppointments {
	nextToken: string;
	appointments: IAppointment[];
	loading: boolean;
}

export interface IAppointment {
	id: string;
	clientName: string;
	clientId: string;
	staffName: string;
	staffId: string;
	cartItems: (GetSalonAppointments_getSalon_allAppointments_items_cart_items | null)[];
	itemCount: number;
	deliveryType: "COLLECT_FROM_RECEPTION" | "DELIVERY_TO_HOME" | null;
	createdAt: string;
	status: "CANCELLED" | "COMPLETED" | "WAITING" | "N/A";
	rebookStatus: "SCHEDULED" | "COMPLETED" | "OPEN" | "N/A";
	rebookDelay: number | null;
	rebookLength: number | null;
	rebookNotificationScheduledAt: string | null;
	rebookNotificationSentAt: string | null;
	rebookedAt: string | null;
	cartId: string;
	notes: string | null;
	clientSelfieImage: string | null;
	client360Image: string | null;
	facialAnalysis: any | null;
	hairAnalysis: any | null;
	productRecommendations: any | null;
	imageLikes: any | null;
	imageShares: any | null;
	supportYourSalon: any | null;
	salonPickup: any | null;
	purchasedItems: any | null;
}

export interface ICart {
	deliveryType: "COLLECT_FROM_RECEPTION" | "DELIVERY_TO_HOME" | null;
	id: string;
	items: (GetSalonAppointments_getSalon_allAppointments_items_cart_items | null)[];
	itemCount: number;
	status: "CANCELLED" | "COMPLETED" | "WAITING" | "N/A";
}

export interface IGetAppointmentsAction {
	type: "GET_ALL_APPOINTMENTS";
	id?: string;
	salonId: string;
	status: "CANCELLED" | "COMPLETED" | "WAITING" | "N/A";
	limit?: number;
	nextToken?: string;
	staff: any;
}

export interface ISetAppointmentsAction extends IActions {
	type: "SET_APPOINTMENTS";
	id?: string;
	appointments: IAppointment[];
}

export interface ISetLoadingAction {
	type: "SET_LOADING";
	loading: boolean;
}

export interface IUpdateStatusAction {
	type: "UPDATE_STATUS";
	id: string;
	status: "CANCELLED" | "COMPLETED" | "WAITING" | "N/A";
}
export interface IUpdateAppointmentAction {
	type: "UPDATE_APPOINTMENT";
	id: string;
	appointment: IAppointment;
}

export interface IUpdateCartAction {
	type: "UPDATE_CART";
	id: string;
	cart: ICart;
}

export interface IUpdateRebookStatusAction {
	type: "UPDATE_REBOOK_STATUS";
	id: string;
	rebookDelay: number | null;
	rebookLength: number | null;
	rebookNotificationScheduledAt: any | null;
	rebookNotificationSentAt: any | null;
	rebookedAt: any;
	rebookStatus: "SCHEDULED" | "COMPLETED" | "OPEN" | "N/A";
}

export interface ISetAppointmentRebooked {
	data: {
		setAppointmentRebooked: IUpdateRebookStatusAction;
	};
}

export interface IDisableAppointmentReminder {
	data: {
		disableAppointmentReminder: IUpdateRebookStatusAction;
	};
}

export interface IEnableAppointmentReminder {
	data: {
		enableAppointmentReminder: IUpdateRebookStatusAction;
	};
}

export interface IClearAppointmentRebooked {
	data: {
		clearAppointmentRebooked: IUpdateRebookStatusAction;
	};
}
export interface IClearAppointmentsAction extends IActions {
	type: "CLEAR_APPOINTMENTS";
	id?: string;
}

export interface ISwitchTreatmentAction {
	type: "SWITCH_TREATMENT";
	appointmentId: string;
	newTreatment: string;
}

export type IAppointmentAction =
	| IGetAppointmentsAction
	| ISetAppointmentsAction
	| ISetLoadingAction
	| IUpdateStatusAction
	| IUpdateRebookStatusAction
	| IUpdateAppointmentAction
	| IUpdateCartAction
	| IClearAppointmentsAction
	| ISwitchTreatmentAction;

export type IAppointmentAsyncAction = any;

export const initialStateAppointments: IStateAppointments = {
	appointments: [],
	nextToken: "",
	loading: true
};

export const reducerAppointments = (
	state: IStateAppointments,
	action: IAppointmentAction | IAppointmentAsyncAction
) => {
	state.appointments.findIndex(appointment => appointment.id === action.id);

	switch (action.type) {
		case "SET_APPOINTMENTS":
			/** FIX STATUS IF NO REBOOK OR RETAIL CART ITEMS */
			const appointmentStatus = action.appointments.slice(0);
			console.log("SETTING APPOINTMENTS", appointmentStatus)
			appointmentStatus.map(appointment => {
				appointment.rebookStatus =
					appointment.rebookLength ||
					appointment.rebookNotificationScheduledAt ||
					appointment.rebookedAt
						? appointment.rebookStatus
						: "N/A";
				appointment.status =
					appointment.cartItems.length > 0 ? appointment.status : "N/A";
			});
			return {
				...state,
				appointments: appointmentStatus.length
					? appointmentStatus
					: state.appointments,
				nextToken: action.nextToken
			};
		case "CLEAR_APPOINTMENTS":
			return {
				...state,
				appointments: [],
				loading: false
			};
		case "SET_LOADING":
			return {
				...state,
				loading: action.loading
			};

		case "UPDATE_STATUS":
			return updateStatus(state, action);

		case "UPDATE_REBOOK_STATUS":
			return updateRebookStatus(state, action);

		case "UPDATE_APPOINTMENT":
			return updateAppointmentState(state, action);

		case "UPDATE_CART":
			return updateCartState(state, action);
	}
	return state;
};

function updateStatus (state: IStateAppointments, action: IUpdateStatusAction) {
	let appoinmentsCache = window.localStorage.getItem(CACHE.APPOINTMENTS);
	const appointments = JSON.parse(
		// @ts-ignore
		appoinmentsCache
	);

	const i = appointments.findIndex(s => s.cartId === action.id);
	return {
		...state,
		appointments: appointments
			? [
					...appointments.slice(0, i),
					{ ...appointments && [ i ], status: action.status },
					...(appointments && appointments.slice(i + 1))
				]
			: [],
		loading: false
	};
}

function updateAppointmentState (
	state: IStateAppointments,
	action: IUpdateAppointmentAction
) {
	console.log('window.localStorage', window.localStorage)
	console.log(CACHE.APPOINTMENTS);

	let appointmentsData = window.localStorage.getItem(CACHE.APPOINTMENTS);
	const appointmentsCache = JSON.parse(
		// @ts-ignore
		appointmentsData
	);

	let appointments: IAppointment[] = [];
	if (appointmentsCache) {
		const i = appointmentsCache.findIndex(s => s.id === action.id);
		console.log("i", i, appointmentsCache);
		if (i !== -1) {
			appointments = [
				...appointmentsCache.slice(0, i),
				action.appointment,
				...appointmentsCache.slice(i + 1)
			];
		} else {
			appointments = [ ...appointmentsCache, action.appointment ];
		}
	}
	
	return {
		...state,
		appointments,
		loading: false
	};
}

function updateRebookStatus (
	state: IStateAppointments,
	action: IUpdateRebookStatusAction
) {
	let appointmentsData = window.localStorage.getItem(CACHE.APPOINTMENTS);
	const appointmentsCache = JSON.parse(
		// @ts-ignore
		appointmentsData
	);
	const i = appointmentsCache.findIndex(s => s.id === action.id);

	return {
		...state,
		appointments: [
			...appointmentsCache.slice(0, i),
			{
				...appointmentsCache[i],
				rebookDelay: action.rebookDelay,
				rebookLength: action.rebookLength,
				rebookNotificationScheduledAt: action.rebookNotificationScheduledAt,
				rebookNotificationSentAt: action.rebookNotificationSentAt,
				rebookedAt: action.rebookedAt,
				rebookStatus:
					action.rebookLength ||
					action.rebookNotificationScheduledAt ||
					action.rebookedAt
						? action.rebookStatus
						: "N/A"
			},
			...appointmentsCache.slice(i + 1)
		],
		loading: false
	};
}

function updateCartState (
	state: IStateAppointments,
	action: IUpdateCartAction
) {
	let appointmentsData = window.localStorage.getItem(CACHE.APPOINTMENTS);
	const appointmentsCache = JSON.parse(
		// @ts-ignore
		appointmentsData
	);
	const i = appointmentsCache.findIndex(s => s.cartId === action.id);
	return {
		...state,
		appointments: [
			...appointmentsCache.slice(0, i),
			{
				...appointmentsCache[i],
				cartItems: action.cart.items,
				itemCount: action.cart.itemCount,
				deliveryType: action.cart.deliveryType,
				status: action.cart.status
			},
			...appointmentsCache.slice(i + 1)
		],
		loading: false
	};
}
