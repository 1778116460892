import React, { Component } from "react";
import {
	Route,
	Switch,
	Redirect,
	BrowserRouter,
	withRouter
} from "react-router-dom";

import AccountSettingsScreen from "./screens/Settings/AccountSettings/AccountSettingsScreen";
import DashboardScreen from "./screens/Dashboard/DashboardScreen";
import WeeklyReportScreen from "./screens/WeeklyReport/WeeklyReportScreen";
import NotFoundScreen from "./screens/NotFound/NotFoundScreen";
import AppointmentsScreen from "./screens/Appointments/AppointmentsScreen";
import ProductsScreen from "./screens/Products/ProductsScreen";
import RetailSettingsScreen from "./screens/Settings/RetailSettings/RetailSettingsScreen";
import SalonSelectorScreen from "./screens/SalonSelector/SalonSelectorScreen";
import SalonSettingsScreen from "./screens/Settings/SalonSettings/SalonSettingsScreen";
import SupportScreen from "./screens/Support/SupportScreen";
import PiiqPosterScreen from "./screens/PiiqPoster/PiiqPosterScreen";
import RegistrationScreen from "./screens/Registration";
import ApplessSignup from "./screens/ApplessSignup";
import OnboardingScreen from "./screens/Onboarding";
import ClientsScreen from "./screens/Clients";
import ClientScreen from "./screens/Clients/ClientScreen";
import IBBScreen from "./screens/IBB";
import IBBDetailScreen from "./screens/IBBDetail";
import AcademyScreen from "./screens/Academy";
import AcademyDetailScreen from "./screens/AcademyDetail";
import OrdersScreen from "./screens/Orders";
import OrderDetailsScreen from "./screens/OrderDetails";
import ResourcesScreen from "./screens/Resources";
import MessagingScreen from "./screens/Messaging";

export class Routes extends Component<any, any> {
	public constructor (props: any) {
		super(props);
	}

	public render () {
		return (
			<>
			<BrowserRouter>
				<Switch>
						
					{/* <Route path="/logout" component={LogoutScreen} exact /> */}
					<Route path="/" component={SalonSelectorScreen} exact />
					<Route path="/register" component={RegistrationScreen} exact />
					<Route path="/signup" component={ApplessSignup} exact />
					<Route path="/:salonId/resources" component={ResourcesScreen} exact />
					<Route path="/:salonId/ibb" component={IBBScreen} exact />
					<Route path="/:salonId/ibb/" component={IBBScreen} exact />
					<Route path="/:salonId/ibb/:ibbId" component={IBBDetailScreen} exact />
					<Route path="/:salonId/ibb/:ibbId/" component={IBBDetailScreen} exact />
					<Route path="/ibb" component={IBBScreen} exact />
					<Route path="/ibb/" component={IBBScreen} exact />
					<Route path="/ibb/:ibbId" component={IBBDetailScreen} exact />
					<Route path="/ibb/:ibbId/" component={IBBDetailScreen} exact />
					<Route
						path="/:salonId/onboarding"
						component={OnboardingScreen}
						exact
					/>
					<Route path="/:salonId/dashboard" component={DashboardScreen} exact />
					<Route path="/:salonId/weekly" component={WeeklyReportScreen} exact />
					<Route
						path="/:salonId/"
						component={withRouter(({ match }) => (
							<Redirect to={`/${match.params.salonId}/dashboard`} />
						))}
						exact
					/>
					<Route path="/:salonId/clients/" component={ClientsScreen} exact />
					<Route path="/:salonId/messaging/" component={MessagingScreen} exact />
					<Route path="/:salonId/academy" component={AcademyScreen} exact />
					<Route path="/:salonId/academy/:academyid" component={AcademyDetailScreen} exact />
					<Route path="/:salonId/orders" component={OrdersScreen} exact />
					<Route path="/:salonId/orders/:orderid" component={OrderDetailsScreen} exact />
					<Route path="/:salonId/clients/:clientId" component={ClientScreen} />
					<Route
						path="/:salonId/appointments/:appointmentId?"
						component={AppointmentsScreen}
						exact
					/>
					<Route path="/:salonId/products" component={ProductsScreen} exact />
					<Route
						path="/:salonId/piiqPoster"
						component={PiiqPosterScreen}
						exact
					/>
					<Route
						path="/:salonId/settings"
						component={withRouter(({ match }) => (
							<Redirect to={`/${match.params.salonId}/settings/salon`} />
						))}
						exact
					/>
					<Route
						path="/:salonId/settings/account"
						component={AccountSettingsScreen}
					/>
					<Route
						path="/:salonId/settings/retail"
						component={RetailSettingsScreen}
					/>
					<Route
						path="/:salonId/settings/salon"
						component={SalonSettingsScreen}
					/>
					<Route path="/:salonId/support" component={SupportScreen} exact />
					<Route component={NotFoundScreen} />
				</Switch>
			</BrowserRouter>
		</>
		);
	}
}
