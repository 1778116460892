import styled from "styled-components";
import { COLORS, SIZE } from "../../config";

export const PaymentRow = styled.div`
	/* background: tomato; */
	.StripeElement {
		padding: 12px;
		border-radius: ${SIZE.BORDER_RADIUS}px;
		margin: ${SIZE.MEDIUM}px 0 0;
		background: ${COLORS.GREY_MID_LIGHT};
		/* height: 30px; */
	}

	.is-invalid {
	}
	.is-empty {
	}
`;
