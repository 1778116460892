import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTermsAndConditions } from "../../contexts/AdminContext";
import Loading from "./../../components/LoadingComponent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import mixpanel from 'mixpanel-browser';

export default function TermsAndConditionDialog() {
  const [open, setOpen] = useState(false);
  const [state] = useTermsAndConditions();
  const { loading, termsAndConditions } = state;


  if (loading && !window.location.href.includes('ibb')) {
    return <Loading />;
  }

  if (!termsAndConditions || !termsAndConditions.bodyHtml) {
    return <></>;
  }

  function handleClickOpen() {
    setOpen(true);
    mixpanel.track("PMA Terms and Conditions - Page accessed event");
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <Button
        onClick={handleClickOpen}
        style={{
          textDecoration: "none",
          color: "black",
          fontSize: "0.6rem",
          fontFamily: "BitstreamRoman",
          textTransform: "capitalize",
          marginTop: "-15px"
        }}
      >
        Terms &amp; Conditions
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">
          <IconButton
            aria-label="Close"
            style={{
              position: "absolute",
              right: "40px",
              top: "75px"
            }}
            onClick={handleClose}
          >
            <CloseIcon style={{ width: "50px", height: "50px" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={false}>
          <DialogContentText
            style={{ padding: "30px" }}
            dangerouslySetInnerHTML={{ __html: termsAndConditions.bodyHtml }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
