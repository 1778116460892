import React, { useState, useEffect, useRef } from "react";
import { animated, useSpring } from "react-spring";
import { FormattedMessage } from "react-intl";
import {
  InputCounter,
  Button,
  Content,
  Input
} from "../../../../components";
import { SubscriptionType, Plan } from "../../types";
import {
  CalcMonthlyCost,
  CalcAnnualCost,
  ZapierNewBetaSignup
} from "../../../../utils";
import { SubscriptionState } from "../../../../contexts/SubscriptionContext";
import { SummaryType } from "../../types";
import { BlockRow, CounterRow, PlanButton, PaymentFreqButton, Footer, PlanHeader, ComingSoon, MostPopular, GirlBackground, AvailableNow } from "./styles";
import mixpanel from 'mixpanel-browser';
import ReactGA from 'react-ga';


ReactGA.initialize('UA-163128698-4');


interface IProps {
  transitionConfig?: any;
  onNavigate: Function;
  onCompleteSection: Function;
  onSkipSection: Function;
  updateSubscriptionState: Function;
  updateOrderContext: Function;
  scrollTo?: Function;
  onUpdateTotal: Function;
}


function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
	  width,
	  height
	};
  }


function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
	useEffect(() => {
	  function handleResize() {
		setWindowDimensions(getWindowDimensions());
	  }
  
	  window.addEventListener('resize', handleResize);
	  return () => window.removeEventListener('resize', handleResize);
	}, []);
  
	return windowDimensions;
  }



const SubscriptionsComponent = (props: IProps) => {
  
  const contentScroll = useRef<HTMLDivElement>(null);
  const context = SubscriptionState();
  const orderDetails = context.state.orderDetails;

  const [ mirrorQuantity, setMirrorQuantitiy ] = useState(
    orderDetails ? orderDetails.mirror_quantity : 0
  );
  const [ benchQuantity, setBenchQuantitiy ] = useState(
    orderDetails ? orderDetails.bench_mount : 0
  );
  const [ wallQuantity, setWallQuantitiy ] = useState(
    orderDetails ? orderDetails.wall_mount : 0
  );
  const [ subscriptionType, setSubscriptionType ] = useState(SubscriptionType[0]);
  const [ plan, setPlan ] = useState() as any;

  const [ betaEmail, setBetaEmail ] = useState('');
  const [ betaPhone, setBetaPhone ] = useState('');
  const [ betaName, setBetaName ] = useState('');
  const [ betaSalonName, setBetaSalonName ] = useState('');
  const [ betaFormSent, setBetaFormSent ] = useState(false);
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (contentScroll && contentScroll.current) {
      contentScroll.current.scrollTop = 0;
      window.scrollTo(0, 0);
    }

    const params = new URL(window.location.href).searchParams;
    const sub = params.get("sub");
    if (sub) {
      setSubscriptionType(sub);
    } 
    const _plan = params.get("plan");
    if (_plan) {
      setPlan(Plan[_plan]);
    } 
    
    mixpanel.track("PMA Register Subscriptions page accessed event");
  }, []);

  useEffect(
    () => {
      updateContext();
      
    },
    [ mirrorQuantity, benchQuantity, wallQuantity, subscriptionType, plan ]
  );

  const cyrb53 = function(str, seed = 0) {
    let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1>>>16), 2246822507) ^ Math.imul(h2 ^ (h2>>>13), 3266489909);
    h2 = Math.imul(h2 ^ (h2>>>16), 2246822507) ^ Math.imul(h1 ^ (h1>>>13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1>>>0);
};

  const onSubmitBetaForm = () => {
    ReactGA.event({
      category: 'Registration',
      action: 'Beta signup',
      label: plan+'-'+cyrb53(betaEmail)
    });
    ReactGA.set({ userId: cyrb53(betaEmail) });

    ZapierNewBetaSignup({betaName, betaEmail, betaSalonName, betaPhone, plan});
    setBetaFormSent(true);
  }

  const onNext = () => {
    props.onNavigate(SummaryType.ACCOUNT);
    props.onCompleteSection();
    props.updateOrderContext();
  };

  const onSubscriptionChange = (subtype: SubscriptionType) => {
    const t = SubscriptionType[subtype];
    console.log(t);
    setSubscriptionType(t);
    if (mirrorQuantity === 0) {
      setBenchQuantitiy(0);
      setWallQuantitiy(0);
    }
  };

  const onPlanChange = (plan: Plan) => {
    // DISABLE PLAN CHANGING FOR
    setPlan(plan);
  };

  const onMirrorChange = (value: string) => {
    let newValue = mirrorQuantity + 1;
    if (value === "decrease") {
      newValue = mirrorQuantity - 1;
    }
    if (mirrorQuantity <= 0) newValue = 1;
    setMirrorQuantitiy(newValue);
  };


  const updateContext = () => {
    props.updateSubscriptionState({
      subscription_type: subscriptionType,
      mirror_quantity: mirrorQuantity,
      wall_mount: wallQuantity,
      bench_mount: benchQuantity,
      colour_brand: 'ignore',
      plan: plan,
      total: subscriptionType == SubscriptionType[0] ? CalcAnnualCost(mirrorQuantity || 1, plan) : CalcMonthlyCost(mirrorQuantity || 1, plan)
    });
    props.onUpdateTotal(subscriptionType == SubscriptionType[0] ? CalcAnnualCost(mirrorQuantity || 1, plan) : CalcMonthlyCost(mirrorQuantity || 1, plan))
  };

  const getMonthlyDescription = () => {
    if (plan !== undefined) {
      let out = `$${CalcMonthlyCost(mirrorQuantity || 1, plan)}/mth inc GST`;
      return out;
    }
  }
  const getAnnualDescription = () => {
    if (plan !== undefined) {
      let out = `$${CalcAnnualCost(mirrorQuantity || 1, plan)} inc GST, billed annually`;
      return out;
    }
  }

  const pricePerMirror = () => {
    if (mirrorQuantity) {
      if (mirrorQuantity === 1) return 275
      if (mirrorQuantity === 2) return 225
      if (mirrorQuantity === 3) return 200
      if (mirrorQuantity >= 4) return 175
    } else {
      return 275
    }
  }
  
  const transitionConfig = useSpring(props.transitionConfig);

  return (
      <Content as={animated.div} style={transitionConfig} ref={contentScroll}>
        <div style={{maxWidth: 900, margin: "0 auto", paddingBottom: 100}}>
          
        
        <h1 style={{marginTop: 40}}>
          <FormattedMessage id="signup.subscription.title" />
        </h1>
        <p>(all prices are inclusive of GST)</p>
        <h2 style={{marginBottom: 12}}>
          <FormattedMessage id="signup.subscription.plan_title" />
        </h2>
        
        <BlockRow style={{flexWrap: "wrap", marginLeft: "-1%"}}>
        
          <PlanButton onClick={() => onPlanChange(Plan.FREE)} active={plan === Plan.FREE}>
            <PlanHeader>
              <h3>Free {(plan === Plan.FREE ? " ✓" : "")}</h3>
              <p>$0/mth</p>
            </PlanHeader>
            
              <div>
                <ComingSoon>Pre-register now</ComingSoon>
                {(plan === Plan.FREE || width > 720) && (<>
                  <p><strong>Try piiq Pro for FREE</strong></p>
                  <p className="description">Includes:</p>
                  <ul>
                    <li>piiq Pro consultation tools on your tablet or mobile.</li>
                    <li>AI haircare recommendations.</li>
                  </ul>
                  <p>*Does not include piiq Smart Mirror.</p>
                </>)}
              </div>
          </PlanButton>
          <PlanButton onClick={() => onPlanChange(Plan.GROWTH)} active={plan === Plan.GROWTH}>
            <PlanHeader>
              <h3>Growth {(plan === Plan.GROWTH ? " ✓" : "")}</h3>
              <p>$29/mth</p>
            </PlanHeader>
            
              <div>
                <ComingSoon>Pre-register now</ComingSoon>
                {(plan === Plan.GROWTH || width > 720)&& (<>
                  <p><strong>Ideal for independent hair professionals</strong></p>
                  <p className="description">Includes (for 1 hair pro):</p>
                  <ul>
                    <li>All Free plan features.</li>
                    <li>Business tools.</li>
                    <li>Advanced client records.</li>
                  </ul>
                  <p>*Does not include piiq Smart Mirror.</p>
                </>
                )}
              </div>
          </PlanButton>
          <PlanButton onClick={() => onPlanChange(Plan.SALON)} active={plan === Plan.SALON}>
            <PlanHeader>
              <h3>Salon {(plan === Plan.SALON ? " ✓" : "")}</h3>
              <p>$79/mth</p>
            </PlanHeader>
             
              <div>
                <ComingSoon>Pre-register now</ComingSoon>
                {(plan === Plan.SALON || width > 720) && (
                  <>
                  <p><strong>piiq Pro for teams</strong></p>
                  <p className="description">Includes (for UNLIMITED hair pros per location):</p>
                  <ul>
                    <li>All Growth plan features.</li>
                    <li>Unlimited logins for all team members in one location.</li>
                  </ul>
                  <p>*Does not include piiq Smart Mirror.</p>
                  </>
                )}
              </div>
          </PlanButton>
          <PlanButton onClick={() => onPlanChange(Plan.PLUS)} active={plan === Plan.PLUS}>
            <PlanHeader>
              <h3>Plus {(plan === Plan.PLUS ? " ✓" : "")}</h3>
              <p>$275/mth</p>
            </PlanHeader>
            
              <div>
                <AvailableNow>Sign up now</AvailableNow>
                {(plan === Plan.PLUS || width > 720) && (
                  <>
                  <p><strong>THE premium Smart Salon experience</strong></p>
                  <p className="description">Includes (for UNLIMITED hair pros per location):</p>
                  <ul>
                    <li>All Salon plan features.</li>
                    <li>The patented piiq Smart Mirror, delivered and installed for you.</li>
                  </ul>
                  </>
                )}
              </div>
          </PlanButton>
        </BlockRow>

        {(plan === Plan.PLUS) && (
          <>
          <CounterRow>
            <div className="counter-row-label">
              <h2>
                <FormattedMessage id="signup.subscription.mirror_title" />
              </h2>
            </div>
            <InputCounter
              onChange={(quantity: string) => onMirrorChange(quantity)}
              value={mirrorQuantity}
            />
          </CounterRow>
          </>
        )}
        {plan === Plan.FREE && ( 
          <h3 style={{marginTop: 30}}>Our Free plan is coming very soon.</h3>
        )}
        {plan === Plan.GROWTH && ( 
          <h3 style={{marginTop: 30}}>Our Growth plan is coming very soon.</h3>
        )}
        {plan === Plan.SALON && ( 
          <h3 style={{marginTop: 30}}>Our Salon plan is coming very soon.</h3>
        )}
        {(plan === Plan.FREE || plan === Plan.GROWTH || plan === Plan.SALON) && ( 
          <>
          <p>Enter your details below and we'll contact you as soon as it's ready</p>
          <Input
            onChange={setBetaName}
            label="Your name*"
            placeholder="e.g. Sam Johnson"
            name="beta_name"
            value={betaName}
          />
          <Input
            onChange={setBetaEmail}
            label="Your email*"
            placeholder="e.g. sam@johnson.com"
            name="beta_email"
            value={betaEmail}
          />
          <Input
            onChange={setBetaSalonName}
            label="Salon/trading name*"
            placeholder="e.g. SJ's Hair"
            name="beta_salon_name"
            value={betaSalonName}
          />
          <Input
            onChange={setBetaPhone}
            label="Your phone number*"
            placeholder="e.g. 0400 123 456"
            name="beta_phone"
            value={betaPhone}
          />
          {betaFormSent && (
            <p style={{color: "green"}}>Thanks for registering your interest. We'll be in touch soon.</p>
          )}
          <Button onPress={onSubmitBetaForm} label="Submit" />
          </>
        )}
        {plan == Plan.PLUS && (
          <>
        <h2 style={{marginBottom: 12}}>
          <FormattedMessage id="signup.subscription.button_title" />
        </h2>
        <BlockRow style={{flexWrap: "wrap", marginLeft: "-1%"}}>
          <PaymentFreqButton onClick={() => onSubscriptionChange(SubscriptionType.MONTHLY)} active={subscriptionType === SubscriptionType[1]}>
            <PlanHeader>
              <h3>Monthly {(subscriptionType === SubscriptionType[1] ? " ✓" : "")}</h3>
            </PlanHeader>
            <div>
              <p>{getMonthlyDescription()}</p>
              {plan === Plan.PLUS && (<p><strong>Price per mirror: ${pricePerMirror()}</strong></p>)}
            </div>
          </PaymentFreqButton>
          <PaymentFreqButton onClick={() => {console.log("HI"); onSubscriptionChange(SubscriptionType.ANNUAL)}} active={subscriptionType === SubscriptionType[0]}>
            <PlanHeader>
              <h3>Saver {(subscriptionType === SubscriptionType[0] ? " ✓" : "")}</h3>
            </PlanHeader>
            <div>
              <MostPopular>Most popular</MostPopular>
              <p>{getAnnualDescription()}</p>
              <p><strong>Get 2 months extra FREE when you pay annually</strong></p>
            </div>
          </PaymentFreqButton>
          
        </BlockRow>
        {plan === Plan.PLUS && (
          <div>
            <p>*Minimum term for piiq Pro Plus is 12 months with free delivery & installation support</p>
          </div>
        )} 
        
        </>
        )}
        {plan === Plan.PLUS && (
          <Button disabled={!subscriptionType || mirrorQuantity === 0} onPress={onNext} label="Next" />
        )}
        <Footer />
        </div>
      </Content>
  );
};

export default SubscriptionsComponent;
