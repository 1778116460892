import React, { useRef, useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import { FormattedMessage } from "react-intl";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useBrands } from "../../../../contexts/AdminContext";
import {
	startDisableBrand,
	startEnableBrand,
	startGetCatalogAllBrands
} from "../../../../actions/brandsActions";

import { Button, Content, SkipButton, Checkbox } from "../../../../components";
import { SectionType, OnboardingDetails } from "../../types";
import { Error, BrandsLoader, Loader, CheckboxRow } from "./styles";

interface IProps {
	onboardVersion: string;
	transitionConfig?: any;
	onNavigate: Function;
	onCompleteSection: Function;
	updateRegistrationState: Function;
	registrationDetails: OnboardingDetails;
	salonId: string;
}

interface IBrand {
	id: string;
	name: string;
	active: boolean;
	brandActive: boolean;
}

const ProductsComponent = (props: IProps) => {
	const contentScroll = useRef<HTMLDivElement>(null);
	const [ state, dispatch ] = useBrands();
	const [ error, setError ] = useState("");
	const [ loading, setLoading ] = useState(false);
	const [ salonId, setSalonId ] = useState("");
	const [ brands, setBrands ]: any = useState([]);
	const [ submited, setSubmited ] = useState(false);

	useEffect(() => {
		if (contentScroll && contentScroll.current) {
			contentScroll.current.scrollTop = 0;
			window.scrollTo(0, 0);
		}

		// Get the Salon ID from the address bar
		const pathId = window.location.pathname;
		const salId = pathId.split("/", 2);
		setSalonId(salId[1]);

		dispatch(startGetCatalogAllBrands(salId[1]));
	}, []);

	useEffect(
		() => {
			//@ts-ignore
			if (!state.loading && !submited) setBrands(state.brands);
		},
		[ state ]
	);

	const onSubmit = async () => {
		setLoading(true);
		setSubmited(true);
		let b = 0;
		await brands.forEach(async (brand, i) => {
			if (brand.active) {
				await dispatch(startEnableBrand(brand.id, salonId));
				b += 1;
			} else {
				await dispatch(startDisableBrand(brand.id, salonId));
				b += 1;
			}

			if (b === brands.length) {
				console.log("done enabling brands");
				setLoading(false);
				/* Brands enabled, proceed */
				proceedNextStep();
			}
		});
	};

	const proceedNextStep = () => {
		setLoading(false);
		props.onNavigate(SectionType.COMPLETE);
		props.onCompleteSection();
	};

	const renderCheckboxRow = (
		title: string,
		onChange: Function,
		checked?: boolean
	) => (
		<CheckboxRow key={title} onClick={() => onChange()}>
			<Checkbox checked={checked} name={title} />
			{/* <input
				type="checkbox"
				checked={checked}
				name={title}
				onChange={() => onChange()}
			/> */}
			<div>{title}</div>
		</CheckboxRow>
	);

	const onBrandSelect = async (brandSelected: IBrand) => {
		const brandsClone = brands.splice(0);
		brandsClone.forEach((b, i) => {
			if (b.id === brandSelected.id) b.active = !brandSelected.active;
		});
		setBrands(brandsClone);
	};

	const transitionConfig = useSpring(props.transitionConfig);

	return (
		<Content as={animated.div} style={transitionConfig} ref={contentScroll}>
			<h1>
				<FormattedMessage id="registration.products.title" />
			</h1>
			<div className="margin-bottom-mid">
				<FormattedMessage id="registration.products.body" />{" "}
			</div>
			<div className="margin-bottom-mid">
				{brands && brands.length ? (
					brands.map(
						(brand: IBrand) =>
							brand.brandActive &&
							renderCheckboxRow(
								brand.name,
								() => onBrandSelect(brand),
								brand.active
							)
					)
				) : (
					<BrandsLoader>
						<CircularProgress />
					</BrandsLoader>
				)}
			</div>
			{loading ? (
				<Loader>
					<CircularProgress />
				</Loader>
			) : (
				<Button onPress={onSubmit} label="NEXT →" />
			)}
			<Error>{error && error}</Error>
			<SkipButton onPress={proceedNextStep} />
		</Content>
	);
};

export default ProductsComponent;
