import { API, graphqlOperation } from "aws-amplify";
import { loader } from "graphql.macro";
import { GraphQLResult } from "@aws-amplify/api/lib/types";
import {
	IPaymentRegister,
	ISalonDetail,
	IRegisterPaymentAction
} from "../reducers/RegistrationReducer";
import { GetSYSAccountNumber } from "./../utils";
const RegisterPaymentGQL = loader("./../graphql/RegisterAccount.graphql");

interface ISalonResult {
	data: {
		registerAccount: {
			salon: {
				id: string;
			};
			password: string;
		};
	};
}
export const startRegisterPayment = (paymentDetails: IPaymentRegister) => {
	return async (
		dispatch: React.Dispatch<IRegisterPaymentAction>,
		state: any
	) => {
		if (!paymentDetails.lorealAccountNumber) {
			await GetSYSAccountNumber(paymentDetails.email).then((r:any) => {
				paymentDetails.lorealAccountNumber = r?.data?.response?.salonID;
			});
		}
		
		const inputParams = {
			input: paymentDetails
		};
		let result;
		
		try {
			result = (await API.graphql(
				graphqlOperation(RegisterPaymentGQL, inputParams)
			)) as ISalonResult;
		} catch (error) {
			return error;
		}

		const salonDetails: ISalonDetail = {
			id: result.data.registerAccount.salon.id,
			password: result.data.registerAccount.password
		};
		return salonDetails;
	};
};

export const registerAccountPayment = (
	paymentDetails: ISalonDetail
): IRegisterPaymentAction => ({
	type: "SET_REGISTER_PAYMENT",
	paymentDetails
});
