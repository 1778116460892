import { createMuiTheme } from "@material-ui/core/styles";
//import { config } from './awsConfig-prod';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#332266"
    },
    secondary: {
      main: "#c0c0c0"
    }
  }
});
