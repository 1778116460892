import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import PieCompt from "../../components/PieCompt";
import DateSelect from "./DateSelect";
import { iWindow } from "../../config";
import {
	InsightsContainer,
	DividerSmall,
	PieColumn,
	Stat,
	ChartContainer,
	StatsRow,
	StatItem,
	StatColumn,
	StatLabel,
	ChartLabel,
	HeaderOption,
	HeaderRow,
	Loader,
	SectionHeader,
	SampleNotice
} from "./styles";
import mixpanel from 'mixpanel-browser';


//////////////IN-SALON TREATMENTS ARTIFACTS//////////////////

const inSalonTreatmentsFill = [
	{
		match: {
			id: "Protein"
		},
		id: "protein"
	},{
		match: {
			id: "Moisture"
		},
		id: "moisture"
	},{
		match: {
			id: "Scalp"
		},
		id: "scalp"
	},{
		match: {
			id: "None"
		},
		id: "none"
	}
];


const inSalonTreatmentsSampleStats = {
	protein: 10,
	moisture: 10,
	scalp: 10,
	none: 10,
	total: 40
}

const inSalonTreatmentsColorScheme = { scheme: "set3" };

//////////////REBOOK ARTIFACTS//////////////////

const rebookfill = [
	{
		match: {
			id: "Yes"
		},
		id: "dots"
	},
	{
		match: {
			id: "Yes"
		},
		id: "dots"
	},
	{
		match: {
			id: "No"
		},
		id: "lines"
	}
];

const rebookSampleStats = 
{
	count: 10,
	later: 20,
	without: 20
}


//////////////RETAIL ARTIFACTS//////////////////
const retailfill = [
	{
		match: {
			id: "Delivery"
		},
		id: "dots"
	},
	{
		match: {
			id: "Pickups"
		},
		id: "lines"
	}
];

const retailSampleStats = {
	delivery: 50,
	pickup: 60,
	none: 80,
	total: 190
}

const colorScheme = [ "#F0F1F2", "#3B3B3B",  "#B08E58", "#61796C"]
const fontStyle = {
	fontSize: 8,
	fontWeight: "bold",
	textTransform: "uppercase"
};

//////////////////////////////////////////

interface iProps {
	appointments: Array<any>;
	windowSize?: iWindow | null;
	loading: boolean;
	isSample: boolean;
}

interface iRebook {
	count: number;
	later: number;
	without: number;
}
interface iRetail {
	delivery: number;
	pickup: number;
	none: number;
	total: number;
}
interface iShare {
	shares: number;
	noShares: number;
}
interface iSalonTreatment {
	protein: number;
	moisture: number;
	scalp: number;
	none: number;
	total: number;
}

export default function Insights (props: iProps) {
	const rebookDefault: iRebook = {
		count: 0,
		later: 0,
		without: 0
	};
	const retailDefault: iRetail = {
		delivery: 0,
		pickup: 0,
		none: 0,
		total: 0
	};
	const shareDefault: iShare = {
		shares: 0,
		noShares: 0
	};
	const inSalonTreatmentDefault: iSalonTreatment = {
		protein: 0,
		moisture: 0,
		scalp: 0,
		none: 0,
		total: 0
	};
	const [ totalAppointments, setTotalAppointments ] = useState(0)
	const [ totalRetail, setTotalRetail ] = useState(0);
	const [ totalInSalonTreatments, setTotalInSalonTreatments ] = useState(0);
	const [ rebookStats, setRebookStats ] = useState(rebookDefault);
	const [ retailStats, setRetailStats ] = useState(retailDefault);
	const [ shareStats, setShareStats ] = useState(shareDefault);
	const [ inSalonTreatmentStats, setInSalonTreatmentStats ] = useState(inSalonTreatmentDefault);
	const [ duration, setDuration ] = useState("all");
	const nowDate = moment(new Date());

	useEffect(
		() => {
			calcAppointmentTotal();
			calcRebook();
			calcRetailStats();
			calcInSalonTreatments();
		},
		[ ]
	);

	useEffect(
		() => {
			calcAppointmentTotal();
			calcRebook();
			calcRetailStats();
			calcInSalonTreatments();
			calcInSalonTreatments();
		},
		[ props.appointments, duration ]
	);

	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		setDuration(event.target.value as string);
		mixpanel.track("PMA Dashboard - KPIs view changed", {"Changed to view" : event.target.value });
	};

	const calcAppointmentTotal = () => {
		let total = 0

		props.appointments.map(appt => {
			const appointmentDate = moment(new Date(appt.createdAt));
			const thisYear = appointmentDate.year() === nowDate.year();
			if(duration === "all" || (duration === "year" &&
					(thisYear || appointmentDate.year() === nowDate.year())) ||
					(duration === "month" &&
						thisYear && appointmentDate.month() === nowDate.month()) ||
					(duration === "week" &&
						thisYear &&
						appointmentDate.week() === nowDate.week())) {
							total += 1;
			}
		})
		setTotalAppointments(total)
	}

	const calcInSalonTreatments = () => {
		let inSalonTreatments: iSalonTreatment = {
			protein: 0,
			moisture: 0,
			scalp: 0,
			none: 0,
			total: 0
		};
		props.appointments.map((appt) => {
			if (appt.supportYourSalon) {
				switch (appt.supportYourSalon.treatment) {
					case "protein":
						inSalonTreatments.protein += 1;
						break;
					case "moisture":
						inSalonTreatments.moisture += 1;
						break;
					case "scalp":
						inSalonTreatments.scalp += 1;
						break;					
				}
				inSalonTreatments.total += 1;
			} else {
				inSalonTreatments.none += 1;
			}
		});
		
		setInSalonTreatmentStats(inSalonTreatments);
		setTotalInSalonTreatments(inSalonTreatments.total);
		
	};


	const calcRebook = () => {
		const rebook: iRebook = {
			count: 0,
			later: 0,
			without: 0
		};

		props.appointments.map((appointment, i) => {
			const appointmentDate = moment(new Date(appointment.createdAt));
			const thisYear = appointmentDate.year() === nowDate.year();
			if (appointment.rebookLength) {
				if (duration === "all") {
					rebook.count += 1;
				} else if (appointmentDate) {
					if (
						(duration === "year" &&
							(thisYear || appointmentDate.year() === nowDate.year())) ||
						(duration === "month" &&
							thisYear &&
							appointmentDate.month() === nowDate.month()) ||
						(duration === "week" &&
							thisYear &&
							appointmentDate.week() === nowDate.week())
					)
						rebook.count += 1;
				}
			}
			if (!appointment.rebookLength) {
				if (duration === "all") {
					rebook.without += 1;
				} else if (appointmentDate) {
					if (
						(duration === "year" &&
							(thisYear || appointmentDate.year() === nowDate.year())) ||
						(duration === "month" &&
							thisYear &&
							appointmentDate.month() === nowDate.month()) ||
						(duration === "week" &&
							thisYear &&
							appointmentDate.week() === nowDate.week())
					)
						rebook.without += 1;
				}
			}
		});

		setRebookStats(rebook);
	};

	const calcRetailStats = () => {
		const retail: iRetail = {
			delivery: 0,
			pickup: 0,
			none: 0,
			total: 0
		};

		props.appointments.map((appointment, i) => {
			const appointmentDate = moment(new Date(appointment.createdAt));
			const thisYear = appointmentDate.year() === nowDate.year();

			if (duration === "all") {
				retail.total += 1;
				if (appointment.salonPickup === true) {
					retail.pickup += 1;
				} else {
					if (appointment.purchasedItems !== null) {
						retail.delivery += 1;
					} else {
						retail.none += 1;
					}
				}
			} else if (appointmentDate) {
				if (
					(duration === "year" &&
						(thisYear || appointmentDate.year() === nowDate.year())) ||
					(duration === "month" &&
						thisYear &&
						appointmentDate.month() === nowDate.month()) ||
					(duration === "week" &&
						thisYear &&
						appointmentDate.week() === nowDate.week())
				) {
					retail.total += 1;
					if (appointment.salonPickup === true) {
						retail.pickup += 1;
					} else {
						if (appointment.purchasedItems !== null) {
							retail.delivery += 1;
						} else {
							retail.none += 1;
						}
					}
				}
			}
			
		});

		setRetailStats(retail);
		setTotalRetail(retail.total);
	};

	const inSalonTreatmentData = [
		{
			id: "None",
			label: "None",
			value: inSalonTreatmentStats.none,
			color: "#FFF"
		}, {
			id: "Protein",
			label: "Protein",
			value: inSalonTreatmentStats.protein,
			color: "#FF0"
		}, {
			id: "Moisture",
			label: "Moisture",
			value: inSalonTreatmentStats.moisture,
			color: "#F0F"
		}, {
			id: "Scalp",
			label: "Scalp",
			value: inSalonTreatmentStats.scalp,
			color: "#0FF"
		}
	];

	const rebookdata = [
		{
			id: "No",
			label: "No",
			value: rebookStats.without,
			color: "hsl(120, 70%, 50%)"
		}, {
			id: "Yes",
			label: "Yes",
			value: rebookStats.count,
			color: "#FFF"
		}
	];

	const retaildata = [
		{
			id: "None",
			label: "None",
			value: retailStats.none,
			color: "#DDD"
		},{
			id: "Deliver to home",
			label: "Deliver to home",
			value: retailStats.delivery,
			color: "#FFF"
		},
		{
			id: "Pick up in-store",
			label: "Pick up in-store",
			value: retailStats.pickup,
			color: "hsl(234, 70%, 50%)"
		}
	];


	const inSalonTreatmentsSampleData = [
		{
			id: "None",
			label: "None",
			value: inSalonTreatmentsSampleStats.none,
			color: "#FFF"
		}, {
			id: "Protein",
			label: "Protein",
			value: inSalonTreatmentsSampleStats.protein,
			color: "#FF0"
		}, {
			id: "Moisture",
			label: "Moisture",
			value: inSalonTreatmentsSampleStats.moisture,
			color: "#F0F"
		}, {
			id: "Scalp",
			label: "Scalp",
			value: inSalonTreatmentsSampleStats.scalp,
			color: "#0FF"
		}
	];

	const rebookSampleData = [
		{
			id: "No",
			label: "No",
			value: rebookSampleStats.without,
			color: "hsl(120, 70%, 50%)"
		}, {
			id: "Yes",
			label: "Yes",
			value: rebookSampleStats.count,
			color: "#FFF"
		}
	];

	const retailSampleData = [
		{
			id: "None",
			label: "None",
			value: retailSampleStats.none,
			color: "#DDD"
		},{
			id: "Deliver to home",
			label: "Deliver to home",
			value: retailSampleStats.delivery,
			color: "#FFF"
		},
		{
			id: "Pick up in-store",
			label: "Pick up in-store",
			value: retailSampleStats.pickup,
			color: "hsl(234, 70%, 50%)"
		}
	];

	return (
		<InsightsContainer>
			<HeaderRow>
				<SectionHeader>
					Your Key <span>Performance Indicators</span>
				</SectionHeader>
				<HeaderOption>
					<div>
						<DateSelect value={duration} onChange={handleChange} />
					</div>
				</HeaderOption>
				
			</HeaderRow>
			{props.isSample && (
				<SampleNotice>We’ve filled out your dashboard with sample data for now. As soon as you have real data we’ll update it here.</SampleNotice>
			)}
			<DividerSmall />
			<StatsRow style={{ opacity: props.isSample ? '0.5' : '1' }}>
				<StatColumn>
					<StatItem>
						<StatLabel>
							<FormattedMessage id="dashboard.insights.totalapps" />
						</StatLabel>
						<Stat>{props.isSample ? 100 : totalAppointments}</Stat>
					</StatItem>
					<StatItem>
						<StatLabel>
							<FormattedMessage id="dashboard.insights.totalinsalontreatments" />
						</StatLabel>
						<Stat>{props.isSample ? 100 : totalInSalonTreatments}</Stat>
					</StatItem>
					<StatItem>
						<StatLabel>
							<FormattedMessage id="dashboard.insights.totalretail" />
						</StatLabel>
						<Stat>{props.isSample ? 100 : totalRetail}</Stat>
					</StatItem>
				</StatColumn>
				{props.loading ? (
					<Loader>
						<CircularProgress />
					</Loader>
				) : (
					<ChartContainer>
						<Grid container>
							<Grid item md={4} xs={4}>
								<PieColumn>
									<ChartLabel>
										<FormattedMessage id="dashboard.insights.insalontreatments" />
									</ChartLabel>
									<PieCompt
										data={props.isSample ? inSalonTreatmentsSampleData : inSalonTreatmentData}
										fill={inSalonTreatmentsFill}
										colorscheme={colorScheme}
										fontStyle={fontStyle}
									/>
								</PieColumn>
							</Grid>
							<Grid item md={4} xs={4}>
								<PieColumn>
									<ChartLabel>
										<FormattedMessage
											id="dashboard.insights.rebook"
											defaultMessage={`Rebooking suggested?`}
										/>
									</ChartLabel>

									<PieCompt
										data={props.isSample ? rebookSampleData : rebookdata}
										fill={rebookfill}
										colorscheme={colorScheme}
										fontStyle={fontStyle}
									/>
								</PieColumn>
							</Grid>
							<Grid item md={4} xs={4}>
								<PieColumn>
									<ChartLabel>
										<FormattedMessage
											id="dashboard.insights.retail"
											defaultMessage={`Retail method`}
										/>
									</ChartLabel>

									<PieCompt
										data={props.isSample ? retailSampleData : retaildata}
										fill={retailfill}
										colorscheme={colorScheme}
										fontStyle={fontStyle}
									/>
								</PieColumn>
							</Grid>
						</Grid>
					</ChartContainer>
				)}
			</StatsRow>
		</InsightsContainer>
	);
}
