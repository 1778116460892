import React, { Component } from "react";
import {
  Button,
  TextField,
  Typography,
  Grid,
  InputAdornment
} from "@material-ui/core";
import Auth from "@aws-amplify/auth";
import { FormattedMessage } from "react-intl";
import { Done } from "@material-ui/icons";
import RecoveryOntheway from "./RecoveryOntheway";

//types
interface Props {
  toggleView(view: string): void;
}

interface IState {
  email: string;
  error: string;
  step: string;
  message: string;
  [name: string]: any;
}

export default class ForgotPasswordForm extends Component<Props, IState> {
  state = {
    email: "",
    valid: false,
    error: "",
    message: "",
    step: "send"
  };
  onChange = (e: any) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    this.setState({ [name]: value });
  };
  onSubmit = async () => {
    const { email } = this.state;

    if (email === "") {
      this.setState({ error: "Please enter a valid email address" });
      return;
    }

    try {
      const cognitoUser = await Auth.forgotPassword(email.trim());
      this.setState({ step: "recovery" });
      console.log("SignIn::Submit::Success:", cognitoUser);
    } catch (e) {
      let error = e.message;
      console.log("SignIn::Submit::Error:", error);
      if (error === "Username/client id combination not found.")
        error = "Sorry, we couldn’t find an account with that email address.";
      this.setState({ error });
    }
  };

  validateEmail(email: string) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  /**
   * Stop enter submitting the form.
   * @param keyEvent Event triggered when the user presses a key.
   */
  onKeyDown(keyEvent: any) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  render() {
    const { toggleView } = this.props;
    const { step } = this.state;
    return (
      <>
        {step === "send" && (
          <form onKeyDown={this.onKeyDown}>
            <Grid
              container
              direction="column"
              spacing={1}
              alignItems="stretch"
              className="centerContent"
            >
              <Grid item>
                <Typography
                  variant="h5"
                  align="center"
                  style={{
                    fontFamily: "titleFont",
                    textTransform: "uppercase"
                  }}
                >
                  <FormattedMessage
                    id="forgotpassword.title"
                    defaultMessage={`Password recovery`}
                  />
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="h5"
                  align="center"
                  style={{ textTransform: "none", fontSize: "0.8rem" }}
                >
                  <FormattedMessage
                    id="forgotpassword.info"
                    defaultMessage={`Enter your email address below and we'll send through a verification code to reset your password.`}
                  />
                </Typography>
              </Grid>
              <Grid item>
                <Grid container justify="space-around" spacing={8}>
                  <Grid item>
                    <Button onClick={() => toggleView("signin")}>
                      Go back to sign in
                    </Button>
                  </Grid>
                  {/* <Grid item>
                <Button onClick={() => toggleView("signup")}>Sign Up</Button>
              </Grid> */}
                </Grid>
            </Grid>
            </Grid>
          </form>
        )}  
      </>
    );
  }
}
