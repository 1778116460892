import styled from "styled-components";
import { SIZE, FONT, BREAKPOINTS } from "../../config";

export const Panel = styled.div`
  margin: 0;
  width: calc(100% - ${SIZE.SMALL}px);
  height: calc(100vh - 100px);
  padding-top: ${SIZE.MEDIUM}px;
  padding-bottom: 90px;
  padding-right: 22px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  font-size: ${FONT.SIZE.BODY};

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #000000;
    border: 2px solid #555555;
  }

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    padding-top: ${30}px;
    padding-bottom: 90px;
  }

  @media (min-width: ${BREAKPOINTS.DEKSTOP_MEDIUM}px) {
    padding-top: 100px;
    padding-bottom: ${SIZE.LARGE}px;
  }
`;
