import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { StaticContext } from "react-router";
import { Notification, Layout, Fetching, H1} from "../../components";
import { Container, Loader, ResetContainer, Content, ProgressAmount, ProgressLabel, ProgressContainer, BannerCTA, Banner, SectionHeader } from './styles'
import { GetSalonOrders } from "../../utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Auth from "@aws-amplify/auth";
import moment from "moment";
import { Table } from "antd";

interface IPathParams {
	salonId: string;
	appointmentId?: string;
}

interface ISorter {
    columnKey: string;
}

export default function OrdersScreen ({
    location,
	match,
	history
}: React.PropsWithChildren<
RouteComponentProps<IPathParams, StaticContext, any>
>) {
    
    const [ loading, setLoading ] = useState(true);
    const [ orders, setOrders ] = useState([]);
    const [ email, setEmail ] = useState('');
    const { salonId } = match.params;
    const [ sortedInfo, setSortedInfo ] = useState<ISorter>({
		columnKey: "Created Date"
	});
    let uniqueId = 0;

    //Ant design UI library is used for the table as it has excellent sorting, filtering features.
	const columns = [
		{
			title: "Order Number",
			dataIndex: "OrderNumber",
			key: "OrderNumber",
			sorter: (a: any, b: any) =>
                a["OrderNumber"] - b["OrderNumber"],
			defaultSortOrder: 'descend',
			ellipsis: true
		},
		{
			title: "Date",
			dataIndex: "Created Date",
			key: "Created Date",
			sorter: (a: any, b: any) =>
				a["Created Date"] - b["Created Date"],
            defaultSortOrder: 'descend',
			ellipsis: true,
            render: (date: any) => {
                console.log(date)
                return (moment(new Date(date)).format("ddd Do MMM Y HH:mm"))
            }
		},
		{
			title: "Email",
			dataIndex: "Email",
			key: "Email",
            sorter: (a: any, b: any) =>
                a["Email"] - b["Email"],
            defaultSortOrder: 'descend',
			ellipsis: true
		},
		{
			title: "Total",
			dataIndex: "GrandTotal",
			key: "GrandTotal",
            sorter: (a: any, b: any) =>
                a["GrandTotal"] - b["GrandTotal"],
            defaultSortOrder: 'descend',
			ellipsis: true
		},
		{
			title: "View",
			key: "id",
			render: (order: any) => (
				<Button
                onClick={() => {onOrderPress(order)}}>
                    VIEW ORDER
                </Button>
			)
		}
	];


    useEffect(() => {
        getAuth();
    }, [])


    const getAuth = async () => {
        try {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            console.log("Cognito User", cognitoUser.attributes.email);
            get(cognitoUser.attributes.email);
            setEmail(cognitoUser.attributes.email);
        } catch (e) {
            console.log("Authenticator::CheckUser:Error:", e.message);
        }
    }
    const get = async (email) => {
        let out = await GetSalonOrders(email);
        setOrders(out.data.response.orders)
        console.log(out.data.response)
        setLoading(false)
    }



    const onOrderPress = (order) => {
        history.push(`/${salonId}/orders/${order._id}`);
        console.log('pushed!')
    }

	const handleChange = (pagination: any, filters: any, sorter: any) => {
		// setFilteredInfo(filters);
		// setSortedInfo(sorter);
	};

	return (
		<Layout>
			<Notification />
			    <Container>
                    <H1>Support Your Salon Orders</H1>
                        <Content>

                        <Table
                        rowKey={() => ++uniqueId + ""}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment     
						// @ts-ignore
                        columns={columns}
                        dataSource={orders}
                        onChange={(pagination, filters, sorter) => {handleChange(pagination, filters, sorter)}}
                        pagination={{
                            size: "small",
                            simple: true,
                            hideOnSinglePage: true,
                            defaultPageSize: 6
                        }}
                        />

                            
                    </Content>
                </Container>
			<Fetching />
		</Layout>
	);
}
