import styled from "styled-components";
import { COLORS, SIZE } from "../../config";

export const ImgAvatar = styled.div`
	width: 28px;
	height: 28px;
	border-radius: 14px;
	overflow: hidden;
	margin-right: ${SIZE.SMALL}px;
	margin-left: -${SIZE.SMALL}px;
	background: ${COLORS.BLACK};
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 28px;
	}

	svg {
		fill: ${COLORS.WHITE};
		width: 20px;
		height: 20px;
	}
`;
