import React from "react";
import { FormattedMessage } from "react-intl";

import { Skip } from "./styles";

interface IInputProps {
  onPress: Function;
}

const SkipComponent = (props: IInputProps) => {
  const onPress = () => {
    props.onPress();
  };

  return (
    <Skip onClick={onPress}>
      <p>
        <FormattedMessage id="registration.button_skip" />
      </p>
      <span>→</span>
    </Skip>
  );
};

export default SkipComponent;
