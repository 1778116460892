import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import NativeSelect from '@material-ui/core/NativeSelect';
import { StaticContext } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import SettingsNavbar from "../../../components/SettingsNavbar";
import { Notification, Layout } from "../../../components";
import { Container, InputContainer, CheckboxContainer, Brands, BrandCheckbox, FixedFooter, Tooltip } from "../style";
import mixpanel from 'mixpanel-browser';
import { UpdateSalonAccount, GetSalonAccount } from "../../../utils";
import Auth from "@aws-amplify/auth";
import { Cancel, CheckCircle } from "@material-ui/icons";
import { useSalons, useBrands } from "../../../contexts/AdminContext";
import { getAllSalons } from "../../../actions/salonsActions";
import { IStateBrands } from "../../../reducers/BrandsReducer";
import { startGetAllBrands } from "../../../actions/brandsActions";
import {
	startSetBrands
} from "../../../actions/salonsActions";

interface IPathParams {
	salonId: string;
}

function Checkbox(props:any) {
	const [ checked, setChecked ] = useState(props.checked);
	
	return (
		<CheckboxContainer>
			<input type="checkbox" 
				id={props.id} 
				name={props.id} 
				checked={checked} 
				onChange={() => {
					props.onChange(props.id, checked);
					setChecked(!checked);
				}}></input>
			<label>{props.name}</label>
		</CheckboxContainer>
	)
}

export default function RetailSettingsScreen ({
	location,
	history,
	match
}: React.PropsWithChildren<
	RouteComponentProps<IPathParams, StaticContext, any>
>) {
	const reducer = useSalons();
	const [ state, dispatch ] = reducer;

	const [ email, setEmail ] = useState('');
	const [ salonDetails, setSalonDetails ] = useState({}) as any;
	const [ originalSalonDetails, setOriginalSalonDetails ] = useState({}) as any;
	// const [ brands, setBrands ] = useState<any[]>([]);
	const [ specialities, setSpecialities ] = useState<any[]>([]);
	const [ vibes, setVibes ] = useState<any[]>([]);
	const [ pref, setPref ] = useState('');
	const [ loading, setLoading ] = useState(true);
	const [ hasUpdated, setHasUpdated ] = useState(false);
	const [ salon, setSalon ] = useState() as any;
	const { salonId } = match.params;
	const { salons } = state;

	const brandsReducer = useBrands();
	const [ brandsState, brandsDispatch ] = brandsReducer;
	const { brands } = brandsState as IStateBrands;
	
	
	const SalonNameInput = useRef<HTMLInputElement>(null);
	const ABNInput = useRef<HTMLInputElement>(null);
	const AccountNumberInput = useRef<HTMLInputElement>(null);
	const AddressLine1Input = useRef<HTMLInputElement>(null);
	const AddressLine2Input = useRef<HTMLInputElement>(null);
	const CityOrSuburbInput = useRef<HTMLInputElement>(null);
	const StateInput = useRef<HTMLInputElement>(null);
	const PostcodeInput = useRef<HTMLInputElement>(null);
	const OwnerFirstNameInput = useRef<HTMLInputElement>(null);
	const OwnerLastNameInput = useRef<HTMLInputElement>(null);
	const PhoneInput = useRef<HTMLInputElement>(null);
	const EmailInput = useRef<HTMLInputElement>(null);
	const BookingURLInput = useRef<HTMLInputElement>(null);
	const InstagramHandleInput = useRef<HTMLInputElement>(null);
	const BankAccountNameInput = useRef<HTMLInputElement>(null);
	const BankBSBInput = useRef<HTMLInputElement>(null);
	const BankAccountNumberInput = useRef<HTMLInputElement>(null);
	
	useEffect(
		() => {
			const s = salons.find(salon => salon.id === salonId) as any;
			if (s !== undefined) {
				setSalon(s);
				setPref(s["PreferredRecommendationBrand"])
			}
			console.log("THIS SALON", salons.find(salon => salon.id === salonId))
		},
		[ salons ]
	);

	// useEffect(
	// 	() => {
			
	// 		salon && setDelayDays(salon.rebookNotificationDelay || 0);
	// 		if (
	// 			salon &&
	// 			salon.rebookNotificationDelay &&
	// 			salon.rebookNotificationDelay === delayDays
	// 		)
	// 			setSaving(false);
	// 	},
	// 	[ salon ]
	// );


	useEffect(() => {
		document.title = "Retail Settings";
		mixpanel.track("PMA Settings - Retail page accessed");
		dispatch(getAllSalons());
		getAuth();
		if (!brands.length) brandsDispatch(startGetAllBrands());
	}, []);


	useEffect(() => {
		if (salonDetails) {
			setPref(salonDetails["PreferredRecommendationBrand"])
			console.log(salonDetails["Brands Visible To Clients"]);
		}
		console.log("CHANGED...")
    }, [salonDetails])	

    const getAuth = async () => {
        try {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            console.log("Cognito User", cognitoUser.attributes.email);
            setEmail(cognitoUser.attributes.email);
			get(cognitoUser.attributes.email);
        } catch (e) {
            console.log("Authenticator::CheckUser:Error:", e.message);
        }
    }

    const get = async (email) => {
        let out = await GetSalonAccount(email);
        console.log('r',out.data.response)
		setSalonDetails(out.data.response.salon);
		setOriginalSalonDetails(out.data.response.salon);
        // setBrands(out.data.response.brands);
		
		if (out.data.response.specialities) {
			setSpecialities(out.data.response.specialities);	
		}
        if (out.data.response.vibes) {
			setVibes(out.data.response.vibes);
		}
		
        setLoading(false)
    }


	const _updateSalonAccount = async () => {
		
		setLoading(true)

		const result = await dispatch(
			startSetBrands(salon.id, salon["BrandsVisibleToClients"], pref)
		);
		
		/* TODO: Remove the TS-Ignore and Fix returning Void so can check for errors */
		// @ts-ignore
		if (result && result.errors) {
			console.log("error", result)
			setLoading(false);
			return;
		} else {
			let _salonDetails = {
				"email" : email,
				"HideFromFinder" : salonDetails["HideFromFinder"] ? salonDetails["HideFromFinder"] : false,
				"Bank Account Name" :  BankAccountNameInput.current?.value,
				"Bank BSB" :  BankBSBInput.current?.value,
				"Bank Account Number" :  BankAccountNumberInput.current?.value,
				"Brands Visible To Clients" : salonDetails["Brands Visible To Clients"] ? salonDetails["Brands Visible To Clients"] : [],
				"PreferredRecommendationBrand" : pref,
				"SFPhoto" : salonDetails["SFPhoto"] ? salonDetails["SFPhoto"] : "",
				"Specialities" : salonDetails["Specialities"] ? salonDetails["Specialities"] : [],
				"Styles" : salonDetails["Styles"] ? salonDetails["Styles"] : []
			}
			console.log('sd',_salonDetails);
			let out = await UpdateSalonAccount(_salonDetails);
			console.log('r',out.data.response)
			get(email);
			setLoading(false)
			setHasUpdated(true)
		}
	}

	const removeItemFromArray = (arr,item) => {
		for( var i = 0; i < arr.length; i++){ 
			if ( arr[i] === item) { 
				arr.splice(i, 1); 
			}
		}
	}

	const onSpecChange = (id,checked) => {
		let _specs = salonDetails["Specialities"] as any[];
		if (!_specs) _specs = [];
		if (_specs?.includes(id) && checked) {
			removeItemFromArray(_specs,id)
		}
		if (!_specs.includes(id) && !checked) {
			_specs.push(id)
		}
		let _salonDetails = salonDetails;
		_salonDetails["Specialities"] = _specs;
		setSalonDetails(_salonDetails);
	}

	const onVibeChange = (id,checked) => {
		let _vibes = salonDetails["Styles"] as any[];
		if (!_vibes) _vibes = [];
		if (_vibes?.includes(id) && checked) {
			removeItemFromArray(_vibes,id)
		}
		if (!_vibes?.includes(id) && !checked) {
			_vibes.push(id)
		}
		let _salonDetails = salonDetails;
		_salonDetails["Styles"] = _vibes;
		setSalonDetails(_salonDetails);
	}

	const onHideFromFinderChange = (id,checked) => {
		let _salonDetails = salonDetails;
		_salonDetails["HideFromFinder"] = !checked;
		setSalonDetails(_salonDetails);
		console.log(_salonDetails["HideFromFinder"])
	}


	const onBrandsVisibleChangeAWS = (id, checked) => {
		console.log(salonDetails);
		let _brands = salon["BrandsVisibleToClients"] as any[];
		if (_brands.includes(id) && checked) {
			console.log("REMOVING...")
			removeItemFromArray(_brands,id)
		} else if (!_brands.includes(id) && !checked) {
			console.log("ADDING...")
			_brands.push(id)
		}
		let _salon = {};
		_salon["BrandsVisibleToClients"] = _brands;
		setSalon({
			...salon,
			..._salon
		});
	}

	const onBrandsVisibleChange = (id, checked) => {
		console.log(salonDetails);
		let _brands = salonDetails["Brands Visible To Clients"] as any[];
		if (_brands.includes(id) && checked) {
			console.log("REMOVING...")
			removeItemFromArray(_brands,id)
		} else if (!_brands.includes(id) && !checked) {
			console.log("ADDING...")
			_brands.push(id)
		}
		let _salonDetails = {};
		_salonDetails["Brands Visible To Clients"] = _brands;
		setSalonDetails({
			...salonDetails,
			..._salonDetails
		});
	}

	const hasChangedPageDetails = () => {
		if (email === originalSalonDetails["email"] &&
			SalonNameInput.current?.value === originalSalonDetails["Salon Name"] &&
			ABNInput.current?.value === originalSalonDetails["ABN"] &&
			AccountNumberInput.current?.value === originalSalonDetails["Account Number"] &&
			AddressLine1Input.current?.value === originalSalonDetails["Address Line 1"] &&
			AddressLine2Input.current?.value === originalSalonDetails["Address Line 2"] &&
			CityOrSuburbInput.current?.value === originalSalonDetails["City or Suburb"] &&
			StateInput.current?.value === originalSalonDetails["State"] &&
			PostcodeInput.current?.value === originalSalonDetails["Postcode"] &&
			OwnerFirstNameInput.current?.value === originalSalonDetails["Owner First Name"] &&
			OwnerLastNameInput.current?.value === originalSalonDetails["Owner Last Name"] &&
			PhoneInput.current?.value === originalSalonDetails["ContactPhone"] &&
			EmailInput.current?.value === originalSalonDetails["ContactEmail"] &&
			BookingURLInput.current?.value === originalSalonDetails["Booking URL"] &&
			InstagramHandleInput.current?.value === originalSalonDetails["InstagramHandle"] &&
			salonDetails["HideFromFinder"] === originalSalonDetails["HideFromFinder"] &&
			BankAccountNameInput.current?.value === originalSalonDetails["Bank Account Name"] &&
			BankBSBInput.current?.value === originalSalonDetails["Bank BSB"] &&
			BankAccountNumberInput.current?.value === originalSalonDetails["Bank Account Number"] &&
			salonDetails["Brands Visible To Clients"] === originalSalonDetails["Brands Visible To Clients"] &&
			pref === originalSalonDetails["PreferredRecommendationBrand"] &&
			salonDetails["SFPhoto"] === originalSalonDetails["SFPhoto"] &&
			salonDetails["Specialities"] === originalSalonDetails["Specialities"] &&
			salonDetails["Styles"] === originalSalonDetails["Styles"]) {
				return true
			} else {
				return false
			}
	}

	const checkCheckboxAWS = (id) => {
		return salon["BrandsVisibleToClients"]?.includes(id)
	}
	const checkCheckbox = (id) => {
		return salonDetails["Brands Visible To Clients"]?.includes(id)
	}

	return (
		<Layout>
			<Notification />
			<Container>
				<SettingsNavbar
					name="retail"
					onChange={(path: string) =>
						history.push(`/${match.params.salonId}/${path}`)}
				/>
				<Grid
					container={true}
					spacing={1}
					justify="center"
					className="inner-child"
					style={{paddingBottom: 100}}>
						{salonDetails ? (
							<>
							<Grid item={true} xs={12}>
							<Tooltip>Don't forget to press the Save button in the bottom right.</Tooltip>
							</Grid>
							<Grid item={true} xs={12} style={{borderBottom: "1px solid black", paddingBottom: 60}}>
								<h2>Brands available to customers</h2>
									<Brands>
									{ brands.sort(( a, b ) => {
										if ( a.BrandName < b.BrandName ){
											return -1;
										}
										if ( a.BrandName > b.BrandName ){
											return 1;
										}
										return 0;
										}
										).map((brand, i) => { 
										if (salon && salon["BrandsAvailableToSalon"] && salon["BrandsAvailableToSalon"].includes(brand["id"])) {
											return (
												<BrandCheckbox 
													key={brand["_id"]}
													active={checkCheckboxAWS(brand["id"]) && !brand["BrandName"]?.includes("Coming")}
													onClick={() => {
														if (!brand["BrandName"]?.includes("Coming")) onBrandsVisibleChangeAWS(brand["id"], salon["BrandsVisibleToClients"]?.includes(brand["id"]))
													}}>
													{checkCheckboxAWS(brand["id"]) ? (<CheckCircle/>) : (<></>)}
													{brand["BrandName"]?.includes("Coming") ? (<div>Coming Soon</div>) : (<></>)}
													<img src={"https://piiq-images-us-west-2-prod.s3.us-west-2.amazonaws.com/brand-logos/"+brand["BrandAbbreviation"]+".png"} />
												</BrandCheckbox>
											)
										}
									})}
									</Brands>
									
									<h2>Preferred Retail Recommendation Brand</h2>
									<p>Select your preferred retail brand to be recommended by piiq Pro from the dropdown below.</p>
									<NativeSelect id="brands" value={pref} onChange={(e) => {setPref(e.target.value)}}>
										{/* { brands.map((brand, i) => <option value={brand["_id"]}>{brand["Brand Name"]}</option>) } */}
										{ brands.sort(( a, b ) => {
										if ( a.BrandName < b.BrandName ){
											return -1;
										}
										if ( a.BrandName > b.BrandName ){
											return 1;
										}
										return 0;
										}
										).map((brand, i) => { 
										if (salon && salon["BrandsVisibleToClients"] && salon["BrandsVisibleToClients"].includes(brand["id"])) {
											return (
												<option value={brand["id"]}>{brand["BrandName"]}</option>
											)
										}
									})}
									</NativeSelect>
									{salonDetails && salonDetails["Brands Visible To Clients"] && !salonDetails["Brands Visible To Clients"].includes(pref) && (
										<div style={{color:"red"}}>Make sure you've made your preferred brand available to clients above</div>
									)}
									<hr style={{marginTop: 30}}/>
									<h2>Bank details</h2>
										<p>Enter your bank details below so we can forward you your margin</p>
										<InputContainer>
										<label>Bank Account Name</label>
										<input
											type={"text"}
											name={"bankAccountName"}
											ref={BankAccountNameInput}
											id="bankAccountName"
											defaultValue={salonDetails["Bank Account Name"]}
										/>
										</InputContainer>
										<InputContainer>
										<label>Bank BSB</label>
										<input
											type={"text"}
											name={"bankBSB"}
											ref={BankBSBInput}
											id="bankBSB"
											defaultValue={salonDetails["Bank BSB"]}
										/>
										</InputContainer>
										<InputContainer>
										<label>Bank Account Number</label>
										<input
											type={"text"}
											name={"bankAccountNumber"}
											ref={BankAccountNumberInput}
											id="bankAccountNumber"
											defaultValue={salonDetails["Bank Account Number"]}
										/>
										</InputContainer>
										<hr style={{marginTop: 30}}/>
									<h2>Specialities</h2>	
									{ specialities.map((speciality, i) => { 
										return (
											<Checkbox id={speciality["_id"]} name={speciality["Name"]} onChange={onSpecChange} checked={salonDetails["Specialities"]?.includes(speciality["_id"])} />
										)}
									)}
									<h2>Vibes</h2>
									{ vibes.map((vibe, i) => { 
										return (
											<Checkbox id={vibe["_id"]} name={vibe["Name"]} onChange={onVibeChange} checked={salonDetails["Styles"]?.includes(vibe["_id"])} />
										)}
									)}
									<hr style={{marginTop: 30}}/>

									<h2>Hide from Find a Salon page</h2>
									<Checkbox id={"HideFromFinder"} name={"Hide my salon from the Find a Salon page"} onChange={onHideFromFinderChange} checked={salonDetails["HideFromFinder"]} />
									<hr style={{marginTop: 30}}/>
									<p>
											{hasUpdated ? "Saved successfully" : "Don't forget to save your changes when you're done."}
										</p>
										<FixedFooter>
										<Button
											variant="outlined"
											size="small"
											onClick={() => {_updateSalonAccount()}}
											color="primary"
											style={{
												backgroundColor: "black",
												color: "white",
												width: "150px",
												borderRadius: "20px",
												height: "60px",
											}}
										>
											{loading ? 'Saving...' : 'Save'}
										</Button>
										</FixedFooter>
									</Grid>
								</>
							) : (
								<></>
							)}
				</Grid>
			</Container>
		</Layout>
	);
}
