import styled from "styled-components";
import { COLORS, SIZE, FONT, BREAKPOINTS } from "../../config";

interface iProps {
	dark: boolean;
	viewType: "CLIENT_LIST" | "CLIENT";
	appointmentActive?: boolean;
}

interface iViewType {
	viewType: "CLIENT_LIST" | "CLIENT";
}

const SMALL_VIEW = 850;

export const RowItem = styled.button`
	width: calc(100% - ${SIZE.MEDIUM}px);
	height: 56px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	padding: 0 ${SIZE.MEDIUM}px;
	background: ${(props: iProps) =>
		props.dark ? COLORS.GREY_MID_LIGHT : COLORS.WHITE};
	color: ${COLORS.BLACK};
	font-weight: 400;
	letter-spacing: 1px;
	text-transform: uppercase;
	border-radius: ${SIZE.BORDER_RADIUS}px;
	border: 1px solid transparent;
	outline: none;
	cursor: pointer;
	font-size: ${FONT.SIZE.TITLE};
	position: relative;
	overflow: hidden;
	transition: background .2s ease, border .15s ease;

	&:hover {
		color: ${COLORS.BLACK};
		border: 1px solid ${COLORS.BLACK};
	}
`;

export const Left = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	justify-self: flex-start;
`;
export const Right = styled.div`
	display: flex;
	justify-content: ${(props: iViewType) =>
		props.viewType === "CLIENT_LIST" ? `space-between` : `flex-start`};
	align-items: center;
	flex-direction: row;
	justify-self: flex-end;
	width: ${(props: iViewType) =>
		props.viewType === "CLIENT_LIST" ? `400px` : `100%`};

	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		width: ${(props: iViewType) =>
			props.viewType === "CLIENT_LIST" ? `130px` : `100%`};
		font-size: ${FONT.SIZE.BODY};
		flex-direction: ${(props: iViewType) =>
			props.viewType === "CLIENT_LIST" ? `column` : `row`};
		justify-content: flex-start;
		align-items: flex-start;
		text-align: left;
	}
`;

export const RowIndex = styled.div`
	margin-right: ${SIZE.MEDIUM}px;
	width: 20px;
	font-family: piiqTypeSerif;
	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		margin-right: ${SIZE.SMALL}px;
	}
`;
export const Name = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 150px;
	text-align: left;
	font-size: ${FONT.SIZE.BODY};
	text-transform: none;

	@media (min-width: ${SMALL_VIEW}px) {
		width: 280px;
	}
`;
export const Visits = styled.div`
	margin: 0 ${SIZE.MEDIUM}px;
	width: 50px;
	font-family: piiqTypeSerif;
	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		margin: 0;
		display:  none;
	}
`;
export const LastAppointment = styled.div`
	width: 120px;
	font-size: 16px;
	margin: 0
		${(props: iViewType) =>
			props.viewType === "CLIENT_LIST" ? `${SIZE.MEDIUM}px` : 0};
	text-align: ${(props: iViewType) =>
		props.viewType === "CLIENT_LIST" ? `center` : `left`};

	span {
		font-size: 12px;
	}

	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		margin: 0;
		text-align: left;
		font-size: 12px;
	}
`;
export const Stylist = styled.div`
	width: ${(props: iViewType) =>
		props.viewType === "CLIENT_LIST" ? `120px` : "280px"};
	margin: 0
		${(props: iViewType) =>
			props.viewType === "CLIENT_LIST" ? `${SIZE.MEDIUM}px` : 0};
	text-align: left;
	font-size: ${FONT.SIZE.BODY};
	text-transform: none;

	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		margin: 0;
		font-size: 12px;
	}
`;
export const Chevron = styled.div`
	margin-top: ${(props: iViewType) =>
		props.viewType === "CLIENT_LIST" ? `4px` : `2px`};
	position: ${(props: iViewType) =>
		props.viewType === "CLIENT_LIST" ? `relative` : `absolute`};
	right: ${(props: iViewType) =>
		props.viewType === "CLIENT_LIST" ? `0` : `${SIZE.MEDIUM}px`};

	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		position: absolute;
		right: 10px;
		top: 13px;
	}
`;
export const Avatar = styled.div`
	background: ${COLORS.BLACK};
	border-radius: ${SIZE.BORDER_RADIUS}px;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 34px;
	height: 42px;
	margin: 0 ${SIZE.MEDIUM}px;
	svg {
		width: 24px;
		fill: ${COLORS.WHITE};
	}
	img {
		width: 34px;
	}
	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		margin: 0 ${SIZE.SMALL}px;
	}
`;
