import styled from "styled-components";
import { COLORS, SIZE, FONT, BREAKPOINTS } from "../../config";
import BigCircle from "./../../assets/Images/bigcircle.png";

interface props {
	i?: number;
	complete?: boolean;
	isTotal?: boolean;
	isReward?: boolean;
};

export const RowItem = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	color: ${COLORS.BLACK};
	font-weight: 400;
	font-size: ${FONT.SIZE.TITLE};
	position: relative;
	padding: 20px 0;
`;

export const Left = styled.div`
	margin-right: auto;
`;
export const Right = styled.div`
	margin-left: auto;
	margin-right: 35px;
	@media (max-width: ${BREAKPOINTS.MOBILE_LARGE}px) {
		margin-right: 0;
	}
`;

export const Title = styled.div<props>`
	font-size: 24px;
	color: ${props => (props.i === 0 || props.complete === true) ? 'black' : (props.isReward === true ? '#B09569' : '#C7C7C7')} 

	@media (max-width: ${BREAKPOINTS.MOBILE_LARGE}px) {
		font-size: 16px;
	}
`;

export const Description = styled.div`
	font-size: 16px;
`;

export const EstimatedTime = styled.div`
	font-size: 14px;
	color: #ADADAD;
`;


export const Graphic = styled.div`
	min-width: 60px;
	margin-right: 30px;
	padding-top: 6px;
	position: relative;

	@media (max-width: ${BREAKPOINTS.DEKSTOP}px) {
		display:none;
	}
`;


export const Ball = styled.div<props>`
	background: url(${BigCircle});
	background-size: cover;
	${props => (props.i === 0 && props.complete === false) ? 
		'width: 60px;height: 60px;border-radius: 30px;);' 
		: 
		'width: 22px;height: 22px;border-radius: 11px;background: #C7C7C7;margin-left: 19px;margin-top: 2px;'
	}
	${props => (props.isReward === true) ? 
		'background: #B09569;' : ''
	}`;


export const LineAbove = styled.div<props>`
	${props => (props.i === 0) ? 'display:none;' : ''}
	width 2px; 
	height: 40px;
	background: #C7C7C7;
	position: absolute;
	left: 50%;
	margin-left: -1px;
    top: -30px;
`;

export const LineBelow = styled.div<props>`
	${props => (props.isTotal) ? 'display:none;' : ''}
	width 2px; 
	height: 100%;
	background: #C7C7C7;
	position: absolute;
	left: 50%;
	margin-left: -1px;
`;