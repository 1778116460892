import React, { useEffect, useState } from "react";
import Auth from "@aws-amplify/auth";
import "./style.css";
import TermsAndConditionScreen from "./TermsAndConditionScreen";
import { useTermsAndConditions } from "../../contexts/AdminContext";
import {
  startGetTermsOfServices,
  updateUserTermsOfServices
} from "../../actions/TermsAndConditionsActions";
import Loading from "../../components/LoadingComponent";
import { UpdateUserInput } from "../../graphql/globalTypes";

interface IProps {
  children?: any;
  locale: string;
  country: string;
  language: string;
}

export default function TermsAndConditions (props: IProps) {
  const { children, locale, country, language } = props;
  const [ state, dispatch ] = useTermsAndConditions();
  const [ isSubscriptionPortal, setIsSubscriptionPortal ] = useState(false);
  const { me, termsAndConditions, loading } = state;
  const [ agreed, setAgreed ] = useState(true);

  useEffect(() => {
    if (
      window.location.pathname === "/register" ||
      window.location.pathname === "/register/" ||
      window.location.pathname === "/signup" ||
      window.location.pathname === "/signup/" ||
      window.location.pathname === "/ibb" ||
      window.location.pathname === "/ibb/" 
    ) {
      setIsSubscriptionPortal(true);
    }
  }, []);

  useEffect(
    () => {
      dispatch(startGetTermsOfServices(locale, country, language));
    },
    [ locale, country, language ]
  );

  /* Allow access to the Subscription / Signup Portal */
  if (loading && !isSubscriptionPortal) {
    return <Loading />;
  }

  const handleAgree = async (agree: boolean) => {
    if (agree) {
      if (me && termsAndConditions) {
        const input: UpdateUserInput = {
          termsOfServiceLocale: termsAndConditions.locale,
          termsOfServiceVersion: termsAndConditions.version
        };
        await dispatch(updateUserTermsOfServices(me.id, input));
        setAgreed(true);
        await dispatch(startGetTermsOfServices(locale, country, language));
      }
    } else {
      await Auth.signOut();
    }
  };

  if (
    agreed ||
    !me ||
    !termsAndConditions ||
    !termsAndConditions.bodyHtml ||
    me.termsOfServiceAcceptedAt ||
    (me.termsOfServiceLocale === termsAndConditions.locale &&
      me.termsOfServiceVersion === termsAndConditions.version)
  ) {
    console.log('tos ok')
    return children;
  }

  return (
    <TermsAndConditionScreen
      handleAgree={handleAgree}
      termsAndConditions={termsAndConditions && termsAndConditions.bodyHtml}
    />
  );
}
