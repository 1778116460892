import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import Typography from "@material-ui/core/Typography";import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// types
interface IProps {
  value: string;
  onChange: any;
}

export default (props: IProps) => {
    return (
      <>
        <Typography
			style={{
				textTransform: "uppercase",
				fontSize: "0.5rem",
				fontWeight: "bold"
			}}>
			<FormattedMessage
				id="dashboard.inputlabel"
				defaultMessage={`Show Data For`}
			/>
		</Typography>

		<Select
			value={props.value}
			onChange={props.onChange}
			style={{
				fontSize: "0.6rem",
				marginTop: 2
			}}>
			<MenuItem
				value="week"
				style={{
					fontSize: "0.6rem"
				}}>
				This Week
			</MenuItem>
			<MenuItem
				value="month"
				style={{
					fontSize: "0.6rem"
				}}>
				This Month
			</MenuItem>
			<MenuItem
				value="year"
				style={{
					fontSize: "0.6rem"
				}}>
				This Year
			</MenuItem>
			<MenuItem
				value="all"
				style={{
					fontSize: "0.6rem"
				}}>
				All
			</MenuItem>
		</Select>
      </>
    );
}
