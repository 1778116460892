import React, { useState } from "react";
import { Formik } from "formik";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FormattedMessage } from "react-intl";
import { startCreateStaff } from "../../../actions/staffActions";
import { useStaff } from "../../../contexts/AdminContext";
import mixpanel from 'mixpanel-browser';

interface IProps {
	salonId: string;
}

export default function AddStaff (props: IProps) {
	const [ open, setOpen ] = useState(false);
	const [ state, dispatch ] = useStaff();
	const { salonId } = props;

	function handleClickOpen () {
		setOpen(true);
	}

	function handleClose () {
		setOpen(false);
	}

	function addStaffOnSubmit (values: any, { resetForm }: any) {
		if (values.name) {
			dispatch(startCreateStaff(salonId, values.name));
			mixpanel.track("PMA Settings - Team member added", { "Team member name" : values.name});
			handleClose();
		} else if (!values.name) {
			alert("Please enter the name");
		}
	}

	return (
		<div>
			<Button
				variant="outlined"
				size="small"
				color="default"
				style={{
					width: "100%",
					fontFamily: "BitstreamRoman",
					color: "black",
					borderRadius: "0px",
					maxWidth: 250
				}}
				onClick={handleClickOpen}>
				<FormattedMessage id="button.addstaff" defaultMessage={`ADD STAFF`} />
			</Button>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title">
				<DialogTitle
					id="form-dialog-title"
					style={{ textTransform: "uppercase" }}>
					<FormattedMessage
						id="addstaff.title"
						defaultMessage={`Add Staff`}
					/>{" "}
				</DialogTitle>
				<DialogContent>
					<Formik initialValues={{ name: "" }} onSubmit={addStaffOnSubmit}>
						{({ values, handleChange, handleBlur, handleSubmit }) => (
							<form onSubmit={handleSubmit}>
								<TextField
									autoFocus
									type="name"
									name="name"
									variant="outlined"
									margin="dense"
									onChange={handleChange}
									onBlur={handleBlur}
									placeholder="Enter the Staff Name"
									value={values.name || ""}
									style={{ minWidth: "360px", color: "black" }}
								/>
								<DialogActions style={{ justifyContent: "center" }}>
									<Button
										variant="outlined"
										size="small"
										onClick={handleClose}
										color="primary"
										style={{
											backgroundColor: "black",
											color: "white",
											width: "50%",
											fontFamily: "BitstreamRoman",
											borderRadius: "0px"
										}}>
										<FormattedMessage
											id="button.cancelstaff"
											defaultMessage={`CANCEL`}
										/>
									</Button>
									<Button
										variant="outlined"
										size="small"
										type="submit"
										color="primary"
										style={{
											backgroundColor: "black",
											color: "white",
											width: "50%",
											fontFamily: "BitstreamRoman",
											borderRadius: "0px"
										}}>
										<FormattedMessage
											id="button.addstaff"
											defaultMessage={`ADD`}
										/>
									</Button>
								</DialogActions>
							</form>
						)}
					</Formik>
				</DialogContent>
			</Dialog>
		</div>
	);
}
