import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { StaticContext } from "react-router";
import { Notification, Layout, Fetching} from "../../components";
import { Container, Row, IFrameContainer, Flex } from './styles'
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface IPathParams {
	salonId: string;
	appointmentId?: string;
}
export default function ResourcesScreen ({
    location,
	match,
	history
}: React.PropsWithChildren<
RouteComponentProps<IPathParams, StaticContext, any>
>) {
    const [ loading, setLoading ] = useState(true);
    const { salonId } = match.params;
    const [ expandedModule, setExpandedModule ] = useState(0);

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])
    
    const Accordion = withStyles({
        root: {
            boxShadow: 'none',
            padding: "0 24px",
            margin: "0 0 12px 0",
            border: '1px solid rgba(0, 0, 0, .125)',
            '&$expanded': {
                margin: "0 0 12px 0"
            },
        },
        expanded: {
            
        },
    })(MuiAccordion);
    
    const AccordionSummary = withStyles({
        root: {
            backgroundColor: 'transparent',
            padding: 0,
            marginBottom: -1,
            minHeight: 56,
            '&$expanded': {
                minHeight: 56,
            },
        },
        content: {
            display:'block',
            '&$expanded': {
                margin: '12px 0',
            },
        },
        expanded: {
        },
    })(MuiAccordionSummary);
    
    const AccordionDetails = withStyles((theme) => ({
        root: {
            width: '100%',
            padding: 0,
            paddingBottom: 24
        }
    }))(MuiAccordionDetails);

	return (
		<Layout>
			<Notification />
			    <Container>
                    <h1>Looking for help? Find it here</h1>
                    <Row>
                        <div>
                            <IFrameContainer>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/o6BAi4rEdkg" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
                            </IFrameContainer>
                            <h3>60 second challenge</h3>
                            <p>Can you beat the clock?</p>
                        </div>
                        <div>
                            <IFrameContainer>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/4veIFARu_go" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
                            </IFrameContainer>
                            <h3>piiq Unboxing Wallmount</h3>
                            <p>DIY Installation (easier than IKEA)</p>
                        </div>
                        <div>
                            <IFrameContainer>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/V8xf946IA-U" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
                            </IFrameContainer>
                            <h3>piiq Unboxing Benchmount</h3>
                            <p>DIY Installation (easier than IKEA)</p>
                        </div>
                    </Row>
                    <Row>
                        <div style={{width: "50%" }}>
                            <h2>Need support?</h2>
                            <Flex>
                                <div>
                                    <a target="_blank" href="https://www.facebook.com/groups/piiqsupportgroup" style={{display: "block", background: "#4267B2", textAlign: "center", padding: "15px 20px", margin: "12px 0", textDecoration: "none", color: "white"}}>
                                        piiq Community Support
                                    </a>
                                </div>
                                <div>
                                    <a target="_blank" href="https://www.piiqdigital.com/contact" style={{display: "block", background: "#000", textAlign: "center", padding: "15px 20px", margin: "12px 0", textDecoration: "none", color: "white"}}>Technical Support</a>
                                </div>
                            </Flex>
                        </div>
                        <div style={{width: "50%"}}>
                            <h2>Get the piiq App for your clients</h2>
                            <Flex>
                                <div style={{width: "50%"}}>
                                    <a href='https://play.google.com/store/apps/details?id=com.piiq&utm_source=pma&utm_campaign=resources_page&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img style={{width:"100%"}} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                                </div>
                                <div style={{width: "50%"}}>
                                    <a href="https://apps.apple.com/us/app/piiq/id1458062799?itsct=apps_box_badge&amp;itscg=30200" ><img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1560124800&h=2c58aed6d4a0825c00059113cb7fe8dd" alt="Download on the App Store" style={{borderRadius: "13px", width: '100%', height: 'auto', padding: '12px 20px'}}/></a>
                                </div>
                            </Flex>
                        </div>
                    </Row>
                    <Row> 
                        <div>
                        <h2>Start up guide</h2>
                        <a target="_blank" href="https://piiq-uploads-us-west-2-prod.s3.us-west-2.amazonaws.com/resources/piiq_start_up_guide.pdf">Click here to download the piiq Start Up Guide</a>
                        </div>
                    </Row>
                    <Accordion expanded={expandedModule === 1} onChange={() => {if (expandedModule === 1) {setExpandedModule(-1)} else {setExpandedModule(1)}}}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}><h2>Download images for social media</h2></AccordionSummary>
                        <AccordionDetails><a target="_blank" href="https://www.dropbox.com/sh/3bhyuk9s93cybeu/AADhDiY7uhIFJODulHuiWhpNa?dl=0">Click here to view images you can use for social media</a></AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expandedModule === 2} onChange={() => {if (expandedModule === 2) {setExpandedModule(-1)} else {setExpandedModule(2)}}}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}><h2>Download videos for social media</h2></AccordionSummary>
                        <AccordionDetails>
                            <div>
                                <a target="_blank" href="https://www.dropbox.com/sh/qmsr5yv1c6l0bsp/AAAQXYXYwnPdCtM3a9HZfa4Oa?dl=0">Click here to view videos you can use for social media</a>
                                <h2>Captions for product feature videos</h2>
                                <h3>piiq Hair Analysis</h3>
                                <p>Does that sheen of fresh salon hair fade away too fast? By understanding your hair with piiq’s hair analysis, you can give it exactly what it needs to stay looking amazing in between appointments.<br />Download the piiq app now </p>
                                <h3>piiq paint</h3>
                                <p>Ever wanted to try on a new colour or shade but we’re worried you wouldn’t like it afterwards? With piiq paint, you can try out colours before committing to them!<br />Download the piiq app now </p>
                                <h3>piiq Persona</h3>
                                <p>1. Sick of communication breakdown? With piiq, you can rest easy that your mood, style and taste is understood by your hairdresser every time.<br />Download the piiq app now</p>
                                <p>2. Discover hair vibes you never knew you had. With piiq persona your mood, style, and taste is communicated without you needing to explain a thing.<br />Download the piiq app now</p>
                                <p>3. What’s your vibe? Beachy, corporate, chic? Piiq Persona helps you discover hair vibes you never knew you had! <br />Download the piiq app now </p>
                                <h3>Piiq 360</h3>
                                <p>Nailed your look? piiq’s 360 not only lets you see your perfect finished look from every angle, but saves it so we can nail it for you every time.<br />Download the piiq app now </p>
                                <h3>Celebrity match</h3>
                                <p>1. Lost your lust for locks? Time to get inspired with piiq’s celebrity match! Download the piiq app now</p>
                                <p>2. Who wore it best, you, or Jennifer Aniston? You, or Taylor Swift? It’s time to get inspired by your celebrity doppelganger with piiq’s celebrity match! Download the piiq app now </p>
                                <h3>Product recommendations</h3>
                                <p>What if every online purchase came with an expert recommendation? Every session, piiq creates a personalized hair care regimen, with products specifically tailored to your unique hair type.<br />Download the piiq app now</p>
                                <h3>piiq Launch Teaser</h3>
                                <p>It’s time for us to connect in a whole new way. Download the piiq app now</p>
                            </div>         
                        </AccordionDetails>
                    </Accordion>
                    {/* <Accordion expanded={expandedModule === 3} onChange={() => {if (expandedModule === 3) {setExpandedModule(-1)} else {setExpandedModule(3)}}}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}><h2>Download assets for your website</h2></AccordionSummary>
                        <AccordionDetails>
                            <a target="_blank" href="">Click here to view assets you can use on your website</a>  
                        </AccordionDetails>
                    </Accordion> */}
                    
                    <Accordion expanded={expandedModule === 4} onChange={() => {if (expandedModule === 4) {setExpandedModule(-1)} else {setExpandedModule(4)}}}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}><h2>Texting & emailing clients</h2></AccordionSummary>
                        <AccordionDetails>
                            <div>
                                <p>The piiq App is your clients’ passport to the ultimate salon experience. </p>
                                <ul>
                                    <li>Seamless check-in experience</li>
                                    <li>360° records</li>
                                    <li>Retail recommendations</li>
                                    <li>24/7 In-App purchasing</li>
                                </ul>
                                <p>To make it easy for your clients to experience piiq, send them a link in their appointment reminder (there’s some copy you can copy and paste below).</p>
                                <p>You might also want to let them know, by email, how cool their next visit will be when they start to Reflect the Future with you using piiq.</p>
                                <p>Feel free to edit and tweak this to suit your salon, then simply copy and paste into your email/text service and send it out.</p>
                                <h3>Text messages</h3>
                                <code>Looking forward to seeing you at 00:00 on xx/xx/xxxx for your appointment with (stylist). For a magical consultation experience, please download the piiq app here before your appointment, create an account and follow the prompts https://piiqdigital.com/download-piiq-app</code>
                                <h3>Emails</h3>
                                <code>Dear (client name),<br /><br />
                                We can't wait to see you in our salon again soon!<br /><br />
                                We're always looking for ways to make your salon visit more magical, and we have something new that we think will blow your mind.<br /><br />
                                For the ultimate consultation experience, including an in-depth advanced hair analysis, cutting-edge face shape analysis, AR colour try-on, personalised haircare recommendations and so much more, make sure you ask for a piiq consultation at your next appointment. You can also book in for an 'Advanced piiq Consultation & Hair Analysis' as a separate service too!<br /><br />
                                To get started, download the piiq app here https://piiqdigital.com/download-piiq-app - create your piiq persona to get started, and we’ll see you soon to show you the rest of the magic in the salon!</code>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    
                    <Accordion expanded={expandedModule === 5} onChange={() => {if (expandedModule === 5) {setExpandedModule(-1)} else {setExpandedModule(5)}}}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}><h2>Posting on social media</h2></AccordionSummary>
                        <AccordionDetails>
                        <div>
                            <p>It’s time to get your clients hyped up about piiq! Share the feature videos on your social media channels, feel free to tag us @piiqsmartsalon too. We’ve even created some ready to use captions for you but of course feel free to use your own!</p>
                            <h3>Caption inspo</h3>
                            <ul>
                                <li><code>Reflect the future</code></li>
                                <li><code>Salon of the future</code></li>
                                <li><code>The Future is now</code></li>
                                <li><code>The future looks good on you. Take a seat</code></li>
                                <li><code>Have we piiqed your interest?</code></li>
                                <li><code>piiq is one of the first in the world using a brand new technology to understand your hair needs more deeply so together we can create even better hair days.</code></li>
                                <li><code>Let us wow you with the most amazing hi-tech consultation using our new state-of-the-art smart mirror!</code></li>
                                <li><code>Your hair has unique needs – we want you to have better hair for longer, so we are now using advanced technology (designed and built right here in Australia) to help us help you.</code></li>
                                <li><code>We have an amazing new smart mirror to give you even more confidence to try a new look.</code></li>
                                <li><code>We can’t wait to WOW you at your next visit.</code></li>
                                <li><code>Our new smart mirror will magically share your hair recommendations to your phone!</code></li>
                                <li><code>This amazing new smart mirror, designed and developed right here in Australia will help you see your hair through our eyes, and give you the confidence to try new looks. Not only that but because your hair has unique needs, all our recommendations, and a record of your visit can be saved in your phone.</code></li>
                                <li><code>Come into our salon to experience how our smart mirror analyses your hair and matches you with look-alike celebrities, so you can try a new style with confidence.</code></li>
                                <li><code>A surprise is waiting for you on your next visit: a mirror with brains that analyses your hair and suggests new looks.</code></li>
                                <li><code>What is a smart mirror? Come into the salon and get your hi-tech hair analysis and celebrity match.</code></li>
                                <li><code>What is a smart mirror? A hi-tech tool to give you the confidence to try new looks - you can even try on new colours digitally before making the commitment!</code></li>
                            </ul>
                            <h2>Hashtags</h2>
                            <code>#piiqsmartsalon #piiqdigital #piiq #smartmirror #reflectthefuture #futureisnow #salonconsultation #salonprofessional #haircolour #piiqpaint #piiqpersona #hairsalon #piiqacademy #seekpiiq #smartconsultation #piiqapp #salonofthefuture #redken #matrix #pureology #shuuemura #kerastase #lorealpro #hair #getpiiq #piiqportrait #hairsalon #piiqhairanalysis #piiqcelebritymatch #piiqhairinspo #piiqboard #piiqshopping #piiq360</code>
                        </div>
                        </AccordionDetails>
                    </Accordion>
                </Container>
			<Fetching />
		</Layout>
	);
}
