import React, { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { CheckboxContainer } from "./styles";

interface IInputProps {
	name: string;
	checked?: boolean;
	onChange?: Function;
	disabled?: boolean;
}

const CheckboxComponent = (props: IInputProps) => {
	const handleChange = () => {
		props.onChange && props.onChange();
	};

	return (
		<CheckboxContainer>
			<Checkbox
				checked={props.checked}
				onChange={handleChange}
				value="primary"
				inputProps={{ "aria-label": "primary checkbox" }}
			/>
		</CheckboxContainer>
	);
};

export default CheckboxComponent;
