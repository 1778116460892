import React, { Component } from "react";
import {
  Button,
  TextField,
  Typography,
  Grid,
  InputAdornment
} from "@material-ui/core";
import { Done, VisibilityOff, Visibility } from "@material-ui/icons";
import Auth from "@aws-amplify/auth";
import { FormattedMessage } from "react-intl";
import { ClearLocalStorage } from "../../../utils"
import mixpanel from 'mixpanel-browser';

// component
import "./../style.css";

//types
interface Props {
  toggleView(view: string): void;
}

interface IState {
  email: string;
  error: string;
  password: string;
  passwordIsMasked: boolean;
  step: string;
  user: any;
  validEmail: boolean;
  loading: boolean;
}

export default class SignInForm extends Component<Props, IState> {
  state = {
    user: null,
    email: "",
    error: "",
    password: "",
    passwordIsMasked: true,
    step: "signin",
    validEmail: false,
    loading: false
  };

  componentDidMount = () => {
    this.checkAuthState();
  }

  
	checkAuthState = async () => {
		try {
			const cognitoUser = await Auth.currentAuthenticatedUser();
			console.log('cognitoUser', cognitoUser)
		} catch (e) {
			console.log("Authenticator::CheckUser:Error:", e);
		}
	}


  togglePasswordMask = () => {
    this.setState(prevState => ({
      passwordIsMasked: !prevState.passwordIsMasked
    }));
  };

  onChangeEmail = (e: any) => {
    const email: string = e.target.value;
    const validEmail = email.match(/^.+\@.+\..+$/) !== null;
    this.setState({ email: email.toLowerCase(), error: "", validEmail });
  };

  onChangePassword = (e: any) => {
    this.setState({ error: "", password: e.target.value });
  };

  onHandleSubmit = async (e: any) => {
    e.preventDefault(e);
    this.setState({loading:true})
    const { email, password } = this.state;
    
    if (email === "") {
      this.setState({ error: "Please enter an email" });
      return;
    }

    if (password === "") {
      this.setState({ error: "Please enter a password" });
      return;
    }

    try {
      const cognitoUser = await Auth.signIn(email.trim(), password);
      console.log("SignIn::Submit::Success:", cognitoUser);
      mixpanel.identify(email.trim());
      if (
        cognitoUser &&
        cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED"
      ) {
        this.setState({ user: cognitoUser, step: "newpassword" });
      }
      this.setState({loading:false})
    } catch (e) {
      //const error = e.message;
      const error =
        "Sorry, either your email or your password is wrong. Please try again or contact us at info@piiqdigital.com";
      console.log("SignIn::Submit::Error:", error, e.message);
      this.setState({ error, password: "", loading: false });
    }
  };

  render() {
    const { step, password, loading, passwordIsMasked, email, validEmail } = this.state;
    const { toggleView } = this.props;
    return (
      <>
        {step === "signin" && (
          <form onSubmit={this.onHandleSubmit}>
            <Grid container direction="column" spacing={3} alignItems="stretch">
              <Grid item >
                <Typography
                  variant="h5"
                  align="center"
                  style={{
                    fontFamily: "titleFont",
                    textTransform: "uppercase"
                  }}
                >
                </Typography>
              </Grid>
              <Grid item >
                <TextField
                  placeholder="Email"
                  name="email"
                  value={email}
                  variant="outlined"
                  onChange={this.onChangeEmail}
                  style={{ width: "100%" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {email && validEmail ? (
                          <Done style={{ cursor: "pointer" }} />
                        ) : (
                          " "
                        )}
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  placeholder="Password"
                  name="password"
                  value={password}
                  variant="outlined"
                  onChange={this.onChangePassword}
                  style={{ width: "100%" }}
                  type={passwordIsMasked ? "password" : "text"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {passwordIsMasked ? (
                          <VisibilityOff
                            style={{ cursor: "pointer" }}
                            onClick={this.togglePasswordMask}
                          />
                        ) : (
                          <Visibility
                            style={{ cursor: "pointer" }}
                            onClick={this.togglePasswordMask}
                          />
                        )}
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item>
                <p
                  style={{
                    fontSize: "0.8rem",
                    width: "80%",
                    textTransform: "none"
                  }}
                >
                  {" "}
                  {this.state.error}{" "}
                </p>
              </Grid>
              <Grid item>
                <button
                  type="submit"
                  style={{
                    fontFamily: "piiqType",
                    width: "100%",
                    height: "40px",
                    fontSize: "16px",
                    textAlign: "center",
                    transitionDuration: "0.4",
                    backgroundColor: loading ? "grey" : "black",
                    color: "white",
                    cursor: "pointer",
                    border: 0
                  }}
                  onClick={this.onHandleSubmit}
                  disabled={loading}
                >
                  {loading ? (
                    <>Signing in...</>
                  ) : (
                    <FormattedMessage
                      id="button.signin"
                      defaultMessage={`Sign In`}
                    />
                  )}
                  
                </button>
              </Grid>
              <Grid item>
                <Grid container spacing={8} justify="space-around">
                  {/* <Grid item>
                <Button onClick={() => toggleView("signup")}>Sign Up</Button>
              </Grid> */}
                  <Grid item >
                    <Button
                      onClick={() => toggleView("forgot")}
                      style={{
                        textTransform: "none",
                        textDecoration: "underline"
                      }}
                    >
                      <FormattedMessage
                        id="button.forgotpassword"
                        defaultMessage={`I forgot my password`}
                      />
                      ?
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
        {/* {step === "newpassword" && (
          <NewPasswordRequired user={this.state.user} />
        )} */}
      </>
    );
  }
}
