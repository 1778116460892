import React, { useEffect } from "react";
import Page from "./../../components/Page";
import Button from "@material-ui/core/Button";
import { Terms, Footer, Inner } from "./styles";
import { useLocalStorage } from "../../utils";
import { CACHE } from "../../config";

interface ITermsAndConditionsProps {
	termsAndConditions: string | null;
	handleAgree(agree: boolean): void;
}

export default function TermsAndConditionScreen (
	props: ITermsAndConditionsProps
) {
	const [ appointmentsCache, setAppointmentsCache ] = useLocalStorage(
		CACHE.APPOINTMENTS,
		[]
	);

	useEffect(() => {
		/* Clean previous Salon data */
		setAppointmentsCache([]);
		document.title = "Terms and conditions";
	}, []);

	const { termsAndConditions, handleAgree } = props;

	return (
		<Terms>
			<Inner>
				<div dangerouslySetInnerHTML={{ __html: termsAndConditions || "" }} />
				<Footer>
					<Button
						variant="outlined"
						onClick={() => {
							handleAgree(false);
						}}
						style={{ marginRight: "10px" }}>
						DisAgree
					</Button>
					<Button
						variant="outlined"
						onClick={() => {
							handleAgree(true);
						}}>
						Agree
					</Button>
				</Footer>
			</Inner>
		</Terms>
	);
}
