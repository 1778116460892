import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { ReactComponent as Logo } from "../../../assets/Images/logo.svg";
import { CSSTransition } from 'react-transition-group';
import DownloadPlaystore from "../../../assets/Images/download_playstore.png";
import DownloadAppstore from "../../../assets/Images/download_appstore.png";
import AWS from 'aws-sdk';
import "../style.css";
import {
	startGetProfile
} from "../../../actions/appointmentsActions";
import { useAppointments } from "../../../contexts/AdminContext";
import Auth from "@aws-amplify/auth";
import { Link } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";

AWS.config.update({
	accessKeyId: 'AKIA3U3BDIH2EPCIWMW2', secretAccessKey: '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', region: 'us-west-2'
});
const dynamoDB = new AWS.DynamoDB.DocumentClient();


//types
interface Props {
  toggleView(view: string): void;
  setCurrentAppointment(appointment: any): void;
  animateInProp: boolean;
  userEmail: string;
  userId: string;
}

const Appointments = (props: Props) => {
  const [ appointmentState, appointmentDispatch ] = useAppointments();
  const [ animateIn, setAnimateIn ] = useState('false');
  const [ _appointments, setAppointments ] = useState([]) as any[];
  const [ loadingAppointments, setLoadingAppointments ] = useState(true);
  
	const { appointments, loading } = appointmentState;


  useEffect(() => {
    if (props.userId) {
      setAnimateIn(props.animateInProp ? 'true' : 'false' )
      window.scrollTo(0, 0);
      appointmentDispatch(startGetProfile());
    } else {
      console.log("NO USER ID?")
      props.toggleView('signin')
    }
  }, [])
  
  const signOut = async () => {
    let r = await Auth.signOut();
    props.toggleView('landing')
    window.location.reload() 
  }

  return (
    <>
    <CSSTransition
      in={animateIn === 'true'}
      timeout={300}
      classNames="opacity">
    <Grid container direction="column" spacing={10} alignItems="stretch">
      <Grid item>
        <Grid container direction="row" justify="space-between">
            <Grid item>
              <Logo width={95} style={{marginTop: 20}}/>
            </Grid>
            <Grid item>
              <Link to={"/signup"} style={{color:'white', textDecoration: 'none'}}>
                <ListItem button onClick={signOut} style={{marginTop: 20, padding: 0, color: "white", fontSize: 24, textTransform: "uppercase", textDecoration: 'none'}}>
                  <FormattedMessage id="menu.signout" defaultMessage={`LOG OUT`} />
                </ListItem>
              </Link>
            </Grid>
          </Grid>
        <h1 id="title">
          Appointments
        </h1>
        {loading ? (
          <>Loading...</>
        ) : (!appointments || appointments.length === 0) ? (
            <>
              <p>You haven't had any piiq appointments yet.</p> 
              <p><a href="https://www.piiqdigital.com/find-piiq" style={{color: 'white'}}>Click here to find your nearest piiq Smart Salon</a></p>
            </>
          ) : appointments.map((a:any) => {
          let options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
          let date = new Date(a.createdAt);
          return (
            <div onClick={() => {
              console.log('going to ', a.id)
              props.setCurrentAppointment(a);
              props.toggleView('appointmentDetails');
            }}
            style={{display:'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
              <div>
                <p>{date.toLocaleDateString("en-US", options)}<br />
                <span style={{color: "grey"}}>{a.staffName}</span></p>
              </div>
              <div>
                {'→'}
              </div>

            </div>
          )
        })}
        <hr style={{margin: "35px 0", opacity: 0.4}}></hr>
            <p style={{marginBottom: 24}}>Download the piiq app to keep your records in your pocket wherever you go.</p>
            <a href="http://apps.apple.com/au/app/piiq/id1458062799" target="_blank">
              <img
                src={DownloadAppstore}
                style={{
                  width: "48%",
                  height: "auto",
                  marginRight: '2%'
                }}
              />
            </a>
            <a href="http://play.google.com/store/apps/details?id=com.piiq&hl=en&gl=US" target="_blank">
              <img
                src={DownloadPlaystore}
                style={{
                  width: "48%",
                  height: "auto"
                }}
              />
            </a>
      </Grid>
    </Grid>
    </CSSTransition>
    </>
  );
}

export default Appointments;