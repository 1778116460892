import styled from "styled-components";
import { COLORS, SIZE, FONT, BREAKPOINTS } from "../../config";

interface iListrops {
	viewType?: "CLIENT_LIST" | "CLIENT";
	appointmentActive?: boolean;
}

const SMALL_VIEW = 850;

export const ClientsContainer = styled.div``;
export const Loader = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 60vh;
`;

export const ClientList = styled.div`
	margin: ${SIZE.LARGE}px 0;
	width: ${(props: iListrops) =>
		props.viewType === "CLIENT_LIST" || !props.appointmentActive
			? `100%`
			: `calc(100% - ${374}px)`};
	transition: width .15s ease;

	@media (max-width: 850px) {
		width: 100%;
	}
`;

export const Back = styled.button`
	font-size: ${FONT.SIZE.SMALL};
	border: 0;
	outline: none;
	padding: 0 0 2px;
	border-bottom: 1px solid ${COLORS.BLACK};
	background: ${COLORS.WHITE};
	cursor: pointer;

	svg {
		fill: ${COLORS.BLACK};
		stroke: ${COLORS.BLACK};
		width: 14px;
		height: 12px;
		top: 2px;
		left: -14px;
		position: absolute;
	}
	span {
		color: ${COLORS.BLACK};
		padding: 0 0 ${SIZE.TINY}px 2px;
	}
	&:hover {
		border-bottom: 1px solid ${COLORS.GREY_MID_BASE};
	}
`;

export const Header = styled.div`
	width: ${(props: iListrops) =>
		props.viewType === "CLIENT_LIST" || !props.appointmentActive
			? `calc(100% - ${SIZE.MEDIUM}px)`
			: `calc(100% - ${394}px)`};
	/* display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center; */
	@media (max-width: 850px) {
		width: calc(100% - ${SIZE.MEDIUM}px);
	}
`;

export const Sub = styled.div`
	height: ${SIZE.MEDIUM}px;
	margin-top: -${SIZE.MEDIUM}px;
`;

export const ListHeader = styled.div`
	display: flex;
	flex-direction: row;
	padding: ${SIZE.SMALL}px;
	
`;

export const HeaderRight = styled.div`
	position: absolute;
	right: 0;
	display: flex;
	flex-direction: row;

	div:nth-child(1) {
		width: 92px;
	}
	div:nth-child(2) {
		width: 140px;
	}
	div:nth-child(3) {
		width: 190px;
	}
	@media (max-width: ${BREAKPOINTS.TABLET}px) {
		display: none;
	}
`;
