import React, { useState, useEffect } from "react";
import { useProducts } from "../../contexts/AdminContext";
import { RouteComponentProps } from "react-router-dom";
import { StaticContext } from "react-router";
import { FormattedMessage } from "react-intl";
import {
	startGetAllSalonProducts,
	startClearAllProducts
} from "../../actions/productsActions";
import { IStateProducts } from "../../reducers/ProductsReducer";
import { Pagination, pageData } from "./../../components/Pagination";
import Loading from "./../../components/LoadingComponent";
import { Notification, Layout } from "../../components";
import { ProductItem } from "./ProductItem";

interface IPathParams {
	salonId: string;
}
interface ITokens {
	usedToken: string[];
}

export default function ProductsScreen ({
	location,
	match,
	history
}: React.PropsWithChildren<
	RouteComponentProps<IPathParams, StaticContext, any>
>) {
	const { limit, page } = pageData(location.search);
	const reducer = useProducts();
	const [ state, dispatch ] = reducer;
	const { salonId } = match.params;
	const { products } = state as IStateProducts;
	const { nextToken } = state;
	const [ prevToken, setPrevToken ] = useState() as any;
	const [ usedTokens, setUsedTokens ] = useState<ITokens["usedToken"]>([]);

	useEffect(
		() => {
			if (!products.length) dispatch(startGetAllSalonProducts(salonId));
		},
		[ salonId ]
	);

	useEffect(() => {
		document.title = "Products";
		return () => {
			// Clean product list on unmount
			// dispatch(startClearAllProducts(salonId));
		};
	}, []);

	useEffect(
		() => {
			if (products.length > 0) {
				if (nextToken && prevToken !== nextToken) {
					setPrevToken(nextToken);
					const used: string[] = [ ...usedTokens ];
					// Fetch more if we havn't already used the nextToken
					if (!(usedTokens.indexOf(nextToken) > -1)) {
						used.push(nextToken);
						setUsedTokens(used);
						dispatch(startGetAllSalonProducts(salonId, undefined, nextToken));
					}
				}
			}
		},
		[ products ]
	);

	if (state.loading) {
		return <Loading />;
	}

	const realPage = Math.max(page - 1, 0);
	const productSlice = state.products.slice(
		realPage * limit,
		(realPage + 1) * limit
	);

	return (
		<Layout>
			<Notification />
			<div style={{ width: "90%", margin: "auto" }}>
				<h1>
					<FormattedMessage
						id="products.pageTitle"
						defaultMessage={`Products`}
					/>
				</h1>
				<table style={{ width: "100%", marginTop: "30px" }}>
					<thead
						style={{
							textAlign: "left",
							textTransform: "uppercase",
							fontSize: "0.6rem"
						}}>
						<tr>
							<th
								style={{ padding: "20px", borderBottom: "1px solid #9E9D9E" }}>
								<FormattedMessage
									id="product.status"
									defaultMessage={`Status`}
								/>
							</th>
							<th style={{ borderBottom: "1px solid #9E9D9E" }} />
							<th style={{ borderBottom: "1px solid #9E9D9E" }}>
								<FormattedMessage
									id="product.productName"
									defaultMessage={`Product Name`}
								/>
							</th>
							<th style={{ borderBottom: "1px solid #9E9D9E" }}>
								<FormattedMessage id="product.msrp" defaultMessage={`MSRP`} />
							</th>
							<th style={{ borderBottom: "1px solid #9E9D9E", width: "40%" }}>
								<FormattedMessage
									id="product.customprice"
									defaultMessage={`Custom Price`}
								/>
							</th>
						</tr>
					</thead>
					<tbody>
						{productSlice.map(product => (
							<tr key={product.id}>
								<ProductItem product={product} />
							</tr>
						))}
					</tbody>
				</table>
				<div style={{ textAlign: "center" }}>
					<Pagination
						baseUrl={location.pathname}
						queryString={location.search}
						totalCount={products.length}
					/>
				</div>
			</div>
		</Layout>
	);
}
