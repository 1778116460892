import React, {useState, useEffect} from "react";
import { Input} from "../../components";

import {
	startGetAllProducts
} from "../../actions/productsActions";
import {
	startGetAllBrands
} from "../../actions/brandsActions";

import {
	EditProductDialog,
	Loading,
	ProductContainer,
	DialogHeader,
	DialogFilter,
	SearchInput
} from "./styles";
import { useProducts, useBrands, useSalons } from "../../contexts/AdminContext";
import { IStateProducts } from "../../reducers/ProductsReducer";
import { IStateBrands } from "../../reducers/BrandsReducer";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import NativeSelect from '@material-ui/core/NativeSelect';
import Button from "@material-ui/core/Button";
import LazyLoad from 'react-lazyload';
import Auth from "@aws-amplify/auth";
import { getAllSalons } from "../../actions/salonsActions";


const EditProductDialogComponent = (props:any) => {
	const reducer = useProducts();
	const [ state, dispatch ] = reducer;
	const { products } = state as IStateProducts;

	const brandsReducer = useBrands();
	const [ brandsState, brandsDispatch ] = brandsReducer;
	const { brands } = brandsState as IStateBrands;
	
	const salonsReducer = useSalons();
	const [ salonsState, salonsDispatch ] = salonsReducer;
	const [ salon, setSalon ] = useState() as any;
	const salonId = props.salonId;
	const { salons } = salonsState;

	const [email, setEmail] = useState('') as any;
	const [brandsVisibleToClients, setBrandsVisibleToClients] = useState([]) as any;
	const [brandAbbrevsVisibleToClients, setBrandAbbrevsVisibleToClients] = useState([]) as any;
	
	const [newProduct, setNewProduct] = useState(props.productBeingEdited) as any;
	const [searchProductName, setSearchProductName] = useState('') as any;
	const [filterBrand, setFilterBrand] = useState('') as any;
	const [filterType, setFilterType] = useState('') as any;
	const [filterGender, setFilterGender] = useState('') as any;

	const [loadingProducts, setLoadingProducts] = useState(true) as any;
	const [loadingBrands, setLoadingBrands] = useState(true) as any;
	const [loadingSalon, setLoadingSalon] = useState(true) as any;

	
	useEffect(
		() => {
			getAuth();
		},
	[]);

	useEffect(
		() => {
			if (salons && brands) {
				const s = salons.find(salon => salon.id === salonId) as any;
				if (s !== undefined) {
					setSalon(s);
					let _abbrevsOnly = [] as any[];
					for (let brand of brands) {
						if (s['BrandsVisibleToClients'].includes(brand["id"])) {
							_abbrevsOnly.push(brand.BrandAbbreviation)
						}
					}

					setBrandAbbrevsVisibleToClients(_abbrevsOnly);
					if (brands.length > 0) setLoadingBrands(false)
					if (s["BrandsVisibleToClients"].length > 0) setLoadingSalon(false)
				}
				console.log("THIS SALON", salons.find(salon => salon.id === salonId))
			}
		},
		[ salons && brands ]
	);


    const getAuth = async () => {
        try {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            console.log("Cognito User", cognitoUser.attributes.email);
            setEmail(cognitoUser.attributes.email);
			get(cognitoUser.attributes.email);
        } catch (e) {
            console.log("Authenticator::CheckUser:Error:", e.message);
        }
    }

    const get = async (email) => {
		if (!salons.length) salonsDispatch(getAllSalons());
		if (!products.length) dispatch(startGetAllProducts());
		if (!brands.length) brandsDispatch(startGetAllBrands());
    }


	useEffect(
		() => {
			if (products.length > 0) setLoadingProducts(false)
		},
	[products]);


	
	const onSearchProductNameChange = (value) => {
		setSearchProductName(value)
	}

	const checkProductName = (name) => {
		if (name?.toLowerCase().includes(searchProductName.toLowerCase())) {
			return true
		} else {
		}
	}

	return (
		<EditProductDialog>
			<Dialog
				aria-labelledby="customized-dialog-title"
				open={true}
				maxWidth="sm"
				scroll={'paper'}
			>
				{(!loadingProducts && !loadingBrands && !loadingSalon) ? (
					<>
						<DialogHeader>
							<div style={{display:'flex', width: "100%", justifyContent: "space-between", marginBottom: 12}}>
								<h2>Switch product</h2>
								<IconButton
									aria-label="Close"
									onClick={props.onClose}>
									<CloseIcon />
								</IconButton>
							</div>
							<Button 
								variant="outlined"
								style={{
									color: "black",
									border: 0,
									textTransform: "none",
									textDecoration: "underline",
									fontSize: "0.8rem",
									position: "relative",
									margin: 0,
									padding: 0
								}}
								onClick={() => props.onRemove(props.productBeingEdited)}>
									❌ Remove {props.productBeingEdited.productName}</Button>
						</DialogHeader>
						<DialogFilter>
							<Input
                                    inputStyle={{width: '100%', borderRadius: 0, marginBottom: 8}}
                                    name="email"
                                    onChange={onSearchProductNameChange}
                                    placeholder={"eg Smooth & Shine"}
                                    value={searchProductName}
                                    label="Search by product name or use filters."
                                />
							<div style={{marginBottom: 12}}>
								<NativeSelect onChange={(e) => setFilterBrand(e.target.value)}>
									<option value={''}>All brands</option>
									{salon && brands.map((brand, i) => {
										if (salon["BrandsVisibleToClients"].includes(brand["id"])) {
											return (<option value={brand.BrandAbbreviation} key={i}>{brand.BrandName}</option>)
										}
									})}
								</NativeSelect>
								<NativeSelect onChange={(e) => setFilterType(e.target.value)}>
									<option value={''}>All types</option>
									<option value={'Shampoo'}>Shampoo</option>
									<option value={'Conditioner'}>Conditioner</option>
									<option value={'Treatment'}>Treatment</option>
									<option value={'Styling'}>Styling</option>
								</NativeSelect>
								<NativeSelect onChange={(e) => setFilterGender(e.target.value)}>
									<option value={''}>All</option>
									<option value={'true'}>Men's</option>
								</NativeSelect>
							</div>
						</DialogFilter>
						<LazyLoad height={200} style={{display:'flex', flexWrap:'wrap', maxWidth: '100%', overflowY: "scroll", padding: 12, height: 300, maxHeight: "100%"}}>
						{products.filter((p) => 
								(p.Outofstock !== true) &&
								(p.isArchived !== true) &&
								(brandAbbrevsVisibleToClients.includes(p.Brand)) &&
								(checkProductName(p.ProductName) || searchProductName === '') &&
								(p.Brand === filterBrand || filterBrand === '') &&
								(p.ProductType === filterType || filterType === '') &&
								((p.ForMen === true && filterGender === 'true') || filterGender === '')
							).map((p,i) => {
							return (
								<ProductContainer key={i}>
									<div style={{width:80,height:100, marginRight: 20, textAlign: "center"}}>
										<img src={'https:'+p.ProductImageMain} />
									</div>
									<div>
										<p>{p.ProductName}</p>
										<p>{p.Brand}</p>
										<p>${p.RRPAU}</p>
										<Button 
											variant="outlined"
											style={{
												color: "black",
												fontSize: "0.8rem",
												borderRadius: "0px",
												position: "relative",
												padding: "2px 15px"
											}}
											onClick={() => {
													let _p = {
														bullets: p.Bullets,
														description: p.Description,
														listPrice: p.ListPrice,
														productImageMain: p.ProductImageMain,
														productName: p.ProductName,
														productSize: p.ProductSize,
														productType: p.ProductType,
														rrp: p.RRPAU,
														sku: p.SKU
													}
													setNewProduct(_p);
													props.onSave(_p, props.productBeingEdited)
												}}>
												Select</Button>
									</div>
								</ProductContainer>
							)	
						})}
						</LazyLoad>
					</>
				) : (
					<Loading>Loading...</Loading>
				)}
			</Dialog>
		</EditProductDialog>
	);
};

export default EditProductDialogComponent;
