import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { StaticContext, useParams } from "react-router";
import CircularProgress from "@material-ui/core/CircularProgress";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useAppointments } from "../../contexts/AdminContext";
import {
    startGetAllAppointments,
    getAllAppointments
} from "../../actions/appointmentsActions";
import { Layout, ClientListItem, AppointmentPanel, Notification, Fetching, H1 } from "../../components";
import { ClientsContainer, Loader, ClientList, Back, Header, ListHeader} from "./styles";
import { useLocalStorage } from "../../utils"
import { CACHE } from "../../config"
import { IAppointment } from "../../reducers/AppointmentsReducer";
import mixpanel from 'mixpanel-browser';

interface IPathParams {
	salonId: string;
	appointmentId?: string;
}

interface IClient {
    id: string;
    name: string;
    stylist: string;
    stylistId: string;
    lastAppointment: string;
    appointments: any;
    portrait:string | null;
}


export default function ClientScreen ({
    location,
	match,
	history
}: React.PropsWithChildren<
RouteComponentProps<IPathParams, StaticContext, any>
>) {
    const [appointmentsCache, setAppointmentsCache] = useLocalStorage(CACHE.APPOINTMENTS, []);
    const currentView : "CLIENT_LIST" | "CLIENT" = "CLIENT";
    const defaultAppointment: any = null;
	const [ state, dispatch ] = useAppointments();
	const { salonId } = match.params;
    const { loading, appointments } = state;
    const [ preloading, setPreloading ] = useState(true)
    const [ client, setClient ] = useState() as any;
    const { clientId } = useParams() as any;
    const [ viewType, setViewType ] = useState() as any;

    const [ currentAppointment, setCurrentAppointment] = useState(defaultAppointment)

	useEffect(() => {
        fetchAppointments();
        
        const params = new URLSearchParams(location.search);
        
	    if (params.get("appointmentId")) setCurrentAppointment(appointments.filter((a) => a.id === params.get("appointmentId"))[0])
        
        mixpanel.track("PMA Client details - Page accessed", { "Client ID" : clientId });
	}, []);

    useEffect(() => {
		if(appointments.length) {
            console.log("appointments.length...", appointments.length)
            setAppointmentsCache(appointments)
            filterClient();
        }
    }, [appointments]);
    
    const fetchAppointments = () => {
        console.log("FETCHING APPOINTMENTS...")
        dispatch(startGetAllAppointments(salonId));
    }

    const filterClient = () => {
        const clientList: IClient[] = [];
        const clientIds: string[] = [];
        
        setViewType(currentView);
        appointmentsCache.map(appt => {
            if (!appt.clientId) appt.clientId = 'none';
            const clientModel:IClient = {
                id: appt.clientId,
                name: appt.clientName,
                stylist: appt.staffName,
                stylistId: appt.staffId,
                lastAppointment: appt.createdAt,
                portrait: appt.clientSelfieImage,
                appointments: [appt]
            }
            /* Create the client record */
            if(!clientIds.includes(appt.clientId) && appt.clientId) {
                clientIds.push(appt.clientId)
                clientList.push(clientModel)
            }
            /* Update existing Client record */
            else {
                for (let i in clientList) {
                    if (clientList[i].id === appt.clientId) {
                        clientList[i] = {
                            ...clientModel,
                            appointments: [...clientList[i].appointments, appt]
                        }
                       break; //Stop this loop, record found.
                    }
                  }
            }
        })

        const clientData = clientList.filter((item) => item.id === clientId)
        setClient(clientData[0])
        for (let a of clientData[0].appointments) {
            console.log(a.id);
        }
        console.log("CLIENT", )
        setPreloading(false)
    }

    const onAppointmentPress = (appointment:IAppointment) => {
        setCurrentAppointment(appointment)
        mixpanel.track("PMA Client details - Drawer opened", { "Client ID" : clientId, "Client session ID" : appointment.id });
    }

    const onBackToList = () => {
        history.push(`/${salonId}/clients/`);
    }
    const onCloseAppointment = () => {
        setCurrentAppointment(null);
    }

	return (
		<Layout>
            <Notification />
			<ClientsContainer>
				<Header viewType={viewType} appointmentActive={currentAppointment}>
                    <Back onClick={onBackToList}><ChevronLeftIcon /><span>Back to Client Records</span></Back>
                    <H1>{client?.name}</H1>
                </Header>
                <ClientList viewType={viewType} appointmentActive={currentAppointment}>
				{(!preloading) && (client) ? (<>
                    <ListHeader>
                        {/* <div>#</div> */}
                        <div>Date</div>
                        <div>Stylist</div>
                    </ListHeader>
                    {   client && client.appointments.slice(0).reverse().map((appt, i) => <ClientListItem 
                            key={appt.id} 
                            id={appt.id}
                            name={client.name}
                            portrait={appt.clientSelfieImage}
                            index={i + 1}
                            appointments={client.appointments.length}
                            lastAppointment={appt.createdAt}
                            stylist={appt.staffName}
                            onPress={() => onAppointmentPress(appt)}
                            viewType={viewType}
                        />)}
                    <AppointmentPanel
                        appointment={currentAppointment}
                        salonId={salonId}
                        onCloseAppointment={onCloseAppointment}
                    />
				</>) : preloading ?
                <Loader>
					<CircularProgress/>
				</Loader> :
                <Loader>
                     <div>No client history</div>
                </Loader>
                }
                </ClientList>
                
			</ClientsContainer>
		</Layout>
	);
}
