import React, { useRef, useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import { FormattedMessage } from "react-intl";

import CircularProgress from "@material-ui/core/CircularProgress";
import { Button, Content, SkipButton } from "../../../../components";
import { SectionType, OnboardingDetails } from "../../types";
import { Error, Loader } from "./styles";
import mixpanel from 'mixpanel-browser';
import { useSalons, useBrands } from "../../../../contexts/AdminContext";
import { Brands, BrandCheckbox } from "../../../Settings/style"
import { IStateBrands } from "../../../../reducers/BrandsReducer";
import NativeSelect from '@material-ui/core/NativeSelect';
import { CheckCircle } from "@material-ui/icons";
import { getAllSalons } from "../../../../actions/salonsActions";
import { startGetAllBrands } from "../../../../actions/brandsActions";
import { startSetBrands } from "../../../../actions/salonsActions";
import { UpdateSalonAccount, GetSalonAccount } from "../../../../utils";
import Auth from "@aws-amplify/auth";
import { useParams } from 'react-router-dom';

interface IProps {
	onboardVersion: string;
	transitionConfig?: any;
	onNavigate: Function;
	onCompleteSection: Function;
	updateRegistrationState: Function;
	registrationDetails: OnboardingDetails;
	salonId: string;
}

const RetailComponent = (props: IProps) => {
	const params = useParams() as any;

	const contentScroll = useRef<HTMLDivElement>(null);
	const [ error, setError ] = useState("");
	const [ loading, setLoading ] = useState(false);
	const [ pref, setPref ] = useState('');
	const [ salonDetails, setSalonDetails ] = useState({}) as any;
	const [ email, setEmail ] = useState('');
	
	const reducer = useSalons();
	const [ state, dispatch ] = reducer;
	const [ salon, setSalon ] = useState() as any;
	const { salons } = state;

	const brandsReducer = useBrands();
	const [ brandsState, brandsDispatch ] = brandsReducer;
	const { brands } = brandsState as IStateBrands;
	
	useEffect(() => {
		if (contentScroll && contentScroll.current) {
			contentScroll.current.scrollTop = 0;
			window.scrollTo(0, 0);
		}
		dispatch(getAllSalons());
		getAuth();
		brandsDispatch(startGetAllBrands());
		mixpanel.track("PMA Onboarding - Retail - Page accessed event");
	}, []);

	useEffect(
		() => {
			if (params && params.salonId) {

			
			const s = salons.find(salon => salon.id === params.salonId) as any;
			if (s !== undefined) {
				setSalon(s);
				setPref(s["PreferredRecommendationBrand"])
				console.log('set pref', s["PreferredRecommendationBrand"])
			}
			console.log("THIS SALON", salons.find(salon => salon.id === params.salonId))
		} else {
			console.log('no params')
		}
		},
		[ salons ]
	);


    const getAuth = async () => {
        try {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            console.log("Cognito User", cognitoUser.attributes.email);
            setEmail(cognitoUser.attributes.email);
			get(cognitoUser.attributes.email);
        } catch (e) {
            console.log("Authenticator::CheckUser:Error:", e.message);
        }
    }

    const get = async (email) => {
        let out = await GetSalonAccount(email);
        console.log('r',out.data.response)
		setSalonDetails(out.data.response.salon);
        setLoading(false)
    }


	const proceedNextStep = () => {
		_updateSalonAccount()
		
	};
	const transitionConfig = useSpring(props.transitionConfig);

	const checkCheckboxAWS = (id) => {
		return salon["BrandsVisibleToClients"]?.includes(id)
	}

	const onBrandsVisibleChangeAWS = (id, checked) => {
		let _brands = salon["BrandsVisibleToClients"] as any[];
		console.log(id, checked, salon["BrandsVisibleToClients"]);
		if (_brands.includes(id) && checked) {
			console.log("REMOVING...")
			removeItemFromArray(_brands,id)
		} else if (!_brands.includes(id) && !checked) {
			console.log("ADDING...")
			_brands.push(id)
		}
		let _salon = {};
		_salon["BrandsVisibleToClients"] = _brands;
		console.log('_salon', _salon);
		setSalon({
			...salon,
			..._salon
		});
	}

	const removeItemFromArray = (arr,item) => {
		for( var i = 0; i < arr.length; i++){ 
			if ( arr[i] === item) { 
				arr.splice(i, 1); 
			}
		}
	}


	const _updateSalonAccount = async () => {
		
		setLoading(true)

		const result = await dispatch(
			startSetBrands(salon.id, salon["BrandsVisibleToClients"], pref)
		);
		setLoading(false);
		props.onNavigate(SectionType.COMPLETE);
		props.onCompleteSection();
	}

	return (
		<Content as={animated.div} style={transitionConfig} ref={contentScroll}>
			<h1>
				<FormattedMessage id="registration.retail.title" />
			</h1>
			<div className="margin-bottom-mid">
				<FormattedMessage id="registration.retail.body" />
			</div>
			<Brands>
			{ brands.map((brand, i) => { 
				if (salon && salon["BrandsAvailableToSalon"] && salon["BrandsAvailableToSalon"].includes(brand["id"])) {
					return (
						<BrandCheckbox 
							key={brand["_id"]}
							active={checkCheckboxAWS(brand["id"]) && !brand["BrandName"]?.includes("Coming")}
							onClick={() => {
								if (!brand["BrandName"]?.includes("Coming")) onBrandsVisibleChangeAWS(brand["id"], salon["BrandsVisibleToClients"]?.includes(brand["id"]))
							}}>
							{checkCheckboxAWS(brand["id"]) ? (<CheckCircle/>) : (<></>)}
							{brand["BrandName"]?.includes("Coming") ? (<div>Coming Soon</div>) : (<></>)}
							<img src={"https://piiq-images-us-west-2-prod.s3.us-west-2.amazonaws.com/brand-logos/"+brand["BrandAbbreviation"]+".png"} />
						</BrandCheckbox>
					)
				}
			})}
			</Brands>
			
			<h2>Preferred Retail Recommendation Brand</h2>
			<p>Select your preferred retail brand to be recommended by piiq Pro from the dropdown below.</p>
			<NativeSelect id="brands" value={pref} onChange={(e) => {setPref(e.target.value)}}>
				{/* { brands.map((brand, i) => <option value={brand["_id"]}>{brand["Brand Name"]}</option>) } */}
				{ brands.map((brand, i) => { 
				if (salon && salon["BrandsVisibleToClients"] && salon["BrandsVisibleToClients"].includes(brand["id"])) {
					return (
						<option value={brand["id"]}>{brand["BrandName"]}</option>
					)
				}
			})}
			</NativeSelect>
			{salon && salon["BrandsVisibleToClients"] && !salon["BrandsVisibleToClients"].includes(pref) && (
				<div style={{color:"red"}}>Make sure you've made your preferred brand available to clients above</div>
			)}
			{loading ? (
				<Loader>
					<CircularProgress />
				</Loader>
			) : (
				<Button onPress={proceedNextStep} label="Save" />
			)}
			<Error>{error && error}</Error>
			<SkipButton onPress={proceedNextStep} />
		</Content>
	);
};

export default RetailComponent;
