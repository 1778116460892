import React, { useState, useEffect } from "react";
import Auth from "@aws-amplify/auth";
import { NavPanel, SummaryCard } from "../../components";
import {
	CreateAccount,
	DeliveryDetails,
	Subscriptions,
	Payment,
	OrderDetails,
	Password
} from "./Sections";
import {
	SubscriptionState,
	OrderDetailsModel
} from "../../contexts/SubscriptionContext";
import { SummaryType, SubscriptionType, Plan } from "./types";
import { ReactComponent as PiiqLogo } from "../../assets/Images/logo.svg";
import { ReactComponent as MenuButton } from "../../assets/Images/burger.svg";

import { EnableBodyScrolling, CalcDiscount } from "../../utils";
import { Expander } from "../../components";
import {
	ScreenContainer,
	ContentContainer,
	SummaryContainer,
	SummaryContent,
	Link,
	MobileHeader,
	SummaryScroll,
	SectionContent,
	ContentHeader,
	Tablet,
	MenuIcon,
	MobileOverlay
} from "./styles";

interface IProps {}

const SubscriptionScreen = (props: IProps) => {
	const context = SubscriptionState();
	const section: SummaryType = SummaryType.SUBSCRIPTION;
	const [ currentSection, setCurrentSection ] = useState() as any;
	const [ completedSections, setCompletedSections ] = useState(0);
	const [ skippedSections, setSkippedSections ] = useState([]) as any[];
	const [ subscriptionDetails, setSubscriptionDetails ] = useState(
		OrderDetailsModel
	);
	const [ supplierCode, setSupplierCode ] = useState("");
	const [ supplierCodeDisabled, setSupplierCodeDisabled ] = useState(false);
	const [ orderTotal, setOrderTotal ] = useState(0);
	const [ orderDiscount, setOrderDiscount ] = useState(0);
	const transitionConfig = {
		to: { opacity: 1, transform: `translateY(0)` },
		from: { opacity: 0, transform: `translateY(20px)` }
	};
	const [ navpanelOpen, setNavpanelOpen ] = useState(false);

	useEffect(() => {
		setCurrentSection(section);
		updateSubscriptionState(context.state && context.state.orderDetails);
		// Disable body scroll
		EnableBodyScrolling(false);
		document.title = "piiq Registration";
		/* Signout of any current session to avoid conflicts */
		Auth.signOut();
		document.title = "Registration";

		const params = new URL(window.location.href).searchParams;
		const referral = params.get("referral");
		if (referral) {
			setSupplierCode(referral);
			setSupplierCodeDisabled(true)
		} 
	}, []);

	// USE THIS TO DEBUG DIFFERENT SECTIONS 
	// INSTEAD OF FILLING IN DETAILS EACH TIME

	const DEBUGSetContext = async () => {
		updateSubscriptionState({
			subscription_type: "MONTHLY",
			mirror_quantity: 2,
			bench_mount: 2,
			wall_mount: 0,
			colour_brand: "L'Oreal Professionnel",
			supplier_code: "",
			sub_total: 0,
			discount: 0,
			shipping: 0,
			total: 500
		});
		updateAccountContext();
		updateSalonContext();
	};

	const DEBUGSetContextAndGoToSection = async () => {
		DEBUGSetContext().then(() => {
			setCurrentSection(SummaryType.PASSWORD);
		});
	}

	const updateAccountContext = () => {
		context.dispatch({
			type: "UPDATE_ACCOUNT_DETAILS",
			accountDetails: {
				first_name: "tes",
				last_name: "tes",
				salon: "tes",
				email: "woods@piiqdigital.com",
				phone: "0400000000",
				news_opt_in: false
			}
		});
	};

	const updateSalonContext = () => {
		console.log('updateSalonContext')
		context.dispatch({
			type: "SET_REGISTER_PAYMENT",
			paymentDetails: {
				// password: '123456aA',
				id: "cbd00a8c-6e4a-4fa8-90b4-e1c364665895"
			}
		});
	};

	const onSectionChange = (section: SummaryType) => {
		setNavpanelOpen(false);
		setCurrentSection(section);
		updateOrderContext();
		if (section <= 1) {
			setSkippedSections([])
		}
	}; 

	const onCompleteSection = () => {
		const completed = completedSections + 1;
		setCompletedSections(completed);
	};

	const onSkipSection = (sections: any[]) => {
		const skipped = [...skippedSections, ...sections];
		setSkippedSections(skipped);
	};


	const updateSubscriptionState = (state: any) => {
		setSubscriptionDetails(state);
		console.log('setting subscription details', state)
		updateOrderContext();
	};

	const updateOrderContext = () => {
		console.log('updating order context', )
		context.dispatch({
			type: "UPDATE_ORDER_DETAILS",
			orderDetails: {
				...subscriptionDetails,
				supplier_code: supplierCode,
				total: orderTotal,
				discount: orderDiscount
			}
		});
	};

	const onSupplierCode = (code: string) => {
		setSupplierCode(code);
	};

	const onUpdateTotal = (value: number) => {
		setOrderTotal(value);
	};

	const onUpdateDiscount = (value: string) => {
		const numberValue = parseInt(value.replace("$", ""));
		setOrderDiscount(numberValue);
	};

	const renderSection = () => {
		switch (currentSection) {
			case SummaryType.SUBSCRIPTION:
				return (
					<Subscriptions
						onNavigate={onSectionChange}
						onCompleteSection={onCompleteSection}
						onSkipSection={onSkipSection}
						onUpdateTotal={onUpdateTotal}
						transitionConfig={transitionConfig}
						updateSubscriptionState={updateSubscriptionState}
						updateOrderContext={updateOrderContext}
					/>
				);
			case SummaryType.ACCOUNT:
				return (
					<CreateAccount
						onNavigate={onSectionChange}
						onSkipSection={onSkipSection}
						onCompleteSection={onCompleteSection}
						transitionConfig={transitionConfig}
					/>
				);
			case SummaryType.DELIVERY:
				return (
					<DeliveryDetails
						onNavigate={onSectionChange}
						onSkipSection={onSkipSection}
						onCompleteSection={onCompleteSection}
						transitionConfig={transitionConfig}
					/>
				);
			case SummaryType.PAYMENT:
				return (
					<Payment
						onNavigate={onSectionChange}
						onSkipSection={onSkipSection}
						onCompleteSection={onCompleteSection}
						transitionConfig={transitionConfig}
						supplierCode={supplierCode}
					/>
				);
			case SummaryType.ORDER:
				return (
					<OrderDetails
						onNavigate={onSectionChange}
						onSkipSection={onSkipSection}
						onCompleteSection={onCompleteSection}
						transitionConfig={transitionConfig}
					/>
				);
			case SummaryType.PASSWORD:
				return (
					<Password
						onNavigate={onSectionChange}
						onSkipSection={onSkipSection}
						transitionConfig={transitionConfig}
					/>
				);
		}
	};

	const renderHeader = () => {
		switch (currentSection) {
			case SummaryType.ACCOUNT:
				return "Account";
			case SummaryType.SUBSCRIPTION:
				return "Subscription";
			case SummaryType.DELIVERY:
				return "Delivery";
			case SummaryType.PAYMENT:
				return "PIIQ ORDER";
			case SummaryType.ORDER:
				return "PIIQ ORDER";
			case SummaryType.PASSWORD:
				return "PIIQ ORDER";
		}
	};

	const onMobileNavOpen = () => {
		setNavpanelOpen(!navpanelOpen);
	};

	return (
		<ScreenContainer>
			<NavPanel
				type="SUBSCRIPTION"
				activeSection={currentSection}
				onSectionChange={onSectionChange}
				completedSections={completedSections}
				skippedSections={skippedSections}
				open={navpanelOpen}
			/>
			<ContentContainer>
				<MobileOverlay open={navpanelOpen} onClick={onMobileNavOpen} />
				<MobileHeader>
					<PiiqLogo />
					<MenuIcon onClick={onMobileNavOpen}>
						<MenuButton />
					</MenuIcon>
				</MobileHeader>
				{/* <ContentHeader>{renderHeader()}</ContentHeader> */}
				{/* <Tablet>
					<Expander>
						<SummaryCard
							onSupplierCode={onSupplierCode}
							supplierCode={supplierCode}
							subscriptionDetails={subscriptionDetails}
							supplierCodeDisabled={supplierCodeDisabled}
							currentSection={currentSection}
							updateTotal={onUpdateTotal}
							updateDiscount={onUpdateDiscount}
							desktop={false}
						/>
					</Expander>
				</Tablet> */}
				<SectionContent>{renderSection()}</SectionContent>
			</ContentContainer>
			{/* <SummaryContainer>
					<button onClick={() => { DEBUGSetContextAndGoToSection()}}>DEBUG</button> 
				<div className="summary-header">
					<div className="text-white text-uppercase text-small">
						Order Summary
					</div>
					<Link>
						<a href="mailto:info@piiqdigital.com">Contact Support</a>
						<span>→</span>
					</Link>
				</div>
				<SummaryScroll>
					<SummaryContent>
						<SummaryCard
							onSupplierCode={onSupplierCode}
							supplierCode={supplierCode}
							subscriptionDetails={subscriptionDetails}
							currentSection={currentSection}
							supplierCodeDisabled={supplierCodeDisabled}
							updateTotal={onUpdateTotal}
							updateDiscount={onUpdateDiscount}
							desktop={true}
						/>
					</SummaryContent>
				</SummaryScroll>
			</SummaryContainer> */}
		</ScreenContainer>
	);
};

export default SubscriptionScreen;
