import { API, graphqlOperation } from "aws-amplify";
import { loader } from "graphql.macro";
import { GraphQLResult } from "@aws-amplify/api/lib/types";
import {
  GetTermsOfService,
  GetTermsOfService_defaultTermsOfService
} from "../graphql/GetTermsOfService";
import {
  UpdateTermsOfService,
  UpdateTermsOfService_updateUser,
  UpdateTermsOfServiceVariables
} from "../graphql/UpdateTermsOfService";
import { UpdateUserInput } from "../graphql/globalTypes";
import {
  ITermsAndConditionsAction,
  ISetTermsAndConditionsAction,
  ISetUpdateTermsAndConditionsAction,
  ILoadingTermsAndConditionsAction
} from "../reducers/TermsAndConditionsReducer";
const GetTermsOfServiceGQL = loader("./../graphql/GetTermsOfService.graphql");
const UpdateTermsOfServiceGQL = loader(
  "./../graphql/UpdateTermsOfService.graphql"
);

export const setTermsOfServices = (
  termsAndConditions: GetTermsOfService_defaultTermsOfService,
  user: UpdateTermsOfService_updateUser
): ISetTermsAndConditionsAction => ({
  loading: false,
  type: "SET_TERMS_AND_CONDITIONS",
  termsAndConditions,
  user
});

export const startGetTermsOfServices = (
  locale: string,
  country: string,
  language: string
) => {
  return async (
    dispatch: React.Dispatch<ITermsAndConditionsAction>,
    state: any
  ) => {
    const result = (await API.graphql(
      graphqlOperation(GetTermsOfServiceGQL, {
        locale,
        country,
        language
      })
    )) as GraphQLResult;

    if (result.data) {
      const data = result.data as GetTermsOfService;
      if (data.me) {
        if (data.localeTermsOfService) {
          return dispatch(
            setTermsOfServices(data.localeTermsOfService, data.me)
          );
        }
        if (data.countryTermsOfService) {
          return dispatch(
            setTermsOfServices(data.countryTermsOfService, data.me)
          );
        }
        if (data.languageTermsOfService) {
          return dispatch(
            setTermsOfServices(data.languageTermsOfService, data.me)
          );
        }
        if (data.defaultTermsOfService) {
          return dispatch(
            setTermsOfServices(data.defaultTermsOfService, data.me)
          );
        }
      }
    }
  };
};

export const setUser = (
  user: UpdateTermsOfService_updateUser
): ISetUpdateTermsAndConditionsAction => ({
  type: "SET_USER",
  user
});

export const setLoading = (
  loading: boolean
): ILoadingTermsAndConditionsAction => ({
  type: "LOADING",
  loading
});

export const updateUserTermsOfServices = (
  id: string,
  input: UpdateUserInput
) => {
  return async (
    dispatch: React.Dispatch<ITermsAndConditionsAction>,
    state: any
  ) => {
    try {
      dispatch(setLoading(true));
      const result = (await API.graphql(
        graphqlOperation(UpdateTermsOfServiceGQL, {
          id,
          input
        } as UpdateTermsOfServiceVariables)
      )) as GraphQLResult;
      if (result.data) {
        const data = result.data as UpdateTermsOfService;
        console.log("Update terms executed");
        if (data.updateUser) dispatch(setUser(data.updateUser));
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setLoading(false));
    }
  };
};
