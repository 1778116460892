import React from "react";
import { ISalon } from "../../reducers/SalonsReducer";
import { Link } from "react-router-dom";
import mixpanel from 'mixpanel-browser';

interface ISalonProps {
  salon: ISalon;
}
export function SalonsListItem(props: ISalonProps) {
  const { name, timeZone, id, salonAdmin, salonClient } = props.salon;
  const fireMixpanel = (e) => {
    mixpanel.track("PMA Salons - Switch salon", { "Salon switched to" : name });
  }
  return (
    <>
      <td>
        <Link onClick={fireMixpanel} style={{color:'black'}} to={`/${id}`}>{(salonClient && !salonAdmin) && (<span style={{color:"grey"}}>[CLIENT] </span>)}{name}</Link>
      </td>
      <td> {timeZone} </td>
    </>
  );
}
