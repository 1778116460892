import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { StaticContext } from "react-router";
import { Layout, Notification, Fetching } from "../../components";
import { ChatEngine, ChatFeed } from 'react-chat-engine';
import { createOrGetUser } from "../../utils";
import { useSalons } from "../../contexts/AdminContext";
import { getAllSalons } from "../../actions/salonsActions";
import { ChatEngineContainer } from "./styles";
interface IPathParams {
	salonId: string;
}

export default function MessagingScreen ({
    location,
	match,
	history
}: React.PropsWithChildren<
RouteComponentProps<IPathParams, StaticContext, any>
>) {
    
	const reducer = useSalons();
	const [ state, dispatch ] = reducer;
	const { salons } = state;
	const { salonId } = match.params;
	const [ username, setUsername ] = useState() as any;
	const [ loading, setLoading ] = useState(true);
    
    
	useEffect(() => {
		dispatch(getAllSalons());
		document.title = "Messaging";
		console.log("THIS SALON", salons.find(salon => salon.id === salonId))
	}, []);

	useEffect(
		() => {
            if (salons.find(salon => salon.id === salonId)) {
                getUser(salonId, salons.find(salon => salon.id === salonId)?.name)
                console.log("THIS SALON", salons.find(salon => salon.id === salonId))
            } else {
                console.log("Cant find salon");
            }
		},
		[ salons ]
	);

    const getUser = async (username, name) => {
        let user = await createOrGetUser(username, name);
        if (user) {
            setLoading(false);
            console.log('user', user);
        } else {
            console.log("no user returned");
        }
        
    }


	return (
		<Layout>
            {loading ? (
                <></>
            ) : (
                <ChatEngineContainer>
                    <ChatEngine
                        projectID='4a5cfdb1-4683-458b-bb0e-2377cd9960ea'
                        userName={"Salon#"+salonId}
                        userSecret='123456aA'
                        renderNewChatForm={() => {}}
                        renderChatSettings={() => {}}
                        renderChatFeed={(chatAppState) => <ChatFeed {...chatAppState} />}
                    />
                </ChatEngineContainer>
            )}
		</Layout>
	);
}
