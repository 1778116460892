import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Input, Label, TextArea, Error, Container } from "./styles";

interface IInputProps {
    label?: string,
    placeholder?: string,
    onChange?: Function,
    onKeyDown?: any,
    value?: string,
    type?: string,
    name?: string,
    textarea?: boolean,
    error?: string | null,
    readonly?: boolean,
    autoCompleteOff?: string ,
    id?: string
    inputStyle?: any,
}

const InputComponent = (props: IInputProps) => {

  const onChange = (ev: any) => {
      const value = ev.target.value
      if (props.onChange) props.onChange(value, props.name && props.name)
  }

  if(props.textarea) return (
    <>
      <Label>{props.label}</Label>
      <TextArea 
        name="" 
        id="" 
        cols={30} 
        rows={10}
        onChange={onChange}
        value={props.value} 
        placeholder={props.placeholder} 
      />
    </>
  )

  if(props.type === 'phone') return (
    <>
      <PhoneInput
        country={"au"}
        placeholder="0000-000-000"
        onlyCountries={[ "au", "nz" ]}
        inputClass="phone-input"
        onChange={phone => {if (props.onChange) { props.onChange({ phone })}}}
        value={props.value}
      />
      {props.error && (<Error>{props.error}</Error>)}
    </>
  )
  return (
    <Container>
      <Label>{props.label}</Label>
      <Input
        id={props.id}
        style={props.inputStyle}
        autoComplete={props.autoCompleteOff ? "new-password" : "on"}
        readonly={props.readonly}
        name={props.name}
        error={props.error}
        type={props.type}
        value={props.value} 
        placeholder={props.placeholder} 
        onChange={onChange}
        onKeyPress={props.onKeyDown ? props.onKeyDown : (ev:any) => {}}
      />
      {props.error && (<Error>{props.error}</Error>)}
    </Container>
  );
};

export default InputComponent;
